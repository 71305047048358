export class EventMembershipTypeRate {
     
    constructor(data) {       
            
        this.id = data && data.id ? data.id : undefined;
        this.event = data && data.event ? data.event : undefined;
        this.all_members = data && data.all_members !== undefined ? data.all_members : false;
        this.non_members = data && data.non_members !== undefined ? data.non_members : false;
        this.membership_type = data && data.membership_type !== undefined ? data.membership_type : null;

        this.can_join_all_sessions = data && data.can_join_all_sessions !== undefined ? data.can_join_all_sessions : false;
        this.can_join_single_session = data && data.can_join_single_session !== undefined ? data.can_join_single_session : false;

        this.single_session_price = data && data.single_session_price ? data.single_session_price : undefined;
        this.single_session_refund = data && data.single_session_refund ? data.single_session_refund : undefined;
        this.single_session_refund_club_cancel = data && data.single_session_refund_club_cancel ? data.single_session_refund_club_cancel : undefined;

        this.all_sessions_price = data && data.all_sessions_price ? data.all_sessions_price : undefined;
        this.single_session_price_all_sessions = data && data.single_session_price_all_sessions ? data.single_session_price_all_sessions : undefined;
        this.all_sessions_refund = data && data.all_sessions_refund ? data.all_sessions_refund : undefined;
        this.all_sessions_refund_club_cancel = data && data.all_sessions_refund_club_cancel ? data.all_sessions_refund_club_cancel : undefined;
    }

    validate() {

        if (this.all_members && this.non_members)
            return false;

        if ((this.all_members || this.non_members) && this.membership_type)
            return false;
        
        if (!this.event) return false;

        return true;
    }

    init() {
        this.all_members = false;
        this.non_members = false;
        this.membership_type = null;
        this.can_join_all_sessions = false;
        this.can_join_single_session = false;
        this.single_session_price = null;
        this.single_session_refund = null;
        this.single_session_refund_club_cancel = null;
        this.single_session_price_all_sessions = null;
        this.all_sessions_refund = null;
        this.all_sessions_refund_club_cancel = null;
    }

    // Return an object that is ready for the server (add or patch)
    getEventMembershipTypeRateForServer() {

        const data = {
            event: this.event,
            all_members: this.all_members, 
            non_members: this.non_members,
            membership_type: this.membership_type,
            can_join_all_sessions: this.can_join_all_sessions,
            can_join_single_session: this.can_join_single_session
        };

        if (this.id) {
            data.id = this.id;
        }

        data["single_session_price"] = this.single_session_price == '' ? 0 : parseFloat(this.single_session_price);
        data["single_session_refund"] = this.single_session_refund == '' ? 0 : parseFloat(this.single_session_refund);
        data["single_session_refund_club_cancel"] = this.single_session_refund_club_cancel == '' ? 0 : parseFloat(this.single_session_refund_club_cancel);      

        data["single_session_price_all_sessions"] = this.single_session_price_all_sessions == '' ? 0 : parseFloat(this.single_session_price_all_sessions);
        data["all_sessions_refund"] = this.all_sessions_refund == '' ? 0 : parseFloat(this.all_sessions_refund);
        data["all_sessions_refund_club_cancel"] = this.all_sessions_refund_club_cancel == '' ? 0 : parseFloat(this.all_sessions_refund_club_cancel);

        return data;
    }
}