import { apiClient } from '@/utils/apiclient';
import { CourtGroupBookingRate } from '@/models';
import { CourtGroupBookingRateNonMemberDefaultId, CourtGroupBookingRateMemberDefaultId, CourtGroupBookingRateCoachesDefaultId } from '@/models';
import { IGetCourtGroupBookingRatesMutationPayload, IUpdateBookingRateMutationPayload, IUpdateBookingRateMembershipTypesMutationPayload, IUpdateCourtGroupBookingRatesAction } from './court-group-booking-rates.types';
        
export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        courtGroupId: "",
        bookingRates: [] as CourtGroupBookingRate[],
    },
    mutations: {

        getCourtGroupBookingRates(state, payload: IGetCourtGroupBookingRatesMutationPayload) {
            state.courtGroupId = payload.courtGroupId;
            state.bookingRates = payload.bookingRates;            
        },

        updateBookingRate(state, payload: IUpdateBookingRateMutationPayload) {
            const index = state.bookingRates.findIndex(c => c.id === payload.bookingRate.id);
            
            if(index == -1) {
                state.bookingRates.push(payload.bookingRate);
            }
            else {
                state.bookingRates.splice(index, 1, payload.bookingRate);
            }
        },

        updateBookingRateMembershipTypes(state, payload: IUpdateBookingRateMembershipTypesMutationPayload) {
            const selectedBookingRate = state.bookingRates.find(c => c.id === payload.membershipTypeBookingRate.booking_rate);
            
            const membershipTypeIndex = selectedBookingRate.member_rate_set.findIndex(c => c.id === payload.membershipTypeBookingRate.id);
            
            if(membershipTypeIndex == -1) {
                selectedBookingRate.member_rate_set.push(payload.membershipTypeBookingRate);
            }
            else {
                selectedBookingRate.member_rate_set.splice(membershipTypeIndex, 1, payload.membershipTypeBookingRate);
            }
        }
    },
    getters: {
        courtGroupBookingRates: state => (courtGroupId) => {
            if(state.courtGroupId != courtGroupId)
                return [];
            return state.bookingRates;
        }
    },
    actions: {
    
        getCourtGroupBookingRatesAction({ commit }, courtGroupId): Promise<CourtGroupBookingRate[]> {        
            return apiClient.courtGroupBookingRates(courtGroupId)
                .then((bookingRates) => {
                    commit("getCourtGroupBookingRates", { "courtGroupId": courtGroupId, "bookingRates": bookingRates });
                    return bookingRates;
                });
        },

        updateCourtGroupBookingRatesAction({ dispatch, commit }, payload: IUpdateCourtGroupBookingRatesAction): Promise<void> {

            // Nb: We need to do the validation here:
            // if(!payload.bookingRate.validate())
            //     return Promise.reject(new Error("Invalid booking rate details"));

            // First save the booking rate, then save all the membership type booking rates
            return apiClient.updateCourtGroupBookingRate(payload.courtGroupId, payload.bookingRate)
                .then((bookingRate) => {
                    commit("updateBookingRate", { 'bookingRate': bookingRate });

                    if(payload.membershipTypeId == CourtGroupBookingRateMemberDefaultId 
                        || payload.membershipTypeId == CourtGroupBookingRateNonMemberDefaultId
                        || payload.membershipTypeId == CourtGroupBookingRateCoachesDefaultId)
                        return Promise.resolve(); // nothing to save for this type of membership selection

                        // Nb: use the booking rate id that comes back from the update/create of the booking rate
                    return apiClient.updateMembershipTypeBookingRate(bookingRate.id, payload.membershipTypeId, payload.membershipTypeBookingRate);
                })
                .then(() => {
                    dispatch('getCourtGroupBookingRatesAction', payload.courtGroupId);
                })
        }

    }
}