
import { Club } from '@/models';   
import { IFoundClubMember } from '@/store/club/club-members.types';
import { SelectOption } from '@/models/select-option';
import moment, { Moment } from 'moment';
import SlamFanChampionshipNameEnum from '@/utils/enum/slamfan-championship-name-enum';   
import toastr from '@/utils/toast'; 

function handleAPIErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}

function getStandardAPIErrorText(response: Response) {
	if(response.status == 401)
		return "You are not authorised to perform this action";	
	return "An error occurred";
}

async function getAPIErrorText(response: Response) {

	// look at all the 4XX errors and look for either a .reason or text in the body of the response
	// if nothing is found, we default the text returned
	if (response.status > 399 && response.status < 500) {

		// we have a 4XX
		let msg = await response.json()
								   .then(data => {
									   if (data['reason']) return data.reason;
									   return data;
								   });

		// add default values if we have no text
		if (!msg || msg.length == 0) {
		
			// default messages
			if (response.status == 400) {
				msg = "You are not authorised to perform this action";
			}
		}

		return new Promise((resolve) => {
			resolve(msg);
		});
	}
	
	// Any http codes not proccessed above
	return new Promise((resolve) => {
		resolve("An error occured");
	});
}

const genders = [
	{
		Text: "Unspecified",
		Value: "U"
	}, 
	{
		Text: "Male",
		Value: "M"
	}, 
	{
		Text: "Female",
		Value: "F"
	}
];

const genderPreference = [
	{
		Text: "All",
		Value: "U"
	}, 
	{
		Text: "Male",
		Value: "M"
	}, 
	{
		Text: "Female",
		Value: "F"
	}
];

const coachTypes = [
	{
		Text: "Coach",
		Value: "C"
	}, 
	{
		Text: "Pro",
		Value: "P"
	}
];

const billingCycles = [
	{
    	text: "Yearly",
    	value: "A",
		frequency: "year",
	}, 
	{
    	text: "Monthly",
    	value: "M",
		frequency: "month"
	}
];

const playerSkillsGeneric = [
	{
		Text: "Beginner",
		Value: "Beginner"
	}, 
	{
		Text: "Intermediate",
		Value: "Intermediate"
	},
	{
		Text: "Advanced",
		Value: "Advanced"
	},
	{
		Text: "Professional",
		Value: "Professional"
	}
];

const daysOfWeekDictionary = { 
	'0': {
		id: 0,
		name: "Monday",
		short: "M"
	},
	'1': {
		id: 1,
		name: "Tuesday",
		short: "T"
	},
	'2': {
		id: 2,
		name: "Wednesday",
		short: "W"
	},
	'3': {
		id: 3,
		name: "Thursday",
		short: "Th"
	},
	'4': {
		id: 4,
		name: "Friday",
		short: "F"
	},
	'5': {
		id: 5,
		name: "Saturday",
		short: "Sa"
	},
	'6': {
		id: 6,
		name: "Sunday",
		short: "Su"
	}
};

const ntrpLabels = ["1.0","1.5","2.0","2.5","3.0","3.5","4.0","4.5","5.0","5.5","6.0","6.5","7.0"];

function formatDateToDateString(inputDate: string): string {
	// Note:  Javascript automatically assumes that a date is in UTC, and so it automatically converts them.  The date library we are using (moment.js) doesn't make this
	// assumption unless the date is in an ISO format and ends with "Z" (Zula === UTC).  We want to store dates on the server in native time (so whatever we throw
    // at them, which will be based on the user's timezone).  If any date is provided here in the parameter that DOES identify itself as UTC, it will be converted to local user time.
	if(inputDate == undefined)
		return "";
	return moment(inputDate).format("MMM DD YYYY");
}

function formatDateToDateStringNoYear(inputDate: string): string {
	// Note:  Javascript automatically assumes that a date is in UTC, and so it automatically converts them.  The date library we are using (moment.js) doesn't make this
	// assumption unless the date is in an ISO format and ends with "Z" (Zula === UTC).  We want to store dates on the server in native time (so whatever we throw
    // at them, which will be based on the user's timezone).  If any date is provided here in the parameter that DOES identify itself as UTC, it will be converted to local user time.
	if(inputDate == undefined)
		return "";
	return moment(inputDate).format("D MMM");
}

// Returns a Date or throws an exception if it's not a valid date in any format.
function parseDateString(val: string, countryCode: string): moment.Moment {
	// XXX the *proper* way to do this would be to add a date hint to the Country
	// model, so that the database can configure a list of formats applicable to 
	// that country.  But we can at least base this on a hint from countryCode.
	
	let date: moment.Moment;

	let matchResult  = val.match(/^\s*(\d\d\d\d)-(\d\d?)-(\d\d?)\s*$/);
	if (matchResult) {
		date = moment({ year: Number(matchResult[1]), month: Number(matchResult[2]) - 1, day: Number(matchResult[3]) });
	} else if (countryCode == "US") {
	    matchResult = val.match(/^\s*(\d\d?)\/(\d\d?)\/(\d\d\d\d)\s*$/);
            if (matchResult) date = moment({ year: Number(matchResult[3]), month: Number(matchResult[1]) - 1, day: Number(matchResult[2]) });
	    else throw "Invalid Date Format (US/ISO)";
	} else if (countryCode == "AU") {
	    matchResult = val.match(/^\s*(\d\d?)\/(\d\d?)\/(\d\d\d\d)\s*$/);
            if (matchResult) date = moment({ year: Number(matchResult[3]), month: Number(matchResult[2]) - 1, day: Number(matchResult[1]) });
	    else throw "Invalid Date Format (AU/ISO)";
	} else {
	    throw "Invalid Date Format (ISO)";
	}

        if (date && date.isValid() && date.year() >= 1900 && date.year() < 2100) {
	    return date;
	} else {
	    throw "Invalid Date";
	}
}

function secondsToHoursAndMinutes(seconds: number) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	
	const result = [] as string[];
	
	if (hours > 0) {
	  result.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
	}	
	if (minutes > 0) {
	  result.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
	}
	
	if (result.length === 0) {
	  return "0 minutes";
	}	
	return result.join(" and ");
  }

function getExtension(filename): string {
    const parts = filename.split('.');
    return parts[parts.length - 1].toLowerCase();
}

function commaJoin(inputArray): string {
	const listString = inputArray.join(", ");

	return listString.replace(/,(?=[^,]*$)/, ' and');
}

function createTimeIntervals(from, until, valueFormat, textFormat, intervalMinutes = 30): SelectOption[] {
	const intervalTime = moment(from);
	const intervals: SelectOption[] = [];
	const intervalsKeys: string[] = [];
  
	while(intervalTime <= until) {
		const key = intervalTime.format(valueFormat);
		const timeValue = { value : key, text : intervalTime.format(textFormat) };
		if (intervalsKeys.indexOf(key) == -1) {
			intervals.push(timeValue);
			intervalsKeys.push(key);
		}
	  	intervalTime.add(intervalMinutes, 'minutes');
	}

	return intervals;
}

// Return an arry of two elements, the earliest open and latest close for the club
function getClubMinMaxOpeningHours(club: Club): moment.Moment[] {

	const valueFormat = "HH:mm";

	// first element is earliest open, second is latest close
	const hours = [] as moment.Moment[];
	let open, close;

	// cycle through all the club opening hours
	club.hoursOfOperation.forEach(hop => {
		
		const o = moment(hop.open, valueFormat);
		const c = moment(hop.close, valueFormat);
		
		if (!open) {
			open = o;
		}
		else {
			if (o.isBefore(open)) open = o;
		}

		if (!close) {
			close = c;
		}
		else {
			if (c.isAfter(close)) close = c;
		}
	})

	hours.push(open);
	hours.push(close);

	return hours;
}

// Return an array of half hour times that are restircted to the club opening hours
// a full array of half hour time-slots are passed into this method, along with the club
function createHalfHourIntervalsRestrictToClub(club: Club): SelectOption[] {

	const valueFormat = "HH:mm";

	if (club.hoursOfOperation == null || club.hoursOfOperation.length == 0) {
		return createTimeIntervals(moment().startOf('day'), moment().endOf('day'), valueFormat, "h:mmA");
	}

	const hours = getClubMinMaxOpeningHours(club);
	return createTimeIntervals(hours[0], hours[1], valueFormat, "h:mmA");
}
function createFifteenMinuteIntervalsRestrictToClub(club: Club): SelectOption[] {

	const valueFormat = "HH:mm";

	if (club.hoursOfOperation == null || club.hoursOfOperation.length == 0) {
		return createTimeIntervals(moment().startOf('day'), moment().endOf('day'), valueFormat, "h:mmA", 15);
	}

	const hours = getClubMinMaxOpeningHours(club);
	return createTimeIntervals(hours[0], hours[1], valueFormat, "h:mmA", 15);
}

function validateEmail(email) { 
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
} 

function validateURL(value, requireProtocol = false) {
	let re = null as RegExp|null;

	if(requireProtocol)
		re = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/);
	else
		re = new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/);

    return re.test(value);
}

function isNumeric(value) {
	return !isNaN(parseFloat(value)) && isFinite(value);
}

function isSafari(window) {
	// Return true if the desktop browser is Safari
	// Ref:  window.safari !== undefined; [24/09/2021]
	return window.safari !== undefined;
}

// A paginator function for an array
// Uses array.slice so produces a shallow copy as a reuslt
// perfect for maintaining reference to objects in the original array
function paginator(items, current_page, per_page_items) {
	const page = current_page || 1,
	 	  per_page = per_page_items || 10,
	 	  offset = (page - 1) * per_page,

	paginatedItems = items.slice(offset).slice(0, per_page_items),
	total_pages = Math.ceil(items.length / per_page);

	return {
		page: page,
		per_page: per_page,
		pre_page: page - 1 ? page - 1 : null,
		next_page: (total_pages > page) ? page + 1 : null,
		total: items.length,
		total_pages: total_pages,
		data: paginatedItems
	};
}

// Does the string contain the substring?
// Return true if it does, false if not
function doesStringContainSubstring(str, query) {
	const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    return q && x >= 0;
}

// This will return an HTML string where the query string (if found)
// has been made bold in the original string
// Case insensitive search
// e.g.		boldQueryString('Maria', 'mar'); // "<b>Mar</b>ia"
//			boldQueryString('Almaria', 'Mar'); // "Al<b>mar</b>ia"
function boldQueryString(str, query) {

    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str;
    }
    const l = q.length;
    return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
}

// Search through the existing array of objects
// The objects must have a property defined by propName
// Find the query string in the property and return
function searchArrayAndFilter(items, query, propName) {
	if (!query || query.length == 0 || query == "") return [];	
	return items.filter(o => {
		return doesStringContainSubstring(o[propName], query)
	});
}

// Return the image URL for the club product category
// The category AND a list of all club product categories is passed in
function getClubProductCategoryImageURL(allCategories, category) {
	if (category == null || allCategories == null || allCategories.length == 0) {
		return require(`@/assets/images/club-store-icons/other.svg`);
	}
	// find the category in the list of lists that is all club products
	const c = allCategories.filter(x => x[0] == category);
	if (c) {
		return require(`@/assets/images/club-store-icons/${c[0][2]}.svg`);
	}
	return require(`@/assets/images/club-store-icons/other.svg`);
}

// Return the full card type string from the passed in letter
function getCardTypeName(cardType: string) {
	/*
	// Return the member price formatted
	CARD_BRAND_CHOICES = [(“V”, “Visa”),
		(“M”, “Mastercard”),
		(“D”, “Diners Club”),
		(“A”, “AMEX”),
		(“A”, “American Express”),
		(“J”, “JCB”),
		(“C”, “Discover”),
		(“U”, “Union Pay”)
	*/
	switch(cardType) {
		case "V":
			return "Visa";
		case "M":
			return "Mastercard";
		case "D":
			return "Diners Club";
		case "A":
			return "Amex";
		case "J":
			return "JCB";
		case "C":
			return "Discover";
		case "U":
			return "Union Pay";
		default:
		  	return "-";
	} 
}

// clean a string by removing all the commas
// used when exporting data
function cleanStringForExport(toExport: string, replaceWith = '') {
	if (!toExport || toExport == undefined || toExport == '') return '';
	toExport = toExport + '';
	return toExport.replaceAll(',', replaceWith).trim();
}

// extract the date component from the date/time server string
// 2023-01-30T09:10:42 = 2023-01-30
function cleanDateForExport(toExport: string) {
	if (!toExport) return '';
	return toExport.substring(0,10);
}

// extract the date/time component from the date/time server string
// 2023-01-30T09:10:42 = 2023-01-30 09:10
function cleanDateTimeForExport(toExport: string) {
	if (!toExport) return '';
	return toExport.substring(0,10) + ' ' + toExport.substring(11,16);
}

// return a currency amount to two decimal places
// 45 = 45.00
function cleanCurrencyForExport(toExport: number) {
	if (!toExport) return '0';
	return (Math.round(toExport * 100) / 100).toFixed(2);
}

// create a header for the export
function createHeaderForExport(exportArray: any[], headerText: string, club: Club|null) {
	if (!exportArray) return [];

	// 2/4/23 header not used currently
	if (!headerText || !club) return [];
	return exportArray;

	/*
	const exportDateTime = moment().format('MMMM Do YYYY, h:mm:ssa');

	exportArray.push(['']);
	exportArray.push(['----------------']);
	exportArray.push(['Str8 Sets Tennis (Data Export)']);
	if (club) exportArray.push(['Club: ' + club?.name]);
	exportArray.push(['' + headerText]);
	exportArray.push(['Export created: ' + exportDateTime]);
	exportArray.push(['----------------']);
	exportArray.push(['']);

	return exportArray;
	*/
}

// return the csv content to be exported to a file
function createCSVExportDataEncoded(exportArray: any[], replaceHash: boolean): string {
	if (!exportArray) return '';

	let csvContent = "data:text/csv;charset=utf-8,";
	exportArray.forEach(function(infoArray, index) {

		const dataString = infoArray.length > 0 ? infoArray.join(",") : infoArray[0];
		csvContent += index < exportArray.length ? `${dataString}\n` : dataString;
	});

	csvContent = replaceHash ? csvContent.replace(/#/g, "") : csvContent;
	return encodeURI(csvContent);
}

// export the provided data to CSV file
function exportToCSV(filename: string, data: string, document: Document) {
	if (!data || !document) {
		toastr.error("Failed to export data to CSV file");
		return;
	}

	const link = document.createElement("a");                        
	link.setAttribute("download", filename);
	link.setAttribute("href", data);
	document.body.appendChild(link);
	link.click();

	toastr.success("Data successfully exported to CSV file");
}

// return the export filename club part
// str8sets_export_club_name_
function returnExportFilnameCommon(club: Club|null) {
	const filename = 'str8sets_export_';
	if (!club) return filename;
	return filename + club?.name.toLowerCase().replaceAll(' ', '_');
}

// return the html which is used when showing the autocomplete results for
// club member or client search
function returnPlayerInfoHtml(playerInfo: IFoundClubMember) {
	if ((playerInfo.member_id && playerInfo.member_id >0) && (playerInfo.player_id && playerInfo.player_id > 0)) {
		return "<span class='main-identifier-chip member' style='margin: 4px 5px 4px 0;'><strong>Member</strong></span> " + playerInfo.contact + "<br/>"
				+ "<i>Member is registered on Str8 Sets</i>";
	}
	else if ((playerInfo.member_id && playerInfo.member_id >0) && (!playerInfo.player_id || playerInfo.player_id == 0)) {
		return "<span class='main-identifier-chip member' style='margin: 4px 5px 4px 0;'><strong>Member</strong></span> " + playerInfo.contact + "<br/>"
				+ "<i>Member is NOT registered on Str8 Sets</i>";
	}
	else if ((!playerInfo.member_id || playerInfo.member_id == 0) && (playerInfo.player_id && playerInfo.player_id > 0)) {
		return "<span class='main-identifier-chip nonmember' style='margin: 4px 5px 4px 0;'><strong>Non-Member</strong></span> " + playerInfo.contact + "<br/>"
				+ "<i>Player is registered on Str8 Sets</i>";
	}
	else {
		if (playerInfo.contact != null && playerInfo.contact != "") {
			return "<span class='main-identifier-chip nonmember' style='margin: 4px 5px 4px 0;'><strong>Non-Member</strong></span> " + playerInfo.contact + "<br/>"
					+ "<i>Player is NOT registered on Str8 Sets</i>";
		}
		return "<span style='color: #5f5f5f'><strong>Non-Member|Guest</strong></span><br/>"
					+ "<i>Use the player as typed in the search field</i>";
	}
}
function returnPlayerInfoHtmlSearch(playerInfo: IFoundClubMember) {
	if ((playerInfo.member_id && playerInfo.member_id >0) && (playerInfo.player_id && playerInfo.player_id > 0)) {
		return "<span class='main-identifier-chip member' style='margin: 4px 5px 4px 0;'><strong>Member</strong></span> " + playerInfo.contact + "<br/>"
				+ "<i>Member is registered on Str8 Sets</i>";
	}
	else if ((playerInfo.member_id && playerInfo.member_id >0) && (!playerInfo.player_id || playerInfo.player_id == 0)) {
		return "<span class='main-identifier-chip member' style='margin: 4px 5px 4px 0;'><strong>Member</strong></span> " + playerInfo.contact + "<br/>"
				+ "<i>Member is NOT registered on Str8 Sets</i>";
	}
	else if ((!playerInfo.member_id || playerInfo.member_id == 0) && (playerInfo.player_id && playerInfo.player_id > 0)) {
		return "<span class='main-identifier-chip nonmember' style='margin: 4px 5px 4px 0;'><strong>Non-Member</strong></span> " + playerInfo.contact + "<br/>"
				+ "<i>Player is registered on Str8 Sets</i>";
	}
	else {
		if (playerInfo.contact != null && playerInfo.contact != "") {
			return "<span class='main-identifier-chip nonmember' style='margin: 4px 5px 4px 0;'><strong>Non-Member</strong></span> " + playerInfo.contact + "<br/>"
					+ "<i>Player is NOT registered on Str8 Sets</i>";
		}
		return "<span style='color: #5f5f5f'><strong>Raw Text</strong></span><br/>"
					+ "<i>Use the text as typed and search</i>";
	}
}

// calculate the club OR court group minimum and maximum opening hours
function getMinMaxOpenHours(openHours: []) {
	const minTime = minOpeningTime(openHours);
	const maxTime = maxClosingTime(openHours);
	// return an array
	// [0] = min time
	// [1] = max time
	const times = [] as Moment[];
	times.push(minTime);
	times.push(maxTime);
	return times;
}

function minOpeningTime(openingHours): Moment{
                
	let minOpeningTime!: Moment;
	const openHoursFormat = 'H.m';

	openingHours.map((item) => {

		// update the minimum open time if appropriate
		const itemOpenTimeObj = moment(item.open, openHoursFormat);

		if(!minOpeningTime) {
			minOpeningTime = itemOpenTimeObj;
		}
		else {
			if(itemOpenTimeObj.isBefore(minOpeningTime)) {
				minOpeningTime = itemOpenTimeObj;
			}
		}
	});
	
	// default to midnight to midnight if no hours were set yet
	if(!minOpeningTime) {
		minOpeningTime = moment("0:00", openHoursFormat);
	}

	return minOpeningTime;
}

function maxClosingTime(openingHours): Moment{
	
	let maxClosingTime!: Moment;
	const openHoursFormat = 'H.m';

	openingHours.map((item) => {

		// update the minimum open time if appropriate
		const itemOpenTimeObj = moment(item.close, openHoursFormat);

		if(!maxClosingTime) {
			maxClosingTime = itemOpenTimeObj;
		}
		else {
			if(itemOpenTimeObj.isAfter(maxClosingTime)) {
				maxClosingTime = itemOpenTimeObj;
			}
		}
	});
	
	// default to midnight to midnight if no hours were set yet
	if(!maxClosingTime) {
		maxClosingTime = moment("23:30", openHoursFormat);
	}

	return maxClosingTime;
}

// Colour interpolation
function h2r(hex) {
	// Converts a #ffffff hex string into an [r,g,b] array
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}
function r2h(rgb) {
	// Inverse of the above
    return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

// Interpolates two [r,g,b] colors and returns an [r,g,b] of the result
// Taken from the awesome ROT.js roguelike dev library at
// https://github.com/ondras/rot.js
function interpolateColor(color1, color2, factor) {
  if (arguments.length < 3) { factor = 0.5; }
  const result = color1.slice();
  for (let i=0; i<3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i]-color1[i]));
  }
  return result;
}

// Return the image URL for the slam fan championship
function getSlamChampionshipLogoURL(name) {
	if (name == null) return "";
	
	switch(name) {
		case SlamFanChampionshipNameEnum.AUSTRALIAN_OPEN:
			return require(`@/assets/images/logo-slamfan-au-open.svg`);
		case SlamFanChampionshipNameEnum.FRENCH_OPEN:
			return require(`@/assets/images/logo-slamfan-french-open.svg`);
		case SlamFanChampionshipNameEnum.WIMBLEDON:
			return require(`@/assets/images/logo-slamfan-wimbledon.svg`);
		case SlamFanChampionshipNameEnum.US_OPEN:
			return require(`@/assets/images/logo-slamfan-us-open.svg`);
	}

	return "";
}

export default { 
	genders, 
	genderPreference,
	coachTypes, 
	billingCycles, 
	playerSkillsGeneric,
	daysOfWeekDictionary,
	ntrpLabels,
	handleAPIErrors, 
	getAPIErrorText,
	getStandardAPIErrorText, 	
	formatDateToDateString, 
	formatDateToDateStringNoYear, 
	parseDateString, 
	getExtension, 
	commaJoin, 
	createTimeIntervals, 
	validateEmail, 
	validateURL, 
	isNumeric, 
	isSafari,
	paginator,
	boldQueryString,
	searchArrayAndFilter,
	getClubProductCategoryImageURL,
	getCardTypeName,
	createHalfHourIntervalsRestrictToClub,
	createFifteenMinuteIntervalsRestrictToClub,
	getClubMinMaxOpeningHours,
	cleanStringForExport,
	cleanDateForExport,
	cleanDateTimeForExport,
	cleanCurrencyForExport,
	createHeaderForExport,
	createCSVExportDataEncoded,
	exportToCSV,
	returnExportFilnameCommon,
	returnPlayerInfoHtml,
	returnPlayerInfoHtmlSearch,
	getMinMaxOpenHours,
	h2r,
	r2h,
	interpolateColor,
	getSlamChampionshipLogoURL,
	secondsToHoursAndMinutes
}