<script lang="ts">
    import '@/assets/css/token-input-facebook.min.css';
    import '@/assets/css/token-input.min.css';
    import '@/utils/jquery.tokeninput.js';
    import $ from 'jquery';
    import Cookies from "js-cookie";
    import Vue from 'vue';

    export default Vue.extend({
        name: "TokenInput",
        data () {
            return {
                items: [] as any[],
                settings: this.hint !== null ? {hintText: this.hint} : {}  // Eventually: allow settings to be passed through as a prop
            }
        },
        props: {
            value: {
                type: Array,
                default: () => []
            },
            allowFreeTagging: {
                type: Boolean,
                default: false
            },
            localData: {
                type: Array,
                default: () => []
            },
            url: {
                type: String
            },
            urlRequiresAuth: {
                type: Boolean,
                default: false
            },
            queryParam: {
                type: String,
                default: "search"
            },
            jsonContainer: {
                type: String,
                default: ""
            },
            propertyToSearch: {
                type: String,
                default: 'name'
            },
            hint: {
                type: String,
                default: null
            }
        },
        watch: {
            value: {
                handler(val) {
                    this.items = val;
                },
                immediate:true
            },
            // items(val) {        // THIS DOESN'T APPEAR TO BE NEEDED AT THIS STAGE
            //     this.$emit("changed", val);
            // }
        },
        mounted() {
            // Implementation note: This setup relies on the prepopulation data having been set up.  
            // So the caller should avoid mounting this until it's data is actually ready.  For an example, see in court-booking-edit-series-form where the
            // court and coach data are intiailsed in it's mount, and need to be null before that so the token-input value is not mounted prematurely

            const url_or_data = this.url ? this.url : this.localData;
            $(this.$refs.tokenField as any).tokenInput(url_or_data, $.extend(this.settings, {  
                prePopulate: this.value,
                onAdd: (item) => {
                    this.items.push(item);
                    // this.$emit('add-item', item);
                },
                onDelete:(item) => {
                    this.$emit('delete-item', item);        // need to emit this to do teh delete in the caller, where it's typed
                },
                queryParam: this.queryParam,
                ajaxHeaders: this.urlRequiresAuth ? { "Authorization": "Token " + Cookies.get("authtoken") } : {},
                jsonContainer: this.jsonContainer,
                allowFreeTagging: this.allowFreeTagging,
                propertyToSearch: this.propertyToSearch,
                zindex: 99999,
                theme: 'facebook',
            }));
        }
    })
</script>

<style>

    ul.token-input-list-facebook {
        width: 100%;
        min-width: auto;
        min-height: 34px;
        vertical-align: middle;
        background-image: none;
        border: solid 1px #dee3e6;
        border-radius: 2px;
        background-color: #ffffff;
        color: #555555;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    li.token-input-token-facebook {
        background-color: #026823;
        border-color: #026823;
        color: #fff;
    }

    div.token-input-dropdown-facebook ul li.token-input-selected-dropdown-item-facebook {
        background-color: #026823;
        color: #fff;
    }

</style>

<template>

    <input type="text" class="form-control" ref="tokenField" />

</template>
