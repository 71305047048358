import { Player } from '../player';
import moment from 'moment';

export class Comment {
	constructor(data) {
	
		this.id = data.id;
		this.thread = data && data.thread ? data.thread : undefined;
		this.player = data && data.player ? new Player(data.player) : null;
		this.text = data && data.text ? data.text : "";
        this.stamp = data && data.stamp ? data.stamp : undefined;

	}

	// Return a string in the format name | date
	get comment_description() {
		// server time stamps are UTC. We convert to local 
		const dateUTC = moment.utc(this.stamp);
		const st = moment(dateUTC).local().calendar(); 
		return this.player.full_name + " | " + st;
	}

	is_comment_from_club(club) {		

		// property has not been set previously, let's set it and return the result
		if (club == null || club.admin_set.length == 0) {			
			return false;	
		}

		// cycle through the club admins to check player ID
		return club.admin_set.some(ca => ca.player.id == this.player.id);
	}
}
