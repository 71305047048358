
<script lang="ts">
    import AlertConfirmModal from '@/components/alert-confirm/alert-confirm-modal.vue';
    import Vue from 'vue';  

    export default Vue.extend({
        name: "AlertConfirmDialog",
        components: { 
            'alert-confirm-modal': AlertConfirmModal 
        },
        data: () => ({
            title: undefined as string|undefined,
            message: undefined as string|undefined, 
            yesButton: 'Yes' as string|undefined, 
            noButton: 'No' as string|undefined,   
            isAlert: false as boolean,
            resolvePromise: undefined as any|undefined
        }),
        methods: {
            _yes() {
                (this.$refs.confirm as any).close();
                if (this.resolvePromise) this.resolvePromise(true);
            },
            _no() {
                (this.$refs.confirm as any).close();
                if (this.resolvePromise) this.resolvePromise(false); 
            }
        },
        created() {
            // this is how the alert/confirm is shown, via the eventbus
            this.$modalBus.$on("show-alert-confirm", (options: IAlertConfirmDialogOptions, resolve) => {

                this.isAlert = options.isAlert;
                this.title = options.title;
                this.message = options.message;

                if (options.yesButton) {
                    this.yesButton = options.yesButton;
                }

                if (!this.isAlert) {
                    if (options.noButton) {
                        this.noButton = options.noButton;
                    }
                }
                else {
                    this.yesButton = "Ok";
                }

                (this.$refs.confirm as any).open();

                // resolve the promise
                this.resolvePromise = resolve;
            });
        }
    });
</script>

<style lang="scss" scoped>
    .btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        margin-top: 25px;
    }
    .alert-confirm-body {
        p {            
            color: #496B7B;
            font: normal 15px Open Sans;
            font-stretch: normal;
            text-align: left;
        }
        h2 {
            margin-top: 10px;
            color: #026823;
            font: bold 18px Open Sans;
            font-stretch: normal;
            text-align: left;
        }
    }
    .btn-right {
        float: right;
    }
    .btn-left {
        float: right;
    }
</style>

<template>
    <alert-confirm-modal ref="confirm" class="new-styles">
        <div class="alert-confirm-body">
            <h2>{{ title }}</h2>
            <p v-html=message></p>
        </div>
        <div class="btns">
            <button v-show="!isAlert" type="button" class="btn btn-no-margin-top btn-default btn-left" @click="_no">{{ noButton }}</button>
            <button type="submit" class="btn btn-no-margin-top btn-primary btn-right" @click="_yes" >{{ yesButton }}</button>
        </div>
    </alert-confirm-modal>
</template>