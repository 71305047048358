import { ClubMember } from "@/models/club/";
import { EventParticipantSession } from ".";
import { Player } from "@/models/player";

export class EventParticipant {
    
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;       
        this.player = data && data.player ? new Player(data.player) : null;
        this.created_by = data && data.created_by ? new Player(data.created_by) : null;
        this.admin_added = data && data.admin_added ? new Player(data.admin_added) : null;
        this.member = data && data.member ? new ClubMember(data.member) : null;

        this.agreed_terms = data && data.agreed_terms ? data.agreed_terms : null;

        this.name = data && data.name ? data.name : '';
        this.dob = data && data.dob ? data.dob : '';
        this.email = data && data.email ? data.email : '';
        this.number = data && data.number ? data.number : '';
        this.gender = data && data.gender ? data.gender : '';
        this.price = data && data.price !== undefined ? data.price : undefined;
        this.paying = data && data.paying !== undefined ? data.paying : false;
        this.all_sessions = data && data.all_sessions !== undefined ? data.all_sessions : false;
        this.notes = data && data.notes ? data.notes : ''; 
        this.session_set = data && data.session_set ? data.session_set : [];

        this.modified = data && data.modified ? data.modified : undefined;
        this.created = data && data.created ? data.created : undefined;

        this.sessionparticipant_set = [];
        if (data && data.sessionparticipant_set) {
            this.sessionparticipant_set = data.sessionparticipant_set.map((item) => new EventParticipantSession(item));
        }
    }    
}