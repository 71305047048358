import { Sport } from '..'; 
import { ClubEquipment } from '../court-booking/club-equipment';

export class ClubFeatureRulesCourt {
    constructor(data) {
        
        this.available_sports = [];
		if(data && data.available_sports) {
			this.available_sports = data.available_sports.map((item) => new Sport(item));
		}

        this.available_equipment = [];
        if(data && data.available_equipment) {
			this.available_equipment = data.available_equipment.map((item) => new ClubEquipment(item));
		}      
    }
}