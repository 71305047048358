<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'NumberArrowSelect',
        props: {
            value: {
                type: Number,
                default: 0
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                currentValue: 0
            }
        },
        watch: {
            value: {
                handler(val) {
                    this.currentValue = val;
                },
                immediate:true
            },
            currentValue(val) {
                this.$emit("input", val);
            }
        },
        methods: {
            change() {
                this.$emit("change", this.currentValue);
            },
            increment() {                
                this.currentValue++;
                this.change();
            },
            decrement() {
                if (this.currentValue == 0) return;
                this.currentValue--;
                this.change();
            }            
        }
    })
</script>

<style lang="scss" scoped>

    .container {
        display: inline-flex;
        height: 46px;
        width: 110px;
    }

    .input-field {
        height: 46px;
        width: 62px;
        background: #FFFFFF;
        border: 1px solid #DBDBDB;
        border-radius: 8px;
        font: bold 19px/20px Open Sans;
        color: #026823;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    input:disabled {
        color: #afafaf;
    }
    input:focus {
        outline: none;
    }

    .arrow-selector-container {
        float: right;
        height: 100%;
        margin-left: 10px;
    }

    .arrow {        
        background-size: 23px !important;
        height: 23px;
        width: 23px;
        margin: 0px;
        cursor: pointer;

        &.up {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            background: url('~@/assets/images/arrow-left-green.svg') 0% 0% no-repeat;
        }

        &.down {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            background: url('~@/assets/images/arrow-right-green.svg') 0% 0% no-repeat;
        }
    }

</style>

<template>
    <div class="new-styles container">
        <input
            id="number-arrow"
            class="input-field"
            :disabled="disabled"
            @change="change"
            readonly
            v-model="currentValue" />
        <div v-show="!disabled" class="arrow-selector-container">
            <div class="arrow up" @click="increment" />
            <div class="arrow down" @click="decrement"/>
        </div>
    </div>
</template>