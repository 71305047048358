import { ClubPackage } from '.';

export class PackageForClub {

	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.expires = data && data.expires ? data.expires : undefined;
		this.package  = data && data.package ? new ClubPackage(data.package) : null;
		this.billing_cycle = data && data.billing_cycle ? data.billing_cycle : undefined;
	}
}
