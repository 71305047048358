import { apiClient } from '@/utils/apiclient';
import { ClubCoach } from '@/models';
import { IGetClubCoachesMutationPayload, IAddClubCoachMutationPayload, IUpdateClubCoachMutationPayload, IDeleteClubCoachMutationPayload } from './club-coaches.types';

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        clubId: "",
        clubCoaches: [] as ClubCoach[],
    },
    mutations: {

        getCoaches(state, payload: IGetClubCoachesMutationPayload) {
            state.clubId = payload.clubId;
            state.clubCoaches = payload.coaches;            
        },

        addCoach(state, payload: IAddClubCoachMutationPayload) {
            state.clubCoaches.push(payload.coach)
        },

        updateCoach(state, payload: IUpdateClubCoachMutationPayload) {
            const index = state.clubCoaches.findIndex(c => c.id === payload.coach.id);
            state.clubCoaches.splice(index, 1, payload.coach);
            state.clubCoaches = [...state.clubCoaches]; // replace coaches
        },

        deleteCoach(state, payload: IDeleteClubCoachMutationPayload) {
            state.clubCoaches = [...state.clubCoaches.filter(c => c.coach.id !== payload.coachId)];
        }
    },
    getters: {
        clubCoaches: state => (clubId) => {
            if(state.clubId != clubId)
                return [];
            return state.clubCoaches;
        }
    },
    actions: {

        getClubCoachesAction({ commit }, clubId): Promise<ClubCoach[]> {        
            return apiClient.clubCoaches(clubId)
                .then((coaches) => {
                    commit("getCoaches", { "clubId": clubId, "coaches": coaches });
                    return coaches;
                });
        },

        addClubCoachAction({ commit }, payload: IAddClubCoachMutationPayload): Promise<ClubCoach> {
            return apiClient.clubAddCoach(payload.coach)
                .then((coach) => {
                    commit("addCoach", { "coach": coach });
                    return coach;
            });
        },

        updateClubCoachAction({ commit }, payload: IUpdateClubCoachMutationPayload): Promise<ClubCoach> {
            return apiClient.clubEditCoach(payload.coach)
                .then((coach) => {
                    commit("updateCoach", { "coach": payload.coach });
                    return coach;
            });
        },

        deleteClubCoachAction({ commit }, payload: IDeleteClubCoachMutationPayload): Promise<void> {
            return apiClient.clubDeleteCoach(payload.coachId, payload.clubId)
                .then(() => {
                    commit("deleteCoach", { "coachId": payload.coachId });
                    return;
            });
        }
    }
}