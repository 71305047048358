import Utilities from '@/utils/utilities';

/*** Player object used by the bulk player API - a simplifed version of the Player object which includes a flag for organiser ***/
export class PlayerBulk {
    constructor(data)
    {
        this.id = data && data.id ? data.id : undefined;
        this.first_name = data && data.first_name ? data.first_name : "";
        this.last_name = data && data.last_name ? data.last_name : "";
        this.email = data && data.email ? data.email : "";
        this.mobile = data && data.mobile ? data.mobile : "";
        this.gender = data && data.gender ? data.gender : "U";
    }

    get full_name() {
		return this.first_name + " " + this.last_name;
    }
    
    validate(isMixedTournament) {

        if(!this.first_name)
            return false;

        if(!this.last_name)
            return false;

        if(!this.email || !Utilities.validateEmail(this.email))
            return false;

        if(!isMixedTournament && (this.gender == "" || this.gender == "U"))    // for non-mixed tournaments, the gender MUST be provided
            return false;

        return true;
    }
}