import { Coach } from '..';

export class ClubCoach {
	constructor(data) {

		this.id = data && data.id ? data.id : undefined;
		this.club = data && data.club ? data.club : undefined;	
		this.coach = data && data.coach ? new Coach(data.coach) : null;
		this.ttype = data && data.ttype ? data.ttype : "C" ;
		this.student_gender = data && data.student_gender ? data.student_gender : "U" ;
		this.min_student_age = data && data.min_student_age !== undefined ? data.min_student_age : 4;
		this.max_student_age = data && data.max_student_age !== undefined ? data.max_student_age : 99;
		this.min_ntrp = data && data.min_ntrp !== undefined ? data.min_ntrp : 1.0;
		this.max_ntrp = data && data.max_ntrp !== undefined ? data.max_ntrp : 5.5;
		this.max_hours_day = data && data.max_hours_day !== undefined ? data.max_hours_day : 10;
		this.advance_hours_cancel = data && data.advance_hours_cancel !== undefined ? data.advance_hours_cancel : 0;
		this.advance_days_booking = data && data.advance_days_booking !== undefined ? data.advance_days_booking : 999;
		this.can_book_recurring = data && data.can_book_recurring !== undefined ? data.can_book_recurring : true;
		this.generic_levels = data && data.generic_levels ? data.generic_levels : [];
	}

	get full_name() {
		if(!this.coach) {
			return "";
		}
		return this.coach.full_name;
	}

	get first_name() {
		if(!this.coach || !this.coach.player) {
			return "";
		}
		return this.coach.player.first_name;
	}

	get last_name() {
		if(!this.coach || !this.coach.player) {
			return "";
		}
		return this.coach.player.last_name;
	}

	get type_long() {
		if (this.ttype == 'P') {
			return "Pro";
		}
		return "Coach";
	}

	// Return a list of coach objects from the passed in club coaches list 
	getCoachSet(clubCoaches) {
		if (!clubCoaches || clubCoaches.length == 0) return [];
		return Array.from(clubCoaches).map(c => c.coach);

	}
}
