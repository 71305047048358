import { apiClient } from '@/utils/apiclient';
import { Tournament, Team, Member } from '@/models';
import { IGetTournamentsMutationPayload, IUpdateTournamentMutationPayload, IUpdateTournamentMembersMutationPayload, IUpdateTournamentTeamsMutationPayload, IUpdateTournamentTeamMutationPayload, 
    IDeleteTournamentMemberMutationPayload, IGetTournamentsActionPayload, IGetTournamentActionPayload, ISaveTournamentActionPayload, ISaveTournamentDataActionPayload, ITriggerTournamentDrawActionPayload, 
    ITriggerTournamentPublishActionPayload, IGetTournamentTeamsActionPayload, IAddTournamentTeamsActionPayload, ISaveTournamentTeamActionPayload, IGetTournamentMembersActionPayload, 
    ISaveTournamentMembersActionPayload, IUpdateTournamentMemberActionPayload, ISaveTournamentMemberActionPayload, IDeleteTournamentMemberActionPayload } from './tournaments.types';

class State {
    tournamentsBrief: Tournament[] = [];
    nextTournamentsURL = "";
    prevTournamentsURL = "";
    tournamentsTotal = 0;
    currentPage = 1;
}

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: new State(),
    mutations: {

        getTournaments(state: State, payload: IGetTournamentsMutationPayload) {
            state.tournamentsBrief = payload.tournaments;            
        },
        
        getnextTournamentsURL(state: State, next) {
            state.nextTournamentsURL = next;
        },
        
        getprevTournamentsURL(state: State, prev) {
            state.prevTournamentsURL = prev;
        },
        
        getTournamentsTotal(state: State, total) {
            state.tournamentsTotal = total;
        },

        updateTournament(state, payload: IUpdateTournamentMutationPayload) {
            const index = state.tournamentsBrief.findIndex(c => c.id === payload.tournament.id);
            
            if(index == -1) {
                state.tournamentsBrief.push(payload.tournament);
            }
            else {
                state.tournamentsBrief.splice(index, 1, payload.tournament);
            }
        },

        updateTournamentMembers(state, payload: IUpdateTournamentMembersMutationPayload) {
            const tournament = state.tournamentsBrief.find(c => c.id === payload.tournamentId);
            
            if(tournament) {
                payload.members.forEach((member) => {

                    // update/add each member individually - this may not be a full set of tournament members
                    const memberIndex = tournament.member_set.findIndex(i => i.id == member.id);

                    if(memberIndex >= 0)
                        tournament.member_set.splice(memberIndex, 1, member);
                    else 
                        tournament.member_set.push(member);
                })
            }
        },

        updateTournamentTeams(state, payload: IUpdateTournamentTeamsMutationPayload) {
            const tournament = state.tournamentsBrief.find(c => c.id === payload.tournamentId);
            
            if(tournament) {
                tournament.team_set = payload.teams;
            }
        },

        updateTournamentTeam(state, payload: IUpdateTournamentTeamMutationPayload) {
            const tournament = state.tournamentsBrief.find(c => c.id === payload.tournamentId);
            
            if(tournament) {
                const tournamentIndex = tournament.team_set.findIndex(i => i.id == payload.team.id);

                if(tournamentIndex >= 0)
                    tournament.team_set.splice(tournamentIndex, 1, payload.team);
                else 
                    tournament.team_set.push(payload.team);
            }
        },

        deleteTournamentMember(state, payload: IDeleteTournamentMemberMutationPayload) {
            const tournament = state.tournamentsBrief.find((item) => { return item.id == payload.tournamentId });
    
            tournament.member_set = [...tournament.member_set.filter(c => c.id !== payload.memberId)];
        },

        nextPage(state: State) {
            state.currentPage++;
        },

        prevPage(state: State) {
            state.currentPage--;
        }
    },
    getters: {
        tournaments: state => state.tournamentsBrief,
        tournamentsTotal: (state: State) => state.tournamentsTotal,
        hasNextPage: (state: State) => (state.nextTournamentsURL && state.nextTournamentsURL.length > 0) ? true : false,
        hasPrevPage: (state: State) => (state.prevTournamentsURL && state.prevTournamentsURL.length > 0) ? true : false,
        currentPage: (state: State) => state.currentPage,
    },
    actions: {

        getTournamentsAction({ commit }, payload: IGetTournamentsActionPayload): Promise<Tournament[]> {        
            return apiClient.tournaments(true, payload.playerId, payload.pageNumber, payload.pageSize)
                .then((result) => {
                    const tournaments = result.results.map(
                        (item: any) => new Tournament(item)
                    );

                    commit("getTournaments", { "tournaments": tournaments });
                    commit("getnextTournamentsURL", result.next);
                    commit("getprevTournamentsURL", result.previous);
                    commit("getTournamentsTotal", result.count);

                    return tournaments;
                });
        },

        getNextPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.nextTournamentsURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Tournament(item)
                    );
                    commit("getTournaments", { "tournaments": results });
                    commit("getnextTournamentsURL", result.next);
                    commit("getprevTournamentsURL", result.previous);
                    commit("getTournamentsTotal", result.count);
                    commit("nextPage");

                    return results;
                })
        },

        getPrevPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.prevTournamentsURL)
            .then(result => {
                const results = result.results.map(
                    (item: any) => new Tournament(item)
                );
                commit("getTournaments", { "tournaments": results });
                commit("getnextTournamentsURL", result.next);
                commit("getprevTournamentsURL", result.previous);
                commit("getTournamentsTotal", result.count);
                commit("prevPage");

                return results;
            })
        },

        getTournamentAction({ commit }, payload: IGetTournamentActionPayload): Promise<Tournament> {        
            return apiClient.tournament(payload.tournamentId)
                .then((result) => {
                    commit("updateTournament", { "tournament": result });
                    return result;
                });
        },

        saveTournamentAction({ commit }, payload: ISaveTournamentActionPayload): Promise<Tournament> {
            return apiClient.saveTournament(payload.tournament)
                .then((result) => {
                    commit("updateTournament", { "tournament": result });
                    return result;
                })
        },
        
        saveTournamentDataAction({ commit }, payload: ISaveTournamentDataActionPayload): Promise<Tournament> {
            return apiClient.saveTournamentData(payload.tournamentData, payload.tournamentId)
                .then((result) => {
                    commit("updateTournament", { "tournament": result });
                    return result;
                })
        },

        triggerTournamentDrawAction({ commit }, payload: ITriggerTournamentDrawActionPayload): Promise<Tournament> {            
            return apiClient.triggerTournamentDraw(payload.tournamentId)
                .then((result) => {
                    commit("updateTournament", { "tournament": result });
                    return result;
                })
        },

        triggerTournamentPublishAction({ commit }, payload: ITriggerTournamentPublishActionPayload): Promise<Tournament> {            
            return apiClient.triggerTournamentPublish(payload.tournamentId)
                .then((result) => {
                    commit("updateTournament", { "tournament": result });
                    return result;
                })
        },

        getTournamentTeamsAction({ commit }, payload: IGetTournamentTeamsActionPayload): Promise<Team[]> {
            return apiClient.tournamentTeams(payload.tournamentId)
                .then((result: Team[]) => {
                    commit("updateTournamentTeams", { teams: result, tournamentId: payload.tournamentId });
                    return result;
                });
        },

        addTournamentTeamsAction({ commit }, payload: IAddTournamentTeamsActionPayload): Promise<Team[]> {
            return apiClient.addTournamentTeams(payload.teamsData)
                .then((result: Team[]) => {
                    if(result.length > 0)
                        commit("updateTournamentTeams", { teams: result, tournamentId: result[0].tournament });
                    return result;
                })
        },

        saveTournamentTeamAction({ commit }, payload: ISaveTournamentTeamActionPayload): Promise<Team> {
            return apiClient.saveTournamentTeam(payload.teamData, payload.teamId)
                .then((result: Team) => {
                    if(result)
                        commit("updateTournamentTeam", { team: result, tournamentId: result.tournament });
                    return result;
                })
        },

        getTournamentMembersAction({ commit }, payload: IGetTournamentMembersActionPayload): Promise<Tournament> {        
            return apiClient.tournamentMembers(payload.tournamentId)
                .then((result: Member[]) => {
                    if(result.length > 0)
                        commit("updateTournamentMembers", { members: result, tournamentId: result[0].tournament });
                    return result;
                });
        },

        saveTournamentMembersAction({ commit }, payload: ISaveTournamentMembersActionPayload): Promise<Member[]> {
            return apiClient.saveTournamentMembers(payload.membersData)
                .then((result: Member[]) => {
                    if(result.length > 0)
                        commit("updateTournamentMembers", { members: result, tournamentId: result[0].tournament });
                    return result;
                })

        },
        
        updateTournamentMemberAction({ commit }, payload: IUpdateTournamentMemberActionPayload): Promise<Member> {
            return apiClient.updateTournamentMember(payload.member)
                .then((result: Member) => {
                    if(result)
                        commit("updateTournamentMembers", { members: [ result ], tournamentId: result.tournament });
                    return result;
                });
        },

        saveTournamentMemberDataAction({ commit }, payload: ISaveTournamentMemberActionPayload): Promise<Member> {
            return apiClient.saveTournamentMemberData( payload.memberData, payload.memberId)
                .then((result: Member) => {
                    if(result)
                        commit("updateTournamentMembers", { members: [ result ], tournamentId: result.tournament });
                    return result;
                })
        },

        deleteTournamentMemberAction({ commit }, payload: IDeleteTournamentMemberActionPayload): Promise<void> {
            return apiClient.deleteTournamentMember(payload.memberId)
                .then(() => {
                    commit("deleteTournamentMember", { memberId: payload.memberId, tournamentId: payload.tournamentId });
                })
        },

    },
}