import { apiClient } from '@/utils/apiclient';
import { Player } from '@/models';
import CurrentPlayerService from '@/utils/services/current-player-service';
import moment from 'moment';

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        currentPlayer: null as Player|null
    },
    mutations: {
        setCurrentPlayer(state, player) {
            state.currentPlayer = player;
        },

        addClubToCurrentPlayerAdmin(state, club) {
            state.currentPlayer.club_admin_set.push(club);
            CurrentPlayerService.saveValue(JSON.stringify(state.currentPlayer));
        },

        updateCurrentPlayerAdmin(state, clubAdmins) {
            state.currentPlayer.club_admin_set = clubAdmins;
            CurrentPlayerService.saveValue(JSON.stringify(state.currentPlayer));
        },
        
        addStripeCustomerToCurrentPlayer(state, stripeCustomer) {
            state.currentPlayer.stripe_customer = stripeCustomer;
            CurrentPlayerService.saveValue(JSON.stringify(state.currentPlayer));
        }
    },
    getters: {
        currentPlayer: state => state.currentPlayer,
        currentPlayerHasValidatedEmail: state => {
            if(state.currentPlayer && state.currentPlayer.contact_set) {
                const validatedContactItems = state.currentPlayer.contact_set.filter(item => (item.kind === "E" && item.validated));
                return validatedContactItems.length > 0;
            } else
                return false;		
        },
        followedClubs: state => {
            // sort without mutation
            return state.currentPlayer.club_set.slice(0).sort(function (a,b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
        },
        administeredClubs: state => {
            // get all the clubs from the player's admin set
            return state.currentPlayer.club_admin_set.map(item => item.club).slice(0).sort(function (a,b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
        },
        coachOfClubs: state => {
            if (!state.currentPlayer.coach) return [];
            // get all the clubs the player is a coach of
            return state.currentPlayer.coach.coach_clubs.slice(0).sort(function (a,b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
        },
        memberOfClubs: state => {
            // get all the clubs from the player's club member set
            return state.currentPlayer.club_member_set.map(item => item.club).slice(0).sort(function (a,b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
        },
        isFollowingClub: (state, getters) => (clubId) => {
            return getters.followedClubs.findIndex(item => item.id == clubId) > -1;
        },
        isAdminForClub: (state, getters) => (clubId) => {
            return getters.administeredClubs.findIndex(item => item.id == clubId) > -1;
        },
        isActiveAdmin: (state) => (clubId) => {
            const adminRecord = state.currentPlayer.club_admin_set.find(item => item.club.id == clubId);
            return adminRecord && adminRecord.status == "A";
        },
        isSuspendedAdmin: (state) => (clubId) => {
            const adminRecord = state.currentPlayer.club_admin_set.find(item => item.club.id == clubId);
            return adminRecord && adminRecord.status == "S";
        },
        isPendingAdmin: (state) => (clubId) => {
            const adminRecord = state.currentPlayer.club_admin_set.find(item => item.club.id == clubId);
            return adminRecord && adminRecord.status == "P";
        },
        isInClubRole: (state) => (clubId, role) => {
            const adminRecord = state.currentPlayer.club_admin_set.find(item => item.club.id == clubId);

            if(!adminRecord)
                return false;
            
            const selectedRole = adminRecord.role.find(item => item == role);
            return selectedRole !== undefined && selectedRole != null;
        },        
        getAdminRolesForClub: (state) => (clubId) => {
            const adminRecord = state.currentPlayer.club_admin_set.find(item => item.club.id == clubId);
            return adminRecord.role;
        },
        getAdminIdForClub:  (state) => (clubId) => {
            const adminRecord = state.currentPlayer.club_admin_set.find(item => item.club.id == clubId);
            return adminRecord.id;
        },
        isCoachOfClub: (state, getters) => (clubId) => {
            if (!state.currentPlayer.coach) return false;
            return getters.coachOfClubs.findIndex(item => item.id == clubId) > -1;
        },
        isMemberOfClub: (state, getters) => (clubId) => {
            if (!state.currentPlayer.club_member_set) return false;
            const clubMembershipForClubIndex = getters.memberOfClubs.findIndex(item => item.id == clubId);
            if (clubMembershipForClubIndex == -1) return false;
            // we have a member object, let's check the expiry date
            const now = moment();
            const expiry = moment(state.currentPlayer.club_member_set[clubMembershipForClubIndex].expires);
            return expiry.isAfter(now);
        }
  },
  actions: {
        getCurrentPlayerAction({commit, rootGetters }, forceRefresh = false): Promise<void> {

            if (!rootGetters.isLoggedIn) {
                commit('setCurrentPlayer', undefined);
                return new Promise(resolve => resolve(undefined));
            }

            // We could do the following if we wanted to ensuer that we NEVER used hte cached version of the current player
            // while we were forcing a refresh of the data
            // if(forceRefresh) {
            //   commit('setCurrentPlayer', undefined);
            // }

            let currentPlayerStr = CurrentPlayerService.getValue();

            if (forceRefresh !== true && currentPlayerStr !== null) {
                const playerObj = JSON.parse(currentPlayerStr);
                commit('setCurrentPlayer', new Player(playerObj));

                return new Promise(resolve => resolve(undefined));
            }

            return apiClient.user()
                .then(playerData => {
                    currentPlayerStr = JSON.stringify(playerData);
                    CurrentPlayerService.saveValue(currentPlayerStr);
                    
                    commit('setCurrentPlayer', new Player(playerData));
                    return;
                });
        },

        updateCurrentPlayerContacts({ commit, state }) {

            if(!state.currentPlayer) {
                // no local state yet so we can't do it yet
                return;
            }

            // Get the latest version of the player's Contact data and set it locally.
            // If we don't even have a local copy of the player then don't do anything

            const localCurrentPlayerData = CurrentPlayerService.getValue();
            if(!localCurrentPlayerData)
                return new Promise(resolve => resolve(undefined));

            const currentPlayer = JSON.parse(localCurrentPlayerData);
            return apiClient.playerContacts(currentPlayer.id).then(contact_set => {
                currentPlayer.contact_set = contact_set;
                CurrentPlayerService.saveValue(JSON.stringify(currentPlayer));

                commit('setCurrentPlayer', new Player(currentPlayer));
                return currentPlayer;
            });
        },

        validateCurrentPlayerEmailAction({ dispatch, state }, emailValidationCode: string) {
            return apiClient.validatePlayerEmail(state.currentPlayer, emailValidationCode)
                .then(() => dispatch('updateCurrentPlayerContacts'))
                .then(newCurrentPlayer => {
                    return newCurrentPlayer;
                });
        },

        followClubAction({ state, dispatch }, clubId) {
            const clubIds = state.currentPlayer.club_set.map((item) => {
                return item.id;
            });
            clubIds.push(clubId);

            return apiClient.updatePlayerClubSet(state.currentPlayer.id, clubIds)
                .then(() => dispatch('getCurrentPlayerAction', true));  // do full refresh of player - too  much may have changed with the follow
        },

        unfollowClubAction({ state, dispatch }, clubId ) {
            const clubIds = state.currentPlayer.club_set.map((item) => {
                return item.id;
            });
        
            const indexToRemove = clubIds.indexOf(clubId);
            if (indexToRemove == -1)
                return;

            clubIds.splice(indexToRemove, 1);

            return apiClient.updatePlayerClubSet(state.currentPlayer.id, clubIds)
                .then(() => dispatch('getCurrentPlayerAction', true));  // do full refresh of player - too  much may have changed with the unfollow
        },

        makeClubAdmin({commit, state}, club) {
            return apiClient.addClubAdmin(club.id, state.currentPlayer.id)
                .then((newClubAdminRecord) => {
                    commit('addClubToCurrentPlayerAdmin', newClubAdminRecord);
                    return state.currentPlayer;
                });
        },

        refreshClubAdmins({commit, state}) {
            return apiClient.playerClubAdmins(state.currentPlayer.id)
                .then((clubAdmins) => {
                    commit('updateCurrentPlayerAdmin', clubAdmins);
                });
        },

        addStripeCustomer({commit, state}, newStripeCustomer): Promise<any> {        
            commit('addStripeCustomerToCurrentPlayer', newStripeCustomer);
            return state.currentPlayer;
        }
    }
}