
<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "MessageText",
        props: {
            isError: {
                type: Boolean,
                default: false,
                required: false
            },
            isInfo: {
                type: Boolean,
                default: false,
                required: false
            },
            message: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false
            }
        },
        computed: {
            classes() {
                if (this.isInfo) {
                    return "message-info";
                }
                else if (this.isError) {
                    return "message-error";
                }
                else {
                    return "message-success";
                }
            }
        }    
    })
</script>

<style scoped>
    /* custom styles for our message output */
    .message-error {
        color: #780000;
        background-color: #ffe9e9;
        border-color: #ffe9e9;
    }

    .message-info {
        color: #404040;
        background-color: #f1f1f1;
        border-color: #f1f1f1;
    }

    .message-success {
        color: #0e4d22;
        background-color: #cfe7d6;
        border-color: #cfe7d6;
    }

    .message {
        position: relative;
        font: normal 14px Open Sans;
        padding: 1.5rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 10px;
    }
</style>

<template>
    <div>
        <div :id="id" class="message" :class="classes" v-show="message" v-html="message"></div>
    </div>
</template>
