export class Currency {
	
	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.code = data && data.code ? data.code : "";
		this.name = data && data.name ? data.name : "";
		this.decimals = data && data.decimals ? data.decimals : 0;
		// this.prefix = data && data.prefix ? data.prefix : "";
		// this.postfix = data && data.postfix ? data.postfix : "";
	}
}