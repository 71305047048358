
export class EventJoinParticipant {
    
    constructor(data) {       
    
        this.player = data && data.player ? data.player : null;
        this.member = data && data.member ? data.member : null;
        this.agreed_terms = data && data.agreed_terms ? data.agreed_terms : null;
        this.name = data && data.name ? data.name : ''; 
        this.dob = data && data.dob ? data.dob : ''; 
        this.email = data && data.email ? data.email : ''; 
        this.number = data && data.number ? data.number : ''; 
        this.gender = data && data.gender ? data.gender : ''; 
        this.price = data && data.price !== undefined ? data.price : undefined; 
        this.paying = data && data.paying !== undefined ? data.paying : false;
        this.all_sessions = data && data.all_sessions !== undefined ? data.all_sessions : false;
        this.notes = data && data.notes ? data.notes : ''; 
        this.session_set = data && data.session_set ? data.session_set : [];

        this.stripe_cust = data && data.stripe_cust !== undefined ? data.stripe_cust : undefined; 
        this.club_cust = data && data.club_cust !== undefined ? data.club_cust : undefined; 
        this.exp_month = data && data.exp_month ? data.exp_month : undefined; 
        this.exp_year = data && data.exp_year ? data.exp_year : undefined; 
    }

    // Return an object that is ready for the server
    getPriceCheckForServer() {

        // this is the json required to query for the clinic/session price
        // price = 0 indicates we are wanting the price
        const data = {
            price: 0,   
            player: this.player,
            member: this.member,
            name: this.name,            
            dob: this.dob,
            email: this.email,
            number: this.number,
            paying: this.paying,            
            gender: this.gender,
            notes: this.notes,                     
            all_sessions: this.all_sessions,
            session_set: this.session_set,
            agreed_terms: this.agreed_terms
        }

        return data;
    }

    // Return an object that is ready for the server
    getEventJoinParticpantForServer() {

        const data = {
            price: this.price !== undefined ? this.price : null,   
            player: this.player,
            member: this.member,
            name: this.name,            
            dob: this.dob,
            email: this.email,
            number: this.number,
            paying: this.paying,            
            gender: this.gender,
            notes: this.notes,                     
            all_sessions: this.all_sessions,
            session_set: this.session_set,
            agreed_terms: this.agreed_terms
        }

        if (this.paying) {
            data["club_cust"] = this.club_cust;
            data["stripe_cust"] = this.stripe_cust;
            data["exp_month"] = parseInt(this.exp_month);
            data["exp_year"] = parseInt(this.exp_year);
        }
        else {
            data["price"] = 0;
        }

        //console.log(data);
        return data;
    }

    // participant is requesting a price for new sessions
    getAddSessionPriceCheckForServer() {

        const data = {
            price: 0,   
            paying: this.paying,             
            session_set: this.session_set,
            agreed_terms: this.agreed_terms
        }

        return data;
    }

    // Return json used to add sessions to a participant
    getAddSessionsParticpantForServer() {

        const data = {
            price: this.price !== undefined ? this.price : null,   
            paying: this.paying,             
            session_set: this.session_set,
            agreed_terms: this.agreed_terms
        }

        if (this.paying) {
            data["club_cust"] = this.club_cust;
            data["stripe_cust"] = this.stripe_cust;
            data["exp_month"] = parseInt(this.exp_month);
            data["exp_year"] = parseInt(this.exp_year);
        }
        else {
            data["price"] = 0;
        }

        return data;
    }
}