<script lang="ts">
    import { ValidationProvider } from "vee-validate";
    import Vue from 'vue';

    // IMPORTANT NOTE:
    // DO NOT USE!!
    // This component is no use at the moment, because the specifying of the Value prop as a Number falls
    // over when the user clears the value (as an empty value is "").  There is a PR for this in vue-next, so we
    // can wait until that's ready for general consumption.  An example of where I wanted to use this is in court-add-onboard.vue

    export default Vue.extend({
        name: "NumberInput",
        components: {
            ValidationProvider
        },
        props: {
            value: {
                type: Number,
                default: 0
            },
            rules: {
                type: [String, Object],
                default: ""
            },
            placeholder: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            helpText: {
                type: String,
                default: ""
            },
            name: {
                type: String,
                default: ""
            },
            vid: {
                type: String,
                default: undefined
            },
            showRequiredLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            decimals: {
                type: Number,
                required: false,
                default: null
            },
            min: {
                type: String,
                required: false,
                default: ""
            },
            max: {
                type: String,
                required: false,
                default: ""
            },
            step: {
                type: String,
                required: false,
                default: ""
            }
        },
        data () {
            return {
                currentValue: 0
            }
        },
        watch: {
            value: {
                handler(val) {
                    if(val == '')
                        val = 0;

                    if (this.decimals)
                        this.currentValue = val.toFixed(this.decimals);
                    else
                        this.currentValue = val;
                },
                immediate:true   //this makes it run the handler function on mount in addition to whenever the value changes
            },
            currentValue(val) {
                
                if(val == '')
                    val = 0;

                // allows us to use v-model on our input.
                this.$emit("input", Number(val));
            }
        },
        methods: {
            emitBlur() {
                this.$emit('blur');
            }
        }
    });
</script>

<template>
    <ValidationProvider
        slim
        :rules="rules"
        :name="name || label"
        :vid="vid"
        v-slot="{ errors, required, showRequiredLabel, ariaInput, classes }">
        <div class="form-group" :class="classes">
            <label class="control-label" :for="name" v-if="label">
                <span>{{ label || name }}</span>
                <span class="helper">{{ required && showRequiredLabel ? ' (required)' : '' }}</span>
            </label>
            <span v-if="helpText" class="help-block">{{ helpText }}</span>
            <input
                class="form-control"
                type="number"
                :placeholder="placeholder"
                :min="min"
                :max="max"
                :step="step"
                v-model.number="currentValue"
                v-bind="ariaInput"
                @blur="emitBlur"/>
            <span class="help-block" v-if="errors[0]">
                {{ errors[0] }}
            </span>
        </div>
    </ValidationProvider>
</template>
