<script lang="ts">
    // ref: https://medium.com/js-dojo/vue-js-manage-your-modal-window-s-effortlessly-using-eventbus-518977195eed
    import ModalSlide from "./modal-slide.vue";
    import Vue from 'vue';
    
    export default Vue.extend({
        name: "ModalSlideRoot",
        components: { ModalSlide },
        data() {
            return {
                component: null as Vue|null, 
                isOpen: false,
                customClass: '',
                props: null,
                events: null,
                closeTrigger: false 
            };
        },
        created() {
            this.$modalBus.$on("modal-slide-open", (options: IModalSlideOptions) => {

                this.component = options.component;
                this.customClass = options.customClass ?? '';
                this.props = options.props;
                this.events = options.events;

                // set to false so the modal can be redisplayed (vue is too efficient!)
                this.closeTrigger = false;

                // do a dodgy DOM hack for now, while we're relying on the bootstrap modal
                const bodyElement = document.getElementsByTagName('body')[0];
                bodyElement.classList.add("modal-open");
            });
            
            // allow closing of the modal from anywhere
            this.$modalBus.$on("modal-slide-close", () => {  
                // when called, trigger the leaving transition which upon completion will trigger a complete closure of the nmodal
                this.handleCloseViaTransition();
            })

            this.$modalBus.$on("modal-slide-scroll-to-top", () => {
                 this.handleScrollToTop();
            })
        },
        methods: {
            handleCloseViaTransition() {
                // initiates the modal closure via the leave transition
                // upon completion of the transition handleClose() is executed via the afterLeave hook
                // see: 'Modal.afterLeave()'
                this.closeTrigger = true;
            },
            handleClose() {
                // close the modal entirely
                // reset the scroll first, ready for next time
                this.handleScrollToTop();
                
                // set the component to null which changes isOpen prop for the Modal
                // this is what completely closes the modal
                this.component = null;

                // do a dodgy DOM hack for now, while we're relying on the bootstrap modal
                const bodyElement = document.getElementsByTagName('body')[0];
                bodyElement.classList.remove("modal-open");
            },
            handleScrollToTop() {
                const modalElement = document.getElementById("SiteSlideModal");
                modalElement?.scrollTo(0, 0);
            }
        }
    });
</script>

<template>
    <ModalSlide  :isOpen="!!component"        
                 :customClass="customClass" 
                 :closeTrigger="closeTrigger"
                 @modal-slide-close-commence="handleCloseViaTransition"
                 @modal-slide-close-complete="handleClose">

        <component  v-bind:is="component" 
                    @modal-slide-close-commence="handleCloseViaTransition" 
                    @modal-slide-close-complete="handleClose"                    
                    v-bind="{ ...props }" 
                    v-on="events" />
    </ModalSlide>
</template>
