<script lang="ts">
    import Vue from 'vue';  

    export default Vue.extend({
        name: "AlertConfirmModal",
        data: () => ({
            isVisible: false
        }),
        methods: {
            open() {
                this.isVisible = true
            },
            close() {
                this.isVisible = false
            }
        }
    });
</script>

<style scoped>
    /* css class for the transition */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .alert-confirm-modal {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.0rem;
        display: flex;
        align-items: center;
        z-index: 99999;
    }

    .window {
        background: #fff;
        border-radius: 10px;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        padding: 1.5rem;
    }
</style>

<template>
    <transition name="fade">
        <div class="alert-confirm-modal" v-if="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>