import { apiClient } from '@/utils/apiclient';
import { IGetMatchesMutationPayload, IUpdateMatchMutationPayload, IDeleteMatchMutationPayload, IGetMatchesActionPayload, IApproveMatchActionPayload, IDeleteMatchActionPayload } from './matches.types';
import { Match } from '@/models';

class State {
    matches: Match[] = [];
    nextMatchesURL = "";
    prevMatchesURL = "";
    matchesTotal = 0;
    currentPage = 1;
}

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: new State(),
    mutations: {

        getMatches(state: State, payload: IGetMatchesMutationPayload) {
            state.matches = payload.matches;            
        },
        
        getnextMatchesURL(state: State, next) {
            state.nextMatchesURL = next;
        },
        
        getprevMatchesURL(state: State, prev) {
            state.prevMatchesURL = prev;
        },
        
        getMatchesTotal(state: State, total) {
            state.matchesTotal = total;
        },

        updateMatch(state, payload: IUpdateMatchMutationPayload) {
            const index = state.matches.findIndex(c => c.id === payload.match.id);
            
            if(index == -1) {
                state.matches.push(payload.match);
            }
            else {
                state.matches.splice(index, 1, payload.match);
            }
        },

        deleteMatch(state, payload: IDeleteMatchMutationPayload) {
            state.matches = [...state.matches.filter(c => c.id !== payload.matchId)];
        },

        nextPage(state: State) {
            state.currentPage++;
        },

        prevPage(state: State) {
            state.currentPage--;
        }
    },
    getters: {
        matches: state => state.matches,
        matchesTotal: (state: State) => state.matchesTotal,
        hasNextPage: (state: State) => (state.nextMatchesURL && state.nextMatchesURL.length > 0) ? true : false,
        hasPrevPage: (state: State) => (state.prevMatchesURL && state.prevMatchesURL.length > 0) ? true : false,
        currentPage: (state: State) => state.currentPage,
    },
    actions: {

        getMatchesAction({ commit }, payload: IGetMatchesActionPayload): Promise<Match[]> {        
            return apiClient.matches(payload.playerId, payload.pageNumber, payload.pageSize, payload.filter)
                .then((result) => {
                    const matches = result.results.map(
                        (item: any) => new Match(item)
                    );

                    commit("getMatches", { "matches": matches });
                    commit("getnextMatchesURL", result.next);
                    commit("getprevMatchesURL", result.previous);
                    commit("getMatchesTotal", result.count);

                    return matches;
                });
        },

        getNextPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.nextMatchesURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Match(item)
                    );
                    commit("getMatches", { "matches": results });
                    commit("getnextMatchesURL", result.next);
                    commit("getprevMatchesURL", result.previous);
                    commit("getMatchesTotal", result.count);
                    commit("nextPage");

                    return results;
                })
        },

        getPrevPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.prevMatchesURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Match(item)
                    );
                    commit("getMatches", { "matches": results });
                    commit("getnextMatchesURL", result.next);
                    commit("getprevMatchesURL", result.previous);
                    commit("getMatchesTotal", result.count);
                    commit("prevPage");

                    return results;
                })
        },

        approveMatchAction({ commit }, payload: IApproveMatchActionPayload): Promise<Match> {

            // find the player match set object for this player
            const currentPlayerMatch = payload.match.playermatch_set.find(m => m.player == payload.playerId);

            if(!currentPlayerMatch)
                return Promise.resolve(payload.match); // do nothing, just return the match back

            currentPlayerMatch.approved = true;

            return apiClient.saveMatch(payload.match)
                .then((result) => {
                    commit("updateMatch", { "match": result });
                    return result;
                })
        },

        deleteMatchAction({ commit }, payload: IDeleteMatchActionPayload): Promise<void> {

            // find the player match set object for this player
            const currentPlayerMatch = payload.match.playermatch_set.find(m => m.player == payload.playerId);

            if(!currentPlayerMatch)
                return Promise.resolve(payload.match); // do nothing, just return the match back

            currentPlayerMatch.deleted = true;

            return apiClient.saveMatch(payload.match)
                .then(() => {
                    commit("deleteMatch", { "matchId": payload.match.id });
                })
        },

    },
}