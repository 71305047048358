<script lang="ts">
    import AuthBox from "@/components/auth/auth-box.vue";
    import NavMenu from "@/components/nav-menu.vue";
       
    export default {
        name: 'HeaderMarketing',
        components: {
            "nav-menu": NavMenu,
            "auth-box": AuthBox,
        },
    }

</script>

<template>

    <!-- Header section containing main logo and login -->
    <div id="header-container-fixed">
        <div id="top-page-container">
            <div class="site-container">
                <div id="site-logo">
                    <router-link to="/" title="Str8 Sets" rel="home">
                        <img
                            class="horizontal"
                            src="@/assets/images/logo-horizontal.svg"
                            alt="Str8 Sets"
                            data-no-retina
                        />
                    </router-link>
                </div>
                <auth-box></auth-box>
            </div>
        </div>

        <nav-menu></nav-menu>
    </div>

</template>
