import { Coach } from "@/models/coach";
import { Court } from "@/models/club";
import { Event, EventParticipant, EventWaitlist } from '.';

export class EventSession {
     
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;
        this.event = data && data.event ? new Event(data.event) : null;

        this.number = data && data.number ? data.number : 0; 
        this.start = data && data.start ? data.start : undefined;
        this.end = data && data.end ? data.end : undefined;
        this.cancelled = data && data.cancelled ? data.cancelled : undefined;

        this.session_booking_set = data && data.session_booking_set ? data.session_booking_set : [];

        this.participant_set = [];
        if (data && data.participant_set) {
            this.participant_set = data.participant_set.map((item) => new EventParticipant(item));
        }

        this.waitlist_set = [];
        if(data && data.waitlist_set) {
			this.waitlist_set = data.waitlist_set.map((item) => new EventWaitlist(item));
		}
        
        this.court_set = [];
        if (data && data.court_set) {
            this.court_set = data.court_set.map((item) => new Court(item));
        }

        this.coach_set = [];
        if(data && data.coach_set) {
			this.coach_set = data.coach_set.map((item) => new Coach(item));
		}
    }
}