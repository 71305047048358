import { Match } from '..';
import { Team } from '.';

export class MatchGroup {

    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
 
        this.tournament = data && data.tournament ? data.tournament : undefined;
    
        this.match_set = [];
        if(data && data.match_set) {
			this.match_set = data.match_set.map((item) => new Match(item));
        }
            
        this.team_set = [];
        if(data && data.team_set) {
			this.team_set = data.team_set.map((item) => new Team(item));
        }
    
        this.court = data && data.court ? data.court : "";
        this.dated = data && data.dated ? data.dated : "";	
    
        this.club = data && data && data.club ? data.club : undefined;
        this.club_name = data && data.club_name ? data.club_name : "";
    
        this.playedAtClub = this.club ?? this.club_name;
    }
}