<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "PageNotFound",
        data: () => ({
            mounted: false
        }),
        mounted() {
            this.mounted = true
        }
    })
</script>

<style lang="scss" scoped>
    .notfound_header {
        font: normal 600 30px Open Sans;
        color: #026823;
    }

    .notfound_subheader {
        font: normal 600 40px Open Sans;
        color: #026823;
    }

    .score {
        display: inline-block;
        font: normal 600 100px/100px Open Sans;
        color: #ffd65c;
        margin-top: 30px;
        margin-bottom: 30px;
        border: 6px dashed #ffd65c;
		border-radius: 50px;
		background-color: #242424;
        padding: 30px 60px;
        box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, 0.16);
    }

    .text {
        font: normal normal 20px Open Sans;
        color: #6e6e6e;
    }

    .rotate-enter { 
        transform: perspective(500px) rotate3d(0, 1, 0, 90deg); 
    }
    .rotate-enter-active{ 
        transition: 3.0s; 
    }
</style>

<template>
    <div id="main" class="container">

        <div class="notfound_header">
            Oops...
        </div>
        <Transition name="rotate">
        <div v-if="mounted" class="score">
            0   0   0<br/>
            6   6   6
        </div>
        </Transition>
        <div class="notfound_subheader">
            Game, Set and Match!
        </div>
        <br/>
        <div class="text">
            <strong>Page not found</strong>. Unfortunately the page you were trying to access no longer exists.<br/>
            Please use the menu to restart the match. Best of luck!
        </div>

    </div> 
</template>