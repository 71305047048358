import moment from 'moment';

export const NO_HOURS_DEFAULT = "(No hours set)";

export class HoursOfOperation {
	
	constructor(data) {
		this.id = data && data.id !== undefined ? data.id : undefined;	/* note: can't just check for "id" being true because a value of 0 would return false! */
		this.open = data && data.open ? data.open : undefined;
		this.close = data && data.close ? data.close : undefined;
	}

	validate() {

		if (!this.open)
			return false

		if (!this.close)
			return false;

		if (moment(this.close, "H:mm") < moment(this.open, "H:mm"))
			return false;

		return true;
	}

}
