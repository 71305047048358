const ITEM_KEY = "TennisSportId";

export const getValue = (): string|null => {
  return window.localStorage.getItem(ITEM_KEY);
};

export const saveValue = (value: string) => {
  window.localStorage.setItem(ITEM_KEY, value);
};

export const deleteValue = () => {
  window.localStorage.removeItem(ITEM_KEY);
};

export default { getValue, saveValue, deleteValue };
