import { BookingInstance } from "@/models/court-booking/booking-instance";
import { BookingPerson } from "@/models/court-booking/booking-person";
import { BookingSeriesClubEquipment } from "@/models/court-booking/booking-series-club-equipment";
import { Coach } from "@/models/coach";
import { EventHoursOfSingleSession, EventSession } from "@/models/event";
import { Sport } from "@/models/sport";
import { Transaction } from "@/models/transaction";
import moment from 'moment';
import Utilities from '@/utils/utilities';

export class BookingSeries {
    
    constructor(data) {
    
        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? data.club : undefined;
        this.name = data && data.name ? data.name : ""; 
        
        this.author = data && data.author ? data.author : "";
        this.author_name = data && data.author_name ? data.author_name : "";
        this.player = data && data.player && data.player.id 
                        ? data.player.id 
                        : data && data.player 
                            ? data.player 
                            : "";        // we just store/use the flat ID value

        this.player_name = data && data.player_name ? data.player_name : "";
        this.player_email = data && data.player_email ? data.player_email : ""; 

        this.contact_number = data && data.contact_number ? data.contact_number : "";

        this.guests = data && data.guests ? data.guests : "";

        this.price = data && data.price ? data.price : 0;                                           // Format to apply in the UI: parseFloat(data.price).toFixed(2)
        this.paying = data && data.paying ? data.paying : undefined;  

        this.ttype = data && data.ttype ? data.ttype : "C";
        this.sport = data && data.sport ? new Sport(data.sport) : undefined;
        this.event_session = data && data.event_session ? new EventSession(data.event_session) : undefined;

        this.start_date = data && data.start_date ? data.start_date : undefined;
        this.end_date = data && data.end_date ? data.end_date : undefined;
        this.recurrence = data && data.recurrence ? data.recurrence : '';
        this.recurrence_end = data && data.recurrence_end ? data.recurrence_end : null;

        this.created = data && data.created ? data.created : undefined;
        
        this.court_set = data && data.court_set ? data.court_set : [];                              // flat array of Ids

        this.coach_set = [];
        if(data && data.coach_set) {
			this.coach_set = data.coach_set.map((item) => new Coach(item));
		}

        this.booked_person_set = [];
        if (data && data.booked_person_set) {
            this.booked_person_set = data.booked_person_set.map((item) => new BookingPerson(item));
        }

        this.equipment_set = [];
        if (data && data.equipment_set) {
            this.equipment_set = data.equipment_set.map((item) => new BookingSeriesClubEquipment(item));
        }

        this.requested_equipment = [];
        if (data && data.requested_equipment) {
            this.requested_equipment = data.requested_equipment.map((item) => new BookingSeriesClubEquipment(item));
        }

        this.notes = data && data.notes ? data.notes : "";

        this.booking_instance_set = [];
        if (data && data.booking_instance_set) {
            this.booking_instance_set = data.booking_instance_set.map((item) => new BookingInstance(item));
        }

        this.transaction_set = [];
        if (data && data.transaction_set) {
            this.transaction_set = data.transaction_set.map((item) => new Transaction(item));
        }

        this.stripe_cust = data && data.stripe_cust !== undefined ? data.stripe_cust : undefined; 
        this.club_customer = data && data.club_customer !== undefined ? data.club_customer : undefined; 
        this.card_exp_year = data && data.card_exp_year ? data.card_exp_year : undefined; 
        this.card_exp_month = data && data.card_exp_month ? data.card_exp_month : undefined; 

        // days of the week
        // we flatten the days of week object to the following format
        // - id: 0, name: Monday, start: 14:00, end: 15:00
        // days of week is an array of the above objects
        this.bookingDaysOfWeek = null;
		if(data && data.days_of_week) {

            this.bookingDaysOfWeek = [];
			Object.keys(data.days_of_week).map((key) => {
				// The structure that comes from the server is quite unique for this object:
				// 	   { id: [{ start: "", end: "" }] }
				// where id is the day of the week number, e.g.
				//     { 3: [{ start: "17:00", end: "7:00" }] }

                // item represents a single day
                // extract the booking times within it, there may be multiple
                const bookings = data.days_of_week[key];
                bookings.forEach(book => {    
                    const bookingTimesNiceFormat = {};
                    bookingTimesNiceFormat.id = key;	
                    bookingTimesNiceFormat.name = Utilities.daysOfWeekDictionary[key].name;
                    bookingTimesNiceFormat.start = book && book.start ? book.start : undefined;
                    bookingTimesNiceFormat.end = book && book.end ? book.end : undefined;
    
                    this.bookingDaysOfWeek.push(new EventHoursOfSingleSession(bookingTimesNiceFormat));
                });				
			});
		}

        this.total_paid = data && data.total_paid ? data.total_paid : 0; 
        this.cost = data && data.cost ? data.cost : undefined; 
        this.equipment_cost = data && data.equipment_cost ? data.equipment_cost : undefined; 
        this.cost_difference = data && data.cost_difference ? data.cost_difference : undefined; 
    }

    get start_time() {
        if(this.start_date)
            return moment(this.start_date, moment.ISO_8601).format("HH:mm");
        else
            return "00:00";
    }

    set start_time(value) {
        if(this.start_date == undefined || value == undefined)
            return;

        const combinedDateTimeStr = moment(this.start_date).format("YYYY-MM-DD")+ "T" + value;
        this.start_date = moment(combinedDateTimeStr).format("YYYY-MM-DDTHH:mm:ss");
    }

    get end_time() {
        if(this.end_date)
            return moment(this.end_date, moment.ISO_8601).format("HH:mm");
        else
            return "00:00";
    }

    set end_time(value) {
        if(this.end_date == undefined || value == undefined)
            return;

        const combinedDateTimeStr = moment(this.end_date).format("YYYY-MM-DD") + "T" + value;
        this.end_date = moment(combinedDateTimeStr).format("YYYY-MM-DDTHH:mm:ss");
    }

    getBookedPersons() {
        // return a list of the booked persons, ready to send to the server
        // because BookingPerson has two properties which should not be sent down the line
        const bpersons = [];

        this.booked_person_set.forEach(bp => {
            const nbp = new BookingPerson(bp);

            // obtain the player ID, if there is one (could be null)
            let playerId = bp.player;
            try {              
                if (typeof bp.player === 'number') {
                    // do nothing  
                }
                else playerId = bp.player.id;
            }
            catch (Exception) {
                // do nothing
            }
            
            nbp.player = playerId;
            nbp.player_full = undefined;
            nbp.member_full = undefined;
            nbp.stripe_customer = undefined;
            bpersons.push(nbp);
        })
        return bpersons;
    }

    getRequestedEquipment() {
        // return a list of the equipment items, ready to send to the server
        const equip = [];
        this.equipment_set.forEach(e => {
            const es = {
                id: e.equipment.id,
                quantity: e.quantity
            }
            equip.push(es);
        })
        return equip;
    }

    getDaysOfWeek() {
        // create days of week from the bookingDaysOfWeek field
        if (this.recurrence == "C" && this.bookingDaysOfWeek && this.bookingDaysOfWeek.length > 0) {
            const days_of_week = {};
            this.bookingDaysOfWeek.forEach(s => {
                const stringKey = s.id.toString();
                if (!days_of_week[stringKey]) days_of_week[stringKey] = [];
                days_of_week[stringKey].push({
                    start: s.start,
                    end: s.end
                })
            })
            return days_of_week;
        }
        return {};
    }

    validate() {

        if(!this.club)
            return false;

        if(!this.name)
            return false;

        if(!this.ttype)
            return false;

        if(!this.start_date)
            return false;

        if(!this.end_date)
            return false;

        // End date must be after start date
        if(new Date(this.end_date) <= new Date(this.start_date)) 
            return false;

        if(this.recurrence.length > 0 && !this.recurrence_end)
            return false;

        if(this.court_set.length == 0)
            return false;

        if(this.ttype === 'C' && this.coach_set.length == 0)
            return false;

        return true;
    }

    can_coach_edit(coach) {		

        // the coach can only edit this series if
        // 1. they are the author
        // 2. they are in the coach_set
        if (!coach) return false;

        // are they the author
        if (this.author == coach.player.id) return true;

        // are they listed as a coach in the coach set
        return this.coach_set.some(c => c.id == coach.id);
	}

    setPayingFlagForBookingAllowed() {
        
        // this is used to set the paying flag that will be used for booking allowed
        // for reference, see notes to WEBF-247
        /*
        1) If paying is already set true
        2) If there are no booked persons - set “paying” = false
        3) If the booking type is blocking - set “paying” = false
        4) If no booked persons are marked as “paying” - set “paying” to false        
        5) else set "paying" = true
        */

        if (this.paying === true) return true;
        if (this.booked_person_set.length == 0) return false;
        if (this.ttype == 'B') return false;
        if (!this.booked_person_set.filter(bp => bp.paying == true).length > 0) return false;
        return true;
    }
}