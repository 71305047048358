import { BookingSeries, Format } from '@/models';
import { Club } from '../club/club';
import { MatchaHitResponse } from './hit-response';
import { Player } from '../player';
import moment from 'moment';
import Utilities from '@/utils/utilities';

export class MatchaHitRequest {
	
	constructor(data) {
		this.id = data.id;
		this.start_time_window = data && data.start_time_window ? data.start_time_window : undefined;
		this.end_time_window = data && data.end_time_window ? data.end_time_window : undefined;
		this.agreed_start = data && data.agreed_start ? data.agreed_start : undefined;
		this.players_needed = data && data.players_needed !== undefined ? data.players_needed : undefined;
		this.num_players_requested = data && data.num_players_requested !== undefined ? data.num_players_requested : undefined;
		this.hit_creator = data && data.hit_creator ? new Player(data.hit_creator) : null;
		this.comments = data && data.comments !== undefined ? data.comments : undefined;
		this.cost = data && data.cost !== undefined ? data.cost : undefined;
		this.min_age = data && data.min_age !== undefined ? data.min_age : undefined;
		this.max_age = data && data.max_age !== undefined ? data.max_age : undefined;
		this.gender = data && data.gender !== undefined ? data.gender : undefined;
		this.play_format = data && data.play_format !== undefined ? new Format(data.play_format) : undefined;
		this.court_booking = data && data.court_booking ? new BookingSeries(data.court_booking) : null;

		this.matchahitresponse_set = [];
		if(data && data.matchahitresponse_set && data.matchahitresponse_set.length > 0) {
			this.matchahitresponse_set = data.matchahitresponse_set.map((item) => new MatchaHitResponse(item));
		}

		this.target_players_set = [];
		if(data && data.target_players_set && data.target_players_set.length > 0) {
			this.target_players_set = data.target_players_set.map((item) => new Player(item));
		}

		this.club = [];
		if(data && data.club && data.club.length > 0) {
			this.club = data.club.map((item) => new Club(item));
		}

		// player rows- populated for the display of people associated with the hit request or empty slots
		this.player_list = this.populate_player_list();
	}

	get awaiting_players() {
		return this.target_players_set.filter((item) => {
			const confirmedPlayer = this.matchahitresponse_set.find((filterItem) => {
				return filterItem.responder.id === item.id;
			});
			return !confirmedPlayer;
		})
	}

	// Return an array of type Player that is used to display the players associated with the hit
	populate_player_list() {
		const pl = [];
		// 1. add the hit creator as the first player 
		pl.push(this.hit_creator);

		// 2. add the players that have already responded or are targeted
		if (this.is_hit_private) {	
			// private hit = targeted players	
			this.target_players_set.forEach((tp) => {
				const player = new Player(tp);
				pl.push(player);
			})
		}
		else {
			// public hit = hit response
			this.matchahitresponse_set.forEach((mr) => {
				const player = new Player(mr.responder);
				pl.push(player);
			})
		}

		// 3. determine how many empty spots still remain for the hit
		const remainingSopts = this.num_players_requested - (pl.length-1);

		// 4. process the remaining player spots
		for (let i=0; i<remainingSopts; i++) {
			const playerNumber =  pl.length;
			const player = new Player({
				first_name: "Player " + ("0" + playerNumber).slice(-2)
			});	
			pl.push(player);
		}
		
		return pl;
	}

	// return true if the status of the hit is confirmed
	get is_status_confirmed() {
		return this.players_needed === 0;
	}

	// return true if the hit is a private hit request
	get is_hit_private() {
		return this.target_players_set.length > 0;
	}

	// return the status of the matcha hit 
	// Confirmed
	// Pending (X) --> X = number of players needed
	get hit_status_text() {
		if (this.is_status_confirmed) {
			return "Confirmed";			
		}
		else {
			return "Pending (" + this.players_needed + ")";
		}
	}

	// Return the date for the matcha hit 
	// formatted as mmm dd yyyy
	get hit_date_formatted() {
		const formattedHitDate = Utilities.formatDateToDateString(this.start_time_window);
		return formattedHitDate;
	}

	// Return Public or Private depending upon the request type
	get hit_request_type_formatted() {
		if (this.is_hit_private) return "Private";
		return "Public";
	}	

	// Return the starting time for the hit request
	// 1. If court booking show the booking from and to times
	// 2. If confirmed, show the agreed start time
	// 3. If pending, show the requested start time window
	get hit_start_time_formatted() {

		const timeFormat = "h:mm A";
		let startTime = "";
		let endTime = "";

		if (this.court_booking) {
			// court is booked
			startTime = moment(this.court_booking.start_date).format(timeFormat);
			endTime = moment(this.court_booking.end_date).format(timeFormat);
			return startTime + " - " + endTime;
		}

		if (this.is_status_confirmed) {
			// no court booking but status is confirmed
			startTime = moment(this.agreed_start).format(timeFormat);
			return startTime;
		}

		// pending, show start window
		startTime = moment(this.start_time_window).format(timeFormat);
		endTime = moment(this.end_time_window).format(timeFormat);
		return startTime + " - " + endTime;
	}

	// Return the name of the booked court
	get hit_booked_court_text() {
		if (!this.court_booking) {
			return "";
		}
		// We have court booking, will only be one for matcha hit
		// get the courts (should only be one) for the booking
		const names = this.court_booking.booking_instance_set[0].court_names_list;
		return names.join('\n');
	}

	// Return text which defines the criteria for the publi hit request
	// e.g. 	Doubles | Any Gender | 10 - 100 yo
	//			Singles | Any Gender | 30 - 45 yo
	get hit_criteria_text() {
		if (this.is_hit_private) return "-";

		// Age range
		const ageRange = this.min_age + " - " + this.max_age + " yo";

		// Playing format
		let playFormat = "Doubles";
		if (this.play_format.name == 'singles') playFormat = "Singles";

		// Gender
		let gender = "Any Gender";
		if (this.gender == 'F') gender = "Female";
		else if(this.gender == 'M') gender = "Male";

		const spacer = " | ";
		return playFormat + spacer + gender + spacer + ageRange;
	}
}
