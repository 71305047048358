import { Player } from "@/models/player";

export class BookingPerson {
    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
        this.player = data && data.player && data.player.id 
            ? new Player(data.player) 
            : data && data.player ? data.player : null;
        this.club_member = data && data.club_member ? data.club_member : null;  // we just store/use the flat ID value
        this.full_name = data && data.full_name ? data.full_name : null;
        this.email = data && data.email ? data.email : null;
        this.number = data && data.number ? data.number : null; // used when converting clinic session participants
        this.notes = data && data.notes ? data.notes : null;
        this.paying = data && data.paying ? data.paying : false;
        this.primary = data && data.primary ? data.primary : false;

        // utilitiy properties which are not sent to the server
        this.player_full = data && data.player_full ? data.player_full : null;
        this.member_full = data && data.member_full ? data.member_full : null;
        this.stripe_customer = data && data.stripe_customer ? data.stripe_customer : null;
    }

    get identifier() {
        // the first character of the identifier is a letter
        // signifying if the booked person has an ID
        // used in the booking-payment component
        const idNullCheck = this.id == null
            ? 'N'
            : 'Y'
        return idNullCheck 
            + this.id?.toString() 
            + this.player?.toString() 
            + this.club_member?.toString() 
            + this.full_name?.toString() 
            + this.email?.toString() 
            + this.number?.toString() 
            + this.notes?.toString() + '';
    }

    get identifier_no_id() {
        return this.player?.toString() 
            + this.club_member?.toString() 
            + this.full_name?.toString() 
            + this.email?.toString() 
            + this.number?.toString() 
            + this.notes?.toString() + '';
    }

    get name_for_display() {
        if (this.full_name != null && this.full_name != "") return this.full_name;
        if (this.player != null && this.player.id) {
            return this.player.full_name;
        }
        else return "Name not provided";
    }
}