export class ClubClient {
	constructor(data) {

		this.member_id = data && data.member_id ? data.member_id : undefined;
		this.player_id = data && data.player_id ? data.player_id : undefined;
		this.email = data && data.email ? data.email : "" ;
		this.full_name = data && data.full_name ? data.full_name : "" ;
		this.stripe_customer = data && data.stripe_customer ? data.stripe_customer : null;
	}
}
