import { SlamCompetitorTournament } from './slam-competitor-tournament';

export class SlamMatch {
    constructor(data) {

        this.id = data.id;
        this.start = data && data.start ? data.start : "";
        this.end = data && data.end ? data.end : "";
        this.number = data && data.number !== undefined ? data.number : undefined;
        this.status = data && data.status ? data.status : "";
        this.forfeit = data && data.forfeit ? data.forfeit : "";

        this.home_side_games = data && data.home_side_games ? data.home_side_games : "[]";
        this.away_side_games = data && data.away_side_games ? data.away_side_games : "[]";
        this.home_side_sets = data && data.home_side_sets !== undefined ? data.home_side_sets : undefined;
        this.away_side_sets = data && data.away_side_sets !== undefined ? data.away_side_sets : undefined;
        this.winner = data && data.winner !== undefined ? data.winner : undefined;

        this.home_competitor_set = [];
        if(data && data.home_competitor_set) {
			this.home_competitor_set = data.home_competitor_set.map((item) => new SlamCompetitorTournament(item));
		}

        this.away_competitor_set = [];
        if(data && data.away_competitor_set) {
			this.away_competitor_set = data.away_competitor_set.map((item) => new SlamCompetitorTournament(item));
		}
    }

    get search_prop() {
        try {
            let hcn = "";
            if (this.home_competitor_set != null &&  this.home_competitor_set.length > 0) {
                const hc = this.home_competitor_set[0].competitor;
                hcn = hc.first_name + " " + hc.last_name;
            }

            let acn = "";
            if (this.away_competitor_set != null &&  this.away_competitor_set.length > 0) {
                const ac = this.away_competitor_set[0].competitor;
                acn = ac.first_name + " " + ac.last_name;
            }

            return hcn + " | " + acn;
        }
        catch(err) {
            return "";
        }
	}
}