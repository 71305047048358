import { HoursOfOperation, DayOfOperationItem, Court } from '.';

export class CourtGroup {
    
    constructor(data) {

        this.id = data && data.id ? data.id : undefined;
        this.name = data && data.name ? data.name : undefined; // .extend({ required: { message: "Court group name is required" } });
        this.club = data && data.club ? data.club : undefined;
        this.default = data && data.default ? data.default : false;
        this.ready = data && data.ready ? data.ready : false;
        this.admin_only_booking = data && data.admin_only_booking ? data.admin_only_booking : false;        
        this.advance_days_booking = data && data.advance_days_booking !== undefined ? data.advance_days_booking : 0;
        this.booking_start_after = data && data.booking_start_after !== undefined ? data.booking_start_after : null;
        this.min_booking_duration_members = data && data.min_booking_duration_members !== undefined ? data.min_booking_duration_members : 30;
        this.max_booking_duration_members = data && data.max_booking_duration_members !== undefined ? data.max_booking_duration_members : 120;
        //.extend({
            // required: { message: "Maximum Booking Duration for Members is required"},
            // number:  { message: "Maximum Booking Duration for Members must be numeric" }, 
            // min: { params: 0, message: "Maximum Booking Duration for Members must be positive" },
            // step: { params: 30, message: "Maximum Booking Duration for Members must be a multiple of 30 minutes" }  });
        this.max_booking_duration_nonmembers = data && data.max_booking_duration_nonmembers !== undefined ? data.max_booking_duration_nonmembers : 120;
        //.extend({
            // required: { message: "Maximum Booking Duration for Non-members is required"},
            // number:  { message: "Maximum Booking Duration for Non-members must be numeric" }, 
            // min: { params: 0, message: "Maximum Booking Duration for Non-members must be positive" },
            // step: { params: 30, message: "Maximum Booking Duration for Non-members must be a multiple of 30 minutes" } });
        this.matcha_booking_cancel_advance = data && data.matcha_booking_cancel_advance !== undefined ? data.matcha_booking_cancel_advance : 30;
        //.extend({
            // required: { message: "Value is required"},
            // number:  { message: "Value must be numeric" }, 
            // min: { params: 0, message: "Value must be positive" },
            // max: { params: 600, message: "Value must be less than or equal to 600" },
            // step: { params: 30, message: "Value must be a multiple of 30 minutes" } });
        this.refund_cancelled_by = data && data.refund_cancelled_by !== undefined ? data.refund_cancelled_by : 0;
        this.refund_cancelled = data && data.refund_cancelled !== undefined ? data.refund_cancelled : false;
        this.payment_on_app = data && data.payment_on_app !== undefined ? data.payment_on_app : true;        
        this.inherit_club_hours = data && data.inherit_club_hours !== undefined ? data.inherit_club_hours : true;        
        this.opening_hours = data && data.opening_hours ? data.opening_hours : [];  // gets populated for saves only - may not need now with Vuex, see how it goes
        this.allow_names_member = data && data.allow_names_member !== undefined ? data.allow_names_member : false;
        this.allow_names_nonmember = data && data.allow_names_nonmember !== undefined ? data.allow_names_nonmember : false;

		this.hoursOfOperation = [];
        if(data && data.opening_hours && data.opening_hours.length > 0) {
            this.hoursOfOperation = data.opening_hours.map((item) => {
				// Nb: The structure that comes from the server is quite unique for this object:
				// 	   { id: { close: "", open: "" } }
				// where id is the day of the week number, e.g.
				//     { 3: { close: "17:00", open: "7:00" } }

				const openingHoursNiceFormat = {};
				const id = Number(Object.keys(item));
				openingHoursNiceFormat.id = id;	/* note: can't just check for "id" being true because a value of 0 would return false! */
				openingHoursNiceFormat.open = item && item[id].open ? item[id].open : undefined;
				openingHoursNiceFormat.close = item && item[id].close ? item[id].close : undefined;

				return new HoursOfOperation(openingHoursNiceFormat)
            });
        }

        this.daysofOperation = [
            new DayOfOperationItem({id: 0, name: "Monday", dayType: "weekday"}),
			new DayOfOperationItem({id: 1, name: "Tuesday", dayType: "weekday"}),
			new DayOfOperationItem({id: 2, name: "Wednesday", dayType: "weekday"}),
			new DayOfOperationItem({id: 3, name: "Thursday", dayType: "weekday"}),
			new DayOfOperationItem({id: 4, name: "Friday", dayType: "weekday"}),
			new DayOfOperationItem({id: 5, name: "Saturday", dayType: "weekend"}),
			new DayOfOperationItem({id: 6, name: "Sunday", dayType: "weekend"}),
        ];

		this.daysofOperation.map((item) => item.refreshHours(this.hoursOfOperation));

        this.court_set = [];
        if(data && data.court_set) {
            this.court_set = data.court_set.map((item) => new Court(item));
        }
    }

    get courtsList() {
        const courtNames = this.court_set.map((item) => {
            return item.name;
        });

        return courtNames.join(", ");
    }

    validate() {

        if(!this.name)
            return false;

        if(this.advance_days_booking == undefined || !this.advance_days_booking.toString() || isNaN(this.advance_days_booking)) 
            return false;

        if(Number(this.advance_days_booking) < 0)
            return false;
        
        if(Number(this.advance_days_booking) % 1 != 0)
            return false;


        if(this.min_booking_duration_members == undefined || !this.min_booking_duration_members.toString() || isNaN(this.min_booking_duration_members)) 
            return false;

        if(Number(this.min_booking_duration_members) < 0)
            return false;
        
        if(Number(this.min_booking_duration_members) % 30 != 0)
            return false;

            
        if(this.max_booking_duration_members == undefined || !this.max_booking_duration_members.toString() || isNaN(this.max_booking_duration_members)) 
            return false;

        if(Number(this.max_booking_duration_members) < 0)
            return false;
        
        if(Number(this.max_booking_duration_members) % 30 != 0)
            return false;


        if(this.max_booking_duration_nonmembers == undefined || !this.max_booking_duration_nonmembers.toString() || isNaN(this.max_booking_duration_nonmembers)) 
            return false;

        if(Number(this.max_booking_duration_nonmembers) < 0)
            return false;
        
        if(Number(this.max_booking_duration_nonmembers) % 30 != 0)
            return false;


        if(this.matcha_booking_cancel_advance == undefined || !this.matcha_booking_cancel_advance.toString() || isNaN(this.matcha_booking_cancel_advance)) 
            return false;

        if(Number(this.matcha_booking_cancel_advance) < 0 || Number(this.matcha_booking_cancel_advance) > 600)
            return false;
        
        if(Number(this.matcha_booking_cancel_advance) % 30 != 0)
            return false;
        
            
        if(this.refund_cancelled_by == undefined || !this.refund_cancelled_by.toString() || isNaN(this.refund_cancelled_by)) 
            return false;

        if(Number(this.refund_cancelled_by) < 0)
            return false;
        
        if(Number(this.refund_cancelled_by) % 1 != 0)
            return false;


        return true;
    }

    replacer(key,value)
    {
        const courtGroupIgnoreList = ['hoursOfOperation', 'daysofOperation', 'court_set', 'ready', 'default'];
        
        if (courtGroupIgnoreList.includes(key)) return undefined;
        else return value;
    }
}