import { Thread } from '.';

export class PlayerThread {
	constructor(data) {
	
		this.id = data.id;
		this.player = data && data.player ? data.player : undefined;
		this.read_up_to = data && data.read_up_to ? data.read_up_to : undefined;
		this.thread = data && data.thread ? new Thread(data.thread) : null;
	}
}