import { apiClient } from '@/utils/apiclient';
import { Court, Sport } from '@/models';
import { IGetClubCourtsMutationPayload, IAddClubCourtMutationPayload, IUpdateClubCourtMutationPayload, IDeleteClubCourtMutationPayload, IAddClubCourtAction, IUpdateClubCourtAction, IDeleteClubCourtAction } from './club-courts.types';

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        clubId: "",
        clubCourts: [] as Court[],
    },
    mutations: {

        getCourts(state, payload: IGetClubCourtsMutationPayload) {
            state.clubId = payload.clubId;
            state.clubCourts = payload.courts;            
        },

        addCourt(state, payload: IAddClubCourtMutationPayload) {
            state.clubCourts.push(payload.court) // mutate the courts array - push for place at end, unshift if placing at start
        },

        updateCourt(state, payload: IUpdateClubCourtMutationPayload) {
            const index = state.clubCourts.findIndex(c => c.id === payload.court.id);
            state.clubCourts.splice(index, 1, payload.court);
            state.clubCourts = [...state.clubCourts]; // replace courts
        },

        deleteCourt(state, payload: IDeleteClubCourtMutationPayload) {
            state.clubCourts = [...state.clubCourts.filter(c => c.id !== payload.courtId)];
        }

    },
    getters: {
        // clubCourts: state => (clubId) => {
        //     const result = state.clubCourts.find((item) => { return item.clubId == clubId });
        //     return result?.courts;
        // },
        clubCourts: state => (clubId) => {
            if(state.clubId != clubId)
                return [];
            return state.clubCourts;
        }
    },
    actions: {

        getClubCourtsAction({ commit }, clubId): Promise<Court[]> {        
            return apiClient.clubCourts(clubId)
                .then((courts) => {
                    commit("getCourts", { "clubId": clubId, "courts": courts });
                    return courts;
                });
        },

        updateClubCourtAction({ commit }, payload: IUpdateClubCourtAction): Promise<void> {

            if(!payload.court.validate())
                return Promise.reject(new Error("Invalid court details"));

            return apiClient.updateClubCourt(payload.court)
                .then(() => {
                    commit("updateCourt", { "court": payload.court });
                    return;
            });
        },

        addClubCourtAction({ commit, state }, payload: IAddClubCourtAction): Promise<void> {

            // create courts including use of defaults based on court booking configuration params
            let courtNumber = 1;

            const existingCourts = state.clubCourts;
            if(existingCourts.length > 0) {
                courtNumber = Math.max.apply(null, existingCourts.map((e) => { return e.number }));
                courtNumber++;	/* get one number higher than the current highest */
            }

            const name = "Court " + courtNumber;
            const newCourt = new Court({
                "name" : name,
                "club" : payload.clubId,
                "group": payload.courtGroupId,
                "number": courtNumber,
                "has_lights": payload.hasLights,
                "bookable": payload.bookable,
                "indoor": payload.indoor,
                "surface": payload.surface,
                "access_notes": payload.accessNotes,
                "notes": payload.notes
            });

            const courtSport = new Sport({
                id: payload.sportId
            });
            newCourt.sport = courtSport;

            if(!newCourt.validate())
                return Promise.reject(new Error("Invalid court details"));

            return apiClient.addClubCourt(newCourt)
                .then((returnedCourt) => {
                    commit("addCourt", { "court": returnedCourt });
                    return;
            });
        },

        deleteClubCourtAction({ commit }, payload: IDeleteClubCourtAction): Promise<void> {
            return apiClient.deleteClubCourt(payload.courtId)
                .then(() => {
                    commit("deleteCourt", { "courtId": payload.courtId });
                    return;
            });
        }

    }
}