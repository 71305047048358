const fixtureTypeOptions = [{
    text: "Inter-Club competition",
    value: "InterClub"
}, {
    text: "Intra-Club competition",
    value: "IntraClub"
}];

const playerGenderOptions = [{
    text: "Men Only",
    value: "mens"
}, {
    text: "Women Only",
    value: "womens"
}, {
    text: "Mixed",
    value: "mixed"
}, {
    text: "Flexible",
    value: "flexible"
}];

const typeOptions = [{
    text: "Round Robin",
    value: "roundrobin"
}, {
    text: "Round Robin + Knockout",
    value: "roundrobinknockout"
}];

const playFrequencyOptions = [{
    text: "Weekly",
    value: "weekly"
}, {
    text: "Fortnightly",
    value: "fortnightly"
}, {
    text: "Monthly",
    value: "monthly"
}];

/// TODO: Change this to create it using moment
const timePlayedOptions = ["7:00 am", "7:30 am", "8:00 am", "8:30 am",
"9:00 am", "9:30 am", "10:00 am", "10:30 am",
"11:00 am", "11:30 am", "12:00 pm", "12:30 pm", "1:00 pm", "1:30 pm",
"2:00 pm", "2:30 pm",
"3:00 pm", "3:30 pm", "4:00 pm", "4:30 pm", "5:00 pm", "5:30 pm", "6:00 pm",
"6:30 pm", "7:00 pm",
"7:30 pm", "8:00 pm", "8:30 pm", "9:00 pm", "9:30 pm", "10:00 pm",
"10:30 pm", "11:00 pm", "11:30 pm"
];

const matchNumberOfSetsOptions = [{
    text: "1 set",
    value: "1"
}, {
    text: "Best of 3",
    value: "3"
}, {
    text: "Best of 5",
    value: "5"
}];

const fillinSameRankOptions = [{
    text: "Yes",
    value: "true"
}, {
    text: "No",
    value: "false"
}];


const deuceOptions = [
    { text: "Normal Deuce", value: "normal" },
    { text: "Sudden Death", value: "suddendeath" }
];

const setsUpToOptions = [
    { text: "Standard set", value: "standard" },
    { text: "7", value: "7" },
    { text: "8", value: "8" },
    { text: "9", value: "9" },
    { text: "10", value: "10" },
    { text: "11", value: "11" },
    { text: "12", value: "12" }
];

const tieBreakerOptions = [
    { text: "Standard", value: "standard" },
    { text: "5-5", value: "5-5" },
    { text: "7-7", value: "7-7" },
    { text: "Other", value: "other" }
];

const pointsPerWinOptions = [ 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10 ];
const bonusPointsPerRoundOptions = [ 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10 ];

const cancelledPointsOptions = [
    { text: "Don’t allocate points", value: "nopoints" },
    { text: "Split points between two sides", value: "split" }
];

const bonusPointsWhenCancelledOptions = [
    { text: "Allocate bonus points to the team with most match wins", value: "mostMatchWins" },
    { text: "Allocate bonus points if there is a clear winner, otherwise split bonus points", value: "clearWinner" }
];

const numPlayerPointsSinglesMatchOptions = [ 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10 ];
const numPlayerPointsDoublesMatchOptions = [ 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10 ];

const teamBonusForDrawOptions = [
    { text: "Split bonus points", value: "split" },
    { text: "No bonus Points", value: "nobonus" }
];

const teamLadderSortOrderOptions = [
    { text: "Points", value: "Points" },
    { text: "Win/Loss Match ratio", value: "MatchWinLossRatio" },
    { text: "Win/Loss Sets ratio", value: "SetsWinLossRatio" },
    { text: "Win/Loss Games ratio", value: "GamesWinLossRatio" },
    { text: "Matches Won", value: "Won" },
    { text: "Sets Won", value: "WonSets" },
    { text: "Games Won", value: "WonGames" }
];

const playerLadderSortOrderOptions = [
    { text: "Points", value: "Points" },
    { text: "Win/Loss Match ratio", value: "MatchWinLossRatio" },
    { text: "Win/Loss Sets ratio", value: "SetsWinLossRatio" },
    { text: "Win/Loss Games ratio", value: "GamesWinLossRatio" },
    { text: "Matches Won", value: "WonMatches" },
    { text: "Sets Won", value: "WonSets" },
    { text: "Games Won", value: "WonGames" }
];

export default { fixtureTypeOptions, playerGenderOptions, typeOptions, playFrequencyOptions, timePlayedOptions, matchNumberOfSetsOptions,
    fillinSameRankOptions, deuceOptions, setsUpToOptions, tieBreakerOptions,
    pointsPerWinOptions, bonusPointsPerRoundOptions, cancelledPointsOptions, bonusPointsWhenCancelledOptions, 
    numPlayerPointsSinglesMatchOptions, numPlayerPointsDoublesMatchOptions, teamBonusForDrawOptions,
    teamLadderSortOrderOptions, playerLadderSortOrderOptions }