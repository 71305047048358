import { Club } from  './club';
import { Player } from '..';

export class ClubProduct {

	constructor(data) {
		this.id = data.id;
		this.name = data && data.name ? data.name : "";
		this.desc = data && data.desc ? data.desc : "";
		this.brand = data && data.brand ? data.brand : "";
		this.code = data && data.code ? data.code : "";
		this.category = data && data.category ? data.category : "";
		this.created = data && data.created ? data.created : "";		
		this.duration = data && data.duration ? data.duration.toString() : "";
		this.member_price = data && data.member_price ? data.member_price.toString()  : "";
		this.nonmember_price = data && data.nonmember_price ? data.nonmember_price.toString()  : "";
		this.tax_rate = data && data.tax_rate ? data.tax_rate.toString() : "";
		
		this.enabled = data && data.enabled ? data.enabled : undefined;

		this.club = data && data.club ? new Club(data.club) : undefined;
        this.created_by = data && data.created_by ? new Player(data.created_by) : null;
	}

	// Return the non member price formatted
	get nonmember_price_formatted() {
		if (!this.nonmember_price) return "0.00";
		return (Math.round(this.nonmember_price * 100) / 100).toFixed(2).toString();
	}

	// Return the member price formatted
	get member_price_formatted() {
		if (!this.member_price) return "0.00";
		return (Math.round(this.member_price * 100) / 100).toFixed(2).toString();
	}
}
