import { BookingSeries, Coach, Court } from '@/models';
import moment from 'moment';

export class BookingInstance {
    
    constructor(data) {
        
        this.id = data && data.id ? data.id : undefined;
        this.booking_series = data && data.booking_series ? new BookingSeries(data.booking_series) : new BookingSeries();
        this.start = data && data.start ? data.start : undefined;
        this.end = data && data.end ? data.end : undefined;
        
        this.court_set = [];
        if(data && data.court_set) {
            this.court_set = data.court_set.map((item) => new Court(item));
        }

        this.coach_set = [];
        if(data && data.coach_set) {
            this.coach_set = data.coach_set.map((item) => new Coach(item));
        }

        this.guests = data && data.guests ? data.guests : "";
    }

    get start_time() {
        if(this.start)
            return moment(this.start, moment.ISO_8601).format("HH:mm");
        else
            return "00:00";
    }

    set start_time(value) {
        if(this.start == undefined || value == undefined)
            return;

        const combinedDateTimeStr = moment(this.start).format("YYYY-MM-DD")+ "T" + value;
        this.start = moment(combinedDateTimeStr).format("YYYY-MM-DDTHH:mm:ss");
    }

    get end_time() {
        if(this.end)
            return moment(this.end, moment.ISO_8601).format("HH:mm");
        else
            return "00:00";
    }

    set end_time(value) {
        if(this.end == undefined || value == undefined)
            return;

        const combinedDateTimeStr = moment(this.end).format("YYYY-MM-DD") + "T" + value;
        this.end = moment(combinedDateTimeStr).format("YYYY-MM-DDTHH:mm:ss");
    }

    validate() {
        if(this.booking_series.ttype == 'C' && this.coach_set.length == 0)
            return false;

        return true;
    }

    // Return an array of the booked court names
    // Used to display the booked court for matcha hits
    get court_names_list() {
        const names = [];
        if (this.court_set.length == 0) return names;
        this.court_set.forEach(c => {
            names.push(c.name);
        })
		return names;
	}

    can_coach_edit(coach) {		

        // the coach can only edit this series if
        // 1. they are the author
        // 2. they are in the coach_set      
        if (!coach) return false;
        return this.booking_series.can_coach_edit(coach);
	}
}