
// Local storage of club cart information
// The key includes the club ID so cart storage is per club
// key format = club_ID_cart, club_234_cart

export const getValue = (key: string): string|null => {
  return window.localStorage.getItem(key);
};

export const saveValue = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const deleteValue = (key: string) => {
  window.localStorage.removeItem(key);
};

export default { getValue, saveValue, deleteValue };