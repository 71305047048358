import { ClubMembershipType } from '../club';

export class MembershipTypeBookingRate {
    
    constructor(data) {

        this.id = data && data.id ? data.id : undefined;
        this.booking_rate = data && data.booking_rate ? data.booking_rate : undefined;
        this.membership_type = data && data.membership_type ? new ClubMembershipType(data.membership_type) : undefined;

        this.can_book = data && data.can_book !== undefined  ? data.can_book : true;

        this.price = data && data.price !== undefined ? data.price : 0;
        this.name = data && data.name !== undefined ? data.name : undefined;
    }

    validate() {
            
        if(this.can_book) {
            if(!this.price || isNaN(this.price) || Number(this.price) < 0)
                return false;
        }

        return true;
    }
}