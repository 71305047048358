<script lang="ts">
   
    export default {
        name: 'NavMenu',
    }

</script>

<template>
    <!-- Navigation menu -->
    <div id="nav-container">
        <div class="site-container">
            <div id="menu-not-logged-in" class="cssmenu">
            <label for="tm" id="toggle-menu">Navigation <span class="drop-icon">▾</span></label>
            <input type="checkbox" id="tm">
                <ul class="main-menu">
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/#clubs">Clubs</router-link></li>
                    <li><router-link to="/#players">Players</router-link></li>
                    <li>
                        <label for="sm0" class="sub-heading" >Features <span>▾</span></label>
                        <input type="checkbox" id="sm0">
                        <ul class="sub-menu" id="sm0drop">
                            <li><router-link to="/tennis_find_a_hit_matcha">- Matcha</router-link></li>
                            <li><router-link to="/tennis_clinics">- Clinics</router-link></li>
                            <li><router-link to="/tennis_court_bookings">- Court Bookings</router-link></li>
                            <li><router-link to="/tennis_club_member_fee_collection">- Membership Fee Collection</router-link></li>
                            <li><router-link to="/tennis_club_member_communications">- Member Communications</router-link></li>
                            <li><router-link to="/tennis_club_point_of_sale">- Point of Sale</router-link></li>
                            <li><router-link to="/tennis_league_management">- League Management</router-link></li>
                            <li><router-link to="/tennis_circle">- Circle</router-link></li>
                            <li><router-link to="/tennis_player_profile">- Player Profile</router-link></li>
                            <li><router-link to="/tennis_match_diary">- Match Diary</router-link></li>
                            <li><router-link to="/slamfan">- Slam Fan</router-link></li>
                        </ul>
                    </li>
                    <li><router-link to="/faq">FAQ</router-link></li>
                    <li><router-link to="/feedback">Contact Us</router-link></li>
                </ul>
            </div>
        </div>
    </div>
</template>
