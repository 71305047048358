<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "ModalSlide",
    props: {
        isOpen: Boolean,
        customClass: String,
        closeTrigger: Boolean       // this is the trigger that commences the leave transition
    },
    computed: {
        computedTransition () {
            return 'slide';
        }
    },
    methods: {
        afterLeave() {
            this.$emit('modal-slide-close-complete');
        }
    }
});
</script>

<style lang="scss" scoped>

    .slide-enter-active,
    .slide-leave-active {
        transition: transform 0.5s;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(100vw);
    }

</style>

<style lang="scss">

    .modal.in {
        display: block !important;
    }

    .modal-body .modal-footer {
        margin: 25px -15px -15px;
    }

</style>

<template>
    <div v-if="isOpen">
        <div id="SiteSlideModal"
            class="modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            v-bind:class="[{ in: isOpen }, 'new-styles' ]"
            @click.stop >

            <!-- Display transition which is triggered when closing (closeTrigger), see afterLeave hook -->
            <transition :key="isOpen" v-bind:name="computedTransition" appear @after-leave="afterLeave">
                <div v-if="!closeTrigger" :class="[ customClass ]" >
                    <div class="modal-content">
                        <!-- modal body -->
                        <div class="modal-body">
                            <slot />
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div class="modal-backdrop fade in">
        </div>
    </div>
</template>