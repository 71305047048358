
export class MatchaLevel {
	
	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.name = data && data.name ? data.name : undefined;
		this.description = data && data.description ? data.description : undefined;
		this.number = data && data.number ? data.number : undefined;
		this.min_points = data && data.min_points ? data.min_points : undefined;
		this.max_points = data && data.max_points ? data.max_points : undefined;
		this.red = data && data.red ? data.red : undefined;
		this.green = data && data.green ? data.green : undefined;
		this.blue = data && data.blue ? data.blue : undefined;
	}

	getMatchaLevelTitle() {
		return this.number + " " + this.name;
	}

	getMatchaLevelRGB() {
		return 'rgb(' + this.red + ", " + this.green + ", " + this.blue + ")";
	}
}