import { apiClient } from '@/utils/apiclient';
import { ClubMember } from '@/models';
import { IGetClubMembersMutationPayload, IAddClubMemberMutationPayload, IUpdateClubMembersMutationPayload, IDeleteClubMemberMutationPayload, IGetClubMembersAction, IAddClubMemberAction, IUpdateClubMembersAction, IDeleteClubMemberAction, IUpdateClubMembersMembershipAction } from './club-members.types';

class State {
    clubId = "";
    clubMembers: ClubMember[] = [];
    nextClubMembersURL = "";
    prevClubMembersURL = "";
    ClubMembersTotal = 0;
    currentPage = 1;
}

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: new State(),
    mutations: {

        getMembers(state: State, payload: IGetClubMembersMutationPayload) {
            if(payload.clubId)
                state.clubId = payload.clubId;
            state.clubMembers = payload.members;            
        },
        
        getNextClubMembersURL(state: State, next) {
            state.nextClubMembersURL = next;
        },
        
        getPrevClubMembersURL(state: State, prev) {
            state.prevClubMembersURL = prev;
        },
        
        getClubMembersTotal(state: State, total) {
            state.ClubMembersTotal = total;
        },

        addMember(state: State, payload: IAddClubMemberMutationPayload) {
            state.clubMembers.push(payload.member) // mutate the members array - push for place at end, unshift if placing at start
        },

        updateMembers(state: State, payload: IUpdateClubMembersMutationPayload) {
            payload.members.forEach((member) => {
                const index = state.clubMembers.findIndex(c => c.id == member.id);
                state.clubMembers.splice(index, 1, member);
            });
        },

        updateMembersMembership(state: State, payload: IUpdateClubMembersMutationPayload) {
            payload.members.forEach((member) => {
                //console.log(member);
                const index = state.clubMembers.findIndex(c => c.id == member.id);
                state.clubMembers.splice(index, 1, member);
            });
        },

        deleteMember(state: State, payload: IDeleteClubMemberMutationPayload) {
            state.clubMembers = [...state.clubMembers.filter(c => c.id != payload.memberId)];
        },

        nextPage(state: State) {
            state.currentPage++;
        },

        prevPage(state: State) {
            state.currentPage--;
        },

        resetCurrentPage(state: State) {
            state.currentPage = 1;
        },

        clearClubMembers(state: State) {
            state.clubMembers = [];
        }

    },
    getters: {
        clubMembers: (state: State) => (clubId) => {
            if (state.clubId != clubId)
                return [];
            return state.clubMembers;
        },
        clubMembersTotal: (state: State) => state.ClubMembersTotal,
        hasNextPage: (state: State) => (state.nextClubMembersURL && state.nextClubMembersURL.length > 0) ? true : false,
        hasPrevPage: (state: State) => (state.prevClubMembersURL && state.prevClubMembersURL.length > 0) ? true : false,
        currentPage: (state: State) => state.currentPage,
    },
    actions: {

        getClubMembersAction({ commit }, payload: IGetClubMembersAction): Promise<ClubMember[]> {        
            return apiClient.clubMembers(payload.clubId, payload.pageSize, payload.searchParams)
                .then((result) => {
                    const results = result.results.map(
                        (item: any) => new ClubMember(item)
                    );

                    commit("getMembers", { "clubId": payload.clubId, "members": results });
                    commit("getNextClubMembersURL", result.next);
                    commit("getPrevClubMembersURL", result.previous);
                    commit("getClubMembersTotal", result.count);
                    commit("resetCurrentPage");

                    return results;
                });
        },

        getNextPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.nextClubMembersURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new ClubMember(item)
                    );
                    commit("getMembers", { "members": results });
                    commit("getNextClubMembersURL", result.next);
                    commit("getPrevClubMembersURL", result.previous);
                    commit("getClubMembersTotal", result.count);
                    commit("nextPage");

                    return results;
                })
        },

        getPrevPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.prevClubMembersURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new ClubMember(item)
                    );
                    commit("getMembers", { "members": results });
                    commit("getNextClubMembersURL", result.next);
                    commit("getPrevClubMembersURL", result.previous);
                    commit("getClubMembersTotal", result.count);
                    commit("prevPage");

                    return results;
                })
        },

        updateClubMembersAction({ commit }, payload: IUpdateClubMembersAction): Promise<void> {

            payload.members.map((member: ClubMember) => {
                if(!member.validate())
                    return Promise.reject(new Error("Invalid member details"));
            });

            return apiClient.updateClubMember(payload.clubId, payload.members)
                .then(() => {
                    commit("updateMembers", { "members": payload.members });
                    return;
            });
        },

        addClubMemberAction({ commit }, payload: IAddClubMemberAction): Promise<void> {

            if(!payload.newMember.validate())
                return Promise.reject(new Error("Invalid member details"));

            return apiClient.addClubMembersBulk(payload.clubId, [payload.newMember])
                .then((result) => {

                    if(result.error_rows.length > 0)
                        throw Error(result.error_rows[0].error);

                    commit("addMember", { "member": new ClubMember(result.members[0]) });
                    return;
            });
        },

        deleteClubMemberAction({ commit }, payload: IDeleteClubMemberAction): Promise<void> {

            return apiClient.deleteClubMember(payload.memberId)
                .then(() => {
                    commit("deleteMember", { "memberId": payload.memberId });
                    return;
            });
        },

        updateClubMembersMembershipAction({ commit }, payload: IUpdateClubMembersMembershipAction): Promise<void> {

            payload.members.map((member: ClubMember) => {
                if(!member.validate())
                    return Promise.reject(new Error("Invalid membership details"));     // Note: this has the potential to fail due to changes they haven't made in this edit, so we may want to remove this check
            });

            return apiClient.updateClubMemberMembership(payload.clubId, payload.members)
                .then(() => {
                    commit("updateMembersMembership", { "members": payload.members });
                    return;
            });
        },

        clearClubMembersAction({ commit }) {
            commit("clearClubMembers");
        }
    }
}