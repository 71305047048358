export class NewsArticle {

    constructor(data) {
        this.id = data.id;
        this.text = data.text ? data.text : ""
        this.stamp = data.stamp ? data.stamp : "";
        this.headline = data.headline ? data.headline : "";
        this.readmore = data.readmore ? data.readmore : "";
        this.firstline = data.firstline ? data.firstline : "";
        // this.internal = data.internal ? data.internal : false;
        this.thumbnailUrl = data.thumb ? data.thumb : "";
    }
}