import 'jquery-ui/ui/widgets/datepicker';
import $ from 'jquery';
import Vue from 'vue';

export const datepicker = {
    inserted (el, binding, vnode){
        let options = binding.value || {};

        const defaultOptions = { 
            changeMonth: true,
            changeYear: true,      
            dateFormat: 'M d yy',
            showAnim: 'slideDown',
            yearRange: "2018:+50Y",
            onSelect: function(){
                vnode.elm.dispatchEvent(new CustomEvent('input'));
            }
        };

        if(options.fullDate) {
            options.dateFormat = 'DD d MM, yy'
        }
        else if(options.noYear) {   
            options.dateFormat = 'M d';
            options.defaultDate = new Date('0000, 1, 1');
            options.yearRange = "";
        }
        
        options = $.extend(true, {}, defaultOptions, options);

        $(el)
            .datepicker(options);
        
        if(options.noYear) {
            $(el).focus(function () {
                $(".ui-datepicker-year").hide();
            });
        }

        if(!options.allowKeyboardEdit) {
            $(el).attr("readonly", "true");
        }
    }
}

Vue.directive('datepicker', datepicker);