import { Club, Court } from "@/models/club";
import { Coach } from "@/models/coach";
import { EventSession, EventHoursOfSingleSession, EventMembershipTypeRate } from '.';
import moment from 'moment';
import Utilities from '@/utils/utilities';

export class Event {
    
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? new Club(data.club) : null;

        this.name = data && data.name ? data.name : ''; 
        this.desc = data && data.desc ? data.desc : ''; 
        this.ttype = data && data.ttype !== undefined ? data.ttype : undefined;
        this.can_join_single_session = data && data.can_join_single_session !== undefined ? data.can_join_single_session : false;
        this.allow_nonmember = data && data.allow_nonmember !== undefined ? data.allow_nonmember : false;
        this.allowed_gender = data && data.allowed_gender !== undefined ? data.allowed_gender : undefined;
        this.max_participant_session = data && data.max_participant_session !== undefined ? data.max_participant_session : undefined; 
        this.min_age = data && data.min_age !== undefined ? data.min_age : undefined;
		this.max_age = data && data.max_age !== undefined ? data.max_age : undefined;
        this.min_ntrp = data && data.min_ntrp !== undefined ? data.min_ntrp : undefined;
		this.max_ntrp = data && data.max_ntrp !== undefined ? data.max_ntrp : undefined;
        this.generic_levels = data && data.generic_levels ? data.generic_levels : [];
        this.start_date = data && data.start_date ? data.start_date : undefined;
        this.end_date = data && data.end_date ? data.end_date : undefined;
        this.num_sessions = data && data.num_sessions ? data.num_sessions : undefined; 
        this.def_session_dur_mins = data && data.def_session_dur_mins ? data.def_session_dur_mins : undefined; 
        this.def_start_time = data && data.def_start_time ? data.def_start_time : undefined; 
        this.recurrence = data && data.recurrence ? data.recurrence : '';
        this.excluded_dates = data && data.excluded_dates ? data.excluded_dates : [];
        this.currency_code = data && data.currency_code ? data.currency_code : '';
        this.member_app_join = data && data.member_app_join !== undefined ? data.member_app_join : true;
        this.nonmember_app_join = data && data.nonmember_app_join !== undefined ? data.nonmember_app_join : true;
        this.member_advance_join_days = data && data.member_advance_join_days !== undefined ? data.member_advance_join_days : undefined; 
        this.nonmember_advance_join_days = data && data.nonmember_advance_join_days !== undefined ? data.nonmember_advance_join_days : undefined; 
        this.member_advance_days_method = data && data.member_advance_days_method !== undefined ? data.member_advance_days_method : ''; 
        this.nonmember_advance_days_method = data && data.nonmember_advance_days_method !== undefined ? data.nonmember_advance_days_method : ''; 
        this.allow_waitlisting = data && data.allow_waitlisting !== undefined ? data.allow_waitlisting : false;
        this.waitlist_advance_hours = data && data.waitlist_advance_hours !== undefined ? data.waitlist_advance_hours : ''; 

        this.status = data && data.status ? data.status : ''; 
        this.modified = data && data.modified ? data.modified : undefined;
        this.created = data && data.created ? data.created : undefined;

        this.session_set = [];
        if (data && data.session_set) {
            this.session_set = data.session_set.map((item) => new EventSession(item));
        }

        this.court_set = [];
        if (data && data.court_set) {
            this.court_set = data.court_set.map((item) => new Court(item));
        }

        this.coach_set = [];
        if(data && data.coach_set) {
			this.coach_set = data.coach_set.map((item) => new Coach(item));
		}

        this.membershiptype_rate_set = [];
        if(data && data.membershiptype_rate_set) {
			this.membershiptype_rate_set = data.membershiptype_rate_set.map((item) => new EventMembershipTypeRate(item));
		}

        // days of the week
        // we flatten the days of week object to the following format
        // - id: 0, name: Monday, start: 14:00, end: 15:00
        // session days of week is an array of the above objects
        this.sessionDaysOfWeek = null;
		if(data && data.days_of_week) {

            this.sessionDaysOfWeek = [];
			Object.keys(data.days_of_week).map((key) => {
				// The structure that comes from the server is quite unique for this object:
				// 	   { id: [{ start: "", end: "" }] }
				// where id is the day of the week number, e.g.
				//     { 3: [{ start: "17:00", end: "7:00" }] }

                // item represents a single day
                // extract the session times within it, there may be multiple
                const sessions = data.days_of_week[key];
                sessions.forEach(sess => {    
                    const sessionTimesNiceFormat = {};
                    sessionTimesNiceFormat.id = key;	
                    sessionTimesNiceFormat.name = Utilities.daysOfWeekDictionary[key].name;
                    sessionTimesNiceFormat.start = sess && sess.start ? sess.start : undefined;
                    sessionTimesNiceFormat.end = sess && sess.end ? sess.end : undefined;
    
                    this.sessionDaysOfWeek.push(new EventHoursOfSingleSession(sessionTimesNiceFormat));
                });				
			});
		}

        // not persisted to the database
        this.proficiencySetting = '';
        if (this.generic_levels.length > 0) this.proficiencySetting = 'SELF';
        else if (this.min_ntrp && this.max_ntrp) this.proficiencySetting = 'NTRP';

        // no longer used since the adoption of EventMembershipTypeRate
        this.member_clinic_price = data && data.member_clinic_price !== undefined ? data.member_clinic_price : undefined;
        this.nonmember_clinic_price = data && data.nonmember_clinic_price !== undefined ? data.nonmember_clinic_price : undefined; 
        this.member_single_price = data && data.member_single_price !== undefined ? data.member_single_price : undefined; 
        this.nonmember_single_price = data && data.nonmember_single_price !== undefined ? data.nonmember_single_price : undefined
    }

    validate() {

        if (!this.club)
            return false;

        if (!this.name)
            return false;

        if (!this.desc)
            return false;

        if (!this.ttype)
            return false;

        return true;
    }

    // Return the full status of the clinic (CANCELLED, DRAFT, ACTIVE, COMPLETED)
    getStatusFull() {
        if (this.isDraft()) return "Draft";
        else if (this.isCancelled()) return "Cancelled";
        else if (this.isCompleted()) return "Completed";
        else if (this.isLive()) return "Published|Live";
        return "Published";
    }
    isDraft() {
        // event is still a draft
        return this.status == '' || this.status == 'D';
    }    
    isCancelled() {
        // canceled event
        return this.status == 'C';
    }  
    isCompleted() {
        // event is published but is now in the past
        const endDate = this.getClinicActualEndDate();        
        // add one day to the end date so we can perform
        // a proper less than comparison
        let e = new Date(endDate);
        e = moment(e).add(1, 'd');
        return this.status == 'P' && e < new Date();
    }       
    isPublished() {
        // the event is published
        return this.status == 'P';
    }
    isLive() {
        try {
            const startDate = new Date(this.start_date);            
            // add one day to the end date so we can perform
            // a proper less than comparison
            let endDate = this.getClinicActualEndDate(); 
            endDate = moment(endDate).add(1, 'd');
            const now = new Date();
            return this.isPublished() && startDate < now && endDate > now;
        }
        catch {
            return false;
        }
    }

    // Return the actual end date for the clinic
    getClinicActualEndDate() {
        let endDate = this.end_date;
        if (!this.end_date) {
            endDate = new Date(Math.max.apply(null, this.session_set.map(function(e) {
                return new Date(e.end);
            })));
        }
        return endDate;
    }

    // Return an object that is ready for the server (add or patch)
    getEventForServer() {

        //console.log(this);

        const data = {
            club: this.club.id,
            name: this.name, 
            desc: this.desc,
            ttype: this.ttype,
            min_age: this.min_age,
            max_age: this.max_age,            
            start_date: this.start_date,  
            generic_levels: this.generic_levels,
            def_session_dur_mins: this.def_session_dur_mins,
            recurrence: this.recurrence,
            excluded_dates: this.excluded_dates,
            allowed_gender: this.allowed_gender,
            member_app_join: this.member_app_join,
            nonmember_app_join: this.nonmember_app_join,
            member_advance_days_method: this.member_advance_days_method,
            nonmember_advance_days_method: this.nonmember_advance_days_method,
            allow_waitlisting: this.allow_waitlisting
        };

        if (this.id) {
            data.id = this.id;
        }

        data["max_participant_session"] = this.max_participant_session == '' ? 0 : parseInt(this.max_participant_session);       
        data["member_advance_join_days"] = this.member_advance_join_days == '' ? 0 : parseInt(this.member_advance_join_days);
        data["nonmember_advance_join_days"] = this.nonmember_advance_join_days == '' ? 0 : parseInt(this.nonmember_advance_join_days);        

        // Waitlisting hours set to 0 when clinic not supporting waitlisting
        if (this.allow_waitlisting) {
            data["waitlist_advance_hours"] = this.waitlist_advance_hours == '' ? 0 : parseInt(this.waitlist_advance_hours);  
        }
        else {
            data["waitlist_advance_hours"] = 0;
        }

        // we send either number of sessions OR the end date, cannot send both, one has to be null
        data["num_sessions"] = !this.num_sessions || this.num_sessions == '' ? null : parseInt(this.num_sessions);
        data["end_date"] = !this.end_date || this.end_date == '' ? null : this.end_date;
        if (data["num_sessions"] && data["end_date"]) {
            data["num_sessions"] = null;
        }

        data["court_set"] = this.court_set.map(c => { return c.id; });
        data["coach_set"] = this.coach_set.map(c => { return c.id; });

        // proficiency
        if (this.proficiencySetting == "") {
            data["generic_levels"] = [];
            data["max_ntrp"] = null;
            data["min_ntrp"] = null;
        }
        else if (this.proficiencySetting == "SELF") {
            data["max_ntrp"] = null;
            data["min_ntrp"] = null;
            data["generic_levels"] = this.generic_levels;
        }
        else if (this.proficiencySetting == "NTRP") {
            data["generic_levels"] = [];
            data["max_ntrp"] = this.max_ntrp;
            data["min_ntrp"] = this.min_ntrp;
        }

        // create days of week from the sessionDaysOfWeek field
        if (this.recurrence == "W" && this.sessionDaysOfWeek && this.sessionDaysOfWeek.length > 0) {
            const days_of_week = {};
            this.sessionDaysOfWeek.forEach(s => {
                const stringKey = s.id.toString();
                if (!days_of_week[stringKey]) days_of_week[stringKey] = [];
                days_of_week[stringKey].push({
                    start: s.start,
                    end: s.end
                })
            })
            data.days_of_week = days_of_week;
            data.def_start_time = null;
        }
        else if (this.recurrence == "D") {
            data.days_of_week = null;
            data.def_start_time = this.def_start_time;
        }

        // membership type rates are not included as they are managed seperately

        return data;
    }
}