<script lang="ts">  
    import { Club } from '@/models';
    import { mapGetters, mapActions } from 'vuex';   
    import { Player } from "@/models/player";
    import $ from 'jquery';
    import ClubAdminRolesEnum from '@/utils/enum/club-admin-roles-enum'; 
    import Cookies from "js-cookie";
    import Vue from 'vue';
       
    export default Vue.extend({
        name: 'DashboardLayout',
        data() {
            return {
                isSideMenuCollapsed: false,
                agentAdminCode: ClubAdminRolesEnum.AGENT
            }
        },
        computed: {
            ...mapGetters({                
                isInClubRole: 'currentPlayer/isInClubRole',
                isCoachOfClub: 'currentPlayer/isCoachOfClub',
                currentPlayer: 'currentPlayer/currentPlayer',     
                dashboardActiveMenu: 'dashboard/dashboardActiveMenu',
                dashboardClub: 'dashboard/dashboardClub'
            }),
            currentUserFullName(): string {
                return (this.currentPlayer as Player)?.full_name;    
            },
            showClubDashboard(): boolean {
                if (this.dashboardClub != null) return true;
                return false;
            },
            // access and permissions
            // for admins AND for club coaches
            accessMembers(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.MEMBERSHIP_FEES));
            },
            accessMatcha(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.MATCHA));
            },
            accessCourtBookings(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COURT_BOOKINGS)
                     || this.isCoachOfClub(this.dashboardClub.id));
            },
            accessCourtBookingsConfiguration(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COURT_BOOKINGS));
            },
            accessInsights(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.MEMBERSHIP_FEES) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COURT_BOOKINGS));
            },
            accessClinics(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COURT_BOOKINGS));
            },
            accessNewslettersAndMessages(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COMMUNCIATIONS));
            },
            accessPayments(): boolean  {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.MEMBERSHIP_FEES) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COURT_BOOKINGS));
            },
            accessPointOfSale(): boolean {
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.POINT_OF_SALE));
            },
            accessClubSettings(): boolean {
                // include all the admin roles here because coaches are not permitted
                return (this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.AGENT) 
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COURT_BOOKINGS)
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.MATCHA)
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.MEMBERSHIP_FEES)
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.COMMUNCIATIONS)
                     || this.isInClubRole(this.dashboardClub.id, ClubAdminRolesEnum.POINT_OF_SALE));
            },
            accessSlamFan(): boolean {
                // is the user an administrator for SlamFan
                return this.currentPlayer.slamfan_admin;
            }
        },
        methods: {
            logout() {
                this.setDashboardActiveMenu(null);
                this.setDashboardClub(null);
                Cookies.remove("authtoken");
                localStorage.removeItem("CurrentPlayer");
                localStorage.removeItem("dashboardActiveMenu");
                window.location.href = "/";
            },
            getClubDashboardSectionURL(sectionName: string, club: Club): string {
                if (club == null) return '/clubs/';
                return `/clubs/dashboard/${sectionName}/?clubId=${club?.id}`;
            },
            dashboardMenuClick(identifier: string) {
                this.setDashboardActiveMenu(identifier);
            },
            removeSelectedDashboardMenu() {
                this.setDashboardActiveMenu(null);
            },
            toggleSideMenu(collapse: boolean) {
                // toggle the display of the side navigation menu
                this.isSideMenuCollapsed = collapse;
            },
            gotoHrefLink(link: string) {
                try {
                    // attempt to open the menu link
                    $(`a[href="#` + link + `"]`)[0].click();
                }
                catch(err) {
                    // if the link cannot be found, it's more tha likely the feature is not includes in their subscription
                    // eslint-disable-next-line
                    console.log('Str8 Sets: The link is inactive, please check if your subscription contains this feature.')
                }                
            },
            ...mapActions({
                setDashboardActiveMenu: 'dashboard/setDashboardActiveMenu',
                setDashboardClub: 'dashboard/setDashboardClubAction'
            })
        },
        mounted() {
            // the clubs view is shown by default when the user logs in
            // check if another selection has not already been set, if found use that instead
            if (this.dashboardActiveMenu == null) {
                // dashboard is opened for the first time OR page reload
                // try to find the menu item in local storage
                const actMenu = localStorage.getItem('dashboardActiveMenu');
                if (actMenu && actMenu.length > 0) {
                    this.dashboardMenuClick(actMenu);
                }
                else this.dashboardMenuClick('clubs');
            }
            else this.dashboardMenuClick('clubs');
        }
    });
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="new-styles">

        <div class="dashboard-grid-container" :class="[{ hidesmallnav: !isSideMenuCollapsed }, { hidelargenav: isSideMenuCollapsed }]">

            <!-- header -->
            <header class="dashboard-header">
                <div class="dashboard-header-top">

                    <div class="user-menu-main">
                        <ul>
                            <li class="dropdown">
                                <a href="#" 
                                    class="dropdown-toggle" 
                                    data-toggle="dropdown" 
                                    role="button" 
                                    aria-haspopup="true" 
                                    aria-expanded="false">{{ currentUserFullName }} <img src="@/assets/images/user.svg" alt="User" />
                                </a>
                                <ul class="dropdown-menu">
                                    <li><router-link @click.native="removeSelectedDashboardMenu" to="/faq">FAQ</router-link></li>
                                    <li><router-link @click.native="removeSelectedDashboardMenu" to="/feedback">Contact Us</router-link></li>
                                    <li role="separator" class="divider"></li>
                                    <li><a href="javascript:;" v-on:click="logout">Log out</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                </div>
            </header>

            <!-- side navigation small -->
            <aside v-show="isSideMenuCollapsed" key="menu-small" class="dashboard-sidenavsmall">
                <router-link :to="{ name: 'ClubsIndex' }" title="Str8 Sets" rel="home" class="dashboard-logo-small" >
                    <img
                        src="@/assets/images/logo.svg"
                        alt="Str8 Sets"
                        width="44px"
                        data-no-retina
                        @click="dashboardMenuClick('clubs')"
                    />
                </router-link>

                <div v-show="isSideMenuCollapsed" class="circle right" @click="toggleSideMenu(false)"></div>
                <div class="side-spacer-invisible"></div>    

                <!-- Club Dashboard Menu -->
                <div v-if="showClubDashboard & dashboardClub != null">                                
                    <div class="menu-main">
                        <ul>
                            <!-- club members -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-members'}" 
                                @click="dashboardMenuClick('club-members')">
                                <router-link :class="{ noaccessuser:!accessMembers }" 
                                             :to="getClubDashboardSectionURL('members', dashboardClub)">
                                             <img src="@/assets/images/members-white.svg" alt="Members" />
                                </router-link>                                
                            </li>      

                            <!-- court bookings -->             
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-bookings'}" 
                                @click="dashboardMenuClick('club-bookings')">
                                <router-link :class="{ noaccessuser:!accessCourtBookings }" 
                                             :to="getClubDashboardSectionURL('court-bookings', dashboardClub)">
                                             <img src="@/assets/images/bookings-white.svg" alt="Bookings" />
                                </router-link>
                            </li>   

                            <!-- matcha -->   
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-matcha'}" 
                                @click="dashboardMenuClick('club-matcha')">
                                <router-link :class="{ noaccessuser:!accessMatcha }" 
                                             :to="getClubDashboardSectionURL('matcha', dashboardClub)">
                                             <img src="@/assets/images/matcha-white.svg" alt="Matcha" />
                                </router-link>
                            </li>  

                            <!-- clinics -->  
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-clinics'}"                                 
                                @click="dashboardMenuClick('club-clinics')">
                                <router-link :class="{ noaccessuser:!accessClinics }" 
                                             :to="getClubDashboardSectionURL('clinics', dashboardClub)">
                                             <img src="@/assets/images/clinics-white.svg" alt="Clinics" />
                                </router-link>
                            </li>    

                            <!-- club insights -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-insights'}" 
                                @click="dashboardMenuClick('club-insights')">
                                <router-link :class="{ noaccessuser:!accessInsights }" 
                                             :to="getClubDashboardSectionURL('insights', dashboardClub)">
                                             <img src="@/assets/images/insights-white.svg" alt="Insights" />
                                </router-link>
                            </li>    

                            <!-- messages --> 
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-messages'}" 
                                @click="dashboardMenuClick('club-messages')">
                                <router-link :class="{ noaccessuser:!accessNewslettersAndMessages }" 
                                             :to="getClubDashboardSectionURL('messages', dashboardClub)">
                                             <img src="@/assets/images/messages-white.svg" alt="Messages" />
                                </router-link>
                            </li>      

                            <!-- newsletters -->  
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-newsletters'}" 
                                @click="dashboardMenuClick('club-newsletters')">
                                <router-link :class="{ noaccessuser:!accessNewslettersAndMessages }" 
                                             :to="getClubDashboardSectionURL('newsletters', dashboardClub)">
                                             <img src="@/assets/images/newsletters-white.svg" alt="Newsletters" />
                                </router-link>
                            </li>    

                            <!-- club store -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-pos'}" 
                                @click="dashboardMenuClick('club-pos')">
                                <router-link :class="{ noaccessuser:!accessPointOfSale }" 
                                             :to="getClubDashboardSectionURL('point-of-sale', dashboardClub)">
                                             <img src="@/assets/images/store-white.svg" alt="Club Store" />
                                </router-link>
                            </li>      

                            <!-- payments --> 
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-payments'}" 
                                @click="dashboardMenuClick('club-payments')">
                                <router-link :class="{ noaccessuser:!accessPayments }" 
                                             :to="getClubDashboardSectionURL('transactions', dashboardClub)">
                                             <img src="@/assets/images/payments-white.svg" alt="Payments" />
                                </router-link>
                            </li>      

                            <!-- club information -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-info'}" 
                                @click="dashboardMenuClick('club-info')">
                                <router-link :to="getClubDashboardSectionURL('club-info', dashboardClub)">
                                             <img src="@/assets/images/settings-white.svg" alt="Club Settings" />
                                </router-link>
                            </li>       
                        </ul>
                    </div>
                    <div class="side-spacer"></div> 
                </div>

                <!-- Standard Menu -->                               
                <div class="menu-main">
                    <ul>
                        <li :class="{menu_main_active:dashboardActiveMenu === 'competitions'}" 
                            @click="dashboardMenuClick('competitions')">
                            <router-link to="/tour"><img src="@/assets/images/comps-white.svg" alt="Competitions" /></router-link>
                        </li>
                        <li :class="{menu_main_active:dashboardActiveMenu === 'clubs'}"
                            @click="dashboardMenuClick('clubs')">
                            <router-link to="/clubs"><img src="@/assets/images/clubs-white.svg" alt="Clubs" /></router-link>
                        </li>                        
                        <li :class="{menu_main_active:dashboardActiveMenu === 'matches'}" 
                            @click="dashboardMenuClick('matches')">
                            <router-link to="/matches"><img src="@/assets/images/matches-white.svg" alt="Matches" /></router-link>
                        </li>
                        <li :class="{menu_main_active:dashboardActiveMenu === 'news'}" 
                            @click="dashboardMenuClick('news')">
                            <router-link to="/news"><img src="@/assets/images/news-white.svg" alt="News" /></router-link>
                        </li>                    
                        <li v-if="accessSlamFan" :class="{menu_main_active:dashboardActiveMenu === 'slamfanadmin'}" 
                            @click="dashboardMenuClick('slamfanadmin')">
                            <router-link to="/slamfanadmin"><img src="@/assets/images/logo-slamfan.svg" alt="SlamFan" /></router-link>
                        </li> 
                    </ul>
                </div>
            </aside>

            <!-- side navigation large -->
            <aside v-show="!isSideMenuCollapsed" key="menu-large" class="dashboard-sidenav">
                <router-link :to="{ name: 'ClubsIndex' }" title="Str8 Sets" rel="home" class="dashboard-logo" >
                    <img
                        src="@/assets/images/logo-horizontal-white.svg"
                        alt="Str8 Sets"
                        width="140px"
                        data-no-retina
                        @click="dashboardMenuClick('clubs')"
                    />
                </router-link>

                <div v-show="!isSideMenuCollapsed" class="circle left" @click="toggleSideMenu(true)"></div>
                <div class="side-spacer-invisible"></div> 

                <!-- Club Dashboard Menu -->
                <div v-if="showClubDashboard & dashboardClub != null">                                   
                    <div class="menu-main">
                        <ul>
                            <!-- club members -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-members'}" 
                                @click="dashboardMenuClick('club-members')">
                                <router-link :class="{ noaccessuser:!accessMembers }" 
                                             :to="getClubDashboardSectionURL('members', dashboardClub)">
                                             <img src="@/assets/images/members-white.svg" alt="Members" />Members
                                             <div v-show="accessMembers" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-members'}"/>
                                </router-link>
                            </li>    
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessMembers && dashboardActiveMenu === 'club-members'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('members')">Members List</a></li>
                                    <li><a @click="gotoHrefLink('configuration')">Settings</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- court bookings -->          
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-bookings'}" 
                                @click="dashboardMenuClick('club-bookings')">
                                <router-link :class="{ noaccessuser:!accessCourtBookings }" 
                                             :to="getClubDashboardSectionURL('court-bookings', dashboardClub)">
                                             <img src="@/assets/images/bookings-white.svg" alt="Bookings" />Bookings
                                             <div v-show="accessCourtBookings" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-bookings'}"/>
                                </router-link>
                            </li>      
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessCourtBookings && dashboardActiveMenu === 'club-bookings'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('bookings')">Court Bookings</a></li>
                                    <li v-if="accessCourtBookingsConfiguration"><a @click="gotoHrefLink('courts')">Courts</a></li>
                                    <li v-if="accessCourtBookingsConfiguration"><a @click="gotoHrefLink('equipment')">Equipment</a></li>
                                    <li v-if="accessCourtBookingsConfiguration"><a @click="gotoHrefLink('configuration')">Settings</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- matcha -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-matcha'}" 
                                @click="dashboardMenuClick('club-matcha')">
                                <router-link :class="{ noaccessuser:!accessMatcha }" 
                                             :to="getClubDashboardSectionURL('matcha', dashboardClub)">
                                             <img src="@/assets/images/matcha-white.svg" alt="Matcha" />Matcha
                                             <div v-show="accessMatcha" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-matcha'}"/>
                                </router-link>
                            </li>   
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessMatcha && dashboardActiveMenu === 'club-matcha'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('hitrequests')">Hit Requests</a></li>
                                    <li><a @click="gotoHrefLink('configuration')">Settings</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- clinics -->   
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-clinics'}"                                 
                                @click="dashboardMenuClick('club-clinics')">
                                <router-link :class="{ noaccessuser:!accessClinics }" 
                                             :to="getClubDashboardSectionURL('clinics', dashboardClub)">
                                             <img src="@/assets/images/clinics-white.svg" alt="Clinics" />Clinics
                                             <div v-show="accessInsights" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-clinics'}"/>
                                </router-link>
                            </li>     
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessClinics && dashboardActiveMenu === 'club-clinics'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('clinics')">Clinics</a></li>
                                    <li><a @click="gotoHrefLink('waiting-list')">Waiting List</a></li>
                                    <li><a @click="gotoHrefLink('configuration')">Settings</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- club insights -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-insights'}" 
                                @click="dashboardMenuClick('club-insights')">
                                <router-link :class="{ noaccessuser:!accessInsights }" 
                                             :to="getClubDashboardSectionURL('insights', dashboardClub)">
                                             <img src="@/assets/images/insights-white.svg" alt="Insights" />Insights
                                             <div v-show="accessInsights" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-insights'}"/>
                                </router-link>
                            </li>     
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessInsights && dashboardActiveMenu === 'club-insights'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('court_bookings')">Court Bookings</a></li>
                                    <li><a @click="gotoHrefLink('coaches')">Coaches</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- messages --> 
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-messages'}" 
                                @click="dashboardMenuClick('club-messages')">
                                <router-link :class="{ noaccessuser:!accessNewslettersAndMessages }" 
                                             :to="getClubDashboardSectionURL('messages', dashboardClub)">
                                             <img src="@/assets/images/messages-white.svg" alt="Messages" />Messages
                                             <div v-show="accessNewslettersAndMessages" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-messages'}"/>
                                </router-link>
                            </li>    
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessNewslettersAndMessages && dashboardActiveMenu === 'club-messages'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('messages')">Messages</a></li>
                                    <li><a @click="gotoHrefLink('managegroups')">Manage Groups</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- newsletters -->  
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-newsletters'}" 
                                @click="dashboardMenuClick('club-newsletters')">
                                <router-link :class="{ noaccessuser:!accessNewslettersAndMessages }" 
                                             :to="getClubDashboardSectionURL('newsletters', dashboardClub)">
                                             <img src="@/assets/images/newsletters-white.svg" alt="Newsletters" />Newsletters
                                </router-link>
                            </li>

                            <!-- club store -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-pos'}" 
                                @click="dashboardMenuClick('club-pos')">
                                <router-link :class="{ noaccessuser:!accessPointOfSale }" 
                                             :to="getClubDashboardSectionURL('point-of-sale', dashboardClub)">
                                             <img src="@/assets/images/store-white.svg" alt="Club Store" />Club Store
                                             <div v-show="accessPointOfSale" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-pos'}"/>
                                </router-link>
                            </li>     
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessPointOfSale && dashboardActiveMenu === 'club-pos'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('store')">Club Store</a></li>
                                    <li><a @click="gotoHrefLink('manageitems')">Manage Items</a></li>
                                    <li><a @click="gotoHrefLink('configuration')">Settings</a></li>
                                </ul>
                            </div>
                            </Transition>

                            <!-- payments --> 
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-payments'}" 
                                @click="dashboardMenuClick('club-payments')">
                                <router-link :class="{ noaccessuser:!accessPayments }" 
                                             :to="getClubDashboardSectionURL('transactions', dashboardClub)">
                                             <img src="@/assets/images/payments-white.svg" alt="Payments" />Payments
                                </router-link>
                            </li>

                            <!-- club information -->
                            <li :class="{menu_main_active:dashboardActiveMenu === 'club-info'}" 
                                @click="dashboardMenuClick('club-info')">
                                <router-link :class="{ noaccessuser:!accessClubSettings }"
                                             :to="getClubDashboardSectionURL('club-info', dashboardClub)">
                                             <img src="@/assets/images/settings-white.svg" alt="Club Settings" />Club Settings
                                             <div v-show="accessClubSettings" class="menu-sub-arrow" :class="{ open: dashboardActiveMenu === 'club-info'}"/>
                                </router-link>
                            </li>  
                            <Transition name="fade"> 
                            <div class="menu-sub-main" v-show="accessClubSettings && dashboardActiveMenu === 'club-info'">  
                                <ul>
                                    <li><a @click="gotoHrefLink('hoursofoperation')">Hours</a></li>
                                    <li><a @click="gotoHrefLink('administrators')">Admins</a></li>
                                    <li><a @click="gotoHrefLink('facilities')">Facilities</a></li>
                                    <li><a @click="gotoHrefLink('coaches')">Coaches</a></li>
                                    <li v-if="isInClubRole(dashboardClub.id, agentAdminCode)"><a @click="gotoHrefLink('subscription')">Subscription</a></li>
                                </ul>
                            </div>   
                            </Transition>  
                        </ul>
                    </div>
                    <div class="side-spacer"></div>
                </div>

                <!-- Standard Menu -->                                
                <div class="menu-main">
                    <ul>
                        <li :class="{menu_main_active:dashboardActiveMenu === 'competitions'}" 
                            @click="dashboardMenuClick('competitions')">
                            <router-link to="/tour"><img src="@/assets/images/comps-white.svg" alt="Competitions" />Competitions</router-link>
                        </li>
                        <li :class="{menu_main_active:dashboardActiveMenu === 'clubs'}" 
                            @click="dashboardMenuClick('clubs')">
                            <router-link to="/clubs"><img src="@/assets/images/clubs-white.svg" alt="Clubs" />Clubs</router-link>
                        </li>                        
                        <li :class="{menu_main_active:dashboardActiveMenu === 'matches'}" 
                            @click="dashboardMenuClick('matches')">
                            <router-link to="/matches"><img src="@/assets/images/matches-white.svg" alt="Matches" />My Matches</router-link>
                        </li>
                        <li :class="{menu_main_active:dashboardActiveMenu === 'news'}" 
                            @click="dashboardMenuClick('news')">
                            <router-link to="/news"><img src="@/assets/images/news-white.svg" alt="News" />News</router-link>
                        </li>     
                        <li v-if="accessSlamFan" :class="{menu_main_active:dashboardActiveMenu === 'slamfanadmin'}" 
                            @click="dashboardMenuClick('slamfanadmin')">
                            <router-link to="/slamfanadmin"><img src="@/assets/images/logo-slamfan.svg" alt="SlamFan" />SlamFan</router-link>
                        </li>                                                          
                    </ul>
                </div>
            </aside>

            <!-- main dashboard body -->
            <main class="dashboard-main" :class="[{ dashboardmainwidthplus: isSideMenuCollapsed }]">
                <router-view />
            </main>

            <!-- footer -->
            <footer class="dashboard-footer">
                <div id="download-mini-section" class="download-images">
                    <div class="copyright-small">
                        &#169; Str8 Sets Pty Ltd
                    </div>
                    <div class="image-left">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.elevatemobile.Str8SetsTennis&amp;hl=en" target="_blank" >
                            <img
                                width="140px"
                                height="auto"
                                alt="Google Play"
                                src="@/assets/images/google-play-store.svg"
                                data-no-retina />
                        </a>
                    </div>
                    <div class="image-right">
                        <a href="https://itunes.apple.com/au/app/str8-sets-tennis/id838192982" target="_blank" >
                            <img
                                width="140px"
                                height="auto"
                                alt="Apple Store"
                                src="@/assets/images/apple-app-store.svg"
                                data-no-retina />
                        </a>
                    </div>
                </div>
            </footer>
        </div>

    </div>
</template>
