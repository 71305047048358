import { Club } from '../club';
import { TournamentParams, Member, Round, Team } from '.';

export class Tournament {
    constructor(data) {
        
        this.id = data.id;
        this.published = data.published;
        this.name = data.name;
        this.desc = data.desc;
        this.start = data.start == null ? undefined : data.start;        
        this.finish = data.finish == null ? undefined : data.finish

        this.ready_to_draw = data.ready_to_draw;
        this.sport = data.sport ? data.sport : undefined;
        this.draw_complete = data.draw_complete;
        this.rules = data.rules;

        this.params = new TournamentParams(data.params);

        // Note: I've cleaned this up from the old code so be aware that things may be missing at this stage
        // this.club_set_objects -> use club-set now in the new code!  That's all it was before

		this.club_set = [];
		if(data && data.club_set) {
			this.club_set = data.club_set.map((item) => new Club(item) );
		}

        this.round_set = [];
        if(data && data.round_set && data.round_set.length > 0) {
			this.round_set = data.round_set.map((item) => new Round(item) );
        }

        this.team_set = [];
        if(data && data.team_set && data.team_set.length > 0) {
            this.team_set = data.team_set.map((item) => new Team(item) );       // Nb: the team_set returned from *brief* data will only ever include the team that the specified player is in, so we can display the team name
        }

        // this.currentPlayerTeamName -> do this in the view now

        this.member_set = [];
        if(data && data.member_set && data.member_set.length > 0) {
            this.member_set = data.member_set.map((item) => new Member(item) );
        }

        // REBUILD NOTE: Will see if i need this before doing anything, and refer to notes in the Team class
        // // build up the member_set of the team_set
        // for(var m = 0; m < self.member_set().length; m++) {
        //     for(var t = 0; t < self.team_set().length; t++) {
        //         if(self.team_set()[t].id() == self.member_set()[m].team())
        //         {
        //             self.team_set()[t].members.push(self.member_set()[m]);
        //             break;
        //         }
        //     }
        // }

        // REBUILD NOTE: The following should be done propertly in teh view and without jQuery accessing DOM directly!
        // self.addDateToList = function() {
        //     var dateVal = $("#txtDateNotPlayable");
        //     var currentArray = self.params.datesNotPlayedFormatted();
        //     currentArray.push(dateVal.val());
        //     self.params.datesNotPlayedFormatted(currentArray);
        //     dateVal.val("");
        // }
        
        // self.removeDateFromList = function() {
        //     var datesNotPlayedDOM = $("#selDatesNotPlayed");
        //     var selectedDate = datesNotPlayedDOM.val();
        //     var currentArray = self.params.datesNotPlayedFormatted();
        //     // remove date from array and select list
        //     currentArray = jQuery.grep(currentArray, function(value) {
        //     return value != selectedDate;
        //     });
        //     self.params.datesNotPlayedFormatted(currentArray);
        // }        
    }

    get isMixedTournament() {
        return this.params.PlayerGender == "mixed";
    }

    get isInterclubTournament()  {
        return this.params.FixtureType == "InterClub";
    }
    
    get hasKnockout() {
        return this.params.Type == "roundrobinknockout";
    }

    validate() {

        if(!this.name)
            return false;

        if(!this.desc)
            return false;
        
        if(!this.start)
            return false;

        if(new Date(this.start) < new Date())
            return false;

        if(!this.finish)
            return false;

        if(new Date(this.finish) < new Date(this.start))
            return false;

        return true;

    }
}