<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "LoadingIndicator",
        data() {
            return {
                show: false
            };
        },
        created() {
            this.$eventBus.$on("show-loading", () => {
                this.show = true
            });
            this.$eventBus.$on("hide-loading", () => {
                this.show = false
            });
        }
    });
</script>

<template>

    <transition name="modal-fade">
        <div class="modal-backdrop in at-front" v-show="show">
            <div class="loader">
            </div>
        </div>
    </transition>

</template>

<style lang="scss">

    /* This ensures the loader is always shown */
    /* Important with respect to modals etc */
    .at-front {
        z-index: 2000 !important;
    }

    .modal-backdrop.in {        
        opacity: 0.4 !important;
    }

    .modal-fade-leave-active {
        transition: opacity 0.2s ease;
    }
    .modal-fade-leave-to {
        opacity: 0;
    }

    @keyframes load-animation {
        50%{
            transform:rotatez(180deg) scale(1.5);
            border-style:dotted;
        }
        100%{
            transform:rotatez(360deg) scale(0.9);
        }
    }
    .loader::before {
        content:"";
        color:white;
        height:100px;
        width:100px;
        position: absolute;
        top: 35%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
        background:transparent;
        border-radius:50%;
        border: 15px solid #6ed690;
        border-color:#1ffa3d #13371e;
        animation:load-animation 2s infinite;
    }

</style>
