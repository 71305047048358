import { NO_HOURS_DEFAULT } from '.';
import moment from 'moment';

export class DayOfOperationItem { 

	constructor(data) {
		this.id = data.id;
		this.name = data.name;
		this.hoursText = NO_HOURS_DEFAULT;
		this.hasHours = false;
		this.dayType = data.dayType;
	}

	// Nb: site rebuild: moved this out to be a class and added parameter openingHours to this function, rather than accessing this.hoursOfOperation for the containing club object
	// IN FACT, I think this whole thing is a UI concern! So it shouldn't even be here.... see what it looks like once the site rebuild is done.  It would be good to remove the need to call
	// this refreshHours function whenever a set of opening hours change!

	refreshHours(openingHours) {
		if(!openingHours) {
			this.hoursText = NO_HOURS_DEFAULT;
			return;
		}

		const selectedDayData = openingHours.find((item) => item.id == this.id);
	
		if(!selectedDayData) {
			this.hoursText = NO_HOURS_DEFAULT;
			return;
		}

		this.hasHours = true;
		this.hoursText = "(" + new moment(selectedDayData.open, 'H.m').format("h:mm A") + " to " + new moment(selectedDayData.close, 'H.m').format("h:mm A") + ")";
	}
}
