import { ClubMembershipGroup, MemberEmail } from '.';
import { Player } from '..';
import Utilities from '@/utils/utilities';

export class ClubMember {

	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.club = data && data.club ? data.club : undefined;	// Note: club is just stored as a POJO

		this.player = data && data.player ? new Player(data.player) : null;

		this.membership_number = data && data.membership_number ? data.membership_number : "";
		this.date_joined = data && data.date_joined ? data.date_joined : undefined ;

		this.first_name = data && data.first_name ? data.first_name : "";
		this.last_name = data && data.last_name ? data.last_name : "";

		this.email_set = [];
		if(data && data.email_set) {
			this.email_set = data.email_set.map((item) => new MemberEmail(item) );
		}

		this.street_addr = data && data.street_addr ? data.street_addr : "";
		this.city = data && data.city ? data.city : "";
		this.state = data && data.state ? data.state : "";
		this.postcode = data && data.postcode ? data.postcode : "";
		
		this.phone_number = data && data.phone_number ? data.phone_number : "";

		this.gender = data && data.gender ? data.gender : "U";
		this.dob = data && data.dob ? data.dob : undefined ;

		this.is_str8sets_player = data && data.player ? data.player.is_user : false;
		this.has_club_matcha = data && data.has_club_matcha ? data.has_club_matcha : false;

		this.ntrp_level = data && data.ntrp_level ? data.ntrp_level : null;

		this.membership_type = data && data.membership_type ? data.membership_type : undefined;
		this.expires = data && data.expires ? data.expires : undefined;
		this.billing_cycle = data && data.billing_cycle ? data.billing_cycle : "";
		this.group = data && data.group ? new ClubMembershipGroup(data.group) : null;
		this.stripe_customer = data && data.stripe_customer ? data.stripe_customer : null;

		// Handy function for grabbing the membership type name out of the data - never posted back to the server (see replacer())
		this.membership_type_name = data && data.membership_detail ? data.membership_detail.name : "";
		this.subscription = data && data.subscription ? data.subscription : undefined;

	}

	get full_name() {
		return this.first_name + " " + this.last_name;
	}

	get main_email_contact() {
		return this.email_set.length > 0 ? this.email_set[0].email : '';
	}
	
	set main_email_contact(val) {
		if(this.email_set.length == 0) {
			this.email_set.push(new MemberEmail({email: val }));
		}
		else
			this.email_set[0].email = val;
	}

	validate() {
		
		if(this.membership_number.length > 50)
			return false;

		if(!this.first_name)
			return false;

		if(!this.last_name)
			return false;

		if(this.email_set && this.email_set.length > 0 && !Utilities.validateEmail(this.email_set[0].email))
			return false;

		if(this.street_addr.length > 200)
			return false;
	
		if(this.city.length > 50)
			return false;
	
		if(this.state.length > 50)
			return false;
	
		if(this.postcode.length > 20)
			return false;

		return true;
	}

	replacer(key, value) {
		{
			const courtGroupIgnoreList = ['membership_type_name'];
			
			if (courtGroupIgnoreList.includes(key)) return undefined;
			else return value;
		}
	}
}
