import { SlamChampionship } from './slam-championship';

export class SlamTournament {
    constructor(data) {

        this.id = data.id;
        this.name = data && data.name ? data.name : "";
        this.desc = data && data.desc ? data.desc : "";
        this.gender = data && data.gender ? data.gender : "";

        this.championship = data && data.championship ? new SlamChampionship(data.championship) : null;

        this.start = data && data.start ? data.start : "";
        this.end = data && data.end ? data.end : "";
    }
}