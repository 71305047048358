import { Currency } from '@/models';

export function currency(value: string, currency: Currency|null, includeCurrencyCode = true): string {
    let cost = "";
    if (!currency) return value;

    if (includeCurrencyCode)
        cost += currency.code.toUpperCase() + " ";

    if (currency.decimals > 0)
        cost += parseFloat(value).toFixed(currency.decimals);
    else
        cost += value;

    return cost;
}

export function currencyHTML(value: string, currency: Currency|null, includeCurrencyCode = true, includeDecimals = true): string {
    let cost = "";
    if (!currency) return value;

    if(includeCurrencyCode)
        cost += "<span class='currency'>" + currency.code.toUpperCase() + "&nbsp;</span>";

    cost += "<span class='amount'>";

    if (currency.decimals > 0 && includeDecimals)
        cost += parseFloat(value).toFixed(currency.decimals);
    else
        cost += value;

    cost += "</span>";

    return cost;
}
