
import { apiClient } from "@/utils/apiclient";
import { Country, Surface, Format, Sport } from "@/models";
import { IGetTennisSurfacesActionPayload } from "./index.types";
import clubCart from './club/club-cart';
import clubCoaches from "./club/club-coaches";
import clubCourts from "./club/club-courts";
import clubMembers from './club/club-members';
import ClubProductCategoriesService from "@/utils/services/club-product-categories-service";
import clubs from "./club/clubs";
import Cookies from "js-cookie";
import CountriesService from "@/utils/services/countries-service";
import courtBookings from "./club/court-bookings";
import courtGroupBookingRates from "./club/court-group-booking-rates";
import currentPlayer from "./player/currentPlayer";
import dashboard from "./dashboard/dashboard";
import events from './club/events';
import FormatsService from "@/utils/services/formats-service";
import matchaHitRequests from './matcha/hit-requests';
import matches from './match/matches';
import messages from './message/messages';
import reports from "./club/reports";
import SportsService from "@/utils/services/sports-service";
import SurfacesService from "@/utils/services/surfaces-service";
import TennisSportService from "@/utils/services/tennis-sport-service";
import tournaments from './tournament/tournaments';
import transactions from "./club/transactions";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    modules: {
        clubs: clubs,
        events: events,
        reports: reports,       
        matches: matches,
        clubCart: clubCart,
        messages: messages,
        dashboard: dashboard,
        clubCourts: clubCourts,
        clubCoaches: clubCoaches,
        clubMembers: clubMembers,
        tournaments: tournaments,
        transactions: transactions,
        currentPlayer: currentPlayer,
        courtBookings: courtBookings,
        matchaHitRequests: matchaHitRequests,
        courtGroupBookingRates: courtGroupBookingRates
    },
    state: {
        countries: [] as Country[],
        tennisFormats: [] as Format[],
        clubProductCategories: [] as [],
        tennisSurfaces: [] as Surface[],
        tennisSportId: null as number | null,
        sports: [] as Sport[]        
    },
    mutations: {
        getCountries(state, countries) {
            state.countries = countries;
        },
        getSports(state, sports) {
            state.sports = sports;
        },
        getTennisSportId(state, tennisSportId) {
            state.tennisSportId = tennisSportId;
        },
        getTennisSurfaces(state, surfaces) {
            state.tennisSurfaces = surfaces;
        },
        getTennisFormats(state, formats) {
            state.tennisFormats = formats;
        },
        getClubProductCategories(state, categories) {
            state.clubProductCategories = categories;
        }
    },
    actions: {
        getCountriesAction({ commit }, forceRefresh: boolean): Promise<Country[]> {
            
            const countries = CountriesService.getValue();

            if (forceRefresh !== true && countries !== null) {
                const countriesArray = JSON.parse(countries);
                const countriesObjects = countriesArray.map((item: any) => new Country(item));

                commit("getCountries", countriesObjects);

                return new Promise(resolve => resolve(countriesObjects));
            }

            return apiClient.countries()
                .then(data => {
                    commit("getCountries", data);

                    CountriesService.saveValue(JSON.stringify(data));

                    return data;
                });
        },
        getSportsAction({ commit }, forceRefresh: boolean): Promise<Sport[]> {
            
            const sports = SportsService.getValue();

            if (forceRefresh !== true && sports !== null) {
                const sportsArray = JSON.parse(sports);
                const sportsObjects = sportsArray.map((item: any) => new Sport(item));
                commit("getSports", sportsObjects);
                return new Promise(resolve => resolve(sportsObjects));
            }

            return apiClient.sports()
                .then(data => {
                    commit("getSports", data);
                    SportsService.saveValue(JSON.stringify(data));
                    return data;
                });
        },
        getTennisSportIdAction({ commit, getters }): Promise<number> {
            if (!getters.isLoggedIn) {
                return new Promise(resolve => resolve(0));
            }

            const currentStoredTennisId = TennisSportService.getValue();
            if (currentStoredTennisId != null) {
                commit("getTennisSportId", Number(currentStoredTennisId));
                return new Promise(resolve => resolve(Number(currentStoredTennisId)));
            }

            return apiClient.tennisSportId().then(data => {
                commit("getTennisSportId", data);

                TennisSportService.saveValue(data.toString());
                
                return data;
            });
        },        
        getTennisSurfacesAction({ commit, state }, payload: IGetTennisSurfacesActionPayload = {}): Promise<Surface[]> {
        
            if(!state.tennisSportId)
                return new Promise(resolve => resolve([]));

            const surfaces = SurfacesService.getValue();

            if (payload.forceRefresh !== true && surfaces !== null) {
                const surfacesArray = JSON.parse(surfaces);
                const surfacesObjects = surfacesArray.map((item: any) => new Surface(item));

                commit("getTennisSurfaces", surfacesObjects);

                return new Promise(resolve => resolve(surfacesObjects));
            }

            return apiClient.surfaces(state.tennisSportId)
            .then((data: Surface[]) => {
                commit("getTennisSurfaces", data);

                SurfacesService.saveValue(JSON.stringify(data));

                // optionally add "multiple" surface (not being added to backend)
                if (payload.includeMultipleSurfaceOption) {
                    data.push(new Surface({"id": 0, "sport": "tennis", "name": "Multiple"}));
                }

                return data;
            });
        },
        getTennisFormatsAction({ commit, state }): Promise<Format[]> {
        
            if(!state.tennisSportId)
                return new Promise(resolve => resolve([]));

            const formats = FormatsService.getValue();

            if (formats !== null) {
                const formatsArray = JSON.parse(formats);
                const formatsObjects = formatsArray.map((item: any) => new Format(item));

                commit("getTennisFormats", formatsObjects);

                return new Promise(resolve => resolve(formatsObjects));
            }

            return apiClient.formats(state.tennisSportId)
            .then((data: Format[]) => {
                commit("getTennisFormats", data);

                FormatsService.saveValue(JSON.stringify(data));

                return data;
            });
        },
        getClubProductCategoriesAction({ commit }): Promise<[]> {
        
            const categories = ClubProductCategoriesService.getValue();

            if (categories !== null) {
                const categoriesArray = JSON.parse(categories);

                commit("getClubProductCategories", categoriesArray);

                return new Promise(resolve => resolve(categoriesArray));
            }

            return apiClient.clubProductCategories()
                .then(results => {

                    commit("getTennisFormats", results);
                    ClubProductCategoriesService.saveValue(JSON.stringify(results));
                    return results;
                });
        }
    },
    getters: {
        isLoggedIn: () => {
            const authToken = Cookies.get("authtoken");
            return Boolean(authToken && authToken.length > 0);
        },
        countryNameByCode: state => countryCode => {
            let country: Country | null = null;
            if (state.countries) {
                country = state.countries.find(item => {
                    return item.code == countryCode;
                });
            }
            return country == null ? "" : country.name;
        },
        tennisSurfaceImage: state => surface => {

            const selectedSurface = state.tennisSurfaces.find((item) => { return item.id == surface.id });

            if(!selectedSurface) {
                return "";
            }

            let surfaceColour = "";
            switch(selectedSurface.name.toLowerCase()) {
                case "clay":
                    surfaceColour = "red";
                    break;
                case "hard":
                    surfaceColour = "blue";
                    break;
                case "grass":
                    surfaceColour = "green";
                    break;
                case "carpet":
                    default:
                    surfaceColour = "grey";
                    break;
            }

            const selected = surface.selected ? '' : '-nonselect';

            return  require("@/assets/images/court-" + surfaceColour + selected + ".svg");
        },
        tennisSportId: state => state.tennisSportId,
        sports: state => state.sports,
        tennisSurfaces: state => state.tennisSurfaces
    }
});
