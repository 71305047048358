export class BookingSeriesPayment {
    
    constructor(data) {
    
        this.payment_method = data && data.payment_method ? data.payment_method : undefined; 
        this.amount = data && data.amount ? data.amount : undefined; 

        this.stripe_cust = data && data.stripe_cust !== undefined ? data.stripe_cust : undefined; 
        this.club_cust = data && data.club_cust !== undefined ? data.club_cust : undefined; 
        this.exp_month = data && data.exp_month ? data.exp_month : undefined; 
        this.exp_year = data && data.exp_year ? data.exp_year : undefined; 

        this.paying_person = data && data.paying_person ? data.paying_person : undefined; 
    }
}