import { ClubCartProduct } from '.';

export class ClubCart {
	constructor(data) {
		this.club = data && data.club ? data.club : undefined; // Club Id
        this.member = data && data.member ? data.member : null; // Club Member Id        
        this.discount = data && data.discount ? data.discount : 0;

        this.products = [];
        if (data && data.products) {
            this.products = data.products.map((item) => new ClubCartProduct(item));
        }
    }
}