import { ClubCartDetailProductPrice } from "@/models/";

export class ClubCartChargeRequest {
	constructor(data) {
		this.purpose = data && data.purpose ? data.purpose : ""; 
        this.club = data && data.club ? data.club : null; 
        this.member = data && data.member ? data.member : null; 
        this.discount = data && data.discount ? data.discount : 0; 
        this.notes = data && data.notes ? data.notes : ""; 
        this.amount = data && data.amount ? data.amount : undefined; 
        this.card_last4 = data && data.card_last4 ? data.card_last4 : ""; 
        this.card_exp_year = data && data.card_exp_year ? data.card_exp_year : ""; 
        this.card_exp_month = data && data.card_exp_month ? data.card_exp_month : ""; 
        this.products = data && data.products ? data.products.map((item) => new ClubCartDetailProductPrice(item)) : [];        
    }
}