import { Surface, Format, ClubMembershipType, ClubPackage, CourtGroup, CourtGroupBookingRate, MembershipTypeBookingRate, ClubFeature, 
    MarketFeature, Club, Court, HoursOfOperation, ClubCoach, ClubMember, ClubMembershipGroup, PackageForClub, BookingInstance, 
    BookingSeries, Country, Newsletter, Thread, Comment, NewsArticle, PlayerClubAdmin, Player, PlayerBulk, PlayerStripeCustomer, 
    CoachInsights, Tournament, Team, Member, MatchGroup, Match, Round, Refund, CourtBookingsInsights, MessageGroup, CommentsNew,
    ClubProduct, ClubCart, ClubCartDetail, ClubCustomer, Transaction, ClubCartChargeRequest, Event, EventClubTerms, EventSession, BookingSeriesPayment,
    EventSessionBookingResponse, EventJoinParticipantResponse, EventParticipantPriceResponse, EventJoinParticpant, EventParticipant, Sport, 
    EventMembershipTypeRate, EventClone, ClubClientSearchResult, ClubEquipment, EventWaitlist, SlamMatch, SlamRound } from '@/models';
import Cookies from 'js-cookie';
import moment, { Moment } from 'moment';
import Utilities from '@/utils/utilities';

class APIClient {

    // Dynamic get - used for things like getting next/prev pages, where the URL is given to us
    dynamicGet(url: string): Promise<any> {

        // dev hack to get back to /api/....
        if(process.env.NODE_ENV === 'development') {
            const el = document.createElement('a');
            el.href = url;
            url = `${el.pathname}${el.search}${el.hash}`;
        }
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    tennisSportId(): Promise<number> {

        const url = `/api/sport/?name=tennis`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {

                return data.body.results[0]?.id;
            });
    }

    countries(): Promise<Country[]> {

        const url = `/api/country/`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                let results = data.body.results.map((item: any) => new Country(item));

                // order countries
                results = results.sort(function (l, r) { return l.name > r.name ? 1 : -1 });
    
                return results;
            });
    }

    sports(): Promise<Sport[]> {

        const url = `/api/sport/`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.results.map((item: any) => new Sport(item));
            });
    }

    surfaces(tennisSportId: number): Promise<Surface[]> {

        const url = `/api/surface/?sport=${tennisSportId}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.results.map((item: any) => new Surface(item));
            });
    }

    formats(tennisSportId: number): Promise<Format[]> {

        const url = `/api/format/?sport=${tennisSportId}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.results.map((item: any) => new Format(item));
            });
    }

    news(pageSize: number): Promise<any>  {

        const url = `/api/news/?page_size=${pageSize}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new NewsArticle(item));

                return results;
            });
    }
    
    packages(): Promise<any> {

        const url = `/api/packageforclub/`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(r => r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const mappedPackages = data.body.results.map((item: any) => new ClubPackage(item));
                mappedPackages.sort((a: ClubPackage,b: ClubPackage) => a.order - b.order); 	/* sort in ascending order */

                return mappedPackages;
            });
    }

    marketFeatures(countryCode: string): Promise<any> {

        const url = `/api/marketfeature/?market__country_set=${countryCode}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(r => r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const mappedItems = data.body.results.map((item: any) => new MarketFeature(item));
                return mappedItems; 
            });
    }

    user(): Promise<any> {

        const url = "/api/user/";
    
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return data.body.results[0].player_set[0];
        });
    }

    player(email: string): Promise<any> {
        const url = `/api/player/?email=${email}`;
    
        return fetch(url, {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            if(data.body.count > 0)
                return new Player(data.body.results[0]);

            else
                return null;
        });
    }

    clubAddCoach(clubCoach: ClubCoach): Promise<ClubCoach> {
        const url = `/api/club/${clubCoach.club}/add_coach/`;
        
        const data = {            
            "player": clubCoach.coach.player.id,
			"club_coach": {
                "ttype": clubCoach.ttype,
                "min_student_age": clubCoach.min_student_age,
                "max_student_age": clubCoach.max_student_age,
                "min_ntrp": clubCoach.min_ntrp,
                "max_ntrp": clubCoach.max_ntrp,
                "student_gender": clubCoach.student_gender,
                "generic_levels": clubCoach.generic_levels,
                "advance_days_booking": clubCoach.advance_days_booking,
                "max_hours_day": clubCoach.max_hours_day,
                "advance_hours_cancel": clubCoach.advance_hours_cancel,
                "can_book_recurring": clubCoach.can_book_recurring
            }
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubCoach(data.body);
            });
    }

    clubEditCoach(clubCoach: ClubCoach): Promise<ClubCoach> {
        const url = `/api/clubcoach/${clubCoach.id}/`;

        const data = {       
            "ttype": clubCoach.ttype,
            "min_student_age": clubCoach.min_student_age,
            "max_student_age": clubCoach.max_student_age,
            "min_ntrp": clubCoach.min_ntrp,
            "max_ntrp": clubCoach.max_ntrp,
            "student_gender": clubCoach.student_gender,
            "generic_levels": clubCoach.generic_levels,
            "advance_days_booking": clubCoach.advance_days_booking,
            "max_hours_day": clubCoach.max_hours_day,
            "advance_hours_cancel": clubCoach.advance_hours_cancel,
            "can_book_recurring": clubCoach.can_book_recurring
        }

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubCoach(data.body);
            })
    }

    clubDeleteCoach(coachId: string, clubId: string): Promise<any> {
        const url = `/api/club/${clubId}/remove_coach/`;
        
        const data = {
			"coach": coachId
        };
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})));
    }

    playerContacts(playerId: string): Promise<any> {

        const url = `/api/contact/?playerid=${playerId}`;
    
        return fetch(url, {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            const contact_set = data.body.results;
            return contact_set;
        });
    }
    
    resendPlayerValidation(player: Player): Promise<any> {

        const currentPlayerEmail = player.findContactDetails("E")![0];
        
        if(!currentPlayerEmail)
            return new Promise(resolve => resolve(null));

        const url = `/api/contact/${currentPlayerEmail.id}/resend_validation/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r => r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    validatePlayerEmail(player: Player, validationCode: string): Promise<any> {

        const currentPlayerEmail = player.findContactDetails("E")![0];
        
        if(!currentPlayerEmail)
            return new Promise(resolve => resolve(null));

		const url = `/api/contact/${currentPlayerEmail.id}/validate/`;
		const data = {
			"code": validationCode
        };

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .catch(() => { return Promise.reject() });
    }
        
    updatePlayerClubSet(playerId: string, clubIds: any[]): Promise<any> {
        
        const url = `/api/player/${playerId}/`;
		const data = {
            "club_set": clubIds
        };

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .catch(() => { return Promise.reject() });
    }

    stripeKey(): Promise<string> {
        const url = `/api/stripe_key/`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.key;
            });
    }

    clubById(id: string) {
        const url = `/api/club/${id}/`; 
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const result = new Club(data.body);

                return result;
            });
    }

    clubFind(country: string, searchTerm: string) {
        const url = `/api/club/?country=${country}&search=${searchTerm}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new Club(item));

                return results;
            });
    }

    clubSubscriptionPrice(clubId: string) {
        const url = `/api/clubsubscriptionprice/`;
        
        const data = {
            "club": clubId
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    clubTransactions(clubId: string, pageSize = 0, fromDate: Moment|string = "", toDate: Moment|string = "", players = "", paymentType = "", option = ""): Promise<any> {
        let url = `/api/transaction/?dest_club=${clubId}`;
        
        if(pageSize > 0)
            url += `&page_size=${pageSize}`

        if(fromDate)
            url += `&datefrom=${moment(fromDate).format().substr(0, 10)}T00:00:00`;

        if(toDate)
            url += `&dateto=${moment(toDate).format().substr(0, 10)}T23:59:59`;

        if(players.length > 0)
            url += `&players=${players}`;

        if(paymentType)
            url += `&kind=${paymentType}`;

        if(option == "refunded")
            url += `&status=R`;

        if(option == "failed")
            url += `&status=F`;

        if(option == "successful")
            url += `&status=P`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    clubNewsletters(clubId: string): Promise<Newsletter[]> {
        const url = `/api/newsletter/?club=${clubId}`; 
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {

                const results = data.body.results.map((item: any) => new Newsletter(item));
                return results;
            });
    }

    addClubNewsletter(newsletter: Newsletter): Promise<Newsletter> {
    
        const url = "/api/newsletter/";
        
        const data = JSON.parse(JSON.stringify(newsletter)); // convert to POJO
        
        // flatten
        data.club = data.club.id;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new Newsletter(data.body);
        })
    }

    updateClubNewsletter(newsletter: Newsletter): Promise<Newsletter> {

        const url = `/api/newsletter/${newsletter.id}/`;

        const data = JSON.parse(JSON.stringify(newsletter)); // convert to POJO
        
        // flatten
        data.club = data.club.id;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new Newsletter(data.body);
        })
    }


    deleteClubNewsletter(newsletter: Newsletter): Promise<void> {
        const url = `/api/newsletter/${newsletter.id}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    clubMatchaHitRequests(clubId: string, fromDate: Moment, toDate: Moment, status: string, pageSize = 0): Promise<any> {

        // status will be one of:
        // null = get all hit requests
        // P = pending
        // C = confirmed
        let statusFilter = "";
        switch (status) {
            case "P":
                statusFilter = "&players_needed__gt=0";
                break;
            case "C":
                statusFilter = "&players_needed=0";
                break;
        }

        let url = `/api/matchahitrequest/?adminview=True&inclubs=${clubId}&datefrom=${fromDate.format("YYYY-MM-DD")}&dateto=${toDate.format("YYYY-MM-DD")}${statusFilter}`;
        if(pageSize> 0) 
            url += `&page_size=${pageSize}`;
		
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    clubThreads(clubId: string): Promise<Thread[]> {
        const url = `/api/thread/?club=${clubId}`; 
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {

                const results = data.body.results.map((item: any) => new Thread(item));
                return results;
            });
    }

    clubFeatures(clubId: string): Promise<ClubFeature[]> {
        const url = `/api/clubfeature/?club=${clubId}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new ClubFeature(item));
                return results;
            });
    }

    updateClubFeature(clubFeature: ClubFeature): Promise<void> {
        const url = `/api/clubfeature/${clubFeature.id}/`;

        // use the id for the feature
        //clubFeature.feature = clubFeature.feature.id;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(clubFeature),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    checkCanEnableCourts(clubId: string): Promise<boolean> {
        const url = `/api/club/${clubId}/can_enable_courts/`;
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                if(!data.body.result) 
                    throw data.body;
                    
                return data.body.result;
            });
    }

    clubCourtGroups(clubId: string): Promise<CourtGroup[]> {
        const url = `/api/courtgroup/?club=${clubId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return data.body.results.map((item: any) => new CourtGroup(item));
        })
    }

    addCourtGroup(courtGroup: CourtGroup): Promise<CourtGroup> {
        const url = "/api/courtgroup/";
        
        const data = JSON.parse(JSON.stringify(courtGroup, courtGroup.replacer)); // convert to POJO

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new CourtGroup(data.body);
        })
    }

    updateCourtGroup(courtGroup: CourtGroup): Promise<CourtGroup> {
        const url = `/api/courtgroup/${courtGroup.id}/`;

        const data = JSON.parse(JSON.stringify(courtGroup, courtGroup.replacer)); // convert to POJO

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new CourtGroup(data.body);
        })
    }

    deleteCourtGroup(courtGroupId: string): Promise<void> {
        const url = `/api/courtgroup/${courtGroupId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    courtGroupBookingRates(courtGroupId: string): Promise<CourtGroupBookingRate[]> {
        const url = `/api/courtbookingrate/?courtgroup=${courtGroupId}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new CourtGroupBookingRate(item));
                return results;
            });
    }

    updateCourtGroupBookingRate(courtGroupId: string, bookingRate: CourtGroupBookingRate): Promise<CourtGroupBookingRate> {

        const flattenedData = {
            "courtgroup": courtGroupId,
            "name": bookingRate.name,
            "start_hour": parseInt(bookingRate.start_hour),
            "start_minute": parseInt(bookingRate.start_minute),
            "end_hour": parseInt(bookingRate.end_hour),
            "end_minute": parseInt(bookingRate.end_minute),
            "member_def_price": parseFloat(parseFloat(bookingRate.member_def_price).toFixed(2)),
            "member_can_book": bookingRate.member_can_book,
            "nonmember_def_price": parseFloat(parseFloat(bookingRate.nonmember_def_price).toFixed(2)),
            "nonmember_can_book": bookingRate.nonmember_can_book,
            "coach_book_price": parseFloat(parseFloat(bookingRate.coach_book_price).toFixed(2)),
            "coach_can_book": bookingRate.coach_can_book
        };

		let method = "POST";
		let url = "/api/courtbookingrate/";

		if(bookingRate.id) {
			method = "PATCH";
			url += `${bookingRate.id}/`;
        }
        
        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(flattenedData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new CourtGroupBookingRate(data.body);
            });
    }

    updateMembershipTypeBookingRate(bookingRateId: string, membershipTypeId: string, membershipTypeBookingRate: MembershipTypeBookingRate): Promise<MembershipTypeBookingRate> {
        
        const flattenedData = {
            "booking_rate": bookingRateId,
			"membership_type": membershipTypeId,
			"can_book": membershipTypeBookingRate.can_book,
			"price": membershipTypeBookingRate.can_book ? membershipTypeBookingRate.price : 0 /* force price to 0 if they are not allowed to book */
		};

		let method = "POST";
		let url = "/api/membershiptypebookingrate/";

		if(membershipTypeBookingRate.id) {
			method = "PATCH";
			url += `${membershipTypeBookingRate.id}/`;
        }
        
        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(flattenedData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new MembershipTypeBookingRate(data.body);
        });
    }

    clubCourts(clubId: string): Promise<Court[]> {
        const url = `/api/court/?club=${clubId}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new Court(item));
                return results;
            });
    }

    addClubCourt(court: Court): Promise<Court> {
        const url = `/api/court/`;

        const data = JSON.parse(JSON.stringify(court)); // convert to POJO

        // flatten
        data.sport = data.sport.id;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new Court(data.body);
        })
    }

    updateClubCourt(court: Court): Promise<void> {
        const url = `/api/court/${court.id}/`;

        const data = JSON.parse(JSON.stringify(court)); // convert to POJO

        // flatten
        data.sport = data.sport.id;
        data.surface = data.surface.id;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    deleteClubCourt(courtId: string): Promise<void> {
        const url = `/api/court/${courtId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    clubCoachesReport(clubId: string, fromDate: Moment, toDate: Moment): Promise<CoachInsights[]> {
        const url = `/api/reports/coaches/?club=${clubId}&datefrom=${fromDate.format("YYYY-MM-DD")}&dateto=${toDate.format("YYYY-MM-DD")}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new CoachInsights(item));
                return results;
            });
    }

    clubCourtBookingsReport(clubId: string, fromDate: Moment, toDate: Moment, courts: Array<number>, type: string): Promise<CourtBookingsInsights> {
        let url = `/api/reports/bookings/?club=${clubId}&datefrom=${fromDate.format("YYYY-MM-DD")}&dateto=${toDate.format("YYYY-MM-DD")}`;

        if (courts.length > 0 && type != '') {
            url += `&${type}=${courts.join(',')}`;
        }

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new CourtBookingsInsights(data.body);
            });
    }

    updateClubOpeningHours(club: Club, editedHoursOfOperation: HoursOfOperation): Promise<any> {
        
        // Manipulate the data!
        // Need to send the full list of club open times, rather than just this one, so create an array of all the 
		// hours of operation that we already have
		const dayObjects = [] as any[];

        club.hoursOfOperation.map((item) => {
			const dayObject = {};
			if(item.id === editedHoursOfOperation.id) {
				// Add the edited item
				dayObject[item.id] = {
					"open" : editedHoursOfOperation.open,
					"close" : editedHoursOfOperation.close,
				};
			}
			else {
				dayObject[item.id] = {
					"open" : item.open,
					"close" : item.close,
				};
			}
			dayObjects.push(dayObject);
		});

        // add editted item if it didn't exist already
        const existingObject = dayObjects.find((element) => {
            return Object.keys(element) == editedHoursOfOperation.id;
        });

		if(!existingObject) {
			const dayObject = {};
			// Add the edited item
			dayObject[editedHoursOfOperation.id] ={
				"open" : editedHoursOfOperation.open,
				"close" : editedHoursOfOperation.close,
			};
			dayObjects.push(dayObject);
		}

		const operationData = {
			"OpeningHours" :
				dayObjects
		};

		const data  = operationData;

        const url = `/api/club/${club.id}/set_opening_hours/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})));
    }

    updateClubFacilities(club: Club, facilities: []): Promise<any> {
        const url = `/api/club/${club.id}/set_facilities/`;
        const data = {"Facilities": facilities};

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})));
    }

    updateClubMembershipType(membershipType: ClubMembershipType) {
        
        let url = `/api/clubmembershiptype/`;
        let method = "POST";

		if(membershipType.id) {
			method = "PATCH";
			url += `${membershipType.id}/`;
		}

        const data = JSON.parse(JSON.stringify(membershipType)); // convert to POJO
        
        // flatten
        data.currency = data.currency.id;
        
        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new ClubMembershipType(data.body);
        })
    }

    deleteClubMembershipType(membershipTypeId: string) {
        const url = `/api/clubmembershiptype/${membershipTypeId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    stripeCharge(playerStripeCustomerId: string, clubId: string, amount: number) {
        const url = `/api/stripecustomer/${playerStripeCustomerId}/charge/`;

        const data = {
            "club": clubId,
			 "purpose": "S",
			 "amount": amount
        };

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors);
    }

    clubAdminStripeAuthoriseRedirectURL(clubAdminId: string): Promise<string> {
        const url = `/api/clubadmin/${clubAdminId}/stripe_authorize/`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.redirect;
            });
    } 

    addClubAdmin(clubId: string, playerId: string, roles = []): Promise<PlayerClubAdmin> {
        const url = `/api/clubadmin/`;

        const data = {
            "player": playerId,
            "club": clubId,
            "access": "S"
        };

        if (roles.length > 0) {
            data["role"] = roles;
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            const newClubAdminRecord = new PlayerClubAdmin(data.body);

            return newClubAdminRecord;
        })
        .catch((response) => { throw response; });  // let the caller handle it
    }

    updateClubAdmin(adminId: string, roles = []): Promise<PlayerClubAdmin> {
        const url = `/api/clubadmin/${adminId}/`;

        const data = {
            "role": roles
        };

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const newClubAdminRecord = new PlayerClubAdmin(data.body);

                return newClubAdminRecord;
            })
            .catch((response) => { throw response; });  // let the caller handle it
    }

    playerClubAdmins(playerId: string): Promise<PlayerClubAdmin[]> {

        const url = `/api/clubadmin/?player=${playerId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return data.body.results.map((item: any) => new PlayerClubAdmin(item)); // new PlayerClubAdmin(data.body);
        })
        .catch((response) => { throw response; });  // let the caller handle it
    }

    updateClubPackage(club: Club, packageId: string) {
        let url = `/api/clubpackage/`;

        const data = {
            "club": club.id,
            "package": packageId
        };
        let method = "POST";

        if(club.package) {
            method = "PATCH";
            url += `${club.package.id}/`;
        }

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            const newClubPackage = new PackageForClub(data.body);

            return newClubPackage;
        })
        .catch((response) => { throw response; });  // let the caller handle it
    }

    updateClubBillingCycle(clubPackageId: string, billingCycle): Promise<any> {
        const url = `/api/clubpackage/${clubPackageId}/change_billing_cycle/`;

        const data = {
            "billing_cycle": billingCycle.value
        };

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return data.body;
        })
        .catch((response) => { throw response; });  // let the caller handle it
    }

    clubCoaches(clubId: string) {
        const url = `/api/clubcoach/?club=${clubId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new ClubCoach(item));

                return results;
            });
    }

    clubMembers(clubId: string, pageSize = 0, searchParams: any = undefined): Promise<any> {
        let url = `/api/clubmember/?club=${clubId}`;

        if(pageSize> 0) 
            url += `&page_size=${pageSize}`;

        if(searchParams) {

            if(searchParams.searchTerm)
                url += "&search=" + searchParams.searchTerm.toLowerCase();

            if(searchParams.gender)
                url += "&gender=" + searchParams.gender;
            
            if(searchParams.ageMin) {
                url += "&min_age=" + searchParams.ageMin;
                url += "&max_age=" + (parseInt(searchParams.ageMin) + 10);
            }

            if(searchParams.membershipType && searchParams.membershipType.length > 0)
                url += "&membership_types=" + searchParams.membershipType;


            if(searchParams.playerStatus) {
                url += "&is_user=" + searchParams.playerStatus;
            }

            if(searchParams.membershipStatus) {
                if(searchParams.membershipStatus == "True")
                    url += "&is_expired=1";
                else 
                    url += "&is_expired=0";
            }

            if(searchParams.directDebitStatus)
                url += "&sub_status=" + searchParams.directDebitStatus;
        }

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }
    
    searchClubMembers(searchTerm: string, clubId: string): Promise<ClubMember[]> {
        const url = `/api/clubmember/?club=${clubId}&search=${searchTerm}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new ClubMember(item));

                return results;
            });
    }

    searchClubClients(searchTerm: string, clubId: string): Promise<ClubClientSearchResult> {
        const url = `/api/club/${clubId}/search_clients/`;
        const body = {
            name: searchTerm
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const searchResults = new ClubClientSearchResult(data.body);
                return searchResults;
            });
    }

    checkCanEnableMembershipFeature(clubFeatureId: string): Promise<void> {

        const url = `/api/clubfeature/${clubFeatureId}/can_enable_mem_feature/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    checkMembersAllAssigned(clubId: string): Promise<boolean> {

        const url = `/api/clubmember/?club=${clubId}&no_membership=True`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.count === 0;
            });
    }

    addClubMembersBulk(clubId: string, clubMembers: ClubMember[], flush = false): Promise<any> {
        let url = `/api/cmember/bulk/?club=${clubId}`;

        if(flush) {
			url += "&flush=True";
		}

        const data = clubMembers.map(member => JSON.parse(JSON.stringify(member, member.replacer))); // convert to POJO

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            })
            .catch((response) => { throw response; });  // let the caller handle it
    }

    updateClubMember(clubId: string, clubMembers: ClubMember[]): Promise<void> {
        const url = `/api/cmember/bulk/?club=${clubId}`;

        const data = clubMembers.map(member => {
            const obj = JSON.parse(JSON.stringify(member, member.replacer)); // convert to POJO

            // flatten
            if(obj.group)
                obj.group = obj.group.id;

            return obj;
        });

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    updateClubMemberMembership(clubId: string, clubMembers: ClubMember[]): Promise<void> {
        const url = `/api/cmember/bulk/?club=${clubId}`;

        const bulkData: any[] = [];

        clubMembers.forEach(member => {
            bulkData.push({
                "id": member.id,
                "membership_type": member.membership_type,
                "expires": member.expires,
                "billing_cycle": member.billing_cycle,
                "group": member.group == null ? null : member.group.id  // flat!
            });
        });

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(bulkData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    deleteClubMember(memberId: string): Promise<void> {
        const url = `/api/clubmember/${memberId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    deleteClubAdmin(adminId: string): Promise<void> {
        const url = `/api/clubadmin/${adminId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    updateClubMembershipGroup(group: ClubMembershipGroup, membershipTypeId: number): Promise<ClubMembershipGroup> {

        const flattenedData = {
            "name": group.name,
			"membership_type": membershipTypeId
        };

		let method = "POST";
		let url = "/api/clubmembergroup/";

		if(group.id) {
			method = "PATCH";
			url += `${group.id}/`;
        }
        
        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(flattenedData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
        .then(data => {
            return new ClubMembershipGroup(data.body);
        });
    }

    courtBookings(clubId: string, startDate: Moment, endDate: Moment, bookingType: string|null = null , courts: string|null = null, courtGroups: string|null = null, pageSize: number|null = null, coachId: number|null = null): Promise<any> {
        let url = `/api/courtbookinginstance/?club=${clubId}`; 
        url += `&start=${moment(startDate).format("YYYY-MM-DDTHH:mm")}`;
        url += `&end=${moment(endDate).format("YYYY-MM-DDTHH:mm")}`;

        if (pageSize != null) {
            url += `&page_size=${pageSize}`;
        }
        if(coachId != null) {
            url += `&coaches=${coachId}`;
        }
        if(bookingType != null) {
            url += `&booking_type=${bookingType}`;
        }
        if(courts != null) {
            url += `&courts=${courts}`;
        }
        if(courtGroups != null) {
            url += `&courtGroups=${courtGroups}`;
        }

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    courtBookingsSearch(clubId: string, startDate: Moment|null, endDate: Moment|null, searchString: string|null = null, memberId: string|null, playerId: string|null, pageSize: number|null = null): Promise<any> {
        let url = `/api/courtbookinginstance/?club=${clubId}`; 

        // cater for simple search, playerID or memberId
        if (memberId != null) {
            url += `&member=${memberId}`;
        }
        else if (playerId != null) {
            url += `&player=${playerId}`;
        }
        else {
            url += `&name=${searchString}`;
        }
        
        if (startDate) url += `&start=${moment(startDate).format("YYYY-MM-DDTHH:mm")}`;
        if (endDate) url += `&end=${moment(endDate).format("YYYY-MM-DDTHH:mm")}`;

        if (pageSize != null) {
            url += `&page_size=${pageSize}`;
        }

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    courtBookingsSearchBookings(clubId: string|null, startDate: Moment|null, endDate: Moment|null, searchString: string|null = null, memberId: string|null, playerId: string|null, pageSize: number|null = null, pageNumber: number|null): Promise<any> {
        const url = `/api/courtbookinginstance/0/search_booked_person/`; 

        // cater for simple search, playerID or memberId
        if (playerId != null) {
            memberId = null;
            searchString = null;
        }
        else if (memberId != null) {
            playerId = null;
            searchString = null;
        }
        else {
            memberId = null;
            playerId = null;
        }

        // deal with dates
        if (startDate == null) startDate = moment("2018-01-01");
        if (endDate == null) endDate = moment("2099-12-31");

        const body = {
            player: playerId,
            club: clubId,
            member: memberId,
            search_str: searchString,
            from: moment(startDate).format("YYYY-MM-DD"),
            to_date: moment(endDate).format("YYYY-MM-DD"),
            page: pageNumber,
            pg_size: pageSize
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    courtBookingInstancesForSeriesFuture(seriesId: string, startDate: Moment, pageSize: number|null = null): Promise<any> {
        pageSize = pageSize != null ? pageSize : 1000;
        let url = `/api/courtbookinginstance/?booking_series=${seriesId}`; 
        url += `&start=${moment(startDate).format("YYYY-MM-DDTHH:mm")}`;
        url += `&page_size=${pageSize}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    checkCourtBooking(bookingSeries: BookingSeries): Promise<BookingSeries>  {

        const url = `/api/club/${bookingSeries.club}/booking_allowed/`;

        // create an object to send to the server
        // dates formated correctly
        const data = {
			start: moment(bookingSeries.start_date).format("YYYY-MM-DDTHH:mm"),
			end: moment(bookingSeries.end_date).format("YYYY-MM-DDTHH:mm"),
			recurrence: bookingSeries.recurrence,
			recurrence_end: bookingSeries.recurrence_end,
            days_of_week: bookingSeries.getDaysOfWeek(),
			court_set: bookingSeries.court_set,
			coach_set: bookingSeries.coach_set.map((item) => item.id),
            sport: bookingSeries.sport.id,
            ttype: bookingSeries.ttype,
            booked_person_set: bookingSeries.getBookedPersons(),
            equipment_set: bookingSeries.getRequestedEquipment(),
            paying: bookingSeries.setPayingFlagForBookingAllowed()
		}
        
        // are we attempting to edit the court booking, add the series id
        if (bookingSeries.id != null && bookingSeries.id > 0) {
            data['series_id'] = bookingSeries.id; 
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)    // handle erros in the caller (e.g. booking not allowed result)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    addCourtBookingSeries(bookingSeries: BookingSeries): Promise<BookingSeries> {
        
        const url = `/api/courtbookingseries/?club=${bookingSeries.club}`;

        /// Flatten data
        // convert to POJO
        const data = JSON.parse(JSON.stringify(bookingSeries)); 
        // clean up data
        data.start_date = moment(bookingSeries.start_date).format("YYYY-MM-DDTHH:mm"),
		data.end_date = moment(bookingSeries.end_date).format("YYYY-MM-DDTHH:mm"),
        data.sport = data.sport.id;
        data.coach_set = data.coach_set.map((item) => item.id);
        data.booked_person_set = bookingSeries.getBookedPersons();
        data.requested_equipment = bookingSeries.getRequestedEquipment();                
        // clean up recurrence
        // Fix: the "N" value for recurrence of "none" should be set to null instead. 
        // We are actually not setting this to "N" now in the view, so can probably get rid of this
        data.recurrence = data.recurrence == '' || data.recurrence == 'N' ? null : data.recurrence; 
        data.recurrence_end = !data.recurrence ? null : data.recurrence_end; 
        data.days_of_week = bookingSeries.getDaysOfWeek();
        // delete properties not required
        delete data["booking_series"];
        delete data["booking_instance_set"];
        delete data["transaction_set"];
        delete data["equipment_set"];
        delete data["bookingDaysOfWeek"];
        delete data["cost"];
        delete data["cost_difference"];
        delete data["equipment_cost"];
        delete data["total_paid"];

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    editCourtBookingSeries(bookingSeries: BookingSeries): Promise<any> {
        
        const url = `/api/courtbookingseries/${bookingSeries.id}/edit_series/`;

        /// Flatten data
        // convert to POJO
        const data = JSON.parse(JSON.stringify(bookingSeries)); 
        // clean up data
        data.start_date = moment(bookingSeries.start_date).format("YYYY-MM-DDTHH:mm"),
		data.end_date = moment(bookingSeries.end_date).format("YYYY-MM-DDTHH:mm"),
        data.coach_set = data.coach_set.map((item) => item.id);
        data.booked_person_set = bookingSeries.getBookedPersons();
        data.requested_equipment = bookingSeries.getRequestedEquipment();
        data.sport = data.sport.id;
        // clean up recurrence
        // Fix: the "N" value for recurrence of "none" should be set to null instead. 
        // We are actually not setting this to "N" now in the view, so can probably get rid of this
        data.recurrence = data.recurrence == '' || data.recurrence == 'N' ? null : data.recurrence;
        data.recurrence_end = !data.recurrence ? null : data.recurrence_end;
        data.days_of_week = bookingSeries.getDaysOfWeek();
        // if there is a cost differene, include the card details
        if (bookingSeries.cost_difference == 0) {            
            delete data["club_customer"]; 
            delete data["stripe_cust"];
            delete data["card_exp_year"]; 
            delete data["card_exp_month"];
        }
        // delete properties not required
        delete data["booking_instance_set"];
        delete data["transaction_set"];
        delete data["id"];
        delete data["created"];
        delete data["equipment_set"];
        delete data["bookingDaysOfWeek"];
        delete data["cost"];
        delete data["cost_difference"];
        delete data["equipment_cost"];
        delete data["total_paid"];
        // delete immutable properties
        delete data["club"];
        delete data["sport"];
        delete data["currency"];
        delete data["author"];
        delete data["author_name"];
        delete data["player"];
        delete data["player_name"];
        delete data["player_email"];
        delete data["contact_number"];
        delete data["guests"];

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;   // we don't serialise this as we need to consume it in a different way
            });
    }

    deleteCourtBookingSeries(bookingSeriesId: string): Promise<void> {

        const url = `/api/courtbookingseries/${bookingSeriesId}/cancel/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: null,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors);
    }

    courtBookingSeriesPrice(bookingSeriesId: string): Promise<BookingSeries> {
        
        const url = `/api/courtbookingseries/${bookingSeriesId}/booking_cost/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    courtBookingSeriesChargePayment(bookingSeriesId: string, payment: BookingSeriesPayment): Promise<Transaction> {
        
        const url = `/api/courtbookingseries/${bookingSeriesId}/pay_booking/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(payment),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    updateCourtBookingInstance(bookingInstance: BookingInstance): Promise<BookingInstance> {
        
        const url = `/api/courtbookinginstance/${bookingInstance.id}/`;

        /// Flatten data
        const data = JSON.parse(JSON.stringify(bookingInstance)); // convert to POJO
        delete data["booking_series"]; // remove the booking_series from the instance
        data.coach_set = data.coach_set.map((item) => item.id);

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new BookingInstance(data.body);
            });
    }

    deleteCourtBookingInstance(bookingInstanceId: string): Promise<void> {

        const url = `/api/courtbookinginstance/${bookingInstanceId}/cancel/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: null,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors);
    }

    tournaments(brief: boolean, playerId: string, pageNumber: number, pageSize: number): Promise<any> {

        const url = `/api/tournament/?brief=${brief}&member_set__player=${playerId}&page=${pageNumber}&page_size=${pageSize}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body
            });        
    }

    tournamentsOrganising(brief: boolean, playerId: string, pageNumber: number, pageSize: number): Promise<any> {

        const url = `/api/tournament/?brief=${brief}&member_set__player=${playerId}&member_set__is_organiser=True&page=${pageNumber}&page_size=${pageSize}`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body
            });        
    }

    tournament(tournamentId: string): Promise<Tournament> {

        const url = `/api/tournament/${tournamentId}/`;
        
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Tournament(data.body);
            });        
    }

    saveTournament(tournament: Tournament): Promise<Tournament> {
        
		let method = "POST";
		let url = "/api/tournament/";

		if(tournament.id) {
			method = "PUT";
			url += `${tournament.id}/`;
        }
        
        const data = JSON.parse(JSON.stringify(tournament)); // convert to POJO

        /// Flatten data
        data.club_set = data.club_set.map((item) => item.id);

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Tournament(data.body);
            });
    }
    
    saveTournamentData(tournamentData: any, tournamentId: string): Promise<Tournament> {
        
		const url = `/api/tournament/${tournamentId}/`;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(tournamentData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Tournament(data.body);
            });
    }

    triggerTournamentDraw(tournamentId: string): Promise<Tournament> {
        
		const url = `/api/tournament/${tournamentId}/draw/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: null,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Tournament(data.body);
            });
    }

    updateTournamentDraw(fromRoundId: string): Promise<Tournament> {
        
		const url = `/api/round/${fromRoundId}/updatefrom/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: null,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
    }
    
    markTournamentDrawComplete(tournamentId: string): Promise<Tournament> {
        
        const data = {
			"draw_complete": true
        };
        
		const url = `/api/tournament/${tournamentId}/`;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Tournament(data.body);
            });
    }

    triggerTournamentPublish(tournamentId: string): Promise<Tournament> {
        
		const url = `/api/tournament/${tournamentId}/publish/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: null,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Tournament(data.body);
            });
    }

    tournamentMembers(tournamentId: string): Promise<Member[]> {

        const url = `/api/member/?tournament=${tournamentId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new Member(item));

                return results;
            });
    }

    saveTournamentMembers(membersData: any[]): Promise<Member[]> {

        const url = `/api/member/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(membersData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.map((item: any) => new Member(item));

                return results;
            });
    }

    updateTournamentMember(member: Member): Promise<Member> {
        const url = `/api/member/${member.id}/`;
        
        const data = JSON.parse(JSON.stringify(member)); // convert to POJO

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Member(data.body);
            });
    }

    saveTournamentMemberData(memberData: any, memberId: string): Promise<Member> {
        const url = `/api/member/${memberId}/`;
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(memberData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
        .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Member(data.body);
            });
    }

    deleteTournamentMember(memberId: string): Promise<void> {

        const url = `/api/member/${memberId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    tournamentTeams(tournamentId: string): Promise<Team[]> {
        const url = `/api/team/?tournament=${tournamentId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new Team(item));

                return results;
            });
    }

    addTournamentTeams(teamsData: any[]): Promise<Team[]> {

        const url = `/api/team/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(teamsData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.map((item: any) => new Team(item));

                return results;
            });
    }

    saveTournamentTeam(teamData: any, teamId: string): Promise<Team> {

        const url = `/api/team/${teamId}/`;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(teamData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Team(data.body);
            });
    }

    bulkPlayerLoad(players: PlayerBulk[]): Promise<PlayerBulk[]> {
        const url = `/api/player/bulk/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(players),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.map((item: any) => new PlayerBulk(item));

                return results;
            });
    }

    bulkPlayerFileLoad(formData: FormData): Promise<PlayerBulk[]> {
        const url = `/api/player/bulk/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: formData,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.map((item: any) => new PlayerBulk(item));

                return results;
            });
    }

    matchGroups(tournamentId: string): Promise<MatchGroup[]> {
        const url = `/api/matchgroup/?tournament=${tournamentId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map((item: any) => new MatchGroup(item));

                return results;
            });
    }

    matchGroup(matchGroupId: string): Promise<MatchGroup> {
        const url = `/api/matchgroup/${matchGroupId}/`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new MatchGroup(data.body);
            });
    }

    saveMatchGroupData(matchGroupData: any, matchGroupId = ""): Promise<MatchGroup> {
        
        let url = `/api/matchgroup/`;
        let method = "POST";

        if(matchGroupId != "") {
            method = "PATCH";
            url += `${matchGroupId}/`;
        }

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(matchGroupData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new MatchGroup(data.body);
            });
    }

    matches(playerId: string, pageNumber: number, pageSize: number, filter: any): Promise<any> {

        let url = `/api/match/?commenced=1&player=${playerId}&page=${pageNumber}&page_size=${pageSize}`;
        
        if(filter) {
            url += `&${filter.type}=${filter.value}`;
        }

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body
            });        
    }

    saveMatch(match: Match): Promise<Match> {
        
		let method = "POST";
		let url = "/api/match/";

		if(match.id) {
			method = "PATCH";
			url += `${match.id}/`;
        }
        
        const data = JSON.parse(JSON.stringify(match)); // convert to POJO

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Match(data.body);
            });
    }

    saveMatchData(matchData: any, matchId: string): Promise<Match> {
        
		const url = `/api/match/${matchId}/`;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(matchData),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Match(data.body);
            });
    }

    saveTournamentRound(round: Round): Promise<Round> {
        
		const method = "POST";
		const url = "/api/round/";

        const data = JSON.parse(JSON.stringify(round)); // convert to POJO

        /// Flatten data
        data.tournament = data.tournament.id;

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Round(data.body);
            });
    }

    refundPayment(transactionId: string, amount: any): Promise<Refund> {
        const url = `/api/transaction/` + transactionId + `/refund/`;

        const data = JSON.parse(JSON.stringify(amount)); // convert to POJO

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Refund(data.body);
            })
    }

    createNewPlayerSetupIntent(customerId: string): Promise<PlayerStripeCustomer> {
        
		const method = "POST";
		const url = "/api/stripecustomer/" + customerId + "/create_new_intent/";

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new PlayerStripeCustomer(data.body);
            });
    }

    addPaymentMethodToCustomer(customerId: string, paymentMethodId: string): Promise<PlayerStripeCustomer> {
        
		const method = "POST";
		const url = "/api/stripecustomer/" + customerId + "/add_payment_method/";

        const data = {
            payment_method_id: paymentMethodId
        }

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new PlayerStripeCustomer(data.body);
            });
    }

    // Club Messages, Message Group API Calls
    
    messageGroups(clubId: string): Promise<any> {

        const url = `/api/messagegroup/?club=${clubId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            }); 
    }

    messageGroupsSearch(clubId: string, searchTerm: string, pageSize: number): Promise<any> {

        const url = `/api/messagegroup/?club=${clubId}&search=${searchTerm}&page_size=${pageSize}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            }); 
    }

    messageGroupRecipients(messageGroup: MessageGroup): Promise<ClubMember[]> {
    
        const url = `/api/messagegroup/${messageGroup.id}/group_club_members/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.map((item: any) => new ClubMember(item));
            })
    }

    addMessageGroup(messageGroup: MessageGroup): Promise<MessageGroup> {
    
        const url = "/api/messagegroup/";
        
        const data = {
			"name": messageGroup.name,
            "club": messageGroup.club.id,
            "ttype": messageGroup.ttype,            
            "player_set": messageGroup.return_player_set_ids,
            "membership_type_set": messageGroup.return_membership_type_set_ids,
            "allow_response": messageGroup.allow_response
		};

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new MessageGroup(data.body);
            })
    }

    updateMessageGroup(messageGroup: MessageGroup): Promise<MessageGroup> {

        const url = `/api/messagegroup/${messageGroup.id}/`;

        const data = {
			"name": messageGroup.name,
            "club": messageGroup.club.id,
            "ttype": messageGroup.ttype,            
            "player_set": messageGroup.return_player_set_ids,
            "membership_type_set": messageGroup.return_membership_type_set_ids,
            "allow_response": messageGroup.allow_response
		};
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new MessageGroup(data.body);
            })
    }

    deleteMessageGroup(messageGroup: MessageGroup): Promise<void> {

        const url = `/api/messagegroup/${messageGroup.id}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    addCommentToThread(threadId: string, comment: string, playerId: string): Promise<any> {

        const data = {
			"thread": threadId,
            "player": playerId,
            "text": comment,
		};

        const url = `/api/comment/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const comment = new Comment(data.body);
                return comment;
            });
    }

    newThreadComments(playerId: string): Promise<CommentsNew> {

        const url = `/api/comment/new/${playerId}/`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new CommentsNew(data.body);
            }); 
    }

    markPlayerThreadAsRead(playerThreadId: string): Promise<any> {

        const nowLocal = moment().format('YYYY-MM-DDTHH:mm:ss'); 
		const dateUTC = moment(nowLocal).utc().format('YYYY-MM-DDTHH:mm:ss');

        const data = {
			"read_up_to": dateUTC
		};

        const url = `/api/playerthread/${playerThreadId}/`;

        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            });
    }

    thread(threadId: string): Promise<Thread> {
        const url = `/api/thread/${threadId}/`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Thread(data.body);
            });
    }

    // point of sale API calls

    checkCanEnablePointOfSale(clubId: string): Promise<boolean> {
        const url = `/api/club/${clubId}/can_enable_pos/`;
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                if(!data.body.result) 
                    throw data.body;
                    
                return data.body.result;
            });
    }

    clubProducts(clubId: string, categories: [], searchTerm: string, pageSize: number): Promise<any> {
        
        let url = `/api/clubproduct/?club=${clubId}&page_size=${pageSize}`;
        if (categories && categories.length > 0) {
            // we are filtering by category also
            const categoryString = categories.join(',');
            url = url + `&category=${categoryString}`;
        }
        if (searchTerm && searchTerm.length > 0) {
            // filtering by search term
            url = url + `&search=${searchTerm}`;
        }

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data;
            }); 
    }

    clubProductCategories(): Promise<[]> {
        
        const url = `/api/pos/prod_categories/`;
        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            }); 
    }

    addClubProduct(clubProduct: ClubProduct): Promise<ClubProduct> {
    
        const url = "/api/clubproduct/";
        
        const data = {
            "club": clubProduct.club.id,
			"name": clubProduct.name,         
            "brand": clubProduct.brand,  
            "code": clubProduct.code,  
            "category": clubProduct.category,  
            "desc": clubProduct.desc,  
            "member_price": clubProduct.member_price,  
            "nonmember_price": clubProduct.nonmember_price,  
            "tax_rate": clubProduct.tax_rate,  
            "enabled": clubProduct.enabled,
            "duration": clubProduct.duration,
            "created_by": clubProduct.created_by.id
		};

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubProduct(data.body);
            })
    }

    updateClubProduct(clubProduct: ClubProduct): Promise<ClubProduct> {

        const url = `/api/clubproduct/${clubProduct.id}/`;

        const data = {
			"name": clubProduct.name,           
            "brand": clubProduct.brand,  
            "code": clubProduct.code,  
            "category": clubProduct.category,  
            "desc": clubProduct.desc,  
            "member_price": clubProduct.member_price,  
            "nonmember_price": clubProduct.nonmember_price,  
            "tax_rate": clubProduct.tax_rate,  
            "enabled": clubProduct.enabled,
            "duration": clubProduct.duration,
		};
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubProduct(data.body);
            })
    }

    updateClubProductEnabled(clubProduct: ClubProduct): Promise<ClubProduct> {

        const url = `/api/clubproduct/${clubProduct.id}/`;

        const data = {
            "enabled": clubProduct.enabled
		};
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubProduct(data.body);
            })
    }

    deleteClubProduct(clubProduct: ClubProduct): Promise<void> {
    
        const url = `/api/clubproduct/${clubProduct.id}/delete_product/`;
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    getCartDetails(clubCart: ClubCart): Promise<ClubCartDetail> {
    
        const url = `/api/pos/cart_details/`;
        
        // get the locally managed clubCart and convert to an object which
        // is verified by the server
        const clubCartToSend = new ClubCart({
            club: clubCart.club,
            member: clubCart.member,
            discount: clubCart.discount
        });
        for(const p of clubCart.products) {
            clubCartToSend.products.push({                
                id: p.product_id,
                quantity: p.quantity
            })
        }

        //console.log(JSON.stringify(clubCartToSend));

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(clubCartToSend),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubCartDetail(data.body);
            })
    }
    
    verifyPlayerCreditCard(stripeCustomerId: number, last4: string, expiryMonth: string, expiryYear: string): Promise<boolean> {
    
        const url = `/api/stripecustomer/${stripeCustomerId}/verify/`;

        // only send to the server a limited set of clubCustomer
        const body = {
            card_last4: last4,
            card_exp_month: expiryMonth,
            card_exp_year:  expiryYear
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  { return r.status == 200 })
            .catch(() => {
                return false;
            });
    }

    chargePlayerCreditCard(stripeCustomerId: number, last4: string, expiryMonth: string, expiryYear: string): Promise<boolean> {
    
        const url = `/api/stripecustomer/${stripeCustomerId}/verify/`;

        // only send to the server a limited set of clubCustomer
        const body = {
            card_last4: last4,
            card_exp_month: expiryMonth,
            card_exp_year:  expiryYear
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  { return r.status == 200 })
            .catch(() => {
                return false;
            });
    }

    createClubCustomer(clubCustomer: ClubCustomer): Promise<ClubCustomer> {
    
        const url = `/api/clubcustomer/`;

        // only send to the server a limited set of clubCustomer
        const clubCustomerToSend = {
            club: clubCustomer.club,
            name: clubCustomer.name,
            email:  clubCustomer.email
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(clubCustomerToSend),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubCustomer(data.body);
            })
    }

    // charge a player customer
    pointOfSaleChargePlayerCustomer(playerStripeCustomerId: number, chargeRequest: ClubCartChargeRequest): Promise<Transaction> {
        const url = `/api/stripecustomer/${playerStripeCustomerId}/charge/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(chargeRequest),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Transaction(data.body);
            })
    }

    // charge a club customer (guest)
    pointOfSaleChargeGuestCustomer(clubCustomerId: number, chargeRequest: ClubCartChargeRequest): Promise<Transaction> {
        const url = `/api/clubcustomer/${clubCustomerId}/charge/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(chargeRequest),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Transaction(data.body);
            })
    }

    addPaymentMethodToClubCustomer(clubCustomerId: string, paymentMethodId: string): Promise<ClubCustomer> {
        
		const method = "POST";
		const url = "/api/clubcustomer/" + clubCustomerId + "/add_payment_method/";

        const data = {
            payment_method_id: paymentMethodId
        }

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubCustomer(data.body);
            });
    }

    addCreditCardMotoToClubCustomer(clubCustomerId: string, cardNumber: number, cardExpiryYear: number, cardExpiryMonth: number, cardCVC: number): Promise<ClubCustomer> {
        
		const method = "POST";
		const url = "/api/clubcustomer/" + clubCustomerId + "/create_moto_details/";

        // {
        //    "card_number": 4242424242424242,
        //    "card_expiry_year": 24, 
        //    "card_expiry_month": 12,
        //    "cvc": 123        
        // }

        const data = {
            card_number: cardNumber,
            card_expiry_year: cardExpiryYear,
            card_expiry_month: cardExpiryMonth,
            cvc: cardCVC
        }

        const options: RequestInit = {
            method: method,
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubCustomer(data.body);
            });
    }

    // Events (Clinics) API calls

    // Get all the club events 
    clubEvents(clubId: string, pageSize = 0, fromDate: Moment|string = "", toDate: Moment|string = "", status = "", searchWord = "", type = ""): Promise<any> {

        let url = `/api/event/?club=${clubId}&summary=True`;
        const dateFormat = 'YYYY-MM-DD';

        if(pageSize > 0)
            url += `&page_size=${pageSize}`;

        if(fromDate)
            url += `&from_date=${moment(fromDate).format(dateFormat)}`;

        if(toDate)
            url += `&to_date=${moment(toDate).format(dateFormat)}`;

        if(status)
            url += `&status=${status}`;

        if(searchWord)
            url += `&search=${searchWord}`;

        if(type)
            url += `&ttype=${type}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            }); 
    }

    // Get the club events, small payload (quick)
    clubEventsTiny(clubId: string, fromDate: Moment|string = "", toDate: Moment|string = "", status = ""): Promise<Event[]> {

        let url = `/api/event/?club=${clubId}&tiny=True`;
        const dateFormat = 'YYYY-MM-DD';

        if(fromDate)
            url += `&from_date=${moment(fromDate).format(dateFormat)}`;

        if(toDate)
            url += `&to_date=${moment(toDate).format(dateFormat)}`;

        if(status)
            url += `&status=${status}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map(
                    (item: any) => new Event(item)
                );

                return results;
            }); 
    }

    // get a club event
    clubEvent(clubEventId: string): Promise<Event> {

        const url = `/api/event/${clubEventId}/`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body);
            })
    }

    // Create a new club event
    addClubEvent(clubEvent: Event): Promise<Event> {
    
        const url = "/api/event/";
        
        const data = clubEvent.getEventForServer();
        //console.log(data);

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body);
            })
    }

    // Update the club event
    updateClubEvent(clubEvent: Event): Promise<Event> {

        const url = `/api/event/${clubEvent.id}/`;

        const data = clubEvent.getEventForServer();
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body);
            })
    }

    deleteClubEvent(clubEventId: Event): Promise<void> {
        const url = `/api/event/${clubEventId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
        .then(Utilities.handleAPIErrors)
    }

    // Create sessions for an event
    createClubEventSessions(clubEvent: Event): Promise<EventSession[]> {
        
        const url = `/api/event/${clubEvent.id}/create_sessions/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.map((item: any) => new EventSession(item));
            })
    }

    // Create court bookings for an event
    createClubEventCourtBookings(clubEvent: Event): Promise<EventSessionBookingResponse> {
        
        const url = `/api/event/${clubEvent.id}/book_courts/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventSessionBookingResponse(data.body);
            })
    }

    // Create court booking for a single session
    createClubEventSessionCourtBooking(clubEventSession: EventSession): Promise<EventSession> {
        
        const url = `/api/eventsession/${clubEventSession.id}/create_booking/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventSession(data.body);
            })
    }

    // Publish an event
    publishClubEvent(clubEvent: Event): Promise<Event> {
        
        const url = `/api/event/${clubEvent.id}/publish/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body);
            })
    }

    // Unpublish an event
    unpublishClubEvent(clubEvent: Event): Promise<Event> {
        
        const url = `/api/event/${clubEvent.id}/unpublish/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body);
            })
    }

    // Cancel an event
    cancelClubEvent(clubEvent: Event): Promise<Event> {
        
        const url = `/api/event/${clubEvent.id}/cancel/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body);
            })
    }

    // Edit an event session
    editClubEventSession(clubEventSession: EventSession): Promise<EventSession> {
        
        const url = `/api/eventsession/${clubEventSession.id}/edit/`;

        const data = {
            start: clubEventSession.start,
            end: clubEventSession.end,
            court_set: clubEventSession.court_set.map(c => { return c.id }),
            coach_set: clubEventSession.coach_set.map(c => { return c.id })
        }

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventSession(data.body);
            })
    }

     // Cancel an event session
     cancelClubEventSession(clubEventSession: EventSession): Promise<EventSession> {
        
        const url = `/api/eventsession/${clubEventSession.id}/cancel/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventSession(data.body);
            })
    }

    // Get club terms for the event
    clubTermsByEvent(eventId: string): Promise<EventClubTerms[]> {

        const url = `/api/clubterms/?event=${eventId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.results.map((item: any) => new EventClubTerms(item));
            });
    }

    // Get default club terms for the club
    defaultClubTerms(clubId: string): Promise<EventClubTerms> {

        const url = `/api/clubterms/?club=${clubId}&default=True&ttype=C`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors) 
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const terms = data.body.results.map((item: any) => new EventClubTerms(item));
                // return the top most default terms
                if (terms.length > 0) return terms[0];
            });
    }

    // Create club terms
    addClubTerms(clubTerms: EventClubTerms): Promise<EventClubTerms> {
    
        const url = "/api/clubterms/";
        
        const data = clubTerms.getEventClubTermsForServer();

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventClubTerms(data.body);
            })
    }

    // update the club terms
    updateClubTerms(clubTerms: EventClubTerms): Promise<EventClubTerms> {

        const url = `/api/clubterms/${clubTerms.id}/`;

        const data = clubTerms.getEventClubTermsForServer();
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventClubTerms(data.body);
            })
    }

    // get the clinic/session price
    getClubEventSessionCost(clubEvent: Event, clubEventParticipant: EventJoinParticpant): Promise<EventParticipantPriceResponse> {
        
        const url = `/api/event/${clubEvent.id}/join/`;

        const data = clubEventParticipant.getPriceCheckForServer();

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventParticipantPriceResponse(data.body);
            })
    }

    // get the clinic participants list
    getClubEventParticipantsList(clubEvent: Event): Promise<EventParticipant[]> {
        
        const url = `/api/event/${clubEvent.id}/get_participants/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.map((item: any) => new EventParticipant(item));
            });
    }

    // get a list of clinic session participants
    getClubEventSessionParticipantsList(sessionId: number) {

        const url = `/api/eventsession/${sessionId}/get_participants/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body.map((item: any) => new EventParticipant(item));
            });
    }

     // join the clinic/session
     addParticipantToClubEvent(clubEvent: Event, clubEventParticipant: EventJoinParticpant): Promise<EventJoinParticipantResponse> {
        
        const url = `/api/event/${clubEvent.id}/join/`;

        const data = clubEventParticipant.getEventJoinParticpantForServer();

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventJoinParticipantResponse(data.body);
            })
    }

    // get clinic session price for additional sessions
    getClubEventAdditionalSessionCost(participantId: string, clubEventParticipant: EventJoinParticpant): Promise<EventParticipantPriceResponse> {
        
        const url = `/api/eventparticipant/${participantId}/add_sessions/`;

        const data = clubEventParticipant.getAddSessionPriceCheckForServer();

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventParticipantPriceResponse(data.body);
            })
    }

    // add new sessions to an existing participant
    addSessionsToClubEventParticipant(participantId: string, clubEventParticipant: EventJoinParticpant): Promise<EventJoinParticipantResponse> {
        
        const url = `/api/eventparticipant/${participantId}/add_sessions/`;

        const data = clubEventParticipant.getAddSessionsParticpantForServer();

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventJoinParticipantResponse(data.body);
            })
    }

    // update the clinic participant
    updateClubEventParticipant(clubEventParticipant: EventParticipant): Promise<EventParticipant> {

        const url = `/api/eventparticipant/${clubEventParticipant.id}/edit/`;

        const data = {
            dob: clubEventParticipant.dob,
            email: clubEventParticipant.email,
            name: clubEventParticipant.name,
            gender: clubEventParticipant.gender,
            number: clubEventParticipant.number,
            notes: clubEventParticipant.notes,
        }
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventParticipant(data.body);
            })
    }

    // withdraw player from session
    withdrawParticipantFromClubEventSession(clubEventParticipant: EventParticipant, sessionSet: string[]): Promise<EventParticipant> {

        const url = `/api/eventparticipant/${clubEventParticipant.id}/withdraw/`;

        // sessionSet is a list of session ID's which the player is withdrawing from
        const data = {
            session_set: sessionSet
        }
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventParticipant(data.body);
            })
    }

    // add a event membershipt type rate
    addClubEventMembershipTypeRate(typeRate: EventMembershipTypeRate): Promise<EventMembershipTypeRate> {
    
        const url = "/api/eventmembershiptyperate/";
        const data = typeRate.getEventMembershipTypeRateForServer();
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventMembershipTypeRate(data.body);
            })
    }

    updateClubEventMembershipTypeRate(typeRate: EventMembershipTypeRate): Promise<EventMembershipTypeRate> {

        const url = `/api/eventmembershiptyperate/${typeRate.id}/`;
        const data = typeRate.getEventMembershipTypeRateForServer();
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventMembershipTypeRate(data.body);
            })
    }

    deleteClubEventMembershipTypeRate(typeRateId: EventMembershipTypeRate): Promise<void> {
        const url = `/api/eventmembershiptyperate/${typeRateId}/`;

        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    getClubEventMap(clubId: string, fromDate: Moment, toDate: Moment, eventType: string): Promise<any> {

        const url = "/api/club/" + clubId + "/get_event_map/";
        const body = {
            from: fromDate.format('YYYY-MM-DD'),
            to: toDate.format('YYYY-MM-DD'),
            type: eventType
        }
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            })      
    }

    cloneClubEvent(eventId: string, startDate: Moment|null, endDate: Moment|null, numberSessions: string|null, excludedDates: string[]): Promise<EventClone> {

        let start = null as string|null;
        if (startDate) start = moment(startDate).format('YYYY-MM-DD');
        if (start == null) return new Promise(resolve => resolve(null));

        let end = null as string|null;
        if (endDate) end = moment(endDate).format('YYYY-MM-DD');

        let sessions = null as number|null;
        if (numberSessions) sessions = parseInt(numberSessions);

        const url = "/api/event/" + eventId + "/clone/";
        const body = {
            start_date: start,
            end_date: end,
            num_sessions: sessions,
            excluded_dates: excludedDates
        }
        // sanity check to ensure only end_date or number of sessions is provided
        if (body.end_date) {
            body.num_sessions = null;
        }
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventClone(data.body);
            })      
    }

    openCloseRegistrationForClubEvent(eventId: string, isOpenForMembers: boolean, isOpenForNonMembers: boolean): Promise<Event> {

        const url = "/api/event/" + eventId + "/manage_registration/";
        const body = {
            members: isOpenForMembers,
            non_members: isOpenForNonMembers
        }
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new Event(data.body.event);
            })      
    }

    manageParticipantCheckin(eventParticipantId: string, isCheckin: boolean, sessions: string[]): Promise<EventParticipant> {

        const checkinType = isCheckin ? 'checkin' : 'undo_checkin';
        const url = "/api/eventparticipant/" + eventParticipantId + "/" + checkinType + "/";
        const body = {
            sessions: sessions
        }
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventParticipant(data.body);
            })      
    }

    // obtain the waitlist for a clinic, session or all
    clubEventWaitlist(clubId: string, clinicId: string|null, sessionId: string|null, fromDate: Moment|string = "", toDate: Moment|string = "", searchWord = "", pageSize = 0): Promise<any> {

        let url = '/api/eventwaitlist/';
        const dateFormat = 'YYYY-MM-DD';

        // if the session is provided we only send the session Id to the 
        // server, even if we know the clinic id
        if (sessionId) {
            url += '?session=' + sessionId;
        }
        else {

            // by clinic id
            if (clinicId) {
                url += '?event=' + clinicId;
            }
            else {

                // for the entire club
                if (!clinicId && !sessionId) {
                    url += '?club=' + clubId;
                }
            }

            if(fromDate)
                url += `&from=${moment(fromDate).format(dateFormat)}`;

            if(toDate)
                url += `&to=${moment(toDate).format(dateFormat)}`;
        }

        if(searchWord)
            url += `&search=${searchWord}`;

        if(pageSize > 0)
            url += `&page_size=${pageSize}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data.body;
            }); 
    }

    cancelEventWaitlist(waitlistItemId: string): Promise<void> {

        const url = `/api/eventwaitlist/${waitlistItemId}/cancel/`;

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: null,
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors);
    }

    updateEventWaitlist(clubEventWaitlist: EventWaitlist): Promise<EventWaitlist> {

        const url = `/api/eventwaitlist/${clubEventWaitlist.id}/edit/`;

        const data = {
            notice: parseInt(clubEventWaitlist.notice)
            //club_customer: clubEventWaitlist.club_customer
        }
        
        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new EventWaitlist(data.body);
            })
    }

    clubEventSessionsTiny(clinicId: string): Promise<EventSession[]> {

        const url = '/api/eventsession/?event=' + clinicId + "&tiny=True";

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map(
                    (item: any) => new EventSession(item)
                );
                return results;
            }); 
    }

    addClubEquipment(clubEquipment: ClubEquipment): Promise<ClubEquipment> {
    
        const url = "/api/clubequipment/";
        
        const data = clubEquipment;
        data.court_set.map(c => {
            return c.id
        });

        const options: RequestInit = {
            method: "POST",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubEquipment(data.body);
            })
    }

    updateClubEquipment(clubEquipment: ClubEquipment): Promise<ClubEquipment> {

        const url = `/api/clubequipment/${clubEquipment.id}/`;

        const data = clubEquipment;
        data.court_set.map(c => {
            return c.id
        });
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new ClubEquipment(data.body);
            })
    }

    deleteClubEquipment(clubEquipment: ClubEquipment): Promise<void> {
    
        const url = `/api/clubequipment/${clubEquipment.id}/`;
        
        const options: RequestInit = {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
    }

    clubEquipment(clubId: string, pageSize: number): Promise<any> {
        
        const url = `/api/clubequipment/?club=${clubId}&page_size=${pageSize}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return data;
            }); 
    }

    // SlamFan Admin Calls
    updateSlamMatchScore(slamMatch: SlamMatch): Promise<SlamMatch>{

        const url = `/api/slammatch/${slamMatch.id}/`;

        // send restricted json to the server
        const body = {
            status: slamMatch.status,
            home_side_sets: slamMatch.home_side_sets,
            away_side_sets: slamMatch.away_side_sets,
            home_side_games: '[' + slamMatch.home_side_games + ']',
            away_side_games: '[' + slamMatch.away_side_games + ']',
            winner: slamMatch.winner,
            forfeit: slamMatch.forfeit
        }
        
        const options: RequestInit = {
            method: "PATCH",
            cache: "no-cache",
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                return new SlamMatch(data.body);
            })
    }    

    getSlamTournamentRounds(slamTourId: string): Promise<SlamRound[]> {

        const url = `/api/slamround/?slam_tour=${slamTourId}`;

        const options: RequestInit = {
            method: "GET",
            cache: "no-cache",
            headers: {
                'Authorization': 'Token ' + Cookies.get("authtoken"),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        return fetch(url, options)
            .then(Utilities.handleAPIErrors)
            .then(r =>  r.json().then(data => ({status: r.status, body: data})))
            .then(data => {
                const results = data.body.results.map(
                    (item: any) => new SlamRound(item)
                );
                return results;
            });
    }
}

export const apiClient = new APIClient();
