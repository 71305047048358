import { ClubCartDetailProductPrice } from '.';

export class ClubCartDetail {
	constructor(data) {
			this.total_cost = data && data.total_cost ? data.total_cost : undefined;
			this.total_tax = data && data.total_tax ? data.total_tax : undefined;
			this.sub_total = data && data.sub_total ? data.sub_total : undefined;
			this.discount = data && data.discount ? data.discount : 0;

			this.products_prices = [];
			if (data && data.products_prices) {
					this.products_prices = data.products_prices.map((item) => new ClubCartDetailProductPrice(item));
			}
	}

	// Return the total cost formatted
	get total_cost_formatted() {
		if (!this.total_cost) return "0.00";
		return (Math.round(this.total_cost * 100) / 100).toFixed(2).toString();
	}

	// Return the total tax formatted
	get total_tax_formatted() {
		if (!this.total_tax) return "0.00";
		return (Math.round(this.total_tax * 100) / 100).toFixed(2).toString();
	}

	// Return the discount formatted
	get discount_formatted() {
		if (!this.discount) return "-";
		return (Math.round(this.discount * 100) / 100).toFixed(2).toString() + "%";
	}

	// Return the sub total formatted
	get sub_total_formatted() {
		if (!this.sub_total) return "0.00";
		return (Math.round(this.sub_total * 100) / 100).toFixed(2).toString();
	}
}