import $ from 'jquery';
import Cookies from 'js-cookie';
import PageNotFound from '@/components/page-not-found.vue';
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: () => import('../views/home.vue'),
    },
    {
        path: "/faq",
        name: "FAQ",
        component: () => import(/* webpackChunkName: "faq" */ "../views/public/faq.vue"),
    },
    {
        path: "/feedback",
        name: "Feedback",
        component: () => import(/* webpackChunkName: "feedback" */ "../views/public/feedback.vue"),
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: () => import(/* webpackChunkName: "privacy" */ "../views/public/privacy.vue"),
    },
    {
        path: "/club-terms",
        name: "Club Terms",
        component: () => import(/* webpackChunkName: "club-terms" */ "../views/public/club-terms.vue"),
    },
    {
        path: "/verified",
        name: "Email Verified",
        component: () => import(/* webpackChunkName: "user-verified" */ "../views/user/verified.vue"),
    },
    {
        path: "/signup",
        name: "Sign Up",
        component: () => import(/* webpackChunkName: "signup" */ "../views/public/signup.vue"),
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        component: () => import(/* webpackChunkName: "forgot-password" */ "../views/public/forgot-password.vue"),
    },
    {
        path: "/tennis_find_a_hit_matcha",
        name: "Marketing - Matcha",
        component: () => import(/* webpackChunkName: "tennis-find-a-hit" */ "../views/marketing/tennis-find-a-hit-matcha.vue"),
    },
    {
        path: "/tennis_club_point_of_sale",
        name: "Marketing - Point of Sale",
        component: () => import(/* webpackChunkName: "tennis-club-point-of-sale" */ "../views/marketing/tennis-club-point-of-sale.vue"),
    },
    {
        path: "/tennis_clinics",
        name: "Marketing - Clinics",
        component: () => import(/* webpackChunkName: "tennis-clinics" */ "../views/marketing/tennis-clinics.vue"),
    },
    {
        path: "/tennis_court_bookings",
        name: "Marketing - Court Bookings",
        component: () => import(/* webpackChunkName: "tennis-court-bookings" */ "../views/marketing/tennis-court-bookings.vue"),
    },
    {
        path: "/tennis_club_member_fee_collection",
        name: "Marketing - Club Member Fee Collection",
        component: () => import(/* webpackChunkName: "tennis-fees" */ "../views/marketing/tennis-club-member-fee-collection.vue"),
    },
    {
        path: "/tennis_club_member_communications",
        name: "Marketing - Club Member Communications",
        component: () => import(/* webpackChunkName: "tennis-member-comms" */ "../views/marketing/tennis-club-member-communications.vue"),
    },
    {
        path: "/tennis_league_management",
        name: "Marketing - League Management",
        component: () => import(/* webpackChunkName: "tennis-league" */ "../views/marketing/tennis-league-management.vue"),
    },
    {
        path: "/tennis_circle",
        name: "Marketing - Circle",
        component: () => import(/* webpackChunkName: "tennis-circle" */ "../views/marketing/tennis-circle.vue"),
    },
    {
        path: "/tennis_player_profile",
        name: "Marketing - Player Profile",
        component: () => import(/* webpackChunkName: "tennis-profile" */ "../views/marketing/tennis-player-profile.vue"),
    },
    {
        path: "/tennis_match_diary",
        name: "Marketing - Match Diary",
        component: () => import(/* webpackChunkName: "tennis-diary" */ "../views/marketing/tennis-match-diary.vue"),
    },
    {
        path: "/slamfan",
        name: "SlamFan - For Tennis Grand Slams",
        component: () => import(/* webpackChunkName: "slamfan" */ "../views/marketing/slam-fan.vue"),
    },
    {
        path: "/case-studies_clubs",
        name: "Marketing - Case Study Clubs",
        component: () => import(/* webpackChunkName: "casestudy-clubs" */ "../views/marketing/case-studies-clubs.vue"),
    },
    {
        path: "/case-studies_competitions",
        name: "Marketing - Case Study Competitions",
        component: () => import(/* webpackChunkName: "casestudy-comps" */ "../views/marketing/case-studies-competitions.vue"),
    },
    {
        path: "/sw-club-promotion",
        name: "Str8 Sets in Action",
        component: () => import(/* webpackChunkName: "sw-club-promotion" */ "../views/marketing/sw-club-promotion.vue"),
    },
    {
        path: "/matches",
        name: "Matches",
        component: () => import("../views/matches/list.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/news",
        name: "News",
        component: () => import("../views/news/list.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/slamfanadmin",
        name: "SlamFan",
        component: () => import("../views/slamfan/admin.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tour",
        name: "Tournaments",
        component: () => import("../views/tournaments/list.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/add",
        name: "TournamentAdd",
        component: () => import("../views/tournaments/add.vue"),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/tournament/manageplayers",
        name: "TournamentManagePlayers",
        component: () => import("../views/tournaments/manage-players.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/draw",
        name: "TournamentDraw",
        component: () => import("../views/tournaments/draw.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/manualdraw",
        name: "TournamentManualDraw",
        component: () => import("../views/tournaments/manual-draw.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/createknockoutround",
        name: "TournamentCreateKnockoutRound",
        component: () => import("../views/tournaments/create-knockout-round.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/edit/definition",
        name: "TournamentEditDefinition",
        component: () => import("../views/tournaments/edit/definition.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/edit/playingformat",
        name: "TournamentEditPlayingFormat",
        component: () => import("../views/tournaments/edit/playing-format.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/edit/rules",
        name: "TournamentEditRules",
        component: () => import("../views/tournaments/edit/rules.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/edit/players",
        name: "TournamentEditPlayers",
        component: () => import("../views/tournaments/edit/players.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament/edit/teamsetup",
        name: "TournamentEditTeamSetup",
        component: () => import("../views/tournaments/edit/team-setup.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/clubs",
        name: "ClubsIndex",
        component: () => import("../views/clubs/list.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/clubs/details",
        name: "ClubDetails",
        component: () => import("../views/clubs/details.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/clubs/application",
        name: "ClubApplication",
        component: () => import("../views/clubs/application.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/clubs/dashboard",
        name: "ClubDashboard",
        component: () => import("../views/clubs/dashboard.vue"),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "/clubs/dashboard/club-info",
                name: "ClubDashboardClubInfo",
                component: () => import("../views/clubs/dashboard/club-info.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/members",
                name: "ClubDashboardMembers",
                component: () => import("../views/clubs/dashboard/members.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/matcha",
                name: "ClubDashboardMatcha",
                component: () => import("../views/clubs/dashboard/matcha.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/court-bookings",
                name: "ClubDashboardCourtBookings",
                component: () => import("../views/clubs/dashboard/court-bookings.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/newsletters",
                name: "ClubDashboardNewsletters",
                component: () => import("../views/clubs/dashboard/newsletters.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/messages",
                name: "ClubDashboardMessages",
                component: () => import("../views/clubs/dashboard/messages.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/insights",
                name: "ClubDashboardInsights",
                component: () => import("../views/clubs/dashboard/insights.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/transactions",
                name: "ClubDashboardTransactions",
                component: () => import("../views/clubs/dashboard/transactions.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/point-of-sale",
                name: "ClubDashboardPointOfSale",
                component: () => import("../views/clubs/dashboard/point-of-sale.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/dashboard/clinics",
                name: "ClubDashboardClinics",
                component: () => import("../views/clubs/dashboard/clinics.vue"),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: "/clubs/access-denied",
                name: "ClubDashboardAccessDenied",
                component: () => import("../views/clubs/dashboard/access-denied.vue"),
                meta: {
                    requiresAuth: true,
                }
            }
        ]
    },
    {
        path: '*',
        name: "Page Not Found",
        component: PageNotFound
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
          return {
            selector: to.hash
            // , offset: { x: 0, y: 10 }
          };
        }
        else if (savedPosition) {
            return savedPosition;
        } 
        else {
            return { x: 0, y: 0 };
        }
      }
});

router.beforeEach ( (to, from, next ) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (Cookies.get("authtoken") == null) {

            if(to.path == "/clubs") {
                next({
                    path: '/#clubs',
                })
            }
            else {
                next({
                    path: '/signup',
                    params: { nextUrl: to.fullPath }
                })
            }
        } else {

            //could do some cool admin checks such as shown here from https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router#step-3-%E2%80%94-updating-the-vue-router-file
            // let user = JSON.parse(localStorage.getItem('user'))
            // if(to.matched.some(record => record.meta.is_admin)) {
            //     if(user.is_admin == 1){
            //         next()
            //     }
            //     else{
            //         next({ name: 'userboard'})
            //     }
            // }
            // else {

                (document.querySelectorAll(".cssmenu input[type='checkbox']") as any).forEach(element => element.checked = false);
                $(".navbar-collapse").collapse('hide');

                next();
            // }
        }
    }
    else {
     
        (document.querySelectorAll(".cssmenu input[type='checkbox']") as any).forEach(element => element.checked = false);
                $(".navbar-collapse").collapse('hide');
        
        next()
    }
});

export default router;

