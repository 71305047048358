import { Currency } from '../currency';
import { Player } from '../player';
import ClubSubscriptionStatesEnum from '@/utils/enum/club-subscription-states-enum';

export class Subscription {
 
    constructor(data) {

        this.id = data && data.id ? data.id : undefined;
        this.customer = data && data.customer ? data.customer : undefined;
        this.club = data && data.club ? data.club : undefined;
        this.player = data && data.player ? new Player(data.player) : null;
        this.purpose = data && data.purpose ? data.purpose : "";
        this.amount = data && data.amount ? data.amount : "";   // nb: it is a string (not double) for historical backend reasons
        this.currency = data && data.currency ? new Currency(data.currency) : null;
        this.status = data && data.status ? data.status : "";
        this.recurring = data && data.recurring ? data.recurring : "";
        this.next_charge = data && data.next_charge ? data.next_charge : undefined;
        this.created = data && data.created ? data.created : undefined;
    }

    get subscriptionState() {
        return ClubSubscriptionStatesEnum[this.status];
    }
}
