import '@/utils/validation/vee-validate.js';
import { currency, currencyHTML } from '@/utils/filters/currency';
import { EventBus, ModalBus } from '@/event-bus.js';
import { fixedNumber } from '@/utils/filters/fixed-number';
import { focus } from '@/directives/focus';
import { formattedDate } from '@/utils/filters/formatted-date';
import { ValidationProvider, ValidationObserver, configure } from "vee-validate";
import App from './app.vue';
import retina from 'retinajs';
import router from './router';
import store from './store';
import Vue from 'vue';
import VueHead from 'vue-head';
import VuePromiseBtn from 'vue-promise-btn';
import VueRetina from 'vue-retina';

import '@/components/globals';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery-ui/themes/base/all.css";
import '@/assets/sass/fonts.scss'
import "@/assets/css/str8sets.scss";
import "@/assets/css/style.css";
import "@/assets/css/responsive.css";
import "@/assets/css/vue-promise-btn.css";
import "@/assets/css/new-styles.scss";

Vue.config.productionTip = false

Vue.filter('currency', currency)
Vue.filter('currencyHTML', currencyHTML)
Vue.filter('fixedNumber', fixedNumber)
Vue.filter('formattedDate', formattedDate)

Vue.directive('focus', focus)

Vue.use(VuePromiseBtn);

Vue.use(VueRetina, {retina})

Vue.use(VueHead)

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

configure({
    classes: {
        valid: "",
        invalid: "has-error",
        dirty: ["is-dirty", "is-dirty"] // multiple classes per flag!
        // ...
    }
});

Vue.prototype.$eventBus = EventBus;
Vue.prototype.$modalBus = ModalBus;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
