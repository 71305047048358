
<!-- 
    August 2023 Date Picker Simple
    Anthony Nosek | Str8 Sets

    Notes:  This is a date picker component supporting
            1)  Selection of single date with/without validation
-->

<script lang="ts">
    import { bootstrapPopover } from '@/directives/bootstrap-popover';
    import { ValidationProvider } from "vee-validate";
    //import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import moment from 'moment';
    import VCalendar from 'v-calendar';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'DatePickerSimple',
        model: {
            prop: 'selectedDate'
        },
        components: {
            ValidationProvider,
            'v-date-picker': VCalendar.DatePicker 
        },
        directives: {
            bootstrapPopover
        },
        props: {
            value: {
                type: String,
                default: ""
            },
            tag: {
                type: String,
                default: 'li'
            },
            placeholder: {
                type: String,
                default: "",
                required: false
            },            
            rules: {
                type: [String, Object],
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            name: {
                type: String,
                default: ""
            },
            helpText: {
                type: String,
                default: ""
            },
            popoverText: {
                type: String,
                default: ""
            },
            vid: {
                type: String,
                default: undefined
            },
            showRequiredLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            formGroupClasses: {
                type: Array,
                default: () => [],
            },
            inputClasses: {
                type: Array,
                default: () => [],
            },
            pastDatesOnly: {
                type: Boolean,
                default: false
            },
            futureDatesOnly: {
                type: Boolean,
                default: false
            },
            dateFormat: {
                type: String,
                default: ""
            },
            outputDateFormat: {
                type: String,
                default: "YYYY-MM-DD"
            },
            showDropDownArrow: {
                type: Boolean,
                default: true
            },
            noInitialValue: {
                type: Boolean,
                default: false
            }
        },
        data() { 
            return {
                isOpen: false,
                dateFormatDisplayStringLong: 'dddd DD MMMM, YYYY',
                currentValueDate: null as null|Date,
                attrs: [
                    {
                        // highlight style
                        highlight: {
                            color: 'purple',
                            fillMode: 'light',
                        }
                    },
                    {
                        // a dot for the current day
                        dot: {
                            backgroundColor: '#4e9a3c',
                        },
                        dates: [
                            moment().toDate()
                        ],
                    }
                ]
            }
        },
        watch: {
            value: {
                handler(val) {
                    if (!val) {
                        this.currentValueDate = null;
                    }
                    else {
                        this.currentValueDate = moment(val).toDate();
                    }
                },
                immediate: true             
            },
            currentValueDate(val) {
                if (!val)
                    this.$emit("input", "");
                else
                    this.$emit("input", moment(val, this.outputDateFormat).format(this.outputDateFormat));
            }
        },
        computed: {
            displayDateFormat(): string {
                if (this.dateFormat)
                    return this.dateFormat;
                else return this.dateFormatDisplayStringLong; 
            },
            title(): string|null { 
                if (this.currentValueDate == null) {
                    return null;
                }
                else {
                    return moment(this.currentValueDate).format(this.displayDateFormat);
                }               
            }
        },
        methods: {
            toggleDropDown() {
                this.isOpen = !this.isOpen;
            },
            openDropDown() {
                this.isOpen = true;
            },
            closeDropDown(event) {
                if(!event.currentTarget.contains(event.relatedTarget)) { 
                    this.isOpen = false;
                }
            }
        },
        mounted() {
            if (this.noInitialValue) {
                this.currentValueDate = null;
                this.attrs[1].dates = [];
            }
        }
    });
</script>

<style>   
</style>

<style scoped>

    .dropdown {
        list-style-type: none;
    }

    .dropdown .dropdown-toggle {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    .datepicker-container 
    .vc-container {
        border: none;
    }

    .dropdown-menu {
        margin-top: 10px;
        border-radius: 20px;
    }

    .datepicker-container {
        width: 275px;
    }

    .dropdown-menu:before, .dropdown-menu:after {
        right: 480px !important;
    }

    .dropdown-menu:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: white transparent;
        border-width: 0 10px 8px;
        top: -8px;
        left: 10%;
        margin-left: -10px;
    }

    .arrow {
        background: url('~@/assets/images/arrow-down-green.svg') 0% 0% no-repeat padding-box;
        background-size: 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0px;
        top: 2px;
        cursor: pointer;
    }

    .btn-datepicker {
        width: 140px;
        height: 29px !important;
        margin-bottom: 6px;
        margin-top: 0px !important;
        border: none;
        background-color: white;
        color: #949494;
        text-align: left;
        padding-left: 5px;
        font: normal 14px Open Sans !important;
    }
    .btn-datepicker:hover {
        color: #006721;
    }
    .btn-datepicker-selected {
        color: #006721;
        font-weight: 600 !important;
    }

    .datepicker {
        float: right;
        margin: 13px;
    }

    .input-wrap {
      position: relative;
      display: inline;
    }
    
    .vc-container::v-deep .vc-title {
        color: #496B7B !important;
    }

    .vc-container {
        display: block;
    }

</style>

<template>
    <div class="new-styles">
    <ValidationProvider
        slim
        :rules="rules"
        :name="name || label"
        :vid="vid"
        v-slot="{ errors, required, showRequiredLabel, ariaInput, classes }">
        <div class="form-group" :class="[classes, formGroupClasses]"
            tabindex="-1"
            @focusout="closeDropDown">

            <label class="control-label" :for="name" v-if="label">
                <span>{{ label || name }}</span>
                <span class="helper">{{ required && showRequiredLabel  ? ' (required)' : '' }}</span>
            </label>
            <span v-if="helpText" class="help-block">{{ helpText }}</span> 

            <component class="form-control white dropdown"
                    :is="tag"
                    :class="[{open: isOpen}, {show: isOpen}]"
                    aria-haspopup="true"
                    :aria-expanded="isOpen">
                <slot name="title" :is-open="isOpen">                    
                    <div class="input-wrap">      
                        <input class="white input-no-border dropdown-toggle"                           
                            readonly      
                            @click="toggleDropDown"  
                            v-bind="ariaInput" 
                            :class="inputClasses"
                            :placeholder="placeholder"       
                            :value="title"
                            v-bootstrap-popover="{ placement: 'right', trigger: 'hover focus', content: popoverText}" />
                        <div v-if="showDropDownArrow" class="arrow" @click="toggleDropDown" />  
                    </div>                      
                </slot>

                <ul class="dropdown-menu datepicker-container"
                    :class="[{ open: isOpen }]">               

                    <div class="datepicker">
                        <v-date-picker
                            ref="picker"
                            color='green'
                            v-model="currentValueDate"
                            :min-date="futureDatesOnly ? new Date() : null"
                            :max-date="pastDatesOnly ? new Date() : null"
                            :attributes='attrs'
                            :timezone=undefined
                            is-required>
                        </v-date-picker>
                    </div>

                </ul>
            </component>

            <span class="help-block" v-if="errors[0]">
                {{ errors[0] }}
            </span> 
        </div>
    </ValidationProvider>
    </div>
</template>