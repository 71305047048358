import { MatchSide, PlayerMatch } from '.';

export class Match {

    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
        this.start = data.start == null ? undefined : new Date(data.start).toISOString().substr(0, 19);
        this.finish = data.finish == null ? undefined : new Date(data.finish).toISOString().substr(0, 19);
        this.format = data.format == null ? undefined : data.format.id;
        this.surface = data.surface == null ? undefined : data.surface.id;
        this.matchgroup = data.matchgroup == null ? null : data.matchgroup;	
        this.cancelled = data.cancelled;
        this.commenced = data.commenced;
    
        this.matchside_set = [];
        if(data && data.matchside_set) {
			this.matchside_set = data.matchside_set.map((item) => new MatchSide(item));
        }
 
        this.playermatch_set = [];
        if(data && data.playermatch_set) {
			this.playermatch_set = data.playermatch_set.map((item) => new PlayerMatch(item));
        }

        this.number_of_sets = data.number_of_sets;
    }

    get winning_team() {
        if(this.matchside_set.length == 0)
            return undefined;

        let indexMatchsetWithHighestScore = -1;
        let currentHighestScore = 0;

        for(let i = 0; i < this.matchside_set.length; i++) {
            if(i == 0 || (this.matchside_set[i].score > currentHighestScore)) {
                currentHighestScore = this.matchside_set[i].score;
                indexMatchsetWithHighestScore = i;
            }
        }
        
        if(indexMatchsetWithHighestScore != -1)
            return this.matchside_set[indexMatchsetWithHighestScore];
        else
            return undefined;
    }

    get losing_team() {
		if(this.matchside_set.length == 0)
			return undefined;

		let indexMatchsetWithLowestScore = -1;
		let currentLowestScore = 0;
		for(let i = 0; i < this.matchside_set.length; i++) {
			if(i == 0 || (this.matchside_set[i].score <= currentLowestScore)) {
				currentLowestScore = this.matchside_set[i].score;
				indexMatchsetWithLowestScore = i;
			}
		}
		
		if(indexMatchsetWithLowestScore != -1)
			return this.matchside_set[indexMatchsetWithLowestScore];
		else
			return undefined;
	}
}