import { apiClient } from "@/utils/apiclient";
import { Transaction } from "@/models";
import {IFilterTransactionsActionPayload, IGetPageAction, IGetTransactionsActionPayload} from './transactions.types';

class State {
    allTransactions: Transaction[] = [];
    successfulTransactions: Transaction[] = [];
    failedTransactions: Transaction[] = [];
    refundedTransactions: Transaction[] = [];
    nextAllTransactionsURL = "";
    nextSuccessfulTransactionsURL = "";
    nextFailedTransactionsURL = "";
    nextRefundedTransactionsURL = "";
    prevAllTransactionsURL = "";
    prevSuccessfulTransactionsURL = "";
    prevFailedTransactionsURL = "";
    prevRefundedTransactionsURL = "";
    allTransactionsTotal = 0;
    successfulTransactionsTotal = 0;
    failedTransactionsTotal = 0;
    refundedTransactionsTotal = 0;
    allTransactionsCurrentPage = 1;
    successfulTransactionsCurrentPage = 1;
    failedTransactionsCurrentPage = 1;
    refundedTransactionsCurrentPage = 1;

    options = {
        all: {
            data: 'allTransactions',
            next: 'nextAllTransactionsURL',
            prev: 'prevAllTransactionsURL',
            total: 'allTransactionsTotal',
            current: 'allTransactionsCurrentPage'
        },
        successful: {
            data: 'successfulTransactions',
            next: 'nextSuccessfulTransactionsURL',
            prev: 'prevSuccessfulTransactionsURL',
            total: 'successfulTransactionsTotal',
            current: 'successfulTransactionsCurrentPage'
        },
        failed: {
            data: 'failedTransactions',
            next: 'nextFailedTransactionsURL',
            prev: 'prevFailedTransactionsURL',
            total: 'failedTransactionsTotal',
            current: 'failedTransactionsCurrentPage'
        },
        refunded: {
            data: 'refundedTransactions',
            next: 'nextRefundedTransactionsURL',
            prev: 'prevRefundedTransactionsURL',
            total: 'refundedTransactionsTotal',
            current: 'refundedTransactionsCurrentPage'
        }
    }
}

export default {
    strict: process.env.NODE_ENV !== "production",
    namespaced: true,
    state: new State(),
    mutations: {

        transactions(state: State, payload) {
            state[payload.option] = payload.results;
        },

        nextPage(state: State, property) {
            state[property]++;
        },

        prevPage(state: State, property) {
            state[property]--;
        },

        resetCurrentPage(state: State, property) {
            state[property] = 1;
        }
    },
    getters: {
        allTransactions: (state: State) => state.allTransactions,
        successfulTransactions: (state: State) => state.successfulTransactions,
        failedTransactions: (state: State) => state.failedTransactions,
        refundedTransactions: (state: State) => state.refundedTransactions,
        allTransactionsTotal: (state: State) => state.allTransactionsTotal,
        successfulTransactionsTotal: (state: State) => state.successfulTransactionsTotal,
        failedTransactionsTotal: (state: State) => state.failedTransactionsTotal,
        refundedTransactionsTotal: (state: State) => state.refundedTransactionsTotal,
        hasAllTransactionsNextPage: (state: State) => (state.nextAllTransactionsURL && state.nextAllTransactionsURL.length > 0) ? true : false,
        hasSuccessfulTransactionsNextPage: (state: State) => (state.nextSuccessfulTransactionsURL && state.nextSuccessfulTransactionsURL.length > 0) ? true : false,
        hasFailedTransactionsNextPage: (state: State) => (state.nextFailedTransactionsURL && state.nextFailedTransactionsURL.length > 0) ? true : false,
        hasRefundedTransactionsNextPage: (state: State) => (state.nextRefundedTransactionsURL && state.nextRefundedTransactionsURL.length > 0) ? true : false,
        hasAllTransactionsPrevPage: (state: State) => (state.prevAllTransactionsURL && state.prevAllTransactionsURL.length > 0) ? true : false,
        hasSuccessfulTransactionsPrevPage: (state: State) => (state.prevSuccessfulTransactionsURL && state.prevSuccessfulTransactionsURL.length > 0) ? true : false,
        hasFailedTransactionsPrevPage: (state: State) => (state.prevFailedTransactionsURL && state.prevFailedTransactionsURL.length > 0) ? true : false,
        hasRefundedTransactionsPrevPage: (state: State) => (state.prevRefundedTransactionsURL && state.prevRefundedTransactionsURL.length > 0) ? true : false,
        allTransactionsCurrentPage: (state: State) => state.allTransactionsCurrentPage,
        successfulTransactionsCurrentPage: (state: State) => state.successfulTransactionsCurrentPage,
        failedTransactionsCurrentPage: (state: State) => state.failedTransactionsCurrentPage,
        refundedTransactionsCurrentPage: (state: State) => state.refundedTransactionsCurrentPage
    },
    actions: {
        
        getTransactionsAction({ commit, state }, payload: IGetTransactionsActionPayload): Promise<any> {
            return apiClient.clubTransactions(payload.clubId, 100, '', '', '', '', payload.option)
                .then(result => {
                    
                    const results = result.results.map(
                        (item: any) => new Transaction(item)
                    );
                    commit("transactions", { results: results, option: state.options[payload.option].data });
                    commit("transactions", { results: result.next, option: state.options[payload.option].next });
                    commit("transactions", { results: result.previous, option: state.options[payload.option].prev });
                    commit("transactions", { results: result.count, option: state.options[payload.option].total });

                    return results;
                });
        },

        filterTransactionsAction({ commit, state }, payload: IFilterTransactionsActionPayload): Promise<any> {
            
            const pageSize = 100;

            return apiClient.clubTransactions(payload.clubId, pageSize, payload.fromDate, payload.toDate, payload.players, payload.paymentType, payload.option)
                .then(result => {

                    const results = result.results.map(
                        (item: any) => new Transaction(item)
                    );
                    commit("transactions", { results: results, option: state.options[payload.option].data });
                    commit("transactions", { results: result.next, option: state.options[payload.option].next });
                    commit("transactions", { results: result.previous, option: state.options[payload.option].prev });
                    commit("transactions", { results: result.count, option: state.options[payload.option].total });
                    commit("resetCurrentPage", state.options[payload.option].current)

                    return results;
                });
        },

        getNextPageAction({ commit, state }, payload: IGetPageAction): Promise<any> {
            return apiClient.dynamicGet(state[state.options[payload.option].next])
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Transaction(item)
                    );
                    commit("transactions", { results: results, option: state.options[payload.option].data });
                    commit("transactions", { results: result.next, option: state.options[payload.option].next });
                    commit("transactions", { results: result.previous, option: state.options[payload.option].prev });
                    commit("transactions", { results: result.count, option: state.options[payload.option].total });
                    commit("nextPage", state.options[payload.option].current);

                    return results;
                })
        },

        getPrevPageAction({ commit, state }, payload: IGetPageAction): Promise<any> {
            return apiClient.dynamicGet(state[state.options[payload.option].prev])
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Transaction(item)
                    );
                    commit("transactions", { results: results, option: state.options[payload.option].data });
                    commit("transactions", { results: result.next, option: state.options[payload.option].next });
                    commit("transactions", { results: result.previous, option: state.options[payload.option].prev });
                    commit("transactions", { results: result.count, option: state.options[payload.option].total });
                    commit("prevPage", state.options[payload.option].current);

                    return results;
                })
        }
    },
    modules: {}
};
