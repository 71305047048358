import { Player } from "@/models/player";

export class EventParticipantSession {
     
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;
        this.participant = data && data.participant ? data.participant : undefined;
        this.session = data && data.session ? data.session : undefined;
        this.paying = data && data.paying ? data.paying : false;
        this.withdrawal = data && data.withdrawal ? data.withdrawal : false;
        this.checked_in = data && data.checked_in ? data.checked_in : false;
        this.checked_in_by = data && data.checked_in_by ? new Player(data.checked_in_by) : null;
        this.checkin_date = data && data.checkin_date ? data.checkin_date : null;
    }
}