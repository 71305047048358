
export class ClubCustomer {
	constructor(data) {
		this.id = data && data.id ? data.id : undefined; 
        this.club = data && data.club ? data.club : undefined;  
        this.card_type = data && data.card_type ? data.card_type : undefined;  
        this.name = data && data.name ? data.name : undefined;  
        this.email = data && data.email ? data.email : undefined;  
        this.card_number = data && data.card_number ? data.card_number : undefined;  
        this.card_expiry_month = data && data.card_expiry_month ? data.card_expiry_month : undefined;  
        this.card_expiry_year = data && data.card_expiry_year ? data.card_expiry_year : undefined;  
        this.client_secret = data && data.client_secret ? data.client_secret : undefined;  
        this.setup_intent_status = data && data.setup_intent_status ? data.setup_intent_status : undefined;  
        this.payment_method_id = data && data.payment_method_id ? data.payment_method_id : undefined;  
        this.card_country_code = data && data.card_country_code ? data.card_country_code : undefined;  
    }
}