export class Team {

    constructor(data) {

        this.id = data.id;
        this.tournament = data && data.tournament ? data.tournament : undefined;
        this.name = data && data.name ? data.name : "";
        this.ladder_order = data && data.ladder_order ? data.ladder_order : undefined;

        this.members = [];   /// Populated in the view
        
        this.club = data && data.club ? data.club : undefined;          // Nb: this will be the club ID
        this.club_name = data && data.club_name ? data.club_name : "";

        this.homeClub = this.club ?? this.club_name;
    }
}