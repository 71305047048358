export class EventClubTerms { 
    
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? data.club : undefined;
        this.event = data && data.event ? data.event : undefined;
        this.ttype = data && data.ttype ? data.ttype : undefined; 
        this.default = data && data.default !== undefined ? data.default : false;
        
        this.player_cancel_days = data && data.player_cancel_days !== undefined ? data.player_cancel_days : undefined; 
        this.cancel_by_player_action = data && data.cancel_by_player_action ? data.cancel_by_player_action : ""; 
        this.cancel_by_club_action = data && data.cancel_by_club_action ? data.cancel_by_club_action : "";         

        this.club_refund_text = data && data.club_refund_text ? data.club_refund_text : ""; 
        this.player_refund_text = data && data.player_refund_text ? data.player_refund_text : ""; 
        this.terms_statement = data && data.terms_statement ? data.terms_statement : ""; 
    }

     // Return an object that is ready for the server (add or patch)
     getEventClubTermsForServer() {

        const data = {
            club: this.club,
            event: this.event,
            default: this.default,
            ttype: this.ttype,
            cancel_by_player_action: this.cancel_by_player_action,
            cancel_by_club_action: this.cancel_by_club_action,            
            terms_statement: this.terms_statement
        };

        if (this.id) {
            data.id = this.id;
        }

        data["player_cancel_days"] = this.player_cancel_days == '' ? 0 : parseFloat(this.player_cancel_days);

        //console.log(data);
        return data;
    }
}