
export class Sport {

    constructor(data) {

        this.id = data.id;
        this.desc = data.desc;
        this.name = data.name;
        this.is_court_sport = data.is_court_sport;
    }
}