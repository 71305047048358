import { Event } from '.';

export class EventClone { 
    
    constructor(data) {       

        this.event = data && data.event ? new Event(data.event) : undefined;

        this.errors = [];
        if(data && data.errors) {
			this.errors = data.errors.map((item) => item);
		}

        this.additional_comments = [];
        if(data && data.additional_comments) {
			this.additional_comments = data.additional_comments.map((item) => item);
		}
    }
}