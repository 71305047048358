
export class Format {

    constructor(data) {

        this.id = data.id;
        this.sport = data.sport;
        this.name = data.name;
    }

}