import $ from 'jquery';
import Vue from 'vue';

export const bootstrapPopover = {
    bind(el, binding /*, vnode */){
      
        let options = binding.value;
        
        const defaultOptions = { 
            placement: "bottom", 
            // container: "body",   // "body" shouldn't be the default - so if any popovers break, check if they NEED to be set to body for some reason
            trigger: "hover",
            html: "true"
        };

        options = $.extend(true, {}, defaultOptions, options);

        // If there is already a popover, just update it's contents (or destroy it if it's to be disabled)
        if($(el).data('bs.popover')) {

            if(options.disabled) {
                $(el).popover("destroy");
            }
            else {
                const popover = $(el).attr('data-content',options.content).data('bs.popover');
                popover.setContent();
                popover.$tip.addClass(popover.options.placement);
            }
        }
        else {
            if(!options.disabled)
            {
                $(el).popover(options);
            }
        }


    }
}

Vue.directive('bootstrap-popover', bootstrapPopover);