import { apiClient } from "@/utils/apiclient";
import { IGetHitRequestsActionPayload } from './hit-requests.types';
import { MatchaHitRequest } from "@/models/matcha";

class State {
    hitRequests: MatchaHitRequest[] = [];
    nextHitRequestsURL = "";
    prevHitRequestsURL = "";
    hitRequestsTotal = 0;
    hitRequestsCurrentPage = 1;
}

export default {
    strict: process.env.NODE_ENV !== "production",
    namespaced: true,
    state: new State(),
    mutations: {

        getHitRequests(state: State, hitRequests) {
            state.hitRequests = hitRequests;
        },

        getNextPageURL(state: State, next) {
            state.nextHitRequestsURL = next;
        },

        getPrevPageURL(state: State, prev) {
            state.prevHitRequestsURL = prev;
        },

        nextPage(state: State) {
            state.hitRequestsCurrentPage++;
        },

        prevPage(state: State) {
            state.hitRequestsCurrentPage--;
        },

        resetCurrentPage(state: State) {
            state.hitRequestsCurrentPage = 1;
        },

        getTotal(state: State, total) {
            state.hitRequestsTotal = total;
        }
    },
    getters: {
        hitRequests: (state: State) => state.hitRequests,
        hitRequestsTotal: (state: State) => state.hitRequestsTotal,
        hasHitRequestsNextPage: (state: State) => (state.nextHitRequestsURL && state.nextHitRequestsURL.length > 0) ? true : false,
        hasHitRequestsPrevPage: (state: State) => (state.prevHitRequestsURL && state.prevHitRequestsURL.length > 0) ? true : false,
        hitRequestsCurrentPage: (state: State) => state.hitRequestsCurrentPage
    },
    actions: {
        getHitRequestsAction({ commit }, payload: IGetHitRequestsActionPayload): Promise<any> {
            return apiClient.clubMatchaHitRequests(payload.clubId, payload.fromDate, payload.toDate, payload.status, 100)
            .then(result => {
                
                const results = result.results.map(
                    (item: any) => new MatchaHitRequest(item)
                );
                commit("getHitRequests", results);
                commit("getNextPageURL", result.next);
                commit("getPrevPageURL", result.previous);
                commit("getTotal", result.count);

                return results;
            });
        },

        getNextPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.nextHitRequestsURL)
            .then(result => {
                const results = result.results.map(
                    (item: any) => new MatchaHitRequest(item)
                );
                commit("getHitRequests", results);
                commit("getNextPageURL", result.next);
                commit("getPrevPageURL", result.previous);
                commit("getTotal", result.count);
                commit("nextPage");

                return results;
            })
        },

        getPrevPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.prevHitRequestsURL)
            .then(result => {
                const results = result.results.map(
                    (item: any) => new MatchaHitRequest(item)
                );
                commit("getHitRequests", results);
                commit("getNextPageURL", result.next);
                commit("getPrevPageURL", result.previous);
                commit("getTotal", result.count);
                commit("prevPage");

                return results;
            })
        }
    },
    modules: {}
};
