import { apiClient } from '@/utils/apiclient';
import { CommentsNew } from '@/models/message/comments-new';
import { IGetMessageGroupsPayload, IMessageGroupPayload, IGetNewThreadCommentsPayload, IMarkPlayerThreadAsReadPayload } from './messages.types';
import { MessageGroup } from '@/models/message/message-group';
import Vue from 'vue';

class State {
    messageGroupsTotal = 0;
    messageGroups: MessageGroup[] = [];    
    newThreadComments: CommentsNew = {};
}

// sort this list of message groups by last active date
function sortMessageGroupsByLastActive(state: State) {    
    state.messageGroups.sort(function(a, b) {
                            const na = a.last_activity_date; 
                            const nb = b.last_activity_date; 
                            if (na < nb) {
                                return 1;
                            }
                            if (na > nb) {
                                return -1;
                            }
                            return 0;
                        });
}

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: new State(),
    mutations: {

        getMessageGroups(state: State, messageGroups) {
            state.messageGroups = messageGroups;  
            sortMessageGroupsByLastActive(state);                
        },   

        getTotal(state: State, total) {
            state.messageGroupsTotal = total;
        },

        addMessageGroup(state: State, payload: IMessageGroupPayload) {
            state.messageGroups.push(payload.messageGroup)
            sortMessageGroupsByLastActive(state);  
            state.messageGroupsTotal++;
        },

        updateMessageGroup(state: State, payload: IMessageGroupPayload) {
            const index = state.messageGroups.findIndex(c => c.id === payload.messageGroup.id);
            state.messageGroups.splice(index, 1, payload.messageGroup);
            sortMessageGroupsByLastActive(state);  
        },

        deleteMessageGroup(state: State, payload: IMessageGroupPayload) {
            state.messageGroups = [...state.messageGroups.filter(c => c.id !== payload.messageGroup.id)];
            sortMessageGroupsByLastActive(state);  
            state.messageGroupsTotal--;
        },

        getNewThreadComments(state: State, newThreadComments) {
            Vue.set(state.newThreadComments, 'new', newThreadComments);        
        },

        markPlayerThreadAsRead(state: State, payload: IMarkPlayerThreadAsReadPayload) {
            // Remove the player thread from the new thread comments object
            state.newThreadComments.new.playerthreads = [...state.newThreadComments.new.playerthreads.filter(c => c.id !== payload.playerThreadId)];
        }
    },
    getters: {
        messageGroups: (state: State) => state.messageGroups,
        messageGroupsTotal: (state: State) => state.messageGroupsTotal,
        newThreadComments: (state: State) => state.newThreadComments.new
    },
    actions: {       

        getMessageGroupsAction({ commit }, payload: IGetMessageGroupsPayload): Promise<MessageGroup[]> {        
            return apiClient.messageGroups(payload.clubId)
                .then((result) => {
                    const results = result.results.map(
                        (item: any) => new MessageGroup(item)
                    );

                    commit("getMessageGroups", results);
                    commit("getTotal", result.count);

                    return results;
                });
        },

        updateMessageGroupAction({ commit }, payload: IMessageGroupPayload): Promise<void> {
            return apiClient.updateMessageGroup(payload.messageGroup)
                .then(mg => {
                    commit("updateMessageGroup", { "messageGroup": mg });
                    return;
                });            
        },

        addMessageGroupAction({ commit }, payload: IMessageGroupPayload): Promise<void> {
            return apiClient.addMessageGroup(payload.messageGroup)
                .then(mg => {
                    commit("addMessageGroup", { "messageGroup": mg });
                    return;
                });            
        },

        deleteMessageGroupAction({ commit }, payload: IMessageGroupPayload): Promise<void> {
            return apiClient.deleteMessageGroup(payload.messageGroup)
                .then(() => {
                    commit("deleteMessageGroup", { "messageGroup": payload.messageGroup });
                    return;
                });            
        },

        // Get new thread comments for the player
        getNewThreadCommentsAction({ commit }, payload: IGetNewThreadCommentsPayload): Promise<CommentsNew> {        
            return apiClient.newThreadComments(payload.playerId)
                .then((result) => {
                    commit("getNewThreadComments", result);                    
                    return result;
                });
        },

        // Mark the passed in player thread as being read (used to make new comments, no longer new)
        markPlayerThreadAsReadAction({commit}, payload: IMarkPlayerThreadAsReadPayload): Promise<void> {
            return apiClient.markPlayerThreadAsRead(payload.playerThreadId)
                .then(() => {
                    commit("markPlayerThreadAsRead", { "playerThreadId": payload.playerThreadId });
                    return;
                });            
        }
    }
}