import { apiClient } from "@/utils/apiclient";
import { CoachInsights, CourtBookingsInsights, BookingInstance } from "@/models";

class State {
    coachInsights: CoachInsights[] = [];
    courtBookingsInsights: CourtBookingsInsights = {};
    courtBookingDetails: BookingInstance[] = [];
    nextReportPageURL = "";
    prevReportPageURL = "";
    total = 0;
    currentPage = 1;
}

export default {
    strict: process.env.NODE_ENV !== "production",
    namespaced: true,
    state: new State(),
    mutations: {

        getCoachInsights(state, coachInsights) {
            state.coachInsights = coachInsights;
        },

        getCourtBookingsInsights(state, courtBookingsInsights) {
            state.courtBookingsInsights = courtBookingsInsights;
        },

        getCourtBookingDetails(state, courtBookingDetails) {
            state.courtBookingDetails = courtBookingDetails;
        },

        getNextPageURL(state: State, next) {
            state.nextReportPageURL = next;
        },

        getPrevPageURL(state: State, prev) {
            state.prevReportPageURL = prev;
        },

        getTotal(state: State, total) {
            state.total = total;
        },
        nextPage(state: State) {
            state.currentPage++;
        },

        prevPage(state: State) {
            state.currentPage--;
        },

        resetCurrentPage(state: State) {
            state.currentPage = 1;
        }
    },
    getters: {
        coachInsights: state => state.coachInsights,
        courtBookingsInsights: state => state.courtBookingsInsights,
        courtBookingDetails: state => state.courtBookingDetails,
        total: (state: State) => state.total,
        hasNextPage: (state: State) => (state.nextReportPageURL && state.nextReportPageURL.length > 0) ? true : false,
        hasPrevPage: (state: State) => (state.prevReportPageURL && state.prevReportPageURL.length > 0) ? true : false,
        currentPage: (state: State) => state.currentPage
    },
    actions: {

        getCoachInsights({ commit }, { clubId, fromDate, toDate }): Promise<any> {
            return apiClient.clubCoachesReport(clubId, fromDate, toDate)
                .then(results => {
                    
                    commit("getCoachInsights", results);

                    return results;
                });
        },

        getCourtBookingsInsights({ commit }, { clubId, fromDate, toDate, courts, type }): Promise<any> {
            return apiClient.clubCourtBookingsReport(clubId, fromDate, toDate, courts, type)
                .then(results => {
                    commit("getCourtBookingsInsights", results);
                    return results;
                });
        },

        getCourtBookingDetails({ commit }, { clubId, fromDate, toDate, bookingType, courts, courtGroups, pageSize, coachId }): Promise<any> {
            return apiClient.courtBookings(clubId, fromDate, toDate, bookingType, courts, courtGroups, pageSize, coachId)
                .then((result) => {
                    const results = result.results.map(
                        (item: any) => new BookingInstance(item)
                    );

                    commit("getCourtBookingDetails", results);
                    commit("getNextPageURL", result.next);
                    commit("getPrevPageURL", result.previous);
                    commit("getTotal", result.count);
                    commit("resetCurrentPage");

                    return results;
                });
        },

        getNextPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.nextReportPageURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new BookingInstance(item)
                    );
                    commit("getCourtBookingDetails", results);
                    commit("getNextPageURL", result.next);
                    commit("getPrevPageURL", result.previous);
                    commit("getTotal", result.count);
                    commit("nextPage");

                    return results;
                })
        },

        getPrevPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.prevReportPageURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new BookingInstance(item)
                    );
                    commit("getCourtBookingDetails", results);
                    commit("getNextPageURL", result.next);
                    commit("getPrevPageURL", result.previous);
                    commit("getTotal", result.count);
                    commit("prevPage");

                    return results;
                })
        }
    },
    modules: {}
};
