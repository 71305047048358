<script lang="ts">
    import { bootstrapPopover } from '@/directives/bootstrap-popover';
    import { ValidationProvider } from "vee-validate";
    import Vue from 'vue';

    export default Vue.extend({
        name: "TextInput",
        components: {
            ValidationProvider
        },
        directives: {
            bootstrapPopover
        },
        props: {
            value: {
                type: String,
                default: ""
            },
            rules: {
                type: [String, Object],
                default: ""
            },
            placeholder: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            helpText: {
                type: String,
                default: ""
            },
            tooltipText: {
                type: String,
                default: ""
            },
            popoverText: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            },
            formGroupClasses: {
                type: Array,
                default: () => [],
            },
            inputClasses: {
                type: Array,
                default: () => [],
            },
            name: {
                type: String,
                default: ""
            },
            vid: {
                type: String,
                default: undefined
            },
            showRequiredLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            showCharacterCount: {
                type: Boolean,
                required: false,
                default: false
            },
            characterCountText: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: "text",
                validator(value:string) {
                    return [
                        "url",
                        "text",
                        "password",
                        "tel",
                        "search",
                        "number",
                        "email"
                    ].includes(value);
                }
            },
            min: {
                type: String,
                required: false,
                default: ""
            },
            max: {
                type: String,
                required: false,
                default: ""
            },
            step: {
                type: String,
                required: false,
                default: ""
            }

        },
        data () {
            return {
                currentValue: ""
            }
        },
        watch: {
            value: {
                handler(val) {
                    this.currentValue = val;
                },
                //this makes it run the handler function on mount in addition to whenever the value changes
                immediate:true
            },
            currentValue(val) {
                // allows us to use v-model on our input.
                this.$emit("input", val);
            }
        },
        methods: {
            change() {
                this.$emit("change", this.currentValue);
            }
        }
    });
</script>

<template>
    <ValidationProvider
        slim
        :rules="rules"
        :name="name || label"
        :vid="vid"
        v-slot="{ errors, required, showRequiredLabel, ariaInput, classes }">
        <div class="form-group" :class="[formGroupClasses, classes]">
            <label class="control-label" :for="name" v-if="label">
                <span>{{ label || name }}</span>
                <span href="javascript:;" v-if="tooltipText" class="helper-icon" v-bootstrap-tooltip="{ placement: 'top', title: tooltipText}">?</span>
                <span class="helper">{{ required && showRequiredLabel ? ' (required)' : '' }}</span>
            </label>
            <label v-if="showCharacterCount" class="pull-right"><strong>{{"(" + characterCountText + ")"}}</strong></label>
            <span v-if="helpText" class="help-block">{{ helpText }}</span>
            <input
                class="form-control"
                :class="inputClasses"
                :type="type"
                :placeholder="placeholder"
                :min="min"
                :max="max"
                :step="step"
                :disabled="disabled"
                @change="change"
                v-model="currentValue"
                v-bind="ariaInput"
                v-bootstrap-popover="{ placement: 'right', trigger: 'hover focus', content: popoverText}" />
            <span class="help-block" v-if="errors[0]">
                {{ errors[0] }}
            </span>
        </div>
    </ValidationProvider>
</template>
