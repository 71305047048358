import { ClubCart, ClubCartProduct } from '@/models';
import { IGetClubCartPayload, IAddMemberToCartPayload, IAddProductToCartPayload, IRemoveProductSetFromCartPayload, IStepProductQtyPayload, IAddDiscountToCartPayload } from './club-cart.types';
import ClubStoreCartService from '@/utils/services/club-store-cart-service';

function saveCartToLocalStorage(key: string, cart: ClubCart) {    
    const clubCartStr = JSON.stringify(cart);
    ClubStoreCartService.saveValue(key, clubCartStr);
}

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        clubId: "",
        clubCart: null as ClubCart,
        key: ""
    },
    mutations: {

        getClubCart(state,  payload: IGetClubCartPayload) {
            state.clubCart = payload.clubCart;
            state.clubId = payload.clubId;
            state.key = payload.key;
        },

        addProductToCart(state, payload: IAddProductToCartPayload) {

            // look if the product already exists
            for (const p of state.clubCart.products) {
                if (p.product_id == payload.productId) {
                    p.quantity = p.quantity + payload.quantity;  
                    return;
                }
            }

            // if we get this far the product was not found
            // let's add it to the cart
            const prod = new ClubCartProduct({
                product_id: payload.productId,
                quantity: payload.quantity,
                clubProduct: payload.clubProduct
            })
            state.clubCart.products.push(prod);
        },

        removeProductSetFromCart(state, payload: IRemoveProductSetFromCartPayload) {

            // remove the passed in product ID from the cart entirely
            state.clubCart.products = state.clubCart.products.filter(p => p.product_id !== payload.productId);
        },

        removeProductSetFromCartAndClearIf(state, payload: IRemoveProductSetFromCartPayload) {

            // remove the passed in product ID from the cart entirely
            state.clubCart.products = state.clubCart.products.filter(p => p.product_id !== payload.productId);
            // clear the cart if there are no more products
            if (state.clubCart.products.length == 0) {
                state.clubCart.member = null;
                state.clubCart.products = [];
                state.discount = 0;
            }
        },

        stepProductQuantity(state, payload: IStepProductQtyPayload) {
            
            let step = 1;
            if (!payload.increment) step = -1;

            // look if the product already exists
            for (const p of state.clubCart.products) {
                if (p.product_id == payload.productId) {
                    p.quantity = p.quantity + step;  
                    if (p.quantity < 0) p.quantity = 0;
                    return;
                }
            }
        },

        addMemberToCart(state, memberId) {
            state.clubCart.member = memberId;
        },

        addDiscountToCart(state, discount) {
            state.clubCart.discount = discount;
        },

        clearClubCart(state) {
            state.clubCart.products = [];
            state.discount = 0;
        },
        
        deleteClubCart(state) {
            state.clubCart = null;
            state.clubId = "";
            state.key = "";   
        }
    },
    getters: {
        clubCart: state => (clubId) => {
            if(state.clubId != clubId)
                return null;
            return state.clubCart;
        },
        clubCartItemCount: state => (clubId) => {
            if(state.clubId != clubId)
                return 0;

            // return the total number of items in the cart
            if (state.clubCart == null || state.clubCart.products.length == 0) return 0;
            // traverse all the products and tally up the quantity
            let qty = 0;
            state.clubCart.products.forEach(p => { qty = qty + p.quantity });
            return qty;
        }
    },
    actions: {

        // initialise the club cart store
        // ensure there is a clubCart for the club
        initClubCartAction({ commit }, clubId) {
        
            // compile the key
            const key = "club_" + clubId + "_cart";

            const clubCartStr = ClubStoreCartService.getValue(key);
            if (clubCartStr != null) {

                // we found a club cart in local storage, let's use it
                const cartObj = JSON.parse(clubCartStr);
                commit('getClubCart', { "clubId": clubId, "clubCart": new ClubCart(cartObj), "key": key });                
            }
            else {

                // no club cart found in local storage, let's create one
                const newCart = new ClubCart({
                    club: clubId
                });
                commit('getClubCart', { "clubId": clubId, "clubCart": newCart, "key": key });
            }

            // at this point, state will always be populated with
            //      key, clubId, clubCart
            return new Promise(resolve => resolve(null));
        },

        // add the product to the cart, if the product already exists, add the quantity
        addProductToCartAction({ commit, state }, payload: IAddProductToCartPayload) {

            if (state.clubId != payload.clubId) return;

            commit('addProductToCart', payload);
            saveCartToLocalStorage(state.key, state.clubCart);
        }, 

        // add the member id to the club cart
        addMemberToCartAction({ commit, state }, payload: IAddMemberToCartPayload) {

            if (state.clubId != payload.clubId) return;

            commit('addMemberToCart', payload.memberId);
            saveCartToLocalStorage(state.key, state.clubCart);
        }, 

        // add the discount to the club cart
        addDiscountToCartAction({ commit, state }, payload: IAddDiscountToCartPayload) {

            if (state.clubId != payload.clubId) return;

            commit('addDiscountToCart', payload.discount);
            saveCartToLocalStorage(state.key, state.clubCart);
        }, 

        // remove the entire product from the cart
        removeProductSetFromCartAction({ commit, state }, payload: IRemoveProductSetFromCartPayload) {

            if (state.clubId != payload.clubId) return;

            commit('removeProductSetFromCart', payload);
            saveCartToLocalStorage(state.key, state.clubCart);
        }, 

        // remove the entire product from the cart and clear the cart if left empty
        removeProductSetFromCartAndClearIfAction({ commit, state }, payload: IRemoveProductSetFromCartPayload) {

            if (state.clubId != payload.clubId) return;

            commit('removeProductSetFromCartAndClearIf', payload);
            saveCartToLocalStorage(state.key, state.clubCart);
        }, 

         // step (increment or decrement) the product quantity in the cart
         stepProductQuantityAction({ commit, state }, payload: IStepProductQtyPayload) {

            if (state.clubId != payload.clubId) return;

            commit('stepProductQuantity', payload);
            saveCartToLocalStorage(state.key, state.clubCart);
        }, 

        // clear the club cart
        clearClubCartAction({ commit, state }, clubId) {

            // this will clear the club cart products selection
            if (state.key && state.clubId == clubId) {       
                commit('clearClubCart');
                saveCartToLocalStorage(state.key, state.clubCart);
            }
        },

        // remove the club cart from local storage
        deleteClubCartAction({ commit, state }, clubId) {

            // this will delete the club cart from local storage
            if (state.key && state.clubId == clubId) {                
                ClubStoreCartService.deleteValue(state.key);
                commit('deleteClubCart');
            }
        }
    }
}