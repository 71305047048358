import { ClubClient } from '..';

export class ClubClientSearchResult {
	constructor(data) {

		this.nonmembers = [];
		if(data && data.nonmembers) {
			this.nonmembers = data.nonmembers.map((item) => new ClubClient(item));
		} 

		this.members = [];
		if(data && data.members) {
			this.members = data.members.map((item) => new ClubClient(item));
		} 
	}
}
