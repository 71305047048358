import { Tournament } from '.';

export class Round {

    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
        this.tournament = data && data.tournament ? new Tournament(data.tournament) : null;
        this.number = data && data.number ? data.number : undefined;
        this.start_date = data && data.start_date ? data.start_date : undefined;
        this.end_date = data && data.end_date ? data.end_date : undefined;
        this.kind = data && data.kind ? data.kind : "";
    }
}