
import { ClubMembershipType } from "@/models/club/club-membership-type";
import { Currency } from "@/models/currency";
import { FailureDetail } from "@/models/failure-detail";
import { Player } from './player';
import { Refund } from "@/models/refund";
import TransactionPaymentMethodEnum from '@/utils/enum/transaction-payment-method-enum';
import TransactionStatusEnum from '@/utils/enum/transaction-status-enum';

export class Transaction {
	constructor(data) {
	
		this.id = data.id;
		this.kind = data && data.kind ? data.kind : "";
		this.desc = data && data.desc ? data.desc : "";
        this.status = data && data.status ? data.status : null;
        this.club = data && data.club ? data.club : null;
        this.created = data && data.created ? data.created : undefined;        
        this.amount = data && data.amount ? data.amount : 0;
        this.paid = data && data.paid ? data.paid : undefined;
        this.currency = new Currency(data.currency);
        this.failure_code  = data && data.failure_code ? data.failure_code : undefined;
        this.failure_reason = data && data.failure_reason ? data.failure_reason : undefined;
        this.failure_detail = data && data.failure_detail ? new FailureDetail(data.failure_detail) : null;
        this.refund_set = data && data.refund_set ? data.refund_set.map((item) => new Refund(item)) : [];
        this.player = data && data.player ? new Player(data.player) : null;
        this.club_customer = data && data.club_customer ? data.club_customer : undefined;
        this.payment_method = data && data.payment_method ? data.payment_method : "";
        this.payer_name = data && data.payer_name ? data.payer_name : "";
        this.item_name = data && data.item_name ? data.item_name : "";
        this.descriptor = data && data.descriptor ? data.descriptor : "";
        this.quantity = data && data.quantity ? data.quantity : "";
        this.membership_type = data && data.membership_type ? new ClubMembershipType(data.membership_type) : null;

        // not being returned by server, can remove
        this.receiver_account = data && data.receiver_account ? data.receiver_account : undefined;
        this.destination_amount = data && data.destination_amount ? data.destination_amount : 0;
    }

    get status_full() {
        return TransactionStatusEnum[this.status];
    }

    get payment_method_full() {
        return TransactionPaymentMethodEnum[this.payment_method];
    }
}
