import { ClubMember } from '.';

export class ClubMembershipGroup {

    constructor(data) {

        this.id = data && data.id ? data.id : undefined;
        this.name = data && data.name ? data.name : "";

        this.members = [];
        if(data && data.members) {
			this.members = data.members.map((item) => new ClubMember(item) );
        }
    }
}