import { apiClient } from '@/utils/apiclient';
import { BookingInstance, BookingSeries } from '@/models';
import { IGetBookingsMutationPayload, IUpdateBookingInstanceMutationPayload, IDeleteBookingInstanceMutationPayload, IAddBookingSeriesMutationPayload, IUpdateBookingSeriesMutationPayload, 
         IDeleteBookingSeriesMutationPayload, IGetBookingsAction, IUpdateBookingInstanceAction, IDeleteBookingInstanceAction, IAddBookingSeriesAction,  
         IDeleteBookingSeriesAction, IGetBookingsBySearchAction, IEditBookingSeriesAction } from './court-bookings.types';

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        clubId: "",
        bookings: [] as BookingInstance[],
        bookingsBySearch: [] as BookingInstance[],
        bookingsBySearchSettings: {} as IGetBookingsBySearchAction,
    },
    mutations: {    

        setBookings(state, payload: IGetBookingsMutationPayload) {
            state.clubId = payload.clubId;
            state.bookings = payload.bookings;            
        },

        setBookingsBySearch(state, payload: IGetBookingsMutationPayload) {
            state.clubId = payload.clubId;
            state.bookingsBySearch = payload.bookings;            
        },

        clearBookingsBySearch(state) {
            state.bookingsBySearch = [];
            state.bookingsBySearchSettings = {} as IGetBookingsBySearchAction;
        },

        updateBookingInstance(state, payload: IUpdateBookingInstanceMutationPayload) {
            // update the bookings
            const index = state.bookings.findIndex(c => c.id === payload.bookingInstance.id);
            if (index == -1) return;
            state.bookings.splice(index, 1, payload.bookingInstance);
            // update if found in the bookings search results
            const indexS = state.bookingsBySearch.findIndex(c => c.id === payload.bookingInstance.id);
            if (indexS == -1) return;
            state.bookingsBySearch.splice(indexS, 1, payload.bookingInstance);
        },

        deleteBookingInstance(state, payload: IDeleteBookingInstanceMutationPayload) {
            state.bookings = [...state.bookings.filter(c => c.id !== payload.bookingInstanceId)];
            state.bookingsBySearch = [...state.bookingsBySearch.filter(c => c.id !== payload.bookingInstanceId)];
        },

        addBookingSeries(state, payload: IAddBookingSeriesMutationPayload) {            
            payload.bookingSeriesData.booking_instance_set.map((item) => {
                const newBookingInstance = new BookingInstance(item);
                newBookingInstance.booking_series = new BookingSeries(payload.bookingSeriesData);
                state.bookings.push(newBookingInstance);
            })
        },

        updateBookingSeries(state, payload: IUpdateBookingSeriesMutationPayload) {
            // to refresh this data, we will delete all previous instances for the given series and add all the new ones
            state.bookings = [...state.bookings.filter(c => c.booking_series.id !== payload.bookingSeriesData.id)];
            payload.bookingSeriesData.booking_instance_set.map((item) => {
                const newBookingInstance = new BookingInstance(item);
                newBookingInstance.booking_series = new BookingSeries(payload.bookingSeriesData);
                state.bookings.push(newBookingInstance);
            })
            // refresh the search bookings if applicable, only replace if the booking exists
            // do not add new bookings as we do not know the search criteria
            const index = state.bookingsBySearch.findIndex(c => c.booking_series.id === payload.bookingSeriesData.id);
            if (index == -1) return;
            // found the booking, do a complete replace
            state.bookingsBySearch = [...state.bookingsBySearch.filter(c => c.booking_series.id !== payload.bookingSeriesData.id)];
            payload.bookingSeriesData.booking_instance_set.map((item) => {
                const newBookingInstance = new BookingInstance(item);
                newBookingInstance.booking_series = new BookingSeries(payload.bookingSeriesData);
                state.bookingsBySearch.push(newBookingInstance);
            })
        },

        deleteBookingSeries(state, payload: IDeleteBookingSeriesMutationPayload) {
            state.bookings = [...state.bookings.filter(c => c.booking_series.id !== payload.bookingSeriesId)];
            state.bookingsBySearch = [...state.bookingsBySearch.filter(c => c.booking_series.id !== payload.bookingSeriesId)];
        },

        incrementBookingsBySearchPageNumber(state) {
            state.bookingsBySearchSettings.pageNumber++;
        },

        decrementBookingsBySearchPageNumber(state) {
            state.bookingsBySearchSettings.pageNumber--;
        },

        setBookingsBySearchSettings(state, payload: IGetBookingsBySearchAction) {
            state.bookingsBySearchSettings = payload;
        }
    },
    getters: {
        bookings: state => (clubId) => {
            if(state.clubId != clubId)
                return [];
            return state.bookings;
        },
        bookingsBySearch: state => (clubId) => {
            if(state.clubId != clubId)
                return [];
            return state.bookingsBySearch;
        },
        bookingsBySearchTotal: state => (clubId) => {  
            if(state.clubId != clubId)
                return 0;
            return state.bookingsBySearchSettings.totalBookings; 
        },
        bookingsBySearchTotalSeconds: state => (clubId) => { 
            if(state.clubId != clubId)
                return 0;
            return state.bookingsBySearchSettings.totalSeconds; 
        },
        bookingsBySearchSearchText: state => (clubId) => { 
            if(state.clubId != clubId)
                return "";
            return state.bookingsBySearchSettings.searchString; 
        },
        bookingsBySearchCurrentPage: state => (clubId) => { 
            if(state.clubId != clubId)
                return 1;
            return state.bookingsBySearchSettings.pageNumber; 
        },
        bookingsBySearchHasNextPage: state => (clubId) => {
            if(state.clubId != clubId)
                return false;
            if (state.bookingsBySearchSettings.totalBookings == 0) return false;
            return (state.bookingsBySearchSettings.pageSize * state.bookingsBySearchSettings.pageNumber) < state.bookingsBySearchSettings.totalBookings;
        },
        bookingsBySearchHasPrevPage: state => (clubId) => {
            if(state.clubId != clubId)
                return false;
            return state.bookingsBySearchSettings.pageNumber > 1;
        }
    },
    actions: {

        getBookingsAction({ commit }, payload: IGetBookingsAction): Promise<BookingInstance[]> {        
            return apiClient.courtBookings(payload.clubId, payload.startDate, payload.endDate)
                .then((bookings) => {
                    const data = bookings.results.map((item: any) => new BookingInstance(item))
                    commit("setBookings", { "clubId": payload.clubId, "bookings": data });
                    return data;
                });
        },

        clearBookingsBySearchAction({ commit }) {
            commit("clearBookingsBySearch");
        },

        getBookingsBySearchAction({ commit }, payload: IGetBookingsBySearchAction): Promise<BookingInstance[]> {        
            return apiClient.courtBookingsSearchBookings(payload.clubId, payload.startDate, payload.endDate, payload.searchString, payload.memberId, payload.playerId, payload.pageSize, payload.pageNumber)
                .then((result) => {
                    const results = result.instances.map(
                        (item: any) => new BookingInstance(item)
                    );                    
                    commit("setBookingsBySearch", { "clubId": payload.clubId, "bookings": results });
                    payload.totalBookings = result.count;
                    payload.totalSeconds = result.total_seconds;
                    commit("setBookingsBySearchSettings", payload);
                    return results;
                });
        },

        getBookingsBySearchNextPageAction({ commit, state }): Promise<BookingInstance[]> {  
            const payload = state.bookingsBySearchSettings;
            commit("incrementBookingsBySearchPageNumber");    
            return apiClient.courtBookingsSearchBookings(payload.clubId, payload.startDate, payload.endDate, payload.searchString, payload.memberId, payload.playerId, payload.pageSize, payload.pageNumber)
                .then((result) => {
                    const results = result.instances.map(
                        (item: any) => new BookingInstance(item)
                    );                    
                    commit("setBookingsBySearch", { "clubId": payload.clubId, "bookings": results });
                    commit("setBookingsBySearchSettings", payload);
                    return results;
                });
        },

        getBookingsBySearchPrevPageAction({ commit, state }): Promise<BookingInstance[]> {  
            const payload = state.bookingsBySearchSettings;
            if (payload.pageNumber > 1) commit("decrementBookingsBySearchPageNumber");     
            return apiClient.courtBookingsSearchBookings(payload.clubId, payload.startDate, payload.endDate, payload.searchString, payload.memberId, payload.playerId, payload.pageSize, payload.pageNumber)
                .then((result) => {
                    const results = result.instances.map(
                        (item: any) => new BookingInstance(item)
                    );                    
                    commit("setBookingsBySearch", { "clubId": payload.clubId, "bookings": results });
                    commit("setBookingsBySearchSettings", payload);
                    return results;
                });
        },

        updateBookingInstanceAction({ commit }, payload: IUpdateBookingInstanceAction): Promise<BookingInstance> {
            if(!payload.bookingInstance.validate())
                return Promise.reject(new Error("Invalid booking details"));

            return apiClient.updateCourtBookingInstance(payload.bookingInstance)
                .then((bookingInstance) => {
                    commit("updateBookingInstance", { "bookingInstance": bookingInstance });
                    return bookingInstance;
                });
        },

        deleteBookingInstanceAction({ commit }, payload: IDeleteBookingInstanceAction): Promise<void> {
            return apiClient.deleteCourtBookingInstance(payload.bookingInstanceId)
                .then(() => {
                    commit("deleteBookingInstance", { "bookingInstanceId": payload.bookingInstanceId });
                    return;
            });
        },

        addBookingSeriesAction({ commit }, payload: IAddBookingSeriesAction): Promise<void> {

            if(!payload.bookingSeries.validate())
                return Promise.reject(new Error("Invalid booking details"));

            return apiClient.addCourtBookingSeries(payload.bookingSeries)
                .then((bookingSeriesData) => {
                    commit("addBookingSeries", { "bookingSeriesData": bookingSeriesData });
                });
        },  

        editBookingSeriesAction({ commit }, payload: IEditBookingSeriesAction): Promise<BookingSeries> {

            if(!payload.bookingSeries.validate())
                return Promise.reject(new Error("Invalid booking details"));

            // set properties for the edit

            /*
                The field payment_difference_handling is added to the court booking series object. This field takes one of the following values:
                “I”: Indicate Ignore price difference. If this is chosen by the admin, the backend will not charge extra or refund the difference. 
                “A”: Auto-charge. It says to the BE that it should automatically handle price differences. 
            */
            if (payload.ignorePriceDifference) {
                payload.bookingSeries.payment_difference_handling = 'I';
                payload.bookingSeries.paying = false;
            }
            else {
                payload.bookingSeries.payment_difference_handling = 'A';
                payload.bookingSeries.paying = true;
            }

            return apiClient.editCourtBookingSeries(payload.bookingSeries)
                .then((bookingSeriesData) => {
                    commit("updateBookingSeries", { "bookingSeriesData": bookingSeriesData });
                    return new BookingSeries(bookingSeriesData);
                });
        },  

        editBookingSeriesLocalAction({ commit }, payload: IEditBookingSeriesAction): Promise<void> {

            if(!payload.bookingSeries.validate())
                return Promise.reject(new Error("Invalid booking details"));

            commit("updateBookingSeries", { "bookingSeriesData": payload.bookingSeries });
            return payload.bookingSeries;
        },   

        deleteBookingSeriesAction({ commit }, payload: IDeleteBookingSeriesAction): Promise<void> {

            return apiClient.deleteCourtBookingSeries(payload.bookingSeriesId)
                .then(() => {
                    commit("deleteBookingSeries", { "bookingSeriesId": payload.bookingSeriesId });
                    return;
            });
        }   
    }
}