import { apiClient } from '@/utils/apiclient';
import { Club, ClubMembershipType, HoursOfOperation } from "@/models";
import { IClubFeaturesState, IUpdateClubMembershipTypeMutationPayload, IDeleteClubMembershipTypeMutationPayload, IGetClubFeaturesMutationPayload, IUpdateClubFeatureMutationPayload, IGetClubCourtGroupsMutationPayload, IAddCourtGroupMutationPayload, IUpdateCourtGroupMutationPayload, IDeleteCourtGroupMutationPayload, 
    IUpdateClubOpeningHoursActionPayload, IUpdateClubMembershipTypeActionPayload, IDeleteClubMembershipTypeActionPayload, IUpdateClubFeatureActionPayload, IRefreshClubCourtGroupsActionPayload, IAddCourtGroupActionPayload, IUpdateCourtGroupActionPayload, IDeleteClubGroupActionPayload, IUpdateClubFacilitiesActionPayload, IUpdateClubBillingCycle } from './clubs.types';

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        clubs: [] as Club[],
        clubFeatures: [] as IClubFeaturesState[]
    },
    mutations: {

        getClub(state, club) {
            const index = state.clubs.findIndex(c => c.id == club.id);
            const selectedClub = state.clubs[index];

            if(selectedClub)
                state.clubs.splice(index, 1, club);
            else
                state.clubs.push(club);
        },

        updateClubHours(state, { club, openingHours }) {
            const index = state.clubs.findIndex(c => c.id == club.id);
            const selectedClub = state.clubs[index];

            const selectedDayData = selectedClub.hoursOfOperation.find((item) => { return item.id == openingHours.id; });

			if(selectedDayData){
				selectedDayData.open = openingHours.open;
				selectedDayData.close = openingHours.close;
			}
			else {
				const newObject = new HoursOfOperation();
				newObject.id = openingHours.id;
				newObject.open = openingHours.open;
				newObject.close = openingHours.close;
				selectedClub.hoursOfOperation.push(newObject);
			}
        },

        updateClubMembershipType(state, payload: IUpdateClubMembershipTypeMutationPayload) {
            const selectedClubInState = state.clubs.find((item) => { return item.id == payload.clubId });

            if(selectedClubInState) {        
                const membershipTypeIndex = selectedClubInState.membership_type_set.findIndex(i => i.id == payload.membershipType.id);

                if(membershipTypeIndex >= 0)
                    selectedClubInState.membership_type_set.splice(membershipTypeIndex, 1, payload.membershipType);
                else 
                    selectedClubInState.membership_type_set.push(payload.membershipType);
            }
        },

        deleteClubMembershipType(state, payload: IDeleteClubMembershipTypeMutationPayload) {
            const selectedClubInState = state.clubs.find((item) => { return item.id == payload.clubId });
    
            selectedClubInState.membership_type_set = [...selectedClubInState.membership_type_set.filter(c => c.id !== payload.membershipType.id)];
        },

        refreshClubHours(state, club) {
            const index = state.clubs.findIndex(c => c.id == club.id);
            const selectedClub = state.clubs[index];

            selectedClub.daysofOperation.map((item) => item.refreshHours(selectedClub.hoursOfOperation));
        },

        updateClubFacilities(state, { club, facilities }) {
            const index = state.clubs.findIndex(c => c.id == club.id);
            const selectedClub = state.clubs[index];

            selectedClub.facilities = facilities;
        },

        getClubFeatures(state, payload: IGetClubFeaturesMutationPayload) {
            const index = state.clubFeatures.findIndex(c => c.id == payload.clubId);
            const selectedClub = state.clubFeatures[index];

            if(selectedClub) {
                selectedClub.clubFeatures = payload.clubFeatures;
            }
            else {
                const newObject: IClubFeaturesState = { 
                    clubId: payload.clubId,
                    clubFeatures: payload.clubFeatures
                };
                state.clubFeatures.push(newObject);
            }
        },

        updateClubFeature(state, payload: IUpdateClubFeatureMutationPayload) {
            const index = state.clubFeatures.findIndex(c => c.clubId == payload.clubId);
            const selectedClubInFeaturesState = state.clubFeatures[index];

            if (selectedClubInFeaturesState) {        
                const clubFeatureIndex = selectedClubInFeaturesState.clubFeatures.findIndex(i => i.id == payload.clubFeature.id);
                selectedClubInFeaturesState.clubFeatures.splice(clubFeatureIndex, 1, payload.clubFeature);
            }
        },

        getCourtGroups(state, payload: IGetClubCourtGroupsMutationPayload) {
            const clubIndex = state.clubs.findIndex(c => c.id == payload.clubId);
            const selectedClub = state.clubs[clubIndex];

            selectedClub.courtgroup_set = payload.courtGroups;
        },

        addCourtGroup(state, payload: IAddCourtGroupMutationPayload) {
        
            const clubIndex = state.clubs.findIndex(c => c.id == payload.clubId);
            const selectedClub = state.clubs[clubIndex];
            
            selectedClub.courtgroup_set.push(payload.courtGroup)
        },

        updateCourtGroup(state, payload: IUpdateCourtGroupMutationPayload) {

            const clubIndex = state.clubs.findIndex(c => c.id == payload.clubId);
            const selectedClub = state.clubs[clubIndex];

            const courtGroupIndex = selectedClub.courtgroup_set.findIndex(i => i.id == payload.courtGroup.id);
            selectedClub.courtgroup_set.splice(courtGroupIndex, 1, payload.courtGroup);
        },

        deleteCourtGroup(state, payload: IDeleteCourtGroupMutationPayload) {
            const clubIndex = state.clubs.findIndex(c => c.id == payload.clubId);
            const selectedClub = state.clubs[clubIndex];

            selectedClub.courtgroup_set = [...selectedClub.courtgroup_set.filter(c => c.id !== payload.courtGroup.id)];
        },

        updateClubPackage(state, { clubId, packageForClub }) {
            const index = state.clubs.findIndex(c => c.id == clubId);
            const selectedClub = state.clubs[index];

            selectedClub.package = packageForClub;
        },

        updateClubSubscription(state, { clubId, subscription }) {
            const index = state.clubs.findIndex(c => c.id == clubId);
            const selectedClub = state.clubs[index];

            selectedClub.subscription = subscription;
        }        
    },
    getters: {
        club: state => (clubId) => {
            return state.clubs.find((item) => { return item.id == clubId });
        },
        clubFeature: state => (clubId, featureName) => {
            const clubFeaturesinState = state.clubFeatures.find((item) => { return item.clubId == clubId });

            if(clubFeaturesinState) {
                const result = clubFeaturesinState.clubFeatures.find((item) => { return item.feature.name == featureName });
                return result;
            }

            return undefined;
        },
        clubCourtGroupOpeningHoursForDayType: state => (clubId, courtGroupId, dayType) => {
            const selectedClub = state.clubs.find((item) => { return item.id == clubId });

            if(!selectedClub)
                return [];

            const selectedCourtGroup = selectedClub.courtgroup_set.find((item) => { return item.id == courtGroupId });

            if(!selectedCourtGroup)
                return [];

            if(selectedCourtGroup.inherit_club_hours) {
                // Get hours from the club
                const openingHoursForDayType = selectedClub.hoursOfOperation.filter((item) => {
                    const dayOfOperationItem = selectedClub.daysofOperation.find((dayItem) => {
                        return dayItem.id == item.id;
                    });
                    
                    return dayOfOperationItem.dayType == dayType;
                });
                
                return openingHoursForDayType;
            }
            else {
                // Get hours from the court group
                const openingHoursForDayType = selectedCourtGroup.hoursOfOperation.filter((item) => {
                    const dayOfOperationItem = selectedCourtGroup.daysofOperation.find((dayItem) => {
                        return dayItem.id == item.id;
                    });
                    
                    return dayOfOperationItem.dayType == dayType;
                });
                
                return openingHoursForDayType;
            }
        }
    },
    actions: {
        // actions let us get to ({ state, getters, commit, dispatch }) {
        getClub({ commit }, clubId): Promise<any> {
            return apiClient.clubById(clubId)
                .then((club) => {
                    commit("getClub", club);
                    return club;
                });
        },

        updateClubOpeningHours({ commit }, payload: IUpdateClubOpeningHoursActionPayload): Promise<any> {

            if (!payload.openingHours.validate())
                return Promise.reject(new Error("Invalid opening hours"));

            return apiClient.updateClubOpeningHours(payload.club, payload.openingHours)
                .then(() => {
                    commit("updateClubHours", { 'club': payload.club, 'openingHours': payload.openingHours });
                    commit("refreshClubHours", payload.club);
                });
        },

        updateClubFacilities({ commit }, payload: IUpdateClubFacilitiesActionPayload): Promise<any> {
            return apiClient.updateClubFacilities(payload.club, payload.facilities)
                .then(() => {
                    commit("updateClubFacilities", { 'club': payload.club, 'facilities': payload.facilities });
                });
        },

        updateClubMembershipTypeAction({ commit }, payload: IUpdateClubMembershipTypeActionPayload): Promise<ClubMembershipType> {

            if(!payload.membershipType.validate())
                return Promise.reject(new Error("Invalid membership type details"));

            return apiClient.updateClubMembershipType(payload.membershipType)
                .then((updatedMembershipType) => {
                    commit("updateClubMembershipType", { 'clubId': payload.club.id, 'membershipType': updatedMembershipType });
                    return updatedMembershipType;
            });
        },

        deleteClubMembershipTypeAction({ commit }, payload: IDeleteClubMembershipTypeActionPayload): Promise<void> {
            return apiClient.deleteClubMembershipType(payload.membershipType.id)
                .then(() => {
                    commit("deleteClubMembershipType", { "clubId": payload.membershipType.club, "membershipType": payload.membershipType });
                });
        },
        
        getClubFeaturesAction({ commit }, clubId): Promise<void> {            
            return apiClient.clubFeatures(clubId)
                .then((clubFeatures) => {
                    commit("getClubFeatures", { "clubId": clubId, "clubFeatures": clubFeatures });
                });
        },

        updateClubFeatureAction({ commit }, payload: IUpdateClubFeatureActionPayload): Promise<void> {
            return apiClient.updateClubFeature(payload.clubFeature)
                .then(() => {
                    commit("updateClubFeature", { "clubId": payload.clubFeature.club, "clubFeature": payload.clubFeature });
                });

        }, 

        refreshClubCourtGroupsAction({ commit }, payload: IRefreshClubCourtGroupsActionPayload): Promise<void> {
            return apiClient.clubCourtGroups(payload.club.id)
                .then((returnedCourtGroups) => {
                    commit("getCourtGroups", { "clubId": payload.club.id, "courtGroups": returnedCourtGroups });
                });
        },

        addCourtGroupAction({ commit }, payload: IAddCourtGroupActionPayload): Promise<any> {

            if(!payload.courtGroup.validate())
                return Promise.reject(new Error("Invalid court group details"));

            return apiClient.addCourtGroup(payload.courtGroup)
                .then((returnedCourtGroup) => {
                    commit("addCourtGroup", { "clubId": returnedCourtGroup.club.id, "courtGroup": returnedCourtGroup });
                    return returnedCourtGroup
                });
        },

        updateCourtGroupAction({ commit }, payload: IUpdateCourtGroupActionPayload): Promise<void> {
            if(!payload.courtGroup.validate())
                return Promise.reject(new Error("Invalid court group details"));

            return apiClient.updateCourtGroup(payload.courtGroup)
                .then((returnedCourtGroup) => {
                    commit("updateCourtGroup", { "clubId": returnedCourtGroup.club.id, "courtGroup": returnedCourtGroup });
                });

        },

        deleteCourtGroupAction({ commit }, payload: IDeleteClubGroupActionPayload): Promise<void> {
            return apiClient.deleteCourtGroup(payload.courtGroup.id)
                .then(() => {
                    commit("deleteCourtGroup", { "clubId": payload.courtGroup.club.id, "courtGroup": payload.courtGroup });
                });

        },

        updateClubBillingCycle({ commit }, payload: IUpdateClubBillingCycle): Promise<void> {
            return apiClient.updateClubBillingCycle(payload.clubPackageId, payload.billingCycle)
                .then((response) => {
                    commit("updateClubPackage", { "clubId": payload.clubId, "packageForClub": response.clubpackage });
                    commit("updateClubSubscription", { "clubId": payload.clubId, "subscription": response.subscription });
                });
        }
    }
}