import { Currency} from '../currency';

export class ClubMembershipType {

    constructor(data) {
        
        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? data.club : undefined;
        this.name = data && data.name ? data.name : "";
        this.comments = data && data.comments ? data.comments : "";
        this.group = data && data.group !== undefined ? data.group : false;
        this.max_number = data && data.max_number ? data.max_number : 0;
        this.annual_price = data && data.annual_price ? Number(data.annual_price) : 0;        
        this.monthly_price = data && data.monthly_price ? Number(data.monthly_price) : 0;
        this.currency = data && data.currency ? new Currency(data.currency) : null;

        // Nb: the following doesn't appear to be in use!
        // this.group_set = data && data.group_set ? data.group_set : [];
    }

    validate() {

        // Annual price must be a positive number
        if(this.annual_price == undefined || this.annual_price == null || isNaN(this.annual_price) || this.annual_price < 0)
            return false;

        // Monthly price must be a positive number
        if(this.monthly_price == undefined || this.monthly_price == null || isNaN(this.monthly_price) || this.monthly_price < 0)
            return false;

        // At least one cycle must be provided (annual price check)
        if(this.annual_price == 0 && this.monthly_price == 0)
            return false;

        return true;
    }
}