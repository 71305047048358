
import { Player } from '../player';

export class MatchaHitResponse { 
	
	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.responder = data && data.responder ? new Player(data.responder) : null;
		this.start_time = data && data.start_time ? data.start_time : undefined;
		this.comments = data && data.comments ? data.comments : undefined;
	}
}