import { Player } from '.';
import { Team } from './tournament';

export class MatchSide {

    constructor(data) {
        this.id = data.id;
        this.team = data.team == null ? undefined : new Team(data.team);

        this.player_set = [];
        if(data && data.player_set) {
			this.player_set = data.player_set.map((item) => new Player(item));
        }

        this.score = data.score;
        this.sets = data && data.sets ? data.sets : [];
    }
}