import { SlamMatch } from './slam-match';

export class SlamRound {
    constructor(data) {

        this.id = data.id;
        this.start = data && data.start ? data.start : "";
        this.end = data && data.end ? data.end : "";
        this.name = data && data.name ? data.name : "";
        this.number = data && data.number ? data.number : undefined; 
        this.open_for_picks = data && data.open_for_picks !== undefined ? data.open_for_picks : false;
        this.closing_date = data && data.closing_date ? data.closing_date : "";

        this.slam_match_set = [];
        if(data && data.slam_match_set) {
			this.slam_match_set = data.slam_match_set.map((item) => new SlamMatch(item));
		}
    }
}