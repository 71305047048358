import { ClubAdmin } from "@/models/club/club-admin";
import { Currency } from '..';
import { HoursOfOperation, ClubMembershipType, PackageForClub, StripeAccount, DayOfOperationItem, CourtGroup, Subscription, ClubFeatureRulesCourt } from '.';

export class Club {

	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.name = data && data.name ? data.name : undefined;
		this.street_address = data && data.street_address ? data.street_address : undefined;
		this.city = data && data.city ? data.city : undefined;
		this.state  = data && data.state ? data.state : undefined;
		this.postcode = data && data.postcode ? data.postcode : undefined;
		this.phone = data && data.phone ? data.phone : undefined;
		this.url = data && data.url ? data.url : undefined;
		this.email = data && data.email ? data.email : undefined;
		// this.subscription_level = data && data.subscription_level ? data.subscription_level : undefined;
		this.countryCode = data && data.country? data.country : undefined;
		this.connect_available = data && data.connect_available? data.connect_available : undefined;
		this.currency = data && data.currency ? new Currency(data.currency) : null;
		this.facilities = data && data.facilities ? data.facilities : [];
		this.admin_set = [];
		if (data && data.admin_set) {
			this.admin_set = data.admin_set.map((item) => new ClubAdmin(item));
		}

		this.package = null;
		if(data && data.package_set && data.package_set.length > 0) {
			this.package = new PackageForClub(data.package_set[0]);
		}

		this.subscription = data && data.subscription ? new Subscription(data.subscription) : undefined;

		this.stripe_account = data && data.stripe_account ? new StripeAccount(data.stripe_account) : undefined;

		this.hoursOfOperation = [];
		if(data && data.opening_hours) {

			this.hoursOfOperation = data.opening_hours.map((item) => {
				// Nb: The structure that comes from the server is quite unique for this object:
				// 	   { id: { close: "", open: "" } }
				// where id is the day of the week number, e.g.
				//     { 3: { close: "17:00", open: "7:00" } }

				const openingHoursNiceFormat = {};
				const id = Number(Object.keys(item));
				openingHoursNiceFormat.id = id;	/* note: can't just check for "id" being true because a value of 0 would return false! */
				openingHoursNiceFormat.open = item && item[id].open ? item[id].open : undefined;
				openingHoursNiceFormat.close = item && item[id].close ? item[id].close : undefined;

				return new HoursOfOperation(openingHoursNiceFormat)
			});
		}

		this.daysofOperation = [
			new DayOfOperationItem({id: 0, name: "Monday", dayType: "weekday"}),
			new DayOfOperationItem({id: 1, name: "Tuesday", dayType: "weekday"}),
			new DayOfOperationItem({id: 2, name: "Wednesday", dayType: "weekday"}),
			new DayOfOperationItem({id: 3, name: "Thursday", dayType: "weekday"}),
			new DayOfOperationItem({id: 4, name: "Friday", dayType: "weekday"}),
			new DayOfOperationItem({id: 5, name: "Saturday", dayType: "weekend"}),
			new DayOfOperationItem({id: 6, name: "Sunday", dayType: "weekend"}),
		];

		this.daysofOperation.map((item) => item.refreshHours(this.hoursOfOperation));

		this.courtgroup_set = [];
		if(data && data.courtgroup_set) {
			this.courtgroup_set = data.courtgroup_set.map((item) => new CourtGroup(item));
		}
	
		this.membership_type_set = [];
		if(data && data.membership_type_set) {
			this.membership_type_set = data.membership_type_set.map((item) => new ClubMembershipType(item));
		}

		this.court_rules = data && data.court_rules ? new ClubFeatureRulesCourt(data.court_rules) : null;
	}

	get formattedAddress() {
		const address = [];
		address.push(this.street_address);
		if(this.city !== undefined)
			address.push(this.city);
		if(this.state !== undefined)
			address.push(this.state);
		if(this.postcode !== undefined)
			address.push(this.postcode);

		return address.join(", ");
	}
}
