import { Sport } from '..';

export class Court {
    constructor(data) {
            
        this.id = data && data.id ? data.id : undefined;
        this.name = data && data.name ? data.name : undefined;
        // this.club = data && data.club ? data.club : undefined;   // removing: not in use and doesn't come from server
        this.group = data && data.group ? data.group : undefined;
        this.sport = data && data.sport ? new Sport(data.sport) : undefined;
        
        // NOTE: this is different to usual, it's got the surface stored as the id and the object stored alongside it!
        // Need to be sure not to send the surfaceObject to the backend
        // this.surface = data && data.surface ? data.surface.id : undefined; //.extend({ required: { message: "Surface is required" } };
        // this.surfaceObject = data && data.surface ? new Surface(data.surface) : undefined;
        
        this.surface = data && data.surface ? data.surface : undefined;
        
        this.has_lights = data && data.has_lights ? data.has_lights : false;
        this.bookable = data && data.bookable ? data.bookable : false;
        this.indoor = data && data.indoor ? data.indoor : false;
        this.number = data && data.number ? data.number : 0;
        this.access_notes = data && data.access_notes ? data.access_notes : "";
        this.notes = data && data.notes ? data.notes : "";
    }

    validate() {
        if(!this.name)
            return false;
        
        if(!this.surface)
            return false;

        if(!this.sport)
            return false;  
    
        return true;
    }

    // Return the court name with sport
	get name_with_sport() {
		if (!this.sport) return name;
		return this.name + " (" + this.sport.desc + ")";
	}

    name_with_sport_by_club(club) {		

		if (club == null) {			
			return this.name;	
		}

        if (club.court_rules 
                && club.court_rules.available_sports 
                && club.court_rules.available_sports.length > 1) {
            return this.name_with_sport;
        }
        else return this.name;
	}
}