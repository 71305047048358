import { Club } from './club/club';
import { ClubMember } from './club/club-member';
import { Coach } from './coach';
import { MatchaLevel } from './matcha/matcha-level';
import { SlamTournament } from './slamfan/slam-tournament';

export class Contact {
	constructor(data) {
	
		this.id = data.id;
		this.kind = data && data.kind ? data.kind : "";
		this.value = data && data.value ? data.value : "";
		this.validated = data && data.validated ? data.validated : false;
	}
}

export class PlayerClubAdmin {
	
	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.status = data && data.status ? data.status : undefined;
		this.access = data && data.access ? data.access : undefined;
		this.club = data && data.club ? new Club(data.club) : null;
		this.role = data && data.role ? data.role : [];
	}
}

export class PlayerStripeCustomer {
	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.player = data && data.player ? data.player : -1;
		this.card_type = data && data.card_type ? data.card_type : "";
		this.card_number = data && data.card_number ? data.card_number : "";
		this.card_expiry_month = data && data.card_expiry_month ? data.card_expiry_month : 0;
		this.card_expiry_year = data && data.card_expiry_year ? data.card_expiry_year : 0;
		this.token = data && data.token ? data.token : "";
		this.client_secret = data && data.client_secret ? data.client_secret : "";
		this.setup_intent_status = data && data.setup_intent_status ? data.setup_intent_status : "";
		this.payment_method_id = data && data.payment_method_id ? data.payment_method_id : "";
		this.card_country_code = data && data.card_country_code ? data.card_country_code : "";
	}
}

export class Player {

	constructor(data) {
		this.id = data && data.id ? data.id : undefined;
		this.first_name = data && data.first_name ? data.first_name : "";
		this.last_name = data && data.last_name ? data.last_name : "";
		this.gender = data && data.gender ? data.gender : "U";
		this.country = data && data.country ? data.country : "";
		this.is_user = data && data.is_user ? data.is_user : false;
		this.slamfan_admin = data && data.slamfan_admin ? data.slamfan_admin : false;

		this.contact_set = [];
		if(data && data.contact_set) {
			this.contact_set = data.contact_set.map((item) => new Contact(item) );
		}

		this.club_set = [];
		if(data && data.club_set) {
			this.club_set = data.club_set.map((item) => new Club(item) );
		}

		this.club_admin_set = [];
		if(data && data.club_admin_set) {
			this.club_admin_set = data.club_admin_set.map((item) => new PlayerClubAdmin(item) );
		}

		this.club_member_set = [];
		if(data && data.club_member_set) {
			this.club_member_set = data.club_member_set.map((item) => new ClubMember(item) );
		}

		this.coach = data.coach ? new Coach(data.coach) : null;
		this.stripe_customer = data.stripe_customer ? new PlayerStripeCustomer(data.stripe_customer) : null;
		this.matcha_level = data.matcha_level ? new MatchaLevel(data.matcha_level) : null;
		this.matcha_doubles_level = data.matcha_doubles_level ? new MatchaLevel(data.matcha_doubles_level) : null;

		this.current_tours = [];
		if(data && data.current_tours) {
			this.current_tours = data.current_tours.map((item) => new SlamTournament(item) );
		}
	}

    get full_name() {
		return this.first_name + " " + this.last_name;
	}

	findContactDetails(searchKind) {
		if(this.contact_set && this.contact_set.length > 0) {
			const contactItems = this.contact_set.filter(item => {
				return (item.kind == searchKind);
			});
			return contactItems;
		}
		else
			return undefined;
	}

}
