<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "ModalPopup",
    props: {
        isOpen: Boolean,
        title: String,
        showClose: Boolean,         // set to false to hide the close "X" in the title header (non-slidein modal)
        isLarge: Boolean,           // is a large modal dialog (extra width, implements modal-lg
        customClass: String,
        closeTrigger: Boolean       // this is the trigger that commences the leave transition
    },
    computed: {
        computedTransition () {
            return 'fade';
        }
    },
    methods: {
        afterLeave() {
            this.$emit('modal-popup-close-complete');
        }
    }
});
</script>

<style lang="scss" scoped>

    .fade-enter-active {
        transition: 0.8s ease;
    }

    .fade-leave-active {
        transition: 0.4s ease;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

</style>

<style lang="scss">

    .modal.in {
        display: block !important;
    }

    .modal-body .modal-footer {
        margin: 25px -15px -15px;
    }

</style>

<template>
    <div v-if="isOpen">
        <div id="SitePopupModal"
            class="modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            v-bind:class="[{ in: isOpen }, 'new-styles' ]"
            @click.stop >

            <!-- Display transition which is triggered when closing (closeTrigger), see afterLeave hook -->
            <transition :key="isOpen" v-bind:name="computedTransition" appear @after-leave="afterLeave">
                <div v-if="!closeTrigger" :class="[{ 'modal-lg': isLarge }, 'modal-dialog', customClass ]" >
                    <div class="modal-content">

                        <!-- modal header with title -->
                        <div class="modal-header" v-if="title && showClose">
                            <button type="button"
                                    class="close"
                                    v-if="showClose"
                                    @click="$emit('modal-popup-close-commence')" >
                                <span aria-hidden="true">&times;</span>
                                <span class="sr-only">Close</span>
                            </button>
                            <h4 class="modal-title" id="ModalLabel" v-html="title"></h4>
                        </div>
                        <!-- else modal header with title (no close button) -->
                        <div v-else-if="title && !showClose" class="modal-header">
                            <h4 class="modal-title" id="ModalLabel" v-html="title"></h4>
                        </div>
                        <!-- modal body -->
                        <div class="modal-body">
                            <slot />
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div class="modal-backdrop fade in">
        </div>
    </div>
</template>