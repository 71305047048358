import { ClubPackageFeature} from '.';

export class ClubPackage {

	constructor(data) {
		this.id = data.id;
		this.name = data && data.name ? data.name : undefined;
		this.desc = data && data.desc ? data.desc : undefined;
		this.detail = data && data.detail ? data.detail : undefined;
		this.cost_text = data && data.cost_text ? data.cost_text : undefined;
		this.order = data && data.order ? data.order : 0;

		this.feature_set = [];
		if (data && data.feature_set) {
			this.feature_set = data.feature_set.map((item) => new ClubPackageFeature(item));
		}
	}
}
