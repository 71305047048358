import { PlayerThread, Comment } from '.';

export class CommentsNew {
	constructor(data) {
	
		// new comments
        this.comments = [];
		if(data && data.comments) {
			this.comments = data.comments.map((item) => new Comment(item) );
		}

		// player threads
		this.playerthreads = [];
		if(data && data.playerthreads) {
			this.playerthreads = data.playerthreads.map((item) => new PlayerThread(item) );
		}
	}
}
