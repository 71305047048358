

<script lang="ts">
    import { datepicker } from '@/directives/datepicker';
    import moment from 'moment';
    import Vue from 'vue';

    export default Vue.extend({
        name: "DatePickerMonthDay",
        directives: {
            datepicker
        },
        props: {
            value: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            helpText: {
                type: String,
                default: ""
            },
            outputDateFormat: {
                type: String,
                default: "YYYY-MM-DD"
            }
        },
        data () {
            return {
                currentValue: "",
                dateFormat: "MMM DD"
            }
        },
        watch: {
            value: {
                handler(val) {
                    if(!val)
                        this.currentValue = "";
                    else
                        this.currentValue = moment(val).format(this.dateFormat);
                },
                immediate:true  
            },
            currentValue(val) {
                if(!val)
                    this.$emit("input", "");
                else
                    this.$emit("input", moment(val, this.dateFormat).format(this.outputDateFormat));  
            }
        }
    });
</script> 

<template>
    <div class="form-group">
        <label class="control-label" v-if="label">
            <span>{{ label }}</span>
        </label>
        <span v-if="helpText" class="help-block">{{ helpText }}</span>
        <input
            class="form-control"
            v-datepicker="{ noYear: true }"
            v-model="currentValue"
        />
    </div>
</template>