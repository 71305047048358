export class CoachInsights {
    constructor(data) {
        this.name = data && data.name ? data.name : undefined;
        this.completed = data && data.completed !== undefined ? data.completed : undefined;
        this.cancelled = data && data.cancelled !== undefined ? data.cancelled : undefined;
        this.with_guests = data && data.with_guests !== undefined ? data.with_guests : undefined;
        this.completed_hours = data && data.completed_hours !== undefined ? data.completed_hours : undefined;
        this.shared_hours = data && data.shared_hours !== undefined ? data.shared_hours : undefined;
        this.total_hours = data && data.total_hours !== undefined ? data.total_hours : undefined;
        this.club_coach = data && data.club_coach !== undefined ? data.club_coach : undefined;
        this.coach = data && data.coach !== undefined ? data.coach : undefined;
    }
}

export class BookingType {
    constructor(data) {
        this.hr_min = data && data.hr_min !== undefined ? data.hr_min : undefined;
        this.completed = data && data.completed !== undefined ? data.completed : undefined;
        this.with_guest = data && data.with_guest !== undefined ? data.with_guest : undefined;
        this.cancelled = data && data.cancelled !== undefined ? data.cancelled : undefined;
        this.total = data && data.total !== undefined ? data.total : undefined;
        this.type = data && data.type !== undefined ? data.type : undefined;
    }
}

export class ByCourtGroup {
    constructor(data) {
        this.available = data && data.available !== undefined ? data.available : undefined;
        this.average = data && data.average !== undefined ? data.average : undefined;
        this.with_guest = data && data.with_guest !== undefined ? data.with_guest : undefined;
        this.name = data && data.name !== undefined ? data.name : undefined;
        this.cancelled = data && data.cancelled !== undefined ? data.cancelled : undefined;
        this.completed = data && data.completed !== undefined ? data.completed : undefined;
        this.total = data && data.total !== undefined ? data.total : undefined;
        this.id = data && data.id !== undefined ? data.id : undefined;
        this.utilisation = data && data.utilisation !== undefined ? data.utilisation : undefined;
        this.total_hr = data && data.total_hr !== undefined ? data.total_hr : undefined;
    }
}

export class CourtBookingsInsights {
    constructor(data) {
        this.completed_bookings = data && data.completed_bookings !== undefined ? data.completed_bookings : undefined;
        this.prev_total_completed = data && data.prev_total_completed !== undefined ? data.prev_total_completed : undefined;
        this.total_members = data && data.total_members !== undefined ? data.total_members : undefined;
        this.cancelled_bookings = data && data.cancelled_bookings !== undefined ? data.cancelled_bookings : undefined;
        this.prev_total_guest = data && data.prev_total_guest !== undefined ? data.prev_total_guest : undefined;

        this.booking_types = data && data.booking_types ? data.booking_types.map((item) => new BookingType(item)) : [];

        this.total_bookings = data && data.total_bookings !== undefined ? data.total_bookings : undefined;
        this.prev_utilisation = data && data.prev_utilisation !== undefined ? data.prev_utilisation : undefined;
        this.available_hours = data && data.available_hours !== undefined ? data.available_hours : undefined;

        this.by_courtgroup = data && data.by_courtgroup ? data.by_courtgroup.map((item) => new ByCourtGroup(item)) : [];

        this.utilisation = data && data.utilisation !== undefined ? data.utilisation : undefined;
        this.total_hours = data && data.total_hours !== undefined ? data.total_hours : undefined;
        this.prev_total_members = data && data.prev_total_members !== undefined ? data.prev_total_members : undefined;
        this.heatmap = data && data.heatmap !== undefined ? data.heatmap : undefined;

        this.by_court = data && data.by_court ? data.by_court.map((item) => new ByCourtGroup(item)) : [];

        this.total_guests = data && data.total_guests !== undefined ? data.total_guests : undefined;
        this.unique_total_members = data && data.unique_total_members !== undefined ? data.unique_total_members : undefined;
    }
}