import { Club } from  './club';
import Utilities from '@/utils/utilities';

export class Newsletter {
    
    constructor(data) {

        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? new Club(data.club) : undefined;
        this.player = data && data.player ? data.player : undefined;
        this.title = data && data.title ? data.title : "";
        this.summary = data && data.summary ? data.summary : "";
        this.url = data && data.url ? data.url : "";
        this.status = data && data.status ? data.status : "";
        this.created = data && data.created ? data.created : "";
    }

    validate() {

        if(!this.title)
            return false;

        if(!this.summary)
            return false;

        if(!Utilities.validateURL(this.url, true))
            return false;

        return true;
    }

    get published_date() {
		return Utilities.formatDateToDateString(this.created);
	}
}