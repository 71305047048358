import { Player } from '../player';

export class Member {

    constructor(data) {

        this.id = data && data.id ? data.id : undefined;
        this.tournament = data && data.tournament ? data.tournament : undefined;
        this.team = data && data.team ? data.team : undefined;
        this.player = data && data.player ? new Player(data.player) : null;
        this.rank = data && data.rank ? data.rank : undefined;
        this.gender = data && data.gender ? data.gender : "U";
        this.is_organiser = data && data.is_organiser !== undefined ? data.is_organiser : false;
    }

    // Note: Flipping this logic - used to be notCurrentPlayer and was using getCurrentPlayer.Id
    isPlayer(playerId) {
        return this.player.id == playerId
    }
}