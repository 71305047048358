import { Currency} from '../currency';

export class MarketFeature {

	constructor(data) {
		this.id = data.id;
		this.feature = data && data.feature ? data.feature : undefined;
		this.lump_sum_price = data && data.lump_sum_price ? data.lump_sum_price : null;
		this.per_player_price = data && data.per_player_price ? data.per_player_price : null;
		this.transaction_fee = data && data.transaction_fee ? data.transaction_fee : null;
		
		this.lump_sum_price_monthly = data && data.lump_sum_price_monthly ? data.lump_sum_price_monthly : null;
		this.per_player_price_monthly = data && data.per_player_price_monthly ? data.per_player_price_monthly : null;
		
		this.processing_fee = data && data.processing_fee ? data.processing_fee : null;
		this.processing_fee_unit = data && data.processing_fee_unit ? data.processing_fee_unit : "";
		this.currency  = data && data.currency ? data.currency : "";
        this.currency_obj  = data && data.currency_obj ? new Currency(data.currency_obj) : null;
	}
}
