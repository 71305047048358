import { EventSession, EventSessionBookingFail } from '.';

export class EventSessionBookingResponse {
     
    constructor(data) {       

        this.failed_sessions = [];
        if (data && data.failed_sessions) {
            this.failed_sessions = data.failed_sessions.map((item) => new EventSessionBookingFail(item));
        }

        this.sessions = [];
        if (data && data.sessions) {
            this.sessions = data.sessions.map((item) => new EventSession(item));
        }
    }
}