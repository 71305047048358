export class PlayerMatch {

    constructor(data) {
        this.id = data.id;
        this.player = data.player;
        this.match = data.match;
        this.approved = data.approved;
        this.deleted = data.deleted;
    }

}