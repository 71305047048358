
<!-- 
    December 2021 Date Picker Calendar
    Anthony Nosek | Str8 Sets

    Notes:  This is a date selection component supporting
            1)  Selection of single date OR
            2)  Selection of a date range with pre-defined range selection

            To set for single date selection only set prop
                isSingleDate (default is date range selection)

            When using as a date range selector AND you want to display predefined 
            ranges, pass in the range keys as a comma seperated string to prop 'rangesToShow'
                rangesToShow='last7Days,last30Days,last90Days'
            The control will only display the ranges for the keys provided
            The full list of ranges is data.allRanges
-->

<script lang="ts">
    //import DatePicker from 'v-calendar/lib/components/date-picker.umd';    
    import moment from 'moment';
    import VCalendar from 'v-calendar';
    import Vue from 'vue';

    export interface Range {
        key: string;
        title: string;
        start: any;
        end: any;
    }

    export default Vue.extend({
        name: 'DatePickerCalendar',
        model: {
            prop: 'selectedDate'
        },
        components: {
            'v-date-picker': VCalendar.DatePicker,
        },
        props: {
            tag: {
                type: String,
                default: 'li'
            },
            isSingleDate: {
                type: Boolean,
                default: false
            },
            singleDateTitleShortFormat: {
                type: Boolean,
                default: false
            },
            singleDateShowIncrementArrows: {
                type: Boolean,
                default: false
            },
            showDropDownArrow: {
                type: Boolean,
                default: true
            },
            rangesToShow: {
                type: String,
                default: ""
            }
        },
        data() { 
            return {
                isOpen: false,
                dateFormatDisplayString: 'MMM DD, YYYY',
                dateFormatYYYYMMDD: 'YYYYMMDD',
                // Data used by the date picker
                selectedDate: {
                    start: moment().subtract(29, 'days').toDate() as Date|null,
                    end: moment().endOf('day').toDate() as Date|null,
                    date: new Date(),
                    displayTitle: ""
                },
                selectedTitle: "",
                selectedKey: "",
                allRanges: {
                    'future': {
                                    key: 'future',
                                    title: 'Future',
                                    start: moment().startOf('day'),
                                    end: moment('20991231', 'YYYYMMDD').toDate()
                                },
                    'next12Months': {
                                    key: 'next12Months',
                                    title: 'Next 12 Months',
                                    start: moment().startOf('day'),
                                    end: moment().add(12, 'month')
                                },
                    'next90Days': {
                                    key: 'next90Days',
                                    title: 'Next 90 Days',
                                    start: moment().startOf('day'),
                                    end: moment().add(90, 'days')
                                },
                    'next30Days': {
                                    key: 'next30Days',
                                    title: 'Next 30 Days',
                                    start: moment().startOf('day'),
                                    end: moment().add(30, 'days')
                                },
                    'next7Days': {
                                    key: 'next7Days',
                                    title: 'Next 7 Days',
                                    start: moment().startOf('day'),
                                    end: moment().add(7, 'days')
                                },
                    'last7Days': {
                                    key: 'last7Days',
                                    title: 'Last 7 Days',
                                    start: moment().subtract(6, 'days'),
                                    end: moment().endOf('day')
                                },
                    'last30Days': {
                                    key: 'last30Days',
                                    title: 'Last 30 Days',
                                    start: moment().subtract(29, 'days'),
                                    end: moment().endOf('day')
                                },
                    'last90Days': {
                                    key: 'last90Days',
                                    title: 'Last 90 Days',
                                    start: moment().subtract(89, 'days'),
                                    end: moment().endOf('day')
                                },
                    'past': {
                                    key: 'past',
                                    title: 'Past',
                                    start: moment('20180101', 'YYYYMMDD').toDate(),
                                    end: moment().endOf('day')
                                },
                    'thisMonth': {
                                    key: 'thisMonth',
                                    title: 'This Month',
                                    start: moment().startOf("month"),
                                    end: moment().endOf("month")
                                },
                    'nextMonth': {
                                    key: 'nextMonth',
                                    title: 'Next Month',
                                    start: moment().add(1, 'months').startOf("month"),
                                    end: moment().add(1, 'months').endOf("month")
                                },
                    'lastMonth': {
                                    key: 'lastMonth',
                                    title: 'Last Month',
                                    start: moment().subtract(1, 'months').startOf("month"),
                                    end: moment().subtract(1, 'months').endOf("month")
                                },
                    'thisCalendarYear': {
                                    key: 'thisCalendarYear',
                                    title: 'This Calendar Year',
                                    start: moment().startOf("year"),
                                    end: moment().endOf("year")
                                },
                    'nextCalendarYear': {
                                    key: 'nextCalendarYear',
                                    title: 'Next Calendar Year',
                                    start: moment().add(1, 'years').startOf("year"),
                                    end: moment().add(1, 'years').endOf("year")
                                },
                    'lastCalendarYear': {
                                    key: 'lastCalendarYear',
                                    title: 'Last Calendar Year',
                                    start: moment().subtract(1, 'years').startOf("year"),
                                    end: moment().subtract(1, 'years').endOf("year")
                                },
                    'all': {
                                    key: 'all',
                                    title: 'All',
                                    start: moment('20180101', 'YYYYMMDD').toDate(),
                                    end: null
                                }
                },
                ranges: [] as Range[],
                attrs: [
                    {
                        // highlight style
                        highlight: {
                            color: 'purple',
                            fillMode: 'light',
                        }
                    },
                    {
                        // a dot for the current day
                        dot: {
                            backgroundColor: '#4e9a3c',
                        },
                        dates: [
                            moment().toDate()
                        ],
                    }
                ]
            }
        },
        computed: {
            title(): string {
                if (this.isSingleDate) {
                    // display the signle date selected
                    if (this.singleDateTitleShortFormat) {
                        return moment(this.selectedDate.date).format(this.dateFormatDisplayString);
                    }
                    else {
                        return moment(this.selectedDate.date, "YYYY-MM-DD").format('dddd DD MMMM, YYYY');
                    }
                }
                else {
                    // display the range title or the date range if manually selected in the calendar control
                    return this.selectedTitle.length > 0
                        ? this.selectedTitle
                        : moment(this.selectedDate.start).format(this.dateFormatDisplayString) + ' - ' + moment(this.selectedDate.end).format(this.dateFormatDisplayString);
                    }
            },
            showNarrow(): boolean {
                // return true if we need to display only the calendar component
                return this.isSingleDate || this.ranges.length == 0;
            }
        },
        methods: {
            toggleDropDown() {
                this.isOpen = !this.isOpen;
            },
            openDropDown() {
                this.isOpen = true;
            },
            closeDropDown(event) {
                if(!event.currentTarget.contains(event.relatedTarget)) { 
                    this.isOpen = false;
                }
            },
            inputDateSelected(value) {
                this.selectedDate.date = new Date(value);
                this.selectedKey = "";
                this.$emit('change', this.selectedDate);                 
            },
            rangeDragged(range) {
                // the user has selected a range by dragging
                this.selectedDate.start = new Date(range.start);
                this.selectedDate.end = new Date(range.end);  
                this.selectedTitle = "";
                this.selectedKey = "";

                this.selectedDate.displayTitle = "Custom";
                this.$emit('change', this.selectedDate);
            },
            clickRange(value) {
                // assing the values
                // check the end date is not 01/01/1970 (revert this to null)
                if (!value.end) {
                    this.selectedDate.end = null; 
                }
                else this.selectedDate.end = new Date(value.end); 

                this.selectedDate.start = new Date(value.start);                 
                this.selectedDate.displayTitle = value.title.toString();
                this.selectedKey = value.key;

                // pass to parent
                this.$emit('change', this.selectedDate);  

                // set local
                this.selectedTitle = value.title.toString();
                this.refreshCalendar();   

                // prevent the event from propgating
                return false;
            },
            progressSelectedDay(days: number) {
                this.selectedDate.date = moment(this.selectedDate.date).add(days, 'days').toDate();
                this.selectedKey = "";
                //this.$emit('change', this.selectedDate);  
                this.refreshCalendarForDate(); 
            },
            refreshCalendar() {
                if (this.$refs && this.$refs.picker) {
                    const pickerElement = (this.$refs.picker as VCalendar.DatePicker);
                    pickerElement?.updateValue(this.selectedDate);
                    pickerElement?.adjustPageRange();
                }
            },
            refreshCalendarForDate() {
                if (this.$refs && this.$refs.picker) {
                    const pickerElement = (this.$refs.picker as VCalendar.DatePicker);
                    pickerElement?.updateValue(this.selectedDate.date);
                }
            },
            selectRanges() {
                // select the ranges using the keys provided
                if (this.rangesToShow.length > 0) {
                    // go through the provided keys and show them in the calendar
                    const rangesToShowArray = this.rangesToShow.split(',');
                    rangesToShowArray.forEach(key => {
                        if (this.allRanges[key]) this.ranges.push(this.allRanges[key]);
                    });
                }
                //console.log(this.ranges);
            }
        },
        mounted() {
            // pre-select a default date range
            this.selectRanges();
            if (this.ranges.length > 0) this.clickRange(this.ranges[0]);
        }
    });
</script>

<style>   
</style>

<style scoped>

    .dropdown {
        list-style-type: none;
    }

    .dropdown .dropdown-toggle {
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    .datepicker-container 
    .vc-container {
        border: none;
    }

    .dropdown-menu {
        margin-top: 10px;
        border-radius: 20px;
    }

    .datepicker-container {
        width: 430px;
    }

    .narrow {
        width: 275px !important;
    }

    .dropdown-menu:before, .dropdown-menu:after {
        right: 480px !important;
    }

    .dropdown-menu:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: white transparent;
        border-width: 0 10px 8px;
        top: -8px;
        left: 10%;
        margin-left: -10px;
    }

    .arrow {
        background: url('~@/assets/images/arrow-down-green.svg') 0% 0% no-repeat padding-box;
        background-size: 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0px;
        top: 2px;
        cursor: pointer;
    }

    .btn-datepicker {
        width: 140px;
        height: 29px !important;
        margin-bottom: 6px;
        margin-top: 0px !important;
        border: none;
        background-color: white;
        color: #949494;
        text-align: left;
        padding-left: 5px;
        font: normal 14px Open Sans !important;
    }
    .btn-datepicker:hover {
        color: #006721;
    }
    .btn-datepicker-selected {
        color: #006721;
        font-weight: 600 !important;
    }

    .datepicker-left {
        float: left;
        width: 120px;
        margin-top: 25px;
        margin-left: 10px;
    }

    .datepicker-right {
        float: right;
        margin: 13px;
    }

    .input-wrap {
      position: relative;
      display: inline;
    }

    .input-arrow-container { 
        position: absolute;
        right: 15px;
        top: -1px;
    }

    .arrow-forward {
        cursor: pointer;
        display: inline-block;
        width: 25px;
        height: 25px;
        background: url('~@/assets/images/arrow-down-green.svg') 0% 0% no-repeat padding-box;
        background-size: 25px;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
    }

    .arrow-back {
        cursor: pointer;
        display: inline-block;
        width: 25px;
        height: 25px;      
        background: url('~@/assets/images/arrow-down-green.svg') 0% 0% no-repeat padding-box;
        background-size: 25px;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }   
    
    .vc-container::v-deep .vc-title {
        color: #496B7B !important;
    }

    .vc-container {
        display: block;
    }

</style>

<template>
    <div class="new-styles">
        <component class="form-control white dropdown"
                :is="tag"
                :class="[{open: isOpen}, {show: isOpen}]"
                aria-haspopup="true"
                :aria-expanded="isOpen"
                tabindex="-1"
                @focusout="closeDropDown">
            <slot name="title" :is-open="isOpen">
                <div class="input-wrap">
                    <input class="white input-no-border dropdown-toggle"
                        readonly      
                        @click="toggleDropDown"        
                        :value="title"
                    />  
                    <div v-if="isSingleDate && singleDateShowIncrementArrows" class="input-arrow-container">
                        <div class="arrow-back" @click="progressSelectedDay(-1)" />
                        <div class="arrow-forward" @click="progressSelectedDay(1)" />
                    </div> 
                    <div v-if="showDropDownArrow" class="arrow" @click="toggleDropDown" />    
                </div>                      
            </slot>

            <ul class="dropdown-menu datepicker-container"
                :class="[{ narrow: showNarrow },{ open: isOpen }]">               

                <div v-if="!showNarrow" class="datepicker-left">
                    <button
                        type="button"
                        class="btn-datepicker"
                        v-for="r in ranges"
                        @click="clickRange(r)"
                        :class="{ 'btn-datepicker-selected': r.key == selectedKey }"
                        :data-range-key="r.key"
                        :key="r.key">
                            {{r.title}}
                    </button>
                </div>

                <div class="datepicker-right">
                    <!-- Single date selection -->
                    <v-date-picker v-if="isSingleDate"
                        ref="picker"
                        color='green'
                        v-model="selectedDate.date"
                        :attributes='attrs'
                        :timezone=undefined
                        is-required
                        @input="inputDateSelected">
                    </v-date-picker>
                    <!-- Date range selection -->
                    <v-date-picker v-else
                        ref="picker"
                        color='green'
                        v-model="selectedDate"
                        :attributes="attrs"
                        :timezone=undefined
                        is-required
                        is-range
                        @drag="rangeDragged">
                    </v-date-picker>
                </div>

            </ul>
        </component>
    </div>
</template>