import { extend } from "vee-validate";
import { required, required_if, email, max, min_value, max_value, regex } from "vee-validate/dist/rules";    //  url,
import moment from 'moment';
import Utilities from '@/utils/utilities';

extend("required", {
    ...required,
    params: ['message'],
    message: (fieldName, placeholders) => {
        return  placeholders.message ? `${placeholders.message}` : `${fieldName == "{field}" ? "Field" : fieldName} is required`;
    }
});

extend("required_if", {
    ...required_if,
    params: ['target', 'values', 'message'],
    message: (fieldName, placeholders) => {
        return placeholders.message ? `${placeholders.message}` : `${fieldName == "{field}" ? "Field" : fieldName} is required`;
    }
});

extend("regex", {
    ...regex,
    params: ['regex', 'message'],
    message: (fieldName, placeholders) => {
        return  placeholders.message ? `${placeholders.message}` : `${fieldName == "{field}" ? "Field" : fieldName} format is invalid`;
    }
});

extend("exact_length", {
    params: [ "length", "message" ],
    validate(val, { length, message }) {
      
        if (val.length !== Number(length))
            return message ?? "Must have " + length + " items";
  
        return true;
    },
});

extend("min_length", {
    params: ["min"],
    validate(val, { min }) {
      if (val.length < min) {
        return "Must have at least " + min + " items";
      }
  
      return true;
    }
});

extend("min_value", {
    ...min_value,
    params: ['min','message'],
    message: (fieldName, placeholders) => {
        return placeholders.message ? `${placeholders.message}` : `${fieldName} must be greater than or equal to ${placeholders.min}`;
    }
});

extend("max_value", {
    ...max_value,
    params: ['max','message'],
    message: (fieldName, placeholders) => {
        return placeholders.message ? `${placeholders.message}` : `Must be less than or equal to ${placeholders.max}`;
    }
});

extend("positive_number_or_zero", {
    validate(val) {
        
        if(isNaN(val) || val < 0)
            return "Must be a positive number";

        return true;
    },
});

extend("positive_number", {
    validate(val) {
        
        if(isNaN(val) || val <= 0)
            return "Must be a number greater than 0";

        return true;
    },
});

extend("require_one_non_zero", {
    params: ['target1', 'target2', 'message'],
    validate(val, { target1, target2, message }) {
        
        if((Number(target1) == 0 && (isNaN(target2) || Number(target2) <= 0))
            || (Number(target2) == 0 && (isNaN(target1) || Number(target1) <= 0)))
            return message;

        return true;
    },
});

extend("non_zero_if", {
    params: ['target', 'targetConditionNum', 'message'],
    validate(val, { target, targetConditionNum, message }) {
        
        if(Number(target) == Number(targetConditionNum) && (isNaN(val) || Number(val) <= 0))
            return message;

        return true;
    },
});

// Nb: the following is kind of useless, as it HAS to be 'required' as well, and that rule takes precedence and so the error shown is 'Field is required' as per that rule above.
// extend("required_array", {
//     validate(value) {

//         if(!value || value.length == 0)
//             return false;

//         return true;
//     },
//     message: "Must choose at least one {_field_}"
// })

extend("max", {
    ...max,
    params: ['length'],
    message: "Please enter no more than {length} characters"
});

extend("email", {
    ...email,
    message: "Please provide a valid email address"
});

extend("url", {
    params: ['requireProtocol'],
    validate(value, { requireProtocol }) {
        if(!Utilities.validateURL(value, requireProtocol === "true"))
            return "This field has to be a valid URL starting with http:// or https://";

        return true;
    }
});

extend("date_format_standard", {
    validate(val) {
        if(val === undefined)
            return true;
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!val.match(regEx)) return false;  // Invalid format
        const d = new Date(val);
        if(!d.getTime()) return false; // Invalid date (or this could be epoch)
        return d.toISOString().slice(0,10) === val;
    },
    message: "Date format must be YYYY-MM-DD"
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Passwords must match'
});

extend("after", {
    params: ['target', 'message'],
    validate(value, { target, message }) {
        if(value)
        {
            if(new Date(value) < new Date(target))
                return message ?? "Date is not valid";
        }
        return true;
    }
});

extend('date_in_future', {
    params: ['message'],
    validate(value, { message }) {
        if(new Date(value) < new Date())
            return message ?? "Must be in the future";
        return true;
    },
})

extend('time_greater_than', {
    params: ['target'],
    validate(value, { target }) {

        if(value)
        {
            if(moment(value, "H:mm") <= moment(target, "H:mm"))
            {
                return false;
            }
        }
        return true;
    },
    message: "{_field_} must be after {target}"
});

extend('time_object_greater_than', {
    params: ['target'],
    validate(value, { target }) {

        if(value && target)
        {
            if(moment(value.value, "H:mm") <= moment(target.value, "H:mm"))
            {
                return false;
            }
        }
        return true;
    },
    message: "{_field_} must be after {target}"
});