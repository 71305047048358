import { SlamCompetitor } from './slam-competitor';

export class SlamCompetitorTournament {
    constructor(data) {

        this.id = data.id;
        this.seeding = data && data.seeding ? data.seeding : "";
        this.bracket_number = data && data.bracket_number ? data.bracket_number : "";

        this.competitor = data && data.competitor ? new SlamCompetitor(data.competitor) : null;
    }
}