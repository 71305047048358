
export class ClubCartProduct {
	constructor(data) {
		this.product_id = data && data.product_id ? data.product_id : undefined; 
        this.quantity = data && data.quantity ? data.quantity : 0;  

        // Store the entire product for reference in the cart
        // Never sent to the server
        this.clubProduct = data && data.clubProduct ? data.clubProduct : undefined;  
    }
}