export class Refund {
    constructor(data) {

        this.id = data.id;
        this.transaction = data && data.transaction ? data.transaction : undefined;
        this.amount = data && data.amount ? data.amount : 0;
        this.refund_id = data && data.refund_id ? data.refund_id : "";
        this.reason = data && data.reason ? data.reason : "";

        this.failure_reason = data && data.failure_reason ? data.failure_reason : null;
        this.failure_message = data && data.failure_message ? data.failure_message : "";
        this.error_code = data && data.error_code ? data.error_code : 0;

        this.status = data && data.status ? data.status : "";

        this.created = data && data.created ? data.created : undefined;
        this.modified = data && data.modified ? data.modified : undefined;
    }
}