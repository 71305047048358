<script lang="ts">
    import { ValidationProvider } from "vee-validate";
    import Vue from 'vue';

    export default Vue.extend({
        name: "TextInput",
        components: {
            ValidationProvider
        },
        props: {
            value: {
                type: String,
                default: ""
            },
            rules: {
                type: [String, Object],
                default: ""
            },
            placeholder: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            helpText: {
                type: String,
                default: ""
            },
            helpLabel: {
                type: String,
                default: ""
            },
            counter: {
                type: String,
                default: ""
            },
            rows: {
                type: String,
                default: "2"
            },
            name: {
                type: String,
                default: ""
            },
            vid: {
                type: String,
                default: undefined
            },
            type: {
                type: String,
                default: "text",
                validator(value:string) {
                    return [
                        "url",
                        "text",
                        "password",
                        "tel",
                        "search",
                        "number",
                        "email"
                    ].includes(value);
                }
            }
        },
        data () {
            return {
                currentValue: ""
            }
        },
        watch: {
            value: {
                handler(val) {
                    this.currentValue = val;
                },
                //this makes it run the handler function on mount in addition to whenever the value changes
                immediate:true
            },
            currentValue(val) {
                // allows us to use v-model on our input.
                this.$emit("input", val);
            }
        }
    });
</script>

<style>
    .help-label {
        font-weight: normal;
        padding-left: 5px;
    }
</style>

<template>
    <ValidationProvider
        slim
        :rules="rules"
        :name="name || label"
        :vid="vid"
        v-slot="{ errors, required, ariaInput, classes }"
    >
        <div class="form-group" :class="classes">
            <label class="control-label" :for="name" v-if="label">
                <span>{{ label || name }}</span>
                <span class="help-label">{{ helpLabel }}</span>
                <span class="helper">{{ required ? ' (required)' : '' }}</span>
            </label>
            <span v-if="helpText" class="help-block">{{ helpText }}</span>
            <span v-if="counter" class="help-block pull-right">{{ counter }}</span>
            <textarea
                class="form-control"
                :type="type"
                :placeholder="placeholder"
                :rows="rows"
                v-model="currentValue"
                v-bind="ariaInput"
            ></textarea>
            <span class="help-block" v-if="errors[0]">
                {{ errors[0] }}
            </span>
        </div>
    </ValidationProvider>
</template>
