import { TextInput, TextArea, SelectInput, NumberInput, DatePickerCalendar, DatePickerMonthDay, DatePickerSimple, CheckboxControl } from './forms-fields';
import MessageText from './message-text.vue';
import Vue from 'vue';

Vue.component('checkbox-control', CheckboxControl)
Vue.component('date-picker-calendar', DatePickerCalendar)
Vue.component('date-picker-month-day', DatePickerMonthDay)
Vue.component('date-picker-simple', DatePickerSimple)
Vue.component('message-text', MessageText);
Vue.component('number-input', NumberInput)
Vue.component('select-input', SelectInput)
Vue.component('text-area', TextArea)
Vue.component('text-input', TextInput)