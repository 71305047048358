import { Club } from '@/models';

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state: {
        dashboardClub: null,
        dashboardClubTitle: "",
        dashboardActiveMenu: null        
    },
    mutations: {

        setDashboardClub(state, club) {
            state.dashboardClub = club;     
        },

        setDashboardClubTitle(state, text) {
            state.dashboardClubTitle = text;     
        },

        setDashboardActiveMenu(state, identifier) {
            state.dashboardActiveMenu = identifier;     
        }
    },
    getters: {
        dashboardClub: (state) => state.dashboardClub,
        dashboardClubTitle: (state) => state.dashboardClubTitle,
        dashboardActiveMenu: (state) => state.dashboardActiveMenu
    },
    actions: {

        setDashboardClubAction({ commit }, club: Club): Club {    
            commit("setDashboardClub", club);
            return club;
        },

        setDashboardClubTitleAction({ commit }, text: string): string {    
            commit("setDashboardClubTitle", text);
            return text;
        },
        
        setDashboardActiveMenu({ commit }, identifier: string): string {    
            // save the dashboard active menu to the store AND to local storage
            // local storage is used when the page is refreshed as store is memory only
            commit("setDashboardActiveMenu", identifier);
            localStorage.setItem('dashboardActiveMenu', identifier);
            return identifier;
        }
    }
}