import moment from 'moment';

export class EventHoursOfSingleSession {
	
	constructor(data) {
		this.id = data && data.id !== undefined ? data.id : undefined;
		this.name = data && data.name !== undefined ? data.name : undefined;
		this.start = data && data.start ? data.start : undefined;
		this.end = data && data.end ? data.end : undefined;
	}

	validate() {

		if(!this.start)
			return false

		if(!this.end)
			return false;

		if(moment(this.end, "H:mm") < moment(this.start, "H:mm"))
			return false;

		return true;
	}
}