import { ClubEquipment } from "@/models/court-booking/club-equipment";

export class BookingSeriesClubEquipment {

    constructor(data) {
        this.id = data.id;
        this.series = data && data.series ? data.series : undefined;
        this.quantity = data && data.quantity ? data.quantity : undefined;
        this.equipment = data && data.equipment ? new ClubEquipment(data.equipment) : undefined; 
    }
}