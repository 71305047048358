import { MembershipTypeBookingRate } from ".";
import moment from 'moment';

export const CourtGroupBookingRateNonMemberDefaultId = "NMD";
export const CourtGroupBookingRateMemberDefaultId = "MD";
export const CourtGroupBookingRateCoachesDefaultId = "CD";

export class CourtGroupBookingRate {
    
    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
        this.courtgroup = data && data.courtgroup ? data.courtgroup : undefined;
        this.name = data && data.name ? data.name : undefined;

        this.start_hour = data && data.start_hour != null ? data.start_hour : undefined;
        this.start_minute = data && data.start_minute != null ? data.start_minute : undefined;

        this.end_hour = data && data.end_hour != null ? data.end_hour : undefined;
        this.end_minute = data && data.end_minute != null ? data.end_minute : undefined;

        this.member_def_price = data && data.member_def_price ? data.member_def_price : 0;        // format to apply in the UI: parseFloat(data.member_def_price).toFixed(2)
        this.member_can_book = data && data.member_can_book !== undefined ? data.member_can_book : true;
        this.nonmember_def_price = data && data.nonmember_def_price ? data.nonmember_def_price: 0;       // format to apply in UI: parseFloat(data.nonmember_def_price).toFixed(2) 
        this.nonmember_can_book = data && data.nonmember_can_book !== undefined ? data.nonmember_can_book : false;
        this.coach_book_price = data && data.coach_book_price ? data.coach_book_price : 0;
        this.coach_can_book = data && data.coach_can_book !== undefined ? data.coach_can_book : true;

        this.member_rate_set = [];
		if (data && data.member_rate_set) {
			this.member_rate_set = data.member_rate_set.map((item) => new MembershipTypeBookingRate(item));
		}
    }

    get start_time_formatted() {
        if(this.start_hour != null && this.start_minute != null) {
            return this.start_hour.toString().padStart(2, '0') + ":" + this.start_minute.toString().padEnd(2, '0');
        }
        else
            return "";
    }
    
    set start_time_formatted(value) {
        if(!value)
            return;

        const indexColon = value.indexOf(":");
        this.start_hour = value.substr(0, indexColon);
        this.start_minute = value.substr(indexColon + 1);
    }
    
    get end_time_formatted() {
        if(this.end_hour != null && this.end_minute != null) {
            return this.end_hour.toString().padStart(2, '0') + ":" + this.end_minute.toString().padEnd(2, '0');
        }
        else
            return "";
    }
    
    set end_time_formatted(value) {
        if(!value)
            return;

        const indexColon = value.indexOf(":");
        this.end_hour = value.substr(0, indexColon);
        this.end_minute = value.substr(indexColon + 1);
    }

    validate() {
        // End time is required
        if(!this.end_time_formatted)
            return false;

        // End time must be after start time
        if(moment(this.end_time_formatted, "H:mm") <= moment(this.start_time_formatted, "H:mm"))
            return false;

        return true;
    }
}