<script lang="ts">
    // ref: https://medium.com/js-dojo/vue-js-manage-your-modal-window-s-effortlessly-using-eventbus-518977195eed
    import $ from 'jquery';
    import ModalPopup from "./modal-popup.vue";
    import Vue from 'vue';
    
    export default Vue.extend({
        name: "ModalPopupRoot",
        components: { ModalPopup },
        data() {
            return {
                component: null as Vue|null,
                title: "",
                isOpen: false,
                isLarge: false,         // is a large modal dialog (extra width, implements modal-lg
                customClass: '',
                showClose: true,        // set to false to hide the close "X" in the title header
                props: null,
                events: null,
                closeTrigger: false,     // setting to true will initiate the leaving transition
                anotherModalOpen: false  // we detect if another modal is open (likely the slide modal)
                                         // detection is determined by the presence of modal-open class
            };
        },
        created() {
            this.$modalBus.$on("modal-popup-open", (options: IModalPopupOptions) => {

                this.component = options.component;
                this.title = options.title;
                this.isLarge = options.isLarge ?? false;
                this.customClass = options.customClass ?? '';
                this.showClose = options.showClose ?? true;
                this.props = options.props;
                this.events = options.events;

                // set to false so the modal can be redisplayed (vue is too efficient!)
                this.closeTrigger = false;

                // do a dodgy DOM hack for now, while we're relying on the bootstrap modal
                this.anotherModalOpen = $('body').hasClass('modal-open');
                if (!this.anotherModalOpen) {
                    const bodyElement = document.getElementsByTagName('body')[0];
                    bodyElement.classList.add("modal-open");
                }
            });
            
            // allow closing of the modal from anywhere
            this.$modalBus.$on("modal-popup-close", () => {  
                // when called, trigger the leaving transition which upon completion will trigger a complete closure of the modal
                this.handleCloseViaTransition();
            })

            this.$modalBus.$on("modal-popup-close-forced", () => {  
                // ensure we close the popup AND remove the modal-open class
                this.handleCloseForced();
            })

            this.$modalBus.$on("modal-popup-scroll-to-top", () => {
                 this.handleScrollToTop();
            })
        },
        methods: {
            handleCloseForced() {
                // close the popup modal and foce the removal of class 'modal-open'
                this.anotherModalOpen = false;
                this.handleCloseViaTransition();
            },
            handleCloseViaTransition() {
                // initiates the modal closure via the leave transition
                // upon completion of the transition handleClose() is executed via the afterLeave hook
                // see: 'Modal.afterLeave()'
                this.closeTrigger = true;
            },
            handleClose() {
                // close the modal entirely
                // reset the scroll first, ready for next time
                this.handleScrollToTop();
                
                // set the component to null which changes isOpen prop for the Modal
                // this is what completely closes the modal
                this.component = null;

                // do a dodgy DOM hack for now, while we're relying on the bootstrap modal
                if (!this.anotherModalOpen) {
                    const bodyElement = document.getElementsByTagName('body')[0];
                    bodyElement.classList.remove("modal-open");
                }
            },
            handleScrollToTop() {
                const modalElement = document.getElementById("SitePopupModal");
                modalElement?.scrollTo(0, 0);
            }
        }
    });
</script>

<template>
    <ModalPopup  :isOpen="!!component" 
                 :title="title"             
                 :isLarge="isLarge"
                 :showClose="showClose"
                 :customClass="customClass" 
                 :closeTrigger="closeTrigger"
                 @modal-popup-close-commence="handleCloseViaTransition"
                 @modal-popup-close-complete="handleClose">

        <component  v-bind:is="component" 
                    @modal-popup-close-commence="handleCloseViaTransition" 
                    @modal-popup-close-complete="handleClose"                    
                    v-bind="{ ...props }" 
                    v-on="events" />
    </ModalPopup>
</template>
