import { Court } from "@/models/club";
import { Coach } from "@/models/coach";
import { Event } from '.';

export class EventMapBody { 
    
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;
        this.start = data && data.start ? data.start : undefined;
        this.end = data && data.end ? data.end : undefined;
        this.event = data && data.event ? new Event(data.event) : undefined;
        this.cancelled = data && data.cancelled !== undefined ? data.cancelled : false;

        this.number = data && data.number ? data.number : undefined; 
        this.coach_set = [];
        if(data && data.coach_set) {
			this.coach_set = data.coach_set.map((item) => new Coach(item));
		}

        this.court_set = [];
        if(data && data.court_set) {
            this.court_set = data.court_set.map((item) => new Court(item));
        }

        this.num_participants = data && data.num_participants ? data.num_participants : undefined; 
        this.max_participants = data && data.max_participants ? data.max_participants : undefined; 
        this.participant_names = [];
        if(data && data.participant_names) {
			this.participant_names = data.participant_names.map((item) => item);
		}
    }

    getParticpantNamesForExport() {
        return this.participant_names?.join('|');
    }

    getCoachNamesForExport() {
        const coachNames = this.coach_set?.map(c => c.player.full_name);
        return coachNames.join('|');
    }

    getCourtNamesForExport() {
        const courtNames = this.court_set?.map(c => c.name);
        return courtNames.join('|');
    }
}