import { ClubMember } from "@/models/club/";
import { EventSession } from '.';
import { Player } from "@/models/player";

export class EventWaitlist {
     
    constructor(data) {       

        this.id = data && data.id ? data.id : undefined;
        this.creator = data && data.creator ? new Player(data.creator) : null;
        this.status = data && data.status ? data.status : ''; 
        this.order = data && data.order ? data.order : undefined; 
        this.participant_player = data && data.participant_player ? new Player(data.participant_player) : null;
        this.participant_member = data && data.participant_member ? new ClubMember(data.participant_member) : null;
        this.participant_name = data && data.participant_name ? data.participant_name : '';
        this.participant_email = data && data.participant_email ? data.participant_email : '';
        this.participant_number = data && data.participant_number ? data.participant_number : '';
        this.participant_gender = data && data.participant_gender ? data.participant_gender : '';
        this.participant_dob = data && data.participant_dob ? data.participant_dob : '';
        this.paying_player = data && data.paying_player ? new Player(data.paying_player) : null;
        this.club_customer = data && data.club_customer !== undefined ? data.club_customer : undefined; 
        this.session = data && data.session ? new EventSession(data.session) : null;
        this.event_name = data && data.event_name ? data.event_name : '';
        this.notice = data && data.notice ? data.notice : undefined;
        this.created = data && data.created ? data.created : undefined;
        this.modified = data && data.modified ? data.modified : undefined;
    }
}