<script lang="ts">
    import { bootstrapPopover } from '@/directives/bootstrap-popover';
    import { ValidationProvider } from "vee-validate";
    import Vue from 'vue';

    export default Vue.extend({
        name: "SelectInput",
        components: {
            ValidationProvider
        },
        directives: {
            bootstrapPopover
        },
        props: {
            value: {
                type: [String, Number, Array],
                default: ""
            },
            options: {
                type: Array,
                default: () => []
            },
            optionsTextField: {
                type: String,
                required: false
            },
            optionsValueField: {
                type: String,
                required: false
            },
            caption: {
                type: String,
                default: "--Select--"
            },
            rules: {
                type: [String, Object],
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            helpText: {
                type: String,
                default: ""
            },
            popoverText: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            },
            formGroupClasses: {
                type: Array,
                default: () => [],
            },
            inputClasses: {
                type: Array,
                default: () => [],
            },
            name: {
                type: String,
                default: ""
            },
            vid: {
                type: String,
                default: undefined
            },
            showRequiredLabel: {
                type: Boolean,
                required: false,
                default: true
            },
            multiple: {
                type: Boolean,
                required: false,
                default: false
            },
            size: {
                type: String,
                required: false,
                default: ""
            },
        },
        data () {
            return {
                currentValue: ""
            }
        },
        watch: {
            value: {
                handler(val) {
                    if(typeof val == "number")
                        this.currentValue = val.toString();
                    else
                        this.currentValue = val;
                },
                //this makes it run the handler function on mount in addition to whenever the value changes
                immediate:true
            },
            currentValue(val) {
                // allows us to use v-model on our input.
                if(typeof this.value == "number") {
                    if(val === '')
                        this.$emit("input", '');
                    else
                        this.$emit("input", Number(val));
                }
                else {
                    this.$emit("input", val);
                }
            }
        },
        methods: {
            getOptionValue(option) {
                if(this.optionsValueField)
                    return option[this.optionsValueField];
                else if(option.value)
                    return option.value
    
                return option;
            },
            getOptionText(option) {
                if(this.optionsTextField)
                    return option[this.optionsTextField];
                else if(option.text)
                    return option.text;
                else
                    return option;
            },
        }
    });
</script>

<template>
    <ValidationProvider
        slim
        :rules="rules"
        :name="name || label"
        :vid="vid"
        v-slot="{ errors, required, showRequiredLabel, ariaInput, classes }">
        <div class="form-group select-wrapper" :class="[formGroupClasses, classes]">
            <label class="control-label" :for="name" v-if="label">
                <span>{{ label || name }}</span>
                <span class="helper">{{ required && showRequiredLabel ? ' (required)' : '' }}</span>
            </label>
            <span v-if="helpText" class="help-block">{{ helpText }}</span>
            <select 
                class="form-control"
                :class="inputClasses"
                :multiple="multiple"
                :size="size"
                :disabled="disabled"
                v-model="currentValue"
                v-bind="ariaInput"
                v-bootstrap-popover="{ placement: 'right', trigger: 'hover focus', content: popoverText}">
                <option v-if="caption" value="">{{ caption }}</option>
                <option v-for="option in options" v-bind:key="getOptionValue(option)" v-bind:value="getOptionValue(option)">
                    {{ getOptionText(option) }}
                </option>
            </select>
            
            <span class="help-block" v-if="errors[0]">
                {{ errors[0] }}
            </span>
        </div>
    </ValidationProvider>
</template>
