import { MatchDefinition } from "./match-definition";
import TournamentLookups from '@/utils/tournament-lookups';

export class TournamentParams {

    constructor(data) {

        this.Type = data && data.Type ? data.Type : undefined;
        this.FixtureType = data && data.FixtureType ? data.FixtureType : undefined;
        this.PlayerGender = data && data.PlayerGender ? data.PlayerGender : undefined;
        this.Surface = data && data.Surface ? data.Surface : undefined;
        this.Country = data && data.Country ? data.Country : undefined;
        
        this.Deuce = data && data.Deuce ? data.Deuce : undefined;
        this.SetsUpTo = data && data.SetsUpTo ? data.SetsUpTo  : undefined;
        this.TieBreaker = data && data.TieBreaker ? data.TieBreaker : undefined;
        this.TieBreakerOther = data && data.TieBreakerOther ? data.TieBreakerOther : undefined;
        
        this.PointsPerWin = data && data.PointsPerWin !== undefined ? data.PointsPerWin : undefined;
        this.BonusPointsPerRound = data && data.BonusPointsPerRound !== undefined ? data.BonusPointsPerRound : undefined;
        this.CancelledPoints = data && data.CancelledPoints !== undefined ? data.CancelledPoints : undefined;
        this.BonusPointsWhenCancelledOptions = data && data.BonusPointsWhenCancelledOptions ? data.BonusPointsWhenCancelledOptions : "clearWinner";
        this.TeamBonusForDraw = data && data.TeamBonusForDraw ? data.TeamBonusForDraw : undefined; 
        this.TimePlayed = data && data.TimePlayed ? data.TimePlayed : undefined;
        this.DaysOfWeek = data && data.DaysOfWeek ? data.DaysOfWeek : [];

        this.DatesNotPlayed = data && data.DatesNotPlayed ? data.DatesNotPlayed : [];
        
        this.PlayFrequency = data && data.PlayFrequency ? data.PlayFrequency : undefined;

        this.NumberOfCourts = data && data.NumberOfCourts ? data.NumberOfCourts : undefined; 
        this.NumberOfSpareCourts = data && data.NumberOfSpareCourts ? data.NumberOfSpareCourts : undefined;

        this.NumberOfRounds= data && data.NumberOfRounds ? data.NumberOfRounds : undefined; 
        this.NumberOfMatchesPerRound = data && data.NumberOfMatchesPerRound ? data.NumberOfMatchesPerRound : undefined;

        this.NumberOfTeams = data && data.NumberOfTeams ? data.NumberOfTeams : undefined;
        this.NumberOfTeamsKnockout = data && data.NumberOfTeamsKnockout ? data.NumberOfTeamsKnockout : undefined;

        this.NumberOfPlayersPerTeam = data && data.NumberOfPlayersPerTeam ? data.NumberOfPlayersPerTeam : undefined;
        this.NumberOfMenPerTeam = data && data.NumberOfMenPerTeam ? data.NumberOfMenPerTeam : undefined;
        this.NumberOfWomenPerTeam = data && data.NumberOfWomenPerTeam ? data.NumberOfWomenPerTeam : undefined;


        this.match_sets = [];
        if(data && data.match_sets) {
			this.match_sets = data.match_sets.map((item) => new MatchDefinition(item));
        }
        
        this.FillinSameRank = data && data.FillinSameRank ? data.FillinSameRank : false;
        
        this.NumPlayerPointsSinglesMatch = data && data.NumPlayerPointsSinglesMatch ? data.NumPlayerPointsSinglesMatch : undefined;
        this.NumPlayerPointsDoublesMatch = data && data.NumPlayerPointsDoublesMatch ? data.NumPlayerPointsDoublesMatch : undefined;
    
        // B. Squire - 20/05/2016 - change to use an array for other clubs, similarly to how done for known clubs, and move to be a param
        this.club_unknown_set = data && data.club_unknown_set ? data.club_unknown_set : []

        this.PlayerLadderOrder = [];
        if(data && data.PlayerLadderOrder && data.PlayerLadderOrder.length > 0) {
            this.PlayerLadderOrder = data.PlayerLadderOrder;
        }
        else {
            // initialise
            TournamentLookups.playerLadderSortOrderOptions.map((item) => {
                this.PlayerLadderOrder.push(item.value);
            });
        }

        this.TeamLadderOrder = [];
        if(data && data.TeamLadderOrder && data.TeamLadderOrder.length > 0) {
            this.TeamLadderOrder = data.TeamLadderOrder;
        }
        else {  
            // initialise
            TournamentLookups.teamLadderSortOrderOptions.map((item) => {
                this.TeamLadderOrder.push(item.value);
            });
        }
    }

    get match_sets_imbalanced() {
        const imbalancedMatches = [];
        for(let m = 0; m < this.match_sets.length; m++)
        {
            if(this.match_sets[m].MatchPlayers1.join() != this.match_sets[m].MatchPlayers2.join())
                imbalancedMatches[imbalancedMatches.length] = m;
        }
        return imbalancedMatches; // return an array of all matches which are imbalanced
    }

    get match_sets_includes_singles() {
        const firstSinglesMatch = this.match_sets.find((item) => item.MatchPlayFormat == "singles");
        
        return firstSinglesMatch != undefined;
    }
    
    get match_sets_includes_doubles() {
        const firstDoublesMatch = this.match_sets.find((item) => item.MatchPlayFormat == "doubles" || item.MatchPlayFormat == "mixeddoubles");
        
        return firstDoublesMatch != undefined;
    }

    get match_sets_total_singles() {
        const allSingles = this.match_sets.filter((item) => item.MatchPlayFormat == "singles");
        return allSingles.length;
    }
    
    get match_sets_total_doubles() {
        const allDoubles = this.match_sets.filter((item) => item.MatchPlayFormat == "doubles" || item.MatchPlayFormat == "mixeddoubles");
        return allDoubles.length;		
    }
    
    get match_sets_summary() {
        return `Total Matches: ${this.match_sets.length} (Total Singles: ${this.match_sets_total_singles}, Total Doubles: ${this.match_sets_total_doubles})`;
    }
    
    get fillinSameRankStr() {
        return this.FillinSameRank == undefined ? "" : this.FillinSameRank.toString();
    }

    set fillinSameRankStr(value) {
        if(value == "true")
            this.FillinSameRank = true;
        else if(value == "false")
            this.FillinSameRank = false;
        else
            this.FillinSameRank = undefined;
    }

    validate() {
        if(!this.Type)
            return false;

        if(!this.FixtureType)
            return true;

        if(!this.PlayerGender)
            return true;

        if(this.FixtureType == "InterClub" && !this.Surface)
            return false;

        if(!this.Country)
            return false;

        if(!this.Deuce)
            return false;

        if(!this.SetsUpTo)
            return false;

        if(!this.TieBreaker)
            return false;

        if(this.TieBreaker == "other" && !this.TieBreakerOther)
            return false;

        if(!this.PointsPerWin)
            return false;

        if(!this.BonusPointsPerRound)
            return false;

        if(!this.CancelledPoints)
            return false;

        if(!this.BonusPointsWhenCancelledOptions)
            return false;

        if(this.BonusPointsPerRound != 0 && !this.TeamBonusForDraw)
            return false;

        if(!this.TimePlayed)
            return false;

        if(!this.DaysOfWeek || this.DaysOfWeek.length == 0)
            return false;

        // Check that only one day of the week is selected (the phone app requires an array for this, so we need to use checkboxes.  Maybe in the future could change to radio and have
        // and array created from the selected single-value for sending to backend)
        if(this.DaysOfWeek.length > 1)
            return false;

        if(!this.PlayFrequency)
            return false;        

        if(!this.NumberOfCourts || isNaN(this.NumberOfCourts))
            return false;        

        if(!this.NumberOfSpareCourts || isNaN(this.NumberOfSpareCourts))
            return false;        

        if(!this.NumberOfRounds || isNaN(this.NumberOfRounds))
            return false;        

        if(!this.NumberOfMatchesPerRound || isNaN(this.NumberOfMatchesPerRound))
            return false;        

        if(!this.NumberOfTeams || isNaN(this.NumberOfTeams))
            return false;

        if(this.NumberOfTeams < this.NumberOfMatchesPerRound * 2)   // Number of teams must be larger than or equal to the number of matches multiplied by 2
            return false;

        if(this.Type == "roundrobinknockout" || this.Type == "knockout") {          // Must specify Number of Teams in Knockout
            if(!this.NumberOfTeamsKnockout || isNaN(this.NumberOfTeamsKnockout))
                return false;
        }

        if(this.PlayerGender == "mixed") {                                          // Number of Men Per Team is required and must be numeric
            if(!this.NumberOfMenPerTeam || isNaN(this.NumberOfMenPerTeam))
                return false;
        }

        if(this.PlayerGender == "mixed") {                                          // Number of Women Per Team is required and must be numeric
            if(!this.NumberOfWomenPerTeam || isNaN(this.NumberOfWomenPerTeam))
                return false;
        }

        if(!this.match_sets || this.match_sets.length == 0)
            return false;

        if(!this.FillinSameRank)
            return false;

        if(this.FillinSameRank && (!this.match_sets || this.match_sets.length == 0 || this.match_sets_includes_singles)) {
            if(!this.NumPlayerPointsSinglesMatch)
                return false;
        }
        
        if(this.FillinSameRank && (!this.match_sets || this.match_sets.length == 0 || this.match_sets_includes_doubles)) {
            if(!this.NumPlayerPointsDoublesMatch)
                return false;
        }

        if(!this.club_unknown_set || this.club_unknown_set.length == 0)
            return false;

        return true;
    }
}