import { Comment } from '.';
import { MessageGroup } from '.';
import { Player } from '../player';

export class Thread {
	constructor(data) {
	
		this.id = data.id;
		this.player = data && data.player ? new Player(data.player) : null;
		this.subject = data && data.subject ? data.subject : "";
        this.stamp = data && data.stamp ? data.stamp : undefined;
		this.last_activity = data && data.last_activity ? data.last_activity : undefined;

		this.player_set = data && data.player_set ? data.player_set : undefined;
        
		// comments
        this.comment_set = [];
		if(data && data.comment_set) {
			this.comment_set = data.comment_set.map((item) => new Comment(item) );
		}

		// message groups
		this.group_set = [];
		if(data && data.group_set) {
			this.group_set = data.group_set.map((item) => new MessageGroup(item) );
		}
	}
}