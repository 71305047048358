export class ClubPackageFeature {

	constructor(data) {
		this.id = data.id;
		this.name = data && data.name ? data.name : undefined;
		this.desc = data && data.desc ? data.desc : undefined;
		this.detail = data && data.detail ? data.detail : undefined;
		this.cost_text = data && data.cost_text ? data.cost_text : undefined;
		this.image_url = data && data.image_url ? data.image_url : undefined;
	}
}
