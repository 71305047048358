import { Club } from "@/models/club";
import { Player } from '.';

export class Coach {
	constructor(data) {

		this.id = data && data.id ? data.id : undefined;
		this.player = data && data.player ? new Player(data.player) : null;
		this.ttype = data && data.ttype ? data.ttype : "C" ;

		this.coach_clubs = [];
		if(data && data.coach_clubs) {
			this.coach_clubs = data.coach_clubs.map((item) => new Club(item) );
		}
	}

	get full_name() {
		if(!this.player) {
			return "";
		}
		return this.player.first_name + " " + this.player.last_name;
	}
}
