import { ClubPackageFeature } from '.';

export class ClubFeatureMembershipParams {
    constructor(data) {
        this.AnnualCycleEnabled = data && data.AnnualCycleEnabled !== undefined ? data.AnnualCycleEnabled : false;
        this.MonthlyCycleEnabled = data && data.MonthlyCycleEnabled !== undefined ? data.MonthlyCycleEnabled : false;
        
        this.DefaultCycle = data && data.DefaultCycle ? data.DefaultCycle : "A";
        
        this.SingleRolloverDate = data && data.SingleRolloverDate !== undefined ? data.SingleRolloverDate : true;
        // this.SingleRolloverDate.ForEditing = ko.computed({
        //     read: function() {
        //         return this.SingleRolloverDate().toString();  
        //     },
        //     write: function(newValue) {
        //         this.SingleRolloverDate(newValue === "true");
        //     },
        //     owner: this        
        // });          
        
        this.monthlyRolloverDay = data && data.monthlyRolloverDay ? data.monthlyRolloverDay : undefined;

        this.annualRolloverDate = data && data.annualRolloverDate ? data.annualRolloverDate : undefined;

        this.membershipRenewal = data && data.membershipRenewal ? data.membershipRenewal : "forced";
    }

    validate() {
        if(!this.membershipRenewal)
            return false;

        return true;
    }

    get defaultCycleIsAnnual() {
		return this.DefaultCycle == "A";
    }

	get defaultCycleIsMonthly() {
		return this.DefaultCycle == "M";
    }
}

export class ClubFeatureMatchaParams {
    constructor(data) {

        this.matchaMembersOnly = data && data.matchaMembersOnly !== undefined ? data.matchaMembersOnly : true;
        this.matchaNonMemberCanRequest = data && data.matchaNonMemberCanRequest !== undefined ? data.matchaNonMemberCanRequest : false;
        this.matchaNonMemberCanAccept = data && data.matchaNonMemberCanAccept !== undefined ? data.matchaNonMemberCanAccept : false;
    }
}

export class ClubFeatureCourtBookingsParams {
    constructor(data) {
        this.AvailableSurfaceTypes = data && data.AvailableSurfaceTypes !== undefined ? data.AvailableSurfaceTypes : "";

        this.AllCourtsLights = data && data.AllCourtsLights !== undefined ? data.AllCourtsLights : false;
        this.AllCourtsAvailable = data && data.AllCourtsAvailable !== undefined ? data.AllCourtsAvailable : false;
        this.AllCourtsIndoor = data && data.AllCourtsIndoor !== undefined ? data.AllCourtsIndoor : false;

        this.AllCourtsAccessSame = data && data.AllCourtsAccessSame !== undefined ? data.AllCourtsAccessSame : false;
        this.AllCourtsAccessText = data && data.AllCourtsAccessText !== undefined ? data.AllCourtsAccessText : "";
        
        this.AllCourtsNotesRulesSame = data && data.AllCourtsNotesRulesSame !== undefined ? data.AllCourtsNotesRulesSame : false;
        this.AllCourtsNotesRulesText = data && data.AllCourtsNotesRulesText !== undefined ? data.AllCourtsNotesRulesText : "";

        this.MaxBookingMinutesPerDayMember = data && data.MaxBookingMinutesPerDayMember !== undefined ? data.MaxBookingMinutesPerDayMember : undefined;
        this.MaxBookingMinutesPerDayNonMember = data && data.MaxBookingMinutesPerDayNonMember !== undefined ? data.MaxBookingMinutesPerDayNonMember : undefined;
    }
}

export class ClubFeaturePointOfSaleParams {
    constructor(data) {
        this.DefaultTax = data && data.DefaultTax !== undefined ? data.DefaultTax : 0;
        this.TaxInclusivePrice = data && data.TaxInclusivePrice !== undefined ? data.TaxInclusivePrice : true;
    }
}

export class ClubFeatureClinicParams {
    constructor(data) {
        this.sessionDurationMins = data && data.sessionDurationMins !== undefined ? data.sessionDurationMins : 30;
        this.allowNonMembers = data && data.allowNonMembers !== undefined ? data.allowNonMembers : false;
        this.AllowSelfCheckin = data && data.AllowSelfCheckin !== undefined ? data.AllowSelfCheckin : false;
        this.AdvanceCheckinHours = data && data.AdvanceCheckinHours !== undefined ? data.AdvanceCheckinHours : 1;
    }
}

export class ClubFeature {
    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? data.club : undefined;
        this.enabled = data && data.enabled !== undefined ? data.enabled : false;
    
        this.feature = data && data.feature ? new ClubPackageFeature(data.feature) : undefined;

        this.params = null;
        if(data && data.feature) {
            switch(data.feature.name) {
                case "membership":
                    this.params = new ClubFeatureMembershipParams(data.params);
                    break;
                case "matcha":
                    this.params = new ClubFeatureMatchaParams(data.params);
                    break;
                case "courtbooking":
                    this.params = new ClubFeatureCourtBookingsParams(data.params);
                    break;
                case "pointofsale":
                    this.params = new ClubFeaturePointOfSaleParams(data.params);
                    break;
                case "clinics":
                    this.params = new ClubFeatureClinicParams(data.params);
                    break;
                default:
                    break;
            }
        }
        // this.paramsExistInDB = kodata && data.params && Object.keys(data.params).length > 0 ? true : false;	/* client-side only field for determinig if the params have been stored in the DB yet, or if they are just being set to defaults  */
    }
}

