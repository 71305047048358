const TransactionStatusEnum = {
    P: 'Successful',
    F: 'Failed',
    R: 'Refunded',
    A: 'Requires Authorisation',
    S: 'Processing',
    D: 'Cancelled',
    U: 'Unknown'
};

export default TransactionStatusEnum