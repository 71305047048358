import { Player } from '..';

export class ClubAdmin {
    constructor(data) {
        this.id = data && data.id ? data.id : undefined;
        this.club = data && data.club ? data.club : undefined;
        this.player = data && data.player ? new Player(data.player) : null;
        this.role = data && data.role ? data.role : [] ;
    }

    get full_name() {
        if(!this.player) {
            return "";
        }
        return this.player.first_name + " " + this.player.last_name;
    }
}
