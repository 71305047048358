import { apiClient } from "@/utils/apiclient";
import { Event, EventMembershipTypeRate } from "@/models/event/event";
import { IGetEventsActionPayload, IDeleteClubEventPayload, IClubEventPayload, IClubEventMembershipTypeRatePayload, IDeleteClubEventMembershipTypeRatePayload, IClubEventRegistrationPayload } from './events.types';

class State {
    events: Event[] = [];
    nextEventsURL = "";
    prevEventsURL = "";
    eventsTotal = 0;
    eventsCurrentPage = 1;
}

export default {
    strict: process.env.NODE_ENV !== "production",
    namespaced: true,
    state: new State(),
    mutations: {

        getEvents(state: State, events) {
            state.events = events;
        },

        getNextPageURL(state: State, next) {
            state.nextEventsURL = next;
        },

        getPrevPageURL(state: State, prev) {
            state.prevEventsURL = prev;
        },

        nextPage(state: State) {
            state.eventsCurrentPage++;
        },

        prevPage(state: State) {
            state.eventsCurrentPage--;
        },

        resetCurrentPage(state: State) {
            state.eventsCurrentPage = 1;
        },

        getTotal(state: State, total) {
            state.eventsTotal = total;
        },

        deleteEvent(state, payload: IDeleteClubEventPayload) {
            state.events = [...state.events.filter(c => c.id !== payload.eventId)];
            state.eventsTotal--;
        },

        updateEvent(state, payload: IClubEventPayload) {
            const index = state.events.findIndex(c => c.id === payload.event.id);
            state.events.splice(index, 1, payload.event);
            state.events = [...state.events];
        },

        addEvent(state, payload: IClubEventPayload) {
            state.events.push(payload.event);
            state.eventsTotal++;
        },

        addOrUpdateEventMembershipTypeRate(state, payload: IClubEventMembershipTypeRatePayload) {
            const index = state.events.findIndex(c => c.id === payload.eventTypeRate.event);
            if (index == -1) return;

            const event = state.events[index];
            // find the membership type rate if it exists
            const rateIndex = event.membershiptype_rate_set.findIndex(c => c.id === payload.eventTypeRate.id);
            if (rateIndex == -1) {
                // add the new type rate
                event.membershiptype_rate_set.push(payload.eventTypeRate);
            }
            else {
                // update the type rate
                event.membershiptype_rate_set.splice(rateIndex, 1, payload.eventTypeRate);
                event.membershiptype_rate_set = [...event.membershiptype_rate_set];
            }

            state.events.splice(index, 1, event);
            state.events = [...state.events];
        },

        deleteEventMembershipTypeRate(state, payload: IDeleteClubEventMembershipTypeRatePayload) {
            const index = state.events.findIndex(c => c.id === payload.eventId);
            if (index == -1) return;

            const event = state.events[index];
            event.membershiptype_rate_set = [...event.membershiptype_rate_set.filter(c => c.id !== payload.eventTypeRateId)];

            state.events.splice(index, 1, event);
            state.events = [...state.events];
        },
    },
    getters: {
        event: (state) => (eventId) => {
            return state.events.find((item) => { return item.id === eventId });
        },
        events: (state: State) => state.events,
        eventsTotal: (state: State) => state.eventsTotal,
        hasEventsNextPage: (state: State) => (state.nextEventsURL && state.nextEventsURL.length > 0) ? true : false,
        hasEventsPrevPage: (state: State) => (state.prevEventsURL && state.prevEventsURL.length > 0) ? true : false,
        eventsCurrentPage: (state: State) => state.eventsCurrentPage
    },
    actions: {
        getEventsAction({ commit }, payload: IGetEventsActionPayload): Promise<any> {
            return apiClient.clubEvents(payload.clubId, 20, payload.fromDate, payload.toDate, payload.status, payload.searchWord, payload.type)
                .then(result => {
                    
                    const results = result.results.map(
                        (item: any) => new Event(item)
                    );
                    commit("getEvents", results);
                    commit("getNextPageURL", result.next);
                    commit("getPrevPageURL", result.previous);
                    commit("getTotal", result.count);

                    return results;
                });
        },

        getNextPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.nextEventsURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Event(item)
                    );
                    commit("getEvents", results);
                    commit("getNextPageURL", result.next);
                    commit("getPrevPageURL", result.previous);
                    commit("getTotal", result.count);
                    commit("nextPage");

                    return results;
                })
        },

        getPrevPageAction({ commit, state }): Promise<any> {
            return apiClient.dynamicGet(state.prevEventsURL)
                .then(result => {
                    const results = result.results.map(
                        (item: any) => new Event(item)
                    );
                    commit("getEvents", results);
                    commit("getNextPageURL", result.next);
                    commit("getPrevPageURL", result.previous);
                    commit("getTotal", result.count);
                    commit("prevPage");

                    return results;
                })
        },

        addClubEventAction({ commit }, payload: IClubEventPayload): Promise<Event> {

            if(!payload.event.validate())
                return Promise.reject(new Error("Not enough data"));

            return apiClient.addClubEvent(payload.event)
                    .then((event) => {
                        commit("addEvent", { "event": event });
                        return event;
                    });
        },

        updateClubEventAction({ commit }, payload: IClubEventPayload): Promise<Event> {

            if(!payload.event.validate())
                return Promise.reject(new Error("Not enough data"));

            return apiClient.updateClubEvent(payload.event)
                    .then((event) => {
                        commit("updateEvent", { "event": event });
                        return event;
                    });
        },   
        
        getClubEventAction({ commit }, payload: IClubEventPayload): Promise<Event> {
            return apiClient.clubEvent(payload.event.id)
                    .then((event) => {
                        commit("updateEvent", { "event": event });
                        return event;
                    });
        }, 

        publishClubEventAction({ commit }, payload: IClubEventPayload): Promise<Event> {
            return apiClient.publishClubEvent(payload.event)
                    .then((event) => {
                        commit("updateEvent", { "event": event });
                        return event;
                    });
        },

        unpublishClubEventAction({ commit }, payload: IClubEventPayload): Promise<Event> {
            return apiClient.unpublishClubEvent(payload.event)
                    .then((event) => {
                        commit("updateEvent", { "event": event });
                        return event;
                    });
        },

        cancelClubEventAction({ commit }, payload: IClubEventPayload): Promise<Event> {
            return apiClient.cancelClubEvent(payload.event)
                    .then((event) => {
                        commit("updateEvent", { "event": event });
                        return event;
                    });
        },

        deleteClubEventAction({ commit }, payload: IDeleteClubEventPayload): Promise<void> {
            return apiClient.deleteClubEvent(payload.eventId)
                .then(() => {
                    commit("deleteEvent", { "eventId": payload.eventId });
                    return;
            });
        },

        addClubEventMembershipTypeRateAction({ commit }, payload: IClubEventMembershipTypeRatePayload): Promise<EventMembershipTypeRate> {

            if(!payload.eventTypeRate.validate())
                return Promise.reject(new Error("Invalid membership type rate"));

            return apiClient.addClubEventMembershipTypeRate(payload.eventTypeRate)
                .then((typeRate) => {
                    commit("addOrUpdateEventMembershipTypeRate", { "eventTypeRate": typeRate });
                    return typeRate;
                });
        },

        updateClubEventMembershipTypeRateAction({ commit }, payload: IClubEventMembershipTypeRatePayload): Promise<EventMembershipTypeRate> {

            if(!payload.eventTypeRate.validate())
            return Promise.reject(new Error("Invalid membership type rate"));

            return apiClient.updateClubEventMembershipTypeRate(payload.eventTypeRate)
                .then((typeRate) => {
                    commit("addOrUpdateEventMembershipTypeRate", { "eventTypeRate": typeRate });
                    return typeRate;
                });
        }, 

        deleteClubEventMembershipTypeRateAction({ commit }, payload: IDeleteClubEventMembershipTypeRatePayload): Promise<void> {
            return apiClient.deleteClubEventMembershipTypeRate(payload.eventTypeRateId)
                .then(() => {
                    commit("deleteEventMembershipTypeRate", { "eventId": payload.eventId, "eventTypeRateId": payload.eventTypeRateId });
                    return;
            });
        },

        openCloseRegistrationForClubEventAction({ commit }, payload: IClubEventRegistrationPayload): Promise<Event> {
            return apiClient.openCloseRegistrationForClubEvent(payload.eventId, payload.isOpenForMembers, payload.isOpenForNonMembers)
                    .then((event) => {
                        commit("updateEvent", { "event": event });
                        return event;
                    });
        }
    },
    modules: {}
};
