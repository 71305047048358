<script lang="ts">
    import { mapActions, mapGetters } from "vuex";
    import $ from 'jquery';
    import AlertConfirmDialog from '@/components/alert-confirm/alert-confirm-dialog.vue';
    import DashboardLayout from "@/components/dashboard/dashboard-layout.vue";
    import HeaderMarketing from "@/components/header-marketing.vue";
    import LoadingIndicator from '@/components/loading-indicator.vue';
    import ModalPopupRoot from '@/components/modal/modal-popup-root.vue';
    import ModalSlideRoot from '@/components/modal/modal-slide-root.vue';
    import Vue from 'vue';

    export default Vue.extend({
        name: "app",
        components: {
            "header-marketing": HeaderMarketing,
            "dashboard-layout": DashboardLayout,            
            "modal-slide-root": ModalSlideRoot,
            "modal-popup-root": ModalPopupRoot,
            "alert-confirm-dialog": AlertConfirmDialog,
            "loading-indicator": LoadingIndicator
        },
        computed: {
            ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                currentPlayer: 'currentPlayer/currentPlayer'
            })
        },
        methods: {
            ...mapActions('currentPlayer', [
                'getCurrentPlayerAction'
            ]),
            ...mapActions([
                'getTennisSportIdAction',
                'getCountriesAction',
                'getTennisSurfacesAction',
                'getSportsAction'
            ]),
        },
        created() {
            // initialise the store!
            // pass 'true' to enforce a reload of the current player. 
            // Eventually we should just do this based on the staleness of the data
            this.getCurrentPlayerAction(true);          
            this.getTennisSportIdAction()
                .then(() => {
                    // relies on tennis sport ID having been loaded
                    this.getTennisSurfacesAction();         
                })
            this.getCountriesAction();
            this.getSportsAction(true);
        }
    });

    $(function() {
        // used to adjust the menu height for the public site
        const menuHeight = $("#nav-container").outerHeight(true);
        $("#header-container-space").css("height", String(menuHeight));
    });
</script>

<template>
    <!-- Body Container (Used for sticky footer) -->
    <div id="app">   

        <!-- not logged in -->
        <div v-if="!isLoggedIn" class="content-container">
        
            <header-marketing ></header-marketing>
            <div id="header-container-space"></div>   

            <div id="site-body">
                <router-view v-if="!isLoggedIn || currentPlayer" />
                <div v-else id="main" class="site-container container">
                    ... Please wait while we load a few things
                </div>
            </div>

            <div id="footer">
                <div class="site-container" id="download-anchor">
                    <div id="download-mini-section">
                        <div class="text">
                            <p>
                                <b>Download Str8 Sets</b> to your device today
                            </p>
                        </div>
                        <div class="image-left">
                            <a href="https://play.google.com/store/apps/details?id=com.elevatemobile.Str8SetsTennis&amp;hl=en" target="_blank" >
                                <img
                                    width="200px"
                                    height="auto"
                                    alt="Google Play"
                                    src="./assets/images/google-play-store.svg"
                                    data-no-retina />
                            </a>
                        </div>
                        <div class="image-right">
                            <a href="https://itunes.apple.com/au/app/str8-sets-tennis/id838192982" target="_blank" >
                                <img
                                    width="200px"
                                    height="auto"
                                    alt="Apple Store"
                                    src="./assets/images/apple-app-store.svg"
                                    data-no-retina />
                            </a>
                        </div>
                    </div>
                    <div id="copyright-text">
                        <p>&copy; Str8 Sets Pty Ltd | <router-link to="/privacy">Privacy</router-link> | <router-link to="/club-terms">Club Terms</router-link></p>
                    </div>
                </div>
            </div>
            
        </div>

        <!-- logged in -->
        <dashboard-layout v-if="isLoggedIn && currentPlayer">
        </dashboard-layout>

        <!-- Load other items -->
        <modal-slide-root></modal-slide-root>
        <modal-popup-root></modal-popup-root>
        <alert-confirm-dialog></alert-confirm-dialog>
        <loading-indicator></loading-indicator>
    </div>
</template>
