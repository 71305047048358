<script lang="ts">
    import { mapGetters } from 'vuex';    
    import $ from 'jquery';
    import Cookies from "js-cookie";
    import Utilities from '@/utils/utilities';
    import Vue from "vue";

    export default Vue.extend({ 
        name: "AuthBox",
        data() {
            return {
                username: "",
                password: "",
                loginError: "",
                loginExpanded: false,
            }
        },
        computed: {
            signupMobileText(): string {
                return this.loginExpanded ? "Hide Login Section" : "Sign into Str8 Sets | Sign up";
            },
            ...mapGetters({
                isLoggedIn: 'isLoggedIn',
                currentPlayer: 'currentPlayer/currentPlayer'
            })
        },
        methods: {
            login(): Promise<any> {
                this.loginError = "";
                
                let authTokenVal = "";

                const creds = {
                    username: this.username,
                    password: this.password
                };
        
                const loginRequest = new Request("/api/login/", {
                    method: "POST",
                    cache: "no-cache",
                    body: JSON.stringify(creds), // data can be `string` or {object}!
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json'
                    }
                });
        
                return fetch(loginRequest)
                    .then(Utilities.handleAPIErrors) 
                    .then(r => r.json().then(data => ({ status: r.status, body: data })))
                    .then(loginData => {
                        authTokenVal = loginData.body.token;
                        Cookies.set("authtoken", authTokenVal, {
                            expires: 7
                        });
        
                        // Now go and get the user's player object and store it
                        return fetch("/api/user/", {
                            method: "GET",
                            cache: "no-cache",
                            headers: {
                                Authorization: "Token " + authTokenVal,
                                "Content-Type": "application/json",
                                'Accept': 'application/json'
                            }
                        });
                    })
                    .then(Utilities.handleAPIErrors) 
                    .then(r => r.json().then(data => ({ status: r.status, body: data })))
                    .then(userData => {
                        localStorage.setItem(
                            "CurrentPlayer",
                            JSON.stringify(userData.body.results[0].player_set[0])
                        );
                    
                        // Next step, look up the sport object and store the ID in local storage
                        return fetch("/api/sport/?name=tennis", {
                            method: "GET",
                            cache: "no-cache",
                            headers: {
                                Authorization: "Token " + authTokenVal,
                                "Content-Type": "application/json",
                                'Accept': 'application/json'
                            }
                        });
                    })
                    .then(Utilities.handleAPIErrors) 
                    .then(r =>  r.json().then(data => ({ status: r.status, body: data })))
                    .then(sportData => {
                        localStorage.setItem("TennisSportId", sportData.body.results[0].id);
                        window.location.href = "/clubs"; // reload to show logged in status and load new data
                    })
                    .catch(() => {
                        this.loginError = "Login failed, please try again";
                    });
            },
            logout() {
                Cookies.remove("authtoken");
                localStorage.removeItem("CurrentPlayer");
                window.location.href = "/";
            },
            toggleSignupMobile() {
                $("#login-section").slideToggle();  // long term goal: remove jQuery here!
                this.loginExpanded = !this.loginExpanded;
            },
        }
    })
</script>

<style lang="scss" scoped>
    #auth {
        float: right;

        @media (max-width: 768px) {
            float: none;
        }
    }

    #login-section {
        margin-right: 10px; 	
        position:relative;
        font-family: 'Open Sans', sans-serif;
        padding:10px;
        background:#F4F4F4;
        border: #DADADA;
        border-width: 1px;
        border-style: solid;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px; /* future proofing */
        -khtml-border-radius: 4px; /* for old Konqueror browsers */

        @media (max-width: 768px) {
            margin-left: 10px;
            display: none;
        }

        .hidden {
            visibility: hidden;	
            display:none;
        }

        a {
            margin-left:5px;
            color: #026823;
        }

        .button {
            width: 100px;	
            font-weight:300;
            vertical-align:top;
            margin:0;
            background-color: #F4F4F4;
            border: #DADADA 1px solid;	
        }
        .button:hover {
            color: #ffffff;
            background: #026823;
            border: #026823 1px solid;	
        }
    }

    #login-form-error { 
        color: #a94442;
        font-weight:600;
        font-size:12px;
        margin-right: 5px;
        margin-top:5px;
        height:15px;
    }

    #login-form .has-error {
        font-weight:300;
        font-size:12px;
        margin-left:5px;
    }

    .login-form-links {
        display:block;
        font-weight:300;
        font-size:12px;
        margin-right:5px;
        margin-bottom:4px;
    }

    .login-form-links-seperator {	
        position:relative;
        display:inline-block;
        margin-left:3px;
        margin-right:3px;	
    }

    #logged-in {
        padding-top:10px;	
        padding-right:10px;
    }

    #logged-in-text {
        position:relative;
        display:inline-block;
        margin-left:5px;
        color: #026823;
        margin-right: 20px;
        font-size:12px;
    }

    #signup-signin-button {
        display: none;
        padding: 10px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        @media(max-width: 768px) {
            display: block;
        }

        .button {
            width:100%;	
            margin:0px;
            height:44px;	
            font-weight:300;	
        }
    }

</style>

<template>
    <div id="auth">
        <div id="login-section">
            <form id="login-form" role="form" class="form-inline" v-if="!isLoggedIn" v-promise-btn="{action: 'submit'}" v-on:submit.prevent="login">
                <div class="login-form-links">
                    <router-link to="/signup">Signup for Str8 Sets</router-link>
                    <div class="login-form-links-seperator">|</div>
                    <router-link to="/forgot-password">Forgot Password?</router-link>
                </div>
                <ValidationProvider name="Email" slim rules="required|email" v-slot="{ errors, classes }">
                    <div class="form-group" :class="classes">
                        <label for="LoginFormUsername" class="sr-only">Email</label>
                        <input v-model.trim="username" placeholder="Email" id="LoginFormUsername" class="form-control" />
                        <span class="help-block">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <ValidationProvider name="Password" slim rules="required" v-slot="{ errors, classes }">
                    <div class="form-group" :class="classes">
                        <label for="LoginFormPassword" class="sr-only">Password</label>
                        <input v-model="password" type="password" placeholder="Password" id="LoginFormPassword" class="form-control" />
                        <span class="help-block">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
                <button type="submit" class="button-medium button">Sign in</button>
                <br />
                <div id="login-form-error" v-show="loginError">{{loginError}}</div>
            </form>
            
            <div id="logged-in" v-if="isLoggedIn">
                <div id="logged-in-text">
                    <p>Welcome to Str8 Sets Tennis!<br/>You are logged in as <b v-if="currentPlayer">{{ currentPlayer.full_name }}</b></p>                		
                </div>
                <button class="button-medium button" v-on:click="logout">Log out</button>
            </div>
        </div>  

        <!-- Sign-up/Sign-in button - for mobile views -->
        <div id="signup-signin-button">
            <div v-if="!isLoggedIn">
                <button id="signup-signin-button-btn" class="button-medium button" v-on:click="toggleSignupMobile">{{ signupMobileText }}</button>
            </div>
            <div v-else>
                <button id="signup-signin-button-btn" class="button-medium button" v-on:click="logout">Log out</button>
            </div>
        </div> 
    </div>    
</template>
