import { EventParticipant } from ".";
import { Transaction } from "@/models/";

export class EventJoinParticipantResponse {
    
    constructor(data) {       
    
        this.transaction = data && data.transaction ? new Transaction(data.transaction) : null;
        this.participant = data && data.participant ? new EventParticipant(data.participant) : null;
    }
}