import { ClubMembershipType } from '../club/club-membership-type';
import { Player } from '../player';
import { Thread } from '.';
import moment from 'moment';
import Utilities from '@/utils/utilities';

export class MessageGroup {
	constructor(data) {
	
		this.id = data.id;
		this.created = data && data.created ? data.created : undefined;
		this.modified = data && data.modified ? data.modified : undefined;
		this.ttype = data && data.ttype ? data.ttype : undefined;
		this.name = data && data.name ? data.name : undefined;
		this.num_players = data && data.num_players ? data.num_players : 0;
		this.allow_response = data && data.allow_response !== undefined ? data.allow_response : false;
		this.thread = data && data.thread ? new Thread(data.thread) : null;

		// just a club id
		this.club = data && data.club ? data.club : undefined;

		// membership type set
        this.membership_type_set = [];
		if(data && data.membership_type_set) {
			this.membership_type_set = data.membership_type_set.map((item) => new ClubMembershipType(item) );
		}

		// player set
        this.player_set = [];
		if(data && data.player_set) {
			this.player_set = data.player_set.map((item) => new Player(item) );
		}	
	}

	validate() {

        if(!this.name)
            return false;

        return true;
	}

	// Return true if this is the default group
	get is_default_group() {
		return this.ttype == "D";
	}

	// Return test to represent the definition of the message group
	// i.e. All Members, Memb.Type, Custom
	get defined_by() {
		if (this.ttype === undefined) return "";
		switch(this.ttype) {
			case "P":
				// Defined by player_set
			  	return "Custom";
			case "M":
				// Defined by membership type set
			  	return "Memb. Type";
			case "D":
				// Default Group
				return "All Members";
			case "C":
				// Clinic
				return "Clnic";
			default:
			  	return "";
		  } 
	}
	get defined_by_long() {
		if (this.ttype === undefined) return "";
		switch(this.ttype) {
			case "P":
				// Defined by player_set
			  	return "Custom Selection of Players";
			case "M":
				// Defined by membership type set
			  	return "Membership Types";
			case "C":
				// Clinic
				return "Clnic";
			case "D":
				// Default Group
				return "All Members";
			default:
			  	return "";
		  } 
	}

	// Return true if group is defined by membership type
	// otherwise false
	get is_defined_by_membership_type() {
		return this.ttype == "M";
	}

	// Return true if the group is defined by clinic
	// otherwise false
	get is_defined_by_clinic() {
		return this.ttype == "C";
	}

	// Return a string to indicate the number of group constituents
	get group_consituent_description() {
		if (this.num_players == 1) {
			return this.num_players + " Group Member";
		}
		return this.num_players + " Group Members";
	}

	// Return the date the group was created 
	// formatted as mmm dd yyyy
	get created_date_formatted() {
		if (this.created === undefined) return "";
		const formattedDate = Utilities.formatDateToDateString(this.created);
		return formattedDate;
	}

	// Return a string to represent a one-line summary of the message group
	// e.g. "Created Aug 08 20021 | Defined by: Membership Types | 465 Group Members | Recipients can respond"
	get edit_definition() {
		const createdFormatted = "Created: " + this.created_date_formatted;
		const definedBy = "Defined by: " + this.defined_by_long;
		const groupConstituents = this.group_consituent_description;

		let respond = "Recipients can respond";
		if (!this.allow_response) respond = "Recipients cannot respond";

		return createdFormatted + " | " + definedBy + " | " + groupConstituents + " | " + respond; 
	}

	// Return a list of player Ids from the player set (Player)
	// This is used when we send the group to the server for post/patch
	get return_player_set_ids() {
		const ids = [];
		this.player_set.forEach(p => ids.push(p.id));
		return ids;
	}

	// Return a list of membership type Ids from the membership type set (Membership Type)
	// This is used when we send the group to the server for post/patch
	get return_membership_type_set_ids() {
		const ids = [];
		this.membership_type_set.forEach(m => ids.push(m.id));
		return ids;
	}

	// Return the last activity date
	get last_activity_date() {
		if (this.thread == null || !this.thread.last_activity) {
			// no thread or comments, let's use the created date
			return this.created;
		}
		return this.thread.last_activity;
	}

	// Return a humanised string representing the time since the most recent comment	
	get last_activity_from_now() {
		// server time stamps are UTC. We convert to local 
		const dateUTC = moment.utc(this.last_activity_date);
		return moment(dateUTC).local().fromNow();
	}

	// Return true if the thread contains unread comments
	has_unread_comments(newComments) {
		if (!newComments 
			|| !newComments.playerthreads
			|| newComments.playerthreads.length == 0) return false;

		// Cycle through the player threads looking for this thread
		// If found, means there are unread comments
		const pt = newComments.playerthreads.find(p => p.thread.id === this.thread.id);
		return pt != null;
	}
}
